import { Box, Button, Checkbox, CircularProgress, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Link, List, ListItem, ListItemText, Step, StepConnector, StepIconProps, StepLabel, Stepper, styled, Tooltip, Typography } from '@mui/material';
import '../DataPipeline/dataPipeline.scss';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as UploadIcon } from '../../assets/cloud-upload.svg';
import { ReactComponent as DownloadIcon } from '../../assets/dp-download.svg';
import { ReactComponent as InfoIcon } from '../../assets/dp-info.svg';
import { ReactComponent as TickIcon } from '../../assets/tick-icon-dp.svg';
import { ReactComponent as ImportIcon } from '../../assets/dp-upload-icon.svg';
import { ReactComponent as WarningIcon } from '../../assets/warning-dp-job.svg';
import { ReactComponent as SuccessTicketIcon } from '../../assets/icon-vector.svg';
import { useDropzone } from "react-dropzone";
import TeradataIcon from '../../assets/icon-teradata.svg';
import DDHIcon from '../../assets/icon-ddh.svg';
import PowerBiIcon from '../../assets/PowerBI.svg';
import { useState, useEffect, useMemo, ReactElement, useCallback } from 'react';
import { BorderBottom, Height } from '@mui/icons-material';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import { Column } from 'react-data-grid';
import { bulkJobAdditionDp } from 'service/service';
import RowTypography from 'components/Table/RowTypography';
import { invalid } from 'moment';


export const JobBulkUpload = ({ setBulkUploadModal, setSelectedRows, addSchedulesToProject, addProjectLoading, successMsg, getAddedScheduleList }: any) => {

  const [loading, setLoading] = useState({
    downloadSampleFile: false,
    fetchData: false
  })
  const [activeStep, setActiveStep] = useState(0)
  const [jobValidity, setJobValidity] = useState({
    valid: 0,
    inValid: 0
  })
  const [isJobValid, setIsJobValid] = useState(true);
  const [isJobAdded, setIsJobAdded] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [jobData, setJobData] = useState<any>([])
  const [validJobData, setValidJobData] = useState<any>([])
  const [inValidJobData, setInValidJobData] = useState<any>([])
  const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
  const [filterInfo, setFilterInfo] = useState([]);
  const [filterOptions, setFilterOptions] = useState<any>({});
  const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
  const [page, setPage] = useState('1');
  const [pageSize, setPageSize] = useState('100');
  //  const [steps, setSteps] = useState([{
  //   label: 'Upload File',
  //   active: true,
  //   completed: false
  //  },
  //  {
  //   label: '',
  //   active: true,
  //   completed: false
  //  },]
  // )

  const DragDropFileUpload = ({ onDropFiles }: any) => {
    const onDrop = useCallback((acceptedFiles: any) => {
      console.log("Files dropped:", acceptedFiles);
      if (onDropFiles) onDropFiles(acceptedFiles);
    }, [onDropFiles]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: {
        "text/csv": [".csv"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
        "application/vnd.ms-excel": [".xls"],
      },
      multiple: false,
    });
    return (
      <Box
        {...getRootProps()}

      >
        <div style={{ width: '740px', height: '290px', borderRadius: '4px', border: '1px dashed rgba(113, 117, 143, 1)', display: 'flex', alignItems: "center", justifyContent: "center" }}>
          <UploadIcon style={{ width: '170px', height: '130px' }} />
          {
            !isDragActive ? <Typography style={{ margin: '0px 10px' }}>Drop .xlsx or .csv or </Typography> : <Typography style={{ margin: '0px 10px' }}>Drop Here.. </Typography>
          }

          <Button
            component="label"

            variant="text"
            tabIndex={-1}

          >
            <Typography style={{ cursor: 'pointer', color: 'rgba(0, 155, 216, 1)', textDecoration: 'underline' }}>browse from here <VisuallyHiddenInput
              type="file"
              accept='.csv, .xlsx'
              onChange={(event) => onFileUpload(event)}


            /></Typography>
          </Button>

        </div>
      </Box>
    );
  };


  const steps = ['Upload File', 'Confirm & Add'];

  const StyledStepIcon = styled('div')(({ active, completed }: { active: boolean, completed?: boolean }) => ({
    width: '34px',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: active || completed ? 'rgba(0, 155, 216, 1)' : 'rgba(113, 117, 143, 1)',
    color: 'rgba(255, 255, 255, 1)',
    fontWeight: 'bold',
    transition: 'all 0.3s ease',
  }));

  const StyledLabel = styled(Typography)(({ active, completed }: { active: boolean, completed?: boolean }) => ({
    fontSize: '1rem',
    fontWeight: 'bold',
    color: active ? 'rgba(255, 255, 255, 1)' : completed ? 'rgba(0, 155, 216, 1)' : 'rgba(113, 117, 143, 1)',
    transition: 'all 0.3s ease',
  }));

  // const StyledDataGrid = styled(CustomizedDataGrid)({
  //   '& .MuiDataGrid-cell.invalid-cell-dp-bulk': {

  //     backgroundColor: 'rgba(255, 162, 0, 0.15) !important'
  //   },
  // });

  const StyledConnector = styled(StepConnector)(({ completed }: { completed: boolean }) => ({
    '&.MuiStepConnector-root': {
      top: 22, // Align the line with step icon
    },
    '& .MuiStepConnector-line': {
      borderColor: completed ? 'rgba(0, 155, 216, 1)' : 'rgba(113, 117, 143, 1)',
      borderTopWidth: '4px',
    },
    '&.Mui-active .MuiStepConnector-line': {
      borderColor: completed ? 'rgba(0, 155, 216, 1)' : 'rgba(113, 117, 143, 1)', // Line color for active steps
    },
    '&.Mui-completed .MuiStepConnector-line': {
      borderColor: 'rgba(0, 155, 216, 1)'// Line color for completed steps
    },
  }));

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleClose = () => {
    setBulkUploadModal(false)
    isJobAdded && getAddedScheduleList()
  }


  const convertToCSV = (data: any) => {
    const headers = Object.keys(data[0]).join(","); // Extract headers
    const rows = data.map((row: any) => Object.values(row).join(",")); // Extract rows
    return [headers, ...rows].join("\n"); // Combine headers and rows
  };

  // Function to handle the download
  const handleSamplFileDownload = () => {
    // Example data
    const data = [
      { Platform: 'Teradata', Scheduler: "Autosys", Job: "Job 1" },
      { Platform: 'Teradata', Scheduler: "Control-M", Job: "Job 2" },

    ];
    const csvData = convertToCSV(data); // Convert data to CSV format
    const blob = new Blob([csvData], { type: "text/csv" }); // Create a Blob
    const url = URL.createObjectURL(blob); // Create a URL for the Blob

    // Create a temporary link and trigger download
    const link = document.createElement("a");
    link.href = url;
    link.download = "sample.csv"; // Set the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the temporary link
  };

  let requiredData = []

  const onFileUpload = async (event: any) => {
    console.log("FEREEE")
    if (!event?.target?.files[0] && !event[0]) {
      setIsJobValid(false)
      return
    }
    console.log("IIIII")
    const formData = new FormData();
    setLoading({
      ...loading,
      fetchData: true
    })
    console.log("AAAAAAAAAA")
    formData.append('file', event?.target?.files[0] || event[0]);
    console.log("BBBBBBBBBBB")
    let response = await bulkJobAdditionDp(formData)
    if (response.success) {
      setJobData(response.data)
      let inValidData = response.data.filter((obj: any) => !obj.isValid)
      requiredData = response.data.filter((obj: any) => obj.isValid)

      let jobObj: any = []
      requiredData.map((obj: any) => {
        jobObj.push({
          jobName: obj.Job,
          jobId: obj.jobId,
          Scheduler: obj.Scheduler,
          cyclic: obj.cyclic,
          Platform: obj.Platform,
          platformId: obj.platformId
        })
      })
      setSelectedRows(jobObj)
      setInValidJobData(inValidData)
      setValidJobData(requiredData)
      if (inValidData.length) {
        setIsJobValid(false)
      } else {
        setIsJobValid(true)
      }
      setJobValidity({
        inValid: inValidData.length ? inValidData.length : 0,
        valid: inValidData.length ? response.data.length - inValidData.length : response.data.length
      })
    }
    setLoading({
      ...loading,
      fetchData: false
    })

    setActiveStep(1)
  }

  const handleValidCheck = (e: any) => {

    setIsChecked(!isChecked)
    if (e.target.checked) {
      setJobData(validJobData)
    } else {
      let rqData = validJobData.concat(inValidJobData)
      setJobData(rqData)
    }
  }

  const defaultColumnNames: any = ['Jobs', 'Platform', 'Scheduler']

  interface Row {
    Job: string,
    Platform: string,
    Scheduler: string,
    isValid: boolean
  }

  function getColumns(): Array<Column<Row>> {
    return [
      {
        key: 'Job',
        name: 'Jobs',
        cellClass: (row) => (row.isValid ? "" : "invalid-cell-dp-bulk"),
        formatter({ row }) {
          return (

            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <RowTypography>{row.Job ? row.Job : '--'}</RowTypography>
              {!row.isValid && <WarningIcon />}
            </Box>
          );
        },
        width: 335
      },
      {
        key: 'Platform',
        name: 'Platform',
        formatter({ row }) {
          return (
            <RowTypography>
              {row.Platform == 'Teradata' || row.Platform == 'BODS' ? <img src={TeradataIcon} style={{ width: '18px', verticalAlign: 'middle', marginRight: '5px' }} /> : row.Platform == 'DDH' ? <img src={DDHIcon} style={{ width: '18px', verticalAlign: 'middle', marginRight: '5px' }} /> : ''}
              {row.Platform == 'Teradata' ? 'Teradata'
                : row.Platform == 'BODS' ? 'BODS'
                  : row.Platform}
            </RowTypography>
            // <RowTypography>{row.Platform ? row.Platform : '--'}</RowTypography>
          );
        },
        width: 335
      },
      {
        key: 'Scheduler',
        name: 'Scheduler',
        formatter({ row }) {
          return (
            <RowTypography>{row.Scheduler ? row.Scheduler : '--'}</RowTypography>
          );
        },
        width: 335
      },

    ];
  }

  const handleAddProject = async () => {
    await addSchedulesToProject()
    setIsJobAdded(true)
    setIsJobValid(true)
    setActiveStep(2)
  }

  const points = [
    "Metadata for those jobs that are missing can be updated in an excel, or csv file and then uploaded all together.",
    "Ensure that you always input accurate data.",
    "Incorrect entries may not be accurately recorded.",
  ];

  const BulletList = () => {
    return (
      <Box sx={{ minWidth: '50rem', p: 3 }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          Follow the instruction to import a file
        </Typography>
        <List>
          {points.map((item: string, index: number) => (
            <ListItem key={index} sx={{ pl: 0 }}>
              <ListItemText primary={`• ${item}`} />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };


  const handleSorting = (sortingInfo: any) => {
    console.log("SORTTT", sortingInfo)
    let key = sortingInfo['sortBy']
    let data = jobData
    if (sortingInfo.sortDir == 'asc') {
      data.sort((a: any, b: any) => a[key] && b[key] ? a[key].localeCompare(b[key]) : a.Job.localeCompare(b.Job))
    } else {
      data.sort((a: any, b: any) => a[key] && b[key] ? b[key].localeCompare(a[key]) : b.Job.localeCompare(a.Job))
    }
    console.log("JOBBBB", data)
    setJobData(data)
    setSortingInfo(sortingInfo)
  }



  return (
    <>
      <DialogTitle bgcolor="primary.main" sx={{ p: '8px 16px !important' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="widgetTitle" flexGrow={1}>
            Bulk Upload
          </Typography>


          <Stepper activeStep={activeStep} style={{ width: '350px', padding: '5px' }} connector={<StyledConnector completed={activeStep > 0 ? true : false} />}>
            {steps.map((label, index) => {
              const labelProps: {
                optional?: React.ReactNode;
                error?: boolean;
                StepIconProps?: {
                  className?: string;
                };
              } = {};
              const stepProps: { completed?: boolean } = {};
              const isActive = index === activeStep;



              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} StepIconComponent={(props: any) => (
                    activeStep <= index ? (
                      <StyledStepIcon active={props.active ? true : false}>{index + 1}</StyledStepIcon>
                    ) : (
                      <StyledStepIcon active={props.active ? true : false} completed>
                        <TickIcon />
                      </StyledStepIcon>
                    )
                  )}> <StyledLabel active={isActive} completed={activeStep > index}>{label}</StyledLabel>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <IconButton onClick={(e) => handleClose()} sx={{ color: 'white.main' }}>
            <CloseIcon />
          </IconButton>

        </Box>
      </DialogTitle>

      {
        !isJobValid && <Box sx={{
          display: "flex",
          alignItems: "center",
          height: "5vh",
          backgroundColor: 'rgba(255, 162, 0, 0.15)',
          padding: '0px 20px'
        }}>

          <Typography style={{
            color: 'rgba(255, 162, 0, 1)', fontSize: '13px', fontWeight: '500', display: "flex",
            alignItems: "center"
          }}><WarningIcon style={{ marginRight: '5px' }} /> {activeStep == 0 ? 'Sorry, we are unable to read file, please use sample file type and try again.' : `Unable to read ${jobValidity.inValid} ${jobValidity.inValid > 1 ? ' Records' : ' Record'} due to errors ${jobValidity.valid ? `& ${jobValidity.valid} ${jobValidity.valid > 1 ? ' Records' : ' Record'} is already part of the Project` : ''}. Those records can not be added into the Project.`}</Typography>

        </Box>
      }
      <DialogContent >
        {
          loading.fetchData ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",

              }}
            >
              <CircularProgress
                size={80}
                thickness={4}
                sx={{
                  color: "rgba(169, 173, 203, 1)",
                }}
              />
              <Typography style={{ color: 'rgba(169, 173, 203, 1)', width: "20rem", textAlign: 'center', fontWeight: '600', fontSize: '24px', marginTop: '30px' }}>Capturing the results.
                Please wait for a while...</Typography>
            </Box>
          ) :
            activeStep == 0 && !loading.fetchData ? (
              <>
                <Grid container spacing={2}>
                  <Grid item style={{ paddingTop: '40px' }} xs={12}>
                    <Box sx={{ position: 'relative', height: '22px', marginBottom: '5px', '&:before': { backgroundColor: 'rgba(0, 155, 216, 1)', content: '""', height: '1px', left: 0, position: 'absolute', top: '50%', transform: 'translateY(0)', width: '100%' } }}><Typography variant="pageTitle" color="dark.main" style={{ backgroundColor: '#001e3e', color: 'rgba(0, 155, 216, 1)', display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: '600', paddingRight: '20px', position: 'absolute', zIndex: 2 }}>Upload a File to Import <Tooltip title={<BulletList />} arrow componentsProps={{
                      tooltip: {
                        sx: {
                          maxWidth: '50rem', // Adjust width here

                        },
                      },
                    }}>
                      <InfoIcon style={{ height: '20px', width: '20px', marginLeft: '5px' }} />
                    </Tooltip></Typography></Box>
                  </Grid>
                  <Box width='100%' padding='20px 0px' display="flex" alignItems="center" justifyContent="center">

                    <DragDropFileUpload onDropFiles={onFileUpload} />
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  <Button
                    variant="pepwiseSecondaryOutline2"
                    onClick={handleSamplFileDownload}
                    sx={{ width: '270px', color: 'rgba(0, 155, 216, 1)', borderColor: 'rgba(0, 155, 216, 1)' }}
                  >
                    <DownloadIcon style={{ marginRight: '10px', marginBottom: '3px' }} />	Download Sample Files
                  </Button>
                </Grid></>
            ) : activeStep == 1 && !loading.fetchData ? (

              <Grid container spacing={2}>
                <Grid item style={{ paddingTop: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} xs={12}>
                  <Grid item xs={9}>
                    <Box sx={{ position: 'relative', height: '22px', marginBottom: '5px', '&:before': { backgroundColor: 'rgba(0, 155, 216, 1)', content: '""', height: '1px', left: 0, position: 'absolute', top: '50%', transform: 'translateY(0)', width: '100%' } }}>
                      <Typography variant="pageTitle" color="dark.main" style={{ backgroundColor: '#001e3e', color: 'rgba(0, 155, 216, 1)', display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: '600', paddingRight: '20px', position: 'absolute', zIndex: 2 }}>Records Review</Typography>
                    </Box>
                  </Grid>

                  <Grid item style={{ marginLeft: '10px' }} xs={3}>
                    <FormControlLabel style={{ marginRight: '0px' }} control={<Checkbox checked={isChecked} onChange={(e: any) => handleValidCheck(e)} />} label="Show valid records only" />
                  </Grid>


                </Grid>
                <Grid item xs={12} style={{ padding: '10px 20px 0px 20px' }}>
                  <CustomizedDataGrid

                    tableColumns={getColumns()}
                    tableData={jobData}
                    tableType="job-bulk-upload"
                    defaultColumnNames={defaultColumnNames}
                    totalElements={5}
                    onSorting={handleSorting}
                    currSorting={sortingInfo}
                    filterDataOptions={filterOptions}
                    // onFilterCheck={handleFilter}
                    currFilter={filterInfo}
                    // searchDataValues={searchDetails}
                    // onFilterSearch={handleSearch}
                    isRenderPagination={false}
                    // listLength={workspaceSpentList.length}
                    // handlepageSizeChange={handlepageSizeChange}
                    // handlePageChange={handlePageChange}
                    isDateRangeFilter={false}
                    isDisabled={false}
                    style={{ height: '30vh' }}
                  />
                </Grid>
                {
                  jobData.length ? <Grid item xs={12}>
                    <Typography style={{ color: 'rgba(113, 117, 143, 1)', fontStyle: 'italic', fontSize: '14px', fontWeight: '500' }}>Showing 1-{jobData.length} of {jobData.length} results</Typography>
                  </Grid> : <></>
                }
                <Grid item xs={12} style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                  <Grid>

                    <Button
                      component="label"
                      variant="pepwiseSecondaryOutline2"
                      sx={{ width: '200px', color: 'rgba(0, 155, 216, 1)', borderColor: 'rgba(0, 155, 216, 1)' }}
                      tabIndex={-1}

                    >
                      <ImportIcon style={{ marginRight: '10px', marginBottom: '3px' }} /> Re-upload File <VisuallyHiddenInput
                        type="file"
                        accept='.csv, .xlsx'
                        onChange={onFileUpload}

                      />
                    </Button>


                  </Grid>
                  <Grid>
                    <Button
                      variant="text"
                      onClick={handleClose}
                      style={{ color: 'white' }}
                      sx={{ width: '100px', color: 'white' }}
                    >
                      CANCEL
                    </Button>
                    <Button
                      variant="pepwiseSecondary"
                      // disabled={true}
                      onClick={handleAddProject}
                      disabled={!validJobData.length ? true : false}
                      style={{ color: 'white' }}
                      sx={{ width: '270px' }}
                    >
                      ADD JOBS TO PROJECT
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

            ) : activeStep == 2 ? (


              addProjectLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",

                  }}
                >
                  <CircularProgress
                    size={80}
                    thickness={4}
                    sx={{
                      color: "rgba(169, 173, 203, 1)",
                    }}
                  />
                  <Typography style={{ color: 'rgba(169, 173, 203, 1)', width: "20rem", textAlign: 'center', fontWeight: '600', fontSize: '24px', marginTop: '30px' }}>Loading...</Typography>
                </Box>
              ) : (
                <Box sx={{ p: 4, textAlign: 'center' }}>

                  {successMsg !== 'Jobs were already added!' ? (
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={1.5}>
                          <Box sx={{ width: '65px', height: '65px', '& svg': { maxWidth: '100%' } }}><SuccessTicketIcon /></Box>
                        </Grid>
                        <Grid item xs={10}>
                          <Box sx={{ textAlign: 'left', mt: 1, fontSize: '28px', fontWeight: '500' }}>
                            {validJobData.length} {validJobData.length > 1 ? 'Jobs' : 'Job'} added successfully in the Project.</Box>
                          <Box sx={{ textAlign: 'left', mt: 1, fontSize: '18px', fontWeight: '400' }}>Would you like to add more?</Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : <Typography sx={{ textAlign: 'center', mb: 2 }}>{successMsg}</Typography>}

                  {(successMsg == 'Jobs were already added!' || successMsg == 'Record added successfully') && (
                    <Box sx={{ textAlign: 'right', mt: 3 }}>
                      <Button
                        variant="pepwiseOutline"
                        onClick={handleClose}
                        sx={{
                          color: 'info.main'
                        }}
                      >
                        SAVE & EXIT
                      </Button>
                      <Button onClick={() => setActiveStep(0)} variant="pepwiseSecondary">
                        ADD MORE
                      </Button>
                    </Box>
                  )
                    // : (
                    //   <Box sx={{ textAlign: 'right', mt: 2 }}>
                    //     <Button
                    //       variant="pepwiseOutline"
                    //       onClick={handleClose}
                    //       sx={{
                    //         color: 'info.main'
                    //       }}
                    //     >
                    //       Close
                    //     </Button>
                    //   </Box>
                    // )
                  }
                </Box>
              )

              // <Box sx={{ textAlign: 'right', mt: 3 }}>
              //   <Button
              //     variant="pepwiseOutline"
              //     onClick={handleClose}
              //     sx={{
              //       color: 'info.main'
              //     }}
              //   >
              //     SAVE & EXIT
              //   </Button>
              //   <Button onClick={() => setActiveStep(0)} variant="pepwiseSecondary">
              //     ADD MORE
              //   </Button>
              // </Box >
            ) : (
              <></>
            )
        }
      </DialogContent >


    </>
  );
}