import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Tab,
	Tabs,
	Typography
} from '@mui/material';
import {
	getConfigDetails,
	getAlertKpiProgramsSectors,
	saveAlertConfig,
	updateAlertConfig
} from 'service/service';
import Loader from 'components/Loader/loader';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import { useParams } from 'react-router-dom';
import SuccessConfirmationDialog from './SuccessModal';
import './alert-kpi.scss';
import GroupingAlertTab from './GroupingAlertTab';
import { SilencesTab } from './SilencesTab';
import { VariablesTab } from './VariablesTab';
import { ChannelsTab } from './ChannelsTab';
import { SourceTab } from './SourceTab';
import ErrorDialog from './ErrorModal';

export interface VariableRow {
	isEnabled: boolean;
	type: string;
	name: string;
	value: string;
	identifier: string;
	uuid: string;
}

export interface SilenceRow {
	isEnabled: boolean;
	name: string;
	startTime : string;
	endTime: string;
	silencedChannels : Array<string>;
	message: string;
	uuid: string;
}

export interface ChannelRow {
	emailRecipients: any;
	emailSubject: string;
	htmlTemplate: any;
	payload: any;
	title: string;
	bodyTemplate: string;
	webhookUrl: string;
	templateName: string;
	status: string;
	channel: string;
}

const CreateAlertFramework = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [loading, setLoading] = useState(false);
	const [channels, setChannels] = useState<any>({});
	const { id: configId } = useParams<{ id: string }>();
	const [alertId, setAlertId] = useState(configId || null);
	const [variables, setVariables] = useState<VariableRow[]>([]);
	const [maintenanceWindows, setMaintenanceWindows] = useState<any>([])
	const [groupingAlertsConfig, setGroupingAlertsConfig] = useState({})
	const [openDialogSuccess, setOpenDialogSuccess] = useState(false)
	const [openErrorDialog, setOpenErrorDialog] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [programOptions, setProgramOptions] = useState([{ title: '', value: '' }]);
	const [sectorOptions, setSectorOptions] = useState([{ title: '', value: '' }]);
	
	const [formData, setFormData] = useState<any>({
		program: '',
		project: '',
		sector: '',
		ruleName: '',
		alertType: '',
		sourceConfig: { sourceType: '', query: '' },
		emailConfig: { isEnabled: false, emailSubject: '', htmlTemplate: '', emailRecipients: [] },
		serviceNowConfig: { isEnabled: false, payload: {}, serviceNowUrl: '' },
		teamsConfig: { isEnabled: false, title: '', bodyTemplate: '', webhookUrl: '' },
		isCreate: false
	});

	const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	const handleSaveNext = async () => {
		if (!isFormValid()) {
			alert("Please fill in all mandatory fields.");
			return;
		} else {
			const response = await saveConfig();
			if(response.statusCode === 200){
				if (activeTab === 4) {
					if(!configId){
						setOpenDialogSuccess(true); 
					}
				}else{
					setActiveTab(activeTab+1);
				}
			}else{
				setErrorMessage(response.data.message)
				setOpenErrorDialog(true)
			}
		}				
	};

	const handleCancel = async () => {
		window.location.href = '/alertFramework/alert';
	};


	const saveConfig = async () => {
		setLoading(true);
		if (alertId) {
			const configData = formData;
			const emailConfig = channels['Email'];
			if (emailConfig) {
				configData.emailConfig = emailConfig;
			} else if(configData.emailConfig){
				configData.emailConfig = {}
			}
			const serviceNowConfig = channels['Service Now'];
			if (serviceNowConfig) {
				configData.serviceNowConfig = serviceNowConfig;
			} else if(configData.serviceNowConfig){
				configData.serviceNowConfig = {}
			}
			const teamsConfig = channels['Teams'];
			if (teamsConfig) {
				configData.teamsConfig = teamsConfig;
			} else if(configData.teamsConfig){
				configData.teamsConfig = {}
			}
			if (!configId) {
				configData.isCreate = true;
			}
			configData.variables = variables
			configData.maintenanceWindows = maintenanceWindows
			configData.groupingAlertsConfig = groupingAlertsConfig
			const result = await updateAlertConfig(alertId, configData);
			setLoading(false);
			return result
		} else {
			const requestBody = {
				program: formData.program,
				project: formData.project,
				sector: formData.sector,
				ruleName: formData.ruleName,
				alertType: formData.alertType,
				sourceConfig: formData.sourceConfig
			}
			const response = await saveAlertConfig(requestBody);
			setLoading(false);
			if (response.data?._id) {
				setAlertId(response.data._id);
			}
			return response
		}		
	};

	const isFormValid = () => {
		const mandatoryFieldsValid = 
			formData.program &&
			formData.project &&
			formData.sector &&
			formData.ruleName &&
			formData.alertType &&
			formData.sourceConfig.sourceType &&
			formData.sourceConfig.query;
	
		return mandatoryFieldsValid;
	};

	const fetchData = async () => {
		setLoading(true);
		const result: any = await getAlertKpiProgramsSectors();
        if(result.data){
            setProgramOptions(result.data.programs)
            setSectorOptions(result.data.sectors)
        }
		setLoading(false);
	};

	const fetchConfig = async () => {
		setLoading(true)
		const response:any = await getConfigDetails(configId, {})
		if(response.data?.alertConfig){
			const alertConfig = response.data.alertConfig
			setFormData({
				program: alertConfig.program,
				project: alertConfig.project,
				sector: alertConfig.sector,
				ruleName: alertConfig.ruleName,
				alertType: alertConfig.alertType,
				sourceConfig: alertConfig.sourceConfig,
				emailConfig: alertConfig.emailConfig,
				serviceNowConfig: alertConfig.serviceNowConfig,
				teamsConfig: alertConfig.teamsConfig,
				isCreate: false
			})
			setMaintenanceWindows(alertConfig.maintenanceWindows)
			setVariables(alertConfig.variables)
			setGroupingAlertsConfig(alertConfig.groupingAlertsConfig)
			const _channels = {} as any
			if(alertConfig.emailConfig?.emailSubject){
				_channels['Email'] = alertConfig.emailConfig
			}
			if(alertConfig.serviceNowConfig?.payload){
				alertConfig.serviceNowConfig.payload = JSON.stringify(alertConfig.serviceNowConfig.payload)
				_channels['Service Now'] = alertConfig.serviceNowConfig
			}
			if(alertConfig.teamsConfig?.webhookUrl){
				_channels['Teams'] = alertConfig.teamsConfig
			}
			setChannels(_channels)
		}
	}

	useEffect(() => {
		fetchData();
		if(configId){
			fetchConfig();
		}
	}, []);

	return loading ? (
		<Loader />
	) : (
		<Box bgcolor="blue.darker" mt="86px">
			<PageHeader title={[{text: "Alerts", link: '/alertFramework/alert'}, 
				{text: configId ? `Edit alert: ${configId}` : 'Create alert'}]} 
				icon={DashboardIcon} >
				<Typography
					style={{
						color: 'white',
						cursor: 'pointer',
						marginTop: '6px',
						marginRight: '25px'
					}}
					onClick={handleCancel}
				>
					CANCEL
				</Typography>
				<Button variant="pepwiseSecondary" onClick={handleSaveNext}  disabled={!isFormValid()} >
				{activeTab === 4 ? "SAVE" : "SAVE & NEXT"}
				</Button>
				<SuccessConfirmationDialog open={openDialogSuccess} onClose={() => {setOpenDialogSuccess(false); handleCancel()}} />
				<ErrorDialog open={openErrorDialog} onClose={() => {setOpenErrorDialog(false); setErrorMessage('')}} errorMessage={errorMessage}/>
			</PageHeader>
			<Tabs
				value={activeTab}
				onChange={handleTabChange}
				// textColor="inherit"
				// indicatorColor="primary"
				sx={{
					mt: 2,
					'& .MuiTabs-indicator': {
						backgroundColor: 'white !important',
						height: '4px !important'
					},
					'& .MuiTab-root': {
						color: '#F5F5F5 !important',
						textTransform: 'none',
						fontSize: '1.1rem',
						minHeight: '50px',
						height: '50px',
						padding: '12px 16px',
						width: '41vh !important',
						backgroundColor: '#003771 !important'
					},
					'& .MuiTab-root.Mui-selected': {
						color: ' #009BD8 !important',
						backgroundColor: '#001e3e !important' // Highlights selected tab
					}
				}}
			>
				<Tab label="Alert Source*" />
				<Tab label="Delivery Channels*" />
				<Tab label="Variables/Labels" />
				<Tab label="Silences" />
				<Tab label="Grouped Alerts" />
			</Tabs>

			{/* Form Section */}
			{activeTab === 0 && (
				<SourceTab sourceData={formData} setSourceData={setFormData} programOptions={programOptions} sectorOptions={sectorOptions}/>
			)}
			{activeTab === 1 && (
				<ChannelsTab channels={channels} setChannels={setChannels}/>
			)}
			{activeTab === 2 && (
				<VariablesTab variables={variables} setVariables={setVariables}/>
			)}
			{activeTab === 3 && (
				<SilencesTab maintenanceWindows={maintenanceWindows} setMaintenanceWindows={setMaintenanceWindows}/>
			)}
			{activeTab === 4 && (
				<GroupingAlertTab groupingAlertsConfig={groupingAlertsConfig} setGroupingAlertsConfig={setGroupingAlertsConfig}></GroupingAlertTab>
			)}
		</Box>
	);
};

export default CreateAlertFramework;
