// import Header from './Header';
// import Footer from '../LandingPage/LandingFooter';
import '../LandingPage/LandingPage.scss';
import {  Grid } from '@mui/material';
import PlatformCostLogo from '../../assets/ri_money-dollar-circle-fill.svg';

import CustomDashboardIcon from '../../assets/Custom Dashboard_Small.svg';
import { useEffect, useState, lazy, Suspense } from 'react';
// import { getDecodedToken, role, capabilities } from '../../service/service';
import KPILogo from '../../assets/kpistore.svg';
import landingBackArrow from '../../assets/landingBackArrow.svg';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';

// Lazy load components
const Header = lazy(() => import('./Header'));
const Footer = lazy(() => import('../LandingPage/ModuleLandingFooter'));
const capabilitiesNew : any = localStorage.getItem('capabilities');

export default function AlertKpiLanding() {
	const [memberData, setMemberData] = useState<any>(null);
	const [controller, _] = usePepwiseAuthController();
	const { accessToken, capabilities } = controller;
	const capabilitiesArray = capabilitiesNew ? capabilitiesNew.split(',') : [];
	

	// to display data
	useEffect(() => {
		// console.log(moment(sd).diff(end, 'days'))
		// const tokenData = getDecodedToken();
		// console.log('token', accessToken);
		setMemberData(accessToken);
	}, []);

	//navigate to data-pipeline observability
	const handleDataPipelineClick = (event: any, type: string, url: string) => {
		event.preventDefault(); // Prevent the default context menu from showing up
		if (type === 'blank') {
			// Open the URL in a new tab if type is 'blank'
			window.open(url, '_blank');
		} else {
			// Navigate to the provided URL in the current tab
			window.location.href = url;
		}
	};

	const handleBackClick = () => {
		window.location.href = '/LandingPage';
	};

	return (
		<div className="outerLandingPageContainer">
			<Header memberData={memberData} />
			<div style={{ maxWidth: '86%', margin: '0 auto' }}>
				<div className="landingMessage-container">
					<img src={landingBackArrow} alt="CCLogo" className="landingMessageImage" onClick={handleBackClick} />
					<p className="landingMessage">{`Data KPI and Alerts`}</p>
				</div>

				<div className="welcome_text">
					<p>Click on the module below to monitor!</p>
				</div>
				<Grid container spacing={1} style={{ marginTop: '0px' }}>
						<Grid
							item
							xs={4}
							onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/kpiFramework/configs')}
							onContextMenu={(e) => {
								e.preventDefault();
								handleDataPipelineClick(e, 'blank', '/kpiFramework/configs');
							}}
						>
							<div className="observalbility_screens" style={{ textAlign: 'center' }}>
								<div className="title">
									<img
										src={KPILogo}
										alt="CCLogo"
										className="obs_logo"
										style={{ display: 'block', margin: '0 auto' }}
									/>
								</div>
								<div>
									<h3 className="obsTitle" style={{ margin: '10px 0' }}>
                                      KPI Store
									</h3>
								</div>
								<p
									className="message1"
									title="Stay ahead with live monitoring of critical data KPIs."
									style={{ margin: '0', textAlign: 'center' }}
								>
									Stay ahead with live monitoring of critical data KPIs.
								</p>
							</div>
						</Grid>
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/alertFramework/alert')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/alertFramework/alert');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={CustomDashboardIcon}
									alt="tableauLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
                                    Alert Framework
								</h3>
							</div>
							<p
								className="message1"
								title="Detect issues before they impact business with near real-time notifications."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Detect issues before they impact business with near real-time notifications.
							</p>
						</div>
					</Grid>
				</Grid>
			</div>
			<Footer />
		</div>
	);
}
