import moment from 'moment-timezone';

export async function exportToXlsx(fileName: string, tableData: any, tableColumns: any) {
	const [{ utils, writeFile, write  }, { head, body }] = await Promise.all([
		import('xlsx'),
		getGridContent(tableData, tableColumns)
	]);
	const wb = utils.book_new();
	const ws = utils.aoa_to_sheet([...head, ...body]);
	const wscols = [];
	for (let i = 0; i < head[0].length; i++) {
		wscols.push({ wch: parseInt(head[0][i].length) + 10 });
	}
	ws['!cols'] = wscols;
	utils.book_append_sheet(wb, ws, 'Sheet 1');
	// 	writeFile(wb, fileName);

	// SaveAs dialogue
	const wbout = write(wb, { bookType: 'xlsx', type: 'array' });
    if ('showSaveFilePicker' in window) {
        try {
            const fileHandle = await (window as any).showSaveFilePicker({
                suggestedName: fileName,
                types: [{ description: 'Excel File', accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] } }]
            });
            const writable = await fileHandle.createWritable();
            await writable.write(new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            await writable.close();
        } catch (error) {
            console.error("File save error:", error);
        }
    } else {
        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }
}

function getColorTextRepresentation(trendData: string) {
    if (!trendData) return '';

    const colorMap: { [key: string]: string } = {
        '1': "Success",
        '2': "Failed",
        '3': "In Progress",
        '4': "Long Running",
        '5': "Delayed",
		'6': "--",
        '7': "Scheduled"
    };

    return trendData.split('').map(char => colorMap[char] || char).join(' ');
}

const getHrsMin = (totalMinutes: number) => {
	totalMinutes = totalMinutes / 60;
	const days = Math.floor(totalMinutes / (24 * 60));
	const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
	const minutes = totalMinutes % 60;

	// Format the result
	let result = '';
	if (days > 0) result += `${days} day${days > 1 ? 's' : ''} `;
	if (hours > 0) result += `${hours} hr${hours > 1 ? 's' : ''} `;
	if (minutes > 0) result += `${minutes.toFixed(2)} min`;

	let time = result.trim()
	return time
}

async function getGridContent(tableData: any, tableColumns: any) {
	const extractedColumns = [tableColumns.map((x: any) => x.name)];
	const columnKeys = tableColumns.map((x: any) => x.key);
	const extractedRows = tableData.map((x: any) => {
		const row: any = [];
		columnKeys.forEach((key: any) => {
			switch (key) {
				case 'runStartTime':
				case 'runEndTime':
				case 'createdAt':
				case 'deletedAt':
				case 'lastSuccessRun':
					if (x[key]) row.push(moment(x[key]).format());
					else row.push('');
					break;
				case 'Sectors':
					if (x[key]) row.push(x[key].split(', ').join(', '));
					else row.push('--');
					break;
				case 'IP_Delayed_Start':
					if (x[key]) row.push(x[key]);
					else row.push('--');
					break;
				case 'IP_Long_Running':
					if (x[key]) row.push(x[key]);
					else row.push('--');
					break;
				case 'Failed':
					if (x[key]) row.push(x[key]);
					else row.push('--');
					break;
				case 'Success':
					if (x[key]) row.push(x[key]);
					else row.push('--');
					break;
				case 'SLA_Time':
					if (x['SLA_Date'] != null && x['SLA_Date'] > 0) {
						const scheduleDate = x['Schedule_Date'] ? x['Schedule_Date'].split('T')[0] : '';
						row.push(moment(scheduleDate).add(x['SLA_Date'], 'days').format('MM-DD-YY'));
					} else {
						const scheduleDate = x['Schedule_Date'] ? x['Schedule_Date'].split('T')[0] : '';
						row.push(moment(scheduleDate).format('MM-DD-YY'));
					}

					if (x['SLA_Time']) {
						const slaTime = x['SLA_Time'].toString().padStart(4, '0').replace(/(.{2})$/, ':$1');
						row[row.length - 1] += ` ${slaTime}`;
					} else {
						row[row.length - 1] += ' --';
					}
					break;
				case 'Actual_Completion_Time':
					if (x[key]) {
						const [datePart, timePart] = x[key].split('T');
						const [year, month, day] = datePart.split('-');
						const [hour, minute, secondWithMs] = timePart.split(':');
						const [second] = secondWithMs.split('.');
						row.push(`${month}-${day}-${year} ${hour}:${minute}:${second}`);
					} else {
						row.push('--');
					}
					break;
				case 'oDate':
					if (x['orderDate']) row.push(moment(x['orderDate']).format());
					else row.push('');
					break;
				case 'platformId':
					row.push(x[key]== 1 ? 'Teradata' : '');
					break;
				case 'isCyclic':
					row.push(x[key]== '0' ? 'No' : x[key] == '1' ? 'Yes' : x[key]);
					break;
				case 'criticalFlag':
					row.push(x[key] ? 'Yes' : 'No');
					break;
				case 'execStatusTrend':
                    row.push(getColorTextRepresentation(x[key])); // Convert trend colors to text
                    break;
				case 'actualStartTime':
					if (x[key]) row.push(moment(x[key]).tz('America/Chicago').format('MM-DD-YY HH:mm:ss'));
					else row.push('--');
					break;
				case 'actualEndTime':
					if (x[key]) row.push(moment(x[key]).tz('America/Chicago').format('MM-DD-YY HH:mm:ss'));
					else row.push('--');
					break;
				case 'avgRunTime':
					if (x[key]) row.push(getHrsMin(Number(x[key])));
					else row.push('--');
					break;
				case 'totalExecutions':
					row.push(
						x['jobStatus'] === "Not Scheduled" || x['jobStatus'] === "Scheduled" 
							? 0 
							: x[key] 
								? x[key] 
								: '--'
					);
					break;
				default:
					row.push(x[key]);
			}
		});
		return row;
	});

	return {
		head: extractedColumns,
		body: extractedRows
	};
}
