import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import ReactECharts from "echarts-for-react";

interface SegmentedBarChartProps {
  title: string;
  data: {
    technology: string;
    values: { label: string; value: number }[];
  }[];
  options: any;
}

const SegmentedBarChart: React.FC<SegmentedBarChartProps> = ({ title, data, options }) => {
  return (
    <Box sx={{ borderRadius: '5px', backgroundColor: '#003771' }} p={2}>
      <Box sx={{ backgroundColor: '#003771' }}>
        <Typography variant="body1" fontWeight={600} sx={{ marginBottom: 2 }}>
          {title}
        </Typography>
        <Box sx={{ width: "100%", height: 350 }}>
          <ReactECharts option={options} style={{ width: "100%", height: "100%" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default SegmentedBarChart;
