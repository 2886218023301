import { dashboardUrl } from '../../service/config';
import { useState, useEffect, useMemo } from 'react';
import { Select, MenuItem, FormControl, SelectChangeEvent, TextField, InputLabel } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import '../DataPipeline/dataPipeline.scss';
import '../DataEstate/data-estate.scss';
import { getCustomDashboard } from '../../service/service';
import CustomDashboardIcon from '../../assets/Custom_Dashboard.svg';
import useStyles from '../landingScreen/headerStyles';
import Loader from '../Loader/loader';
import AnalyticsVisualization from 'components/framework/analyticsVisualization';
import SearchIcon from '@mui/icons-material/Search';
import { useRef } from 'react';
import RightArrowIcon from '../../assets/ic_round-keyboard-arrow-right.svg';
import DownArrowIcon from '../../assets/down-arrow-icon.svg';

interface Dashboard {
	Dashboard_Url: string;
	Custom_Dashboard_Id: string;
	Dashboard_Name: string;
	Market: string;
}

const CustomDashboard = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { programName } = useParams<{ programName: string }>();
	const { marketName } = useParams<{ marketName: string }>();

	const [loading, setLoading] = useState(true);
	const [dashboardList, setDashboardList] = useState<Dashboard[]>([]);
	const [filteredDashboardList, setFilteredDashboardList] = useState<Dashboard[]>([]);
	const [searchText, setSearchText] = useState('');
	const [selectedDashboard, setSelectedDashboard] = useState<Dashboard | null>(null);
	const [dashboardUrlState, setDashboardUrlState] = useState<string>('');
	const [open, setOpen] = useState(false);
	const searchInputRef = useRef<HTMLInputElement | null>(null);

	const handleProgramClick = () => {
		const normalizedProgramName = (programName || '').toLowerCase().trim();
		const programMappings: { [key: string]: string } = {
			mdip: 'mdip-landing page',
			ibp: 'home page',
			mosaic: 'mosaic-landing page'
		};

		const dashboardKey = programMappings[normalizedProgramName] || '';
		const matchedUrl = dashboardKey ? dashboardUrlMappings[dashboardKey] : '';

		// const mappedDashboard = dashboardList.find((dashboard) => dashboard.Dashboard_Name.toLowerCase() === dashboardKey);

		if (matchedUrl) {
			// setSelectedDashboard(mappedDashboard);
			setDashboardUrlState(matchedUrl);
		}
	};

	useEffect(() => {
		const activeId = document.getElementById('root');
		console.log(window.location.pathname);
		if (window.location.pathname.includes('/data-pipeline/programOps')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
	}, []);

	useEffect(() => {
		const fetchDashboardData = async () => {
			setLoading(true);
			try {
				const tags = programName ? encodeURIComponent(programName) : '';
				const result = await getCustomDashboard({ tags });
				let dashboards: Dashboard[] = result?.data?.list || [];

				if (marketName) {
					dashboards = dashboards.filter((dashboard) =>
						dashboard.Market.toLowerCase().includes(marketName.toLowerCase())
					);
				}

				setDashboardList(dashboards);
				setFilteredDashboardList(dashboards);

				if (dashboards.length > 0) {
					const ibpPageDashboard = dashboards.find((d) => d.Dashboard_Name.toLowerCase() === 'ibp - home page');
					const mdipPageDashboard = dashboards.find((d) => d.Dashboard_Name.toLowerCase() === 'mdip-landing page');
					const mosaicPageDashboard = dashboards.find((d) => d.Dashboard_Name.toLowerCase() === 'mosaic-landing page');

					const initialDashboard =
						programName?.toLowerCase().includes('ibp') && ibpPageDashboard
							? ibpPageDashboard
							: programName?.toLowerCase().includes('mdip') && mdipPageDashboard
							? mdipPageDashboard
							: programName?.toLowerCase().includes('mosaic') && mosaicPageDashboard
							? mosaicPageDashboard
							: dashboards[0];

					if (initialDashboard?.Custom_Dashboard_Id !== selectedDashboard?.Custom_Dashboard_Id) {
						setSelectedDashboard(initialDashboard);
					}
				}
			} catch (error) {
				console.error('Error fetching dashboard data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchDashboardData();
	}, [programName, marketName]);

	useEffect(() => {
		if (!selectedDashboard) return;

		const dashboardKey = selectedDashboard.Dashboard_Name.toLowerCase();
		const matchedKey = Object.keys(dashboardUrlMappings).find((key) => dashboardKey.includes(key));

		const newUrl = matchedKey ? dashboardUrlMappings[matchedKey] : dashboardUrl.MDIPLandingPage;

		if (newUrl !== dashboardUrlState) {
			setDashboardUrlState(newUrl);
		}
	}, [selectedDashboard]);

	useEffect(() => {
		const filtered = dashboardList.filter((dashboard) =>
			dashboard.Dashboard_Name.toLowerCase().includes(searchText.toLowerCase())
		);
		setFilteredDashboardList(filtered);
	}, [searchText, dashboardList]);

	const handleDashboardChange = (event: SelectChangeEvent<string>) => {
		const selectedDashboardId = event.target.value;

		const selectedDashboardUrl =
			dashboardList.find((d) => d.Custom_Dashboard_Id === selectedDashboardId)?.Dashboard_Url || '';

		if (selectedDashboardUrl) {
			setDashboardUrlState(selectedDashboardUrl);
		}
	};

	const dashboardUrlMappings: { [key: string]: string } = {
		'thailand mvs & gtm landing': dashboardUrl.MDIPLandingPage,
		'thailand mvs & gtm gold': dashboardUrl.MDIPGoldPage,
		'thailand mvs & gtm detailed': dashboardUrl.MDIPDetailedPage,
		timeseries: dashboardUrl.IBPGDOTimeseries,
		turkey: dashboardUrl.IBPTurkeyGDOTimeseries,
		vietnam: dashboardUrl.MDIPVIETNAM,
		pmf: dashboardUrl.MDIPPMF,
		'activity details': dashboardUrl.IBPGDOActivityDetail,
		'error board': dashboardUrl.IBPGDOErrorBoard,
		'is&oe board': dashboardUrl.IBPGDOIsoe,
		'main page': dashboardUrl.IBPGDOMainPage,
		'gdo status': dashboardUrl.IBPGDOStatus,
		'home page': dashboardUrl.IBPGDOHomePage,
		servicenow: dashboardUrl.IBPServiceNow,
		'gdo metric': dashboardUrl.IBPMetric,
		'taiwan gtm detailed': dashboardUrl.MDIPTAIWANGTMDETAILED,
		mt2dc: dashboardUrl.MDIPMT2DC,
		'panorama dashboard': dashboardUrl.MOSAIC,
		'synapse analytics dashboard': dashboardUrl.MOSAIC,
		'premosaic synapse analytics dashboard': dashboardUrl.MOSAIC,
		'e2e reconciliation': dashboardUrl.MOSAIC,
		'data quality monitoring dashboard': dashboardUrl.MOSAIC,
		'manual file monitoring report global': dashboardUrl.MOSAIC,
		'mdip-landing page': dashboardUrl.MDIPGeneralLanding,
		'mosaic-landing page': dashboardUrl.MosaicGeneralLanding
	};

	const analyticsVisualizationComponent = useMemo(() => {
		console.log('Rendering AnalyticsVisualization with URL:', dashboardUrlState);
		return (
			<AnalyticsVisualization
				params={{
					dashboardUrl: dashboardUrlState,
					className: 'datapipelineCustomDashboardContainer',
					otherParams: '',
					seamless: true
				}}
			/>
		);
	}, [dashboardUrlState]);

	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			<div className="breadcrumb-custom" style={{ position: 'absolute', marginTop: '-33px' }}>
				<span className="job-menu" onClick={(e) => e.stopPropagation()} style={{ cursor: 'pointer' }}>
					<img
						src={CustomDashboardIcon}
						className={classes.jobIcon}
						style={{ marginTop: '-5px' }}
						alt="Custom Dashboards Icon"
					/>
					<span className="titleLink" onClick={() => navigate('/data-pipeline/programOpsDashboard')}>
						Program Ops Dashboards
					</span>
					<img src={RightArrowIcon} style={{ position: 'absolute' }} />
					{marketName && (
						<>
							<span
								className="titleLink"
								onClick={() => navigate(`/data-pipeline/programOps/${programName}/markets`)}
								style={{ paddingLeft: '22px' }}
							>
								{marketName}
							</span>
							<img src={RightArrowIcon} style={{ position: 'absolute' }} />
						</>
					)}
					<FormControl className="w-64">
						<Select
							className={classes.selectPadding}
							value={selectedDashboard?.Custom_Dashboard_Id || ''}
							onChange={handleDashboardChange}
							open={open}
							onOpen={() => setOpen(true)}
							onClose={(event) => {
								if (searchInputRef.current && searchInputRef.current.contains(event.target as Node)) {
									return;
								}
								setSearchText('');
								setOpen(false);
							}}
							displayEmpty
							IconComponent={() => null}
							MenuProps={{
								PaperProps: {
									style: { maxHeight: 300, overflowY: 'auto' },
									onMouseDown: (e: any) => e.stopPropagation()
								}
							}}
							renderValue={() => (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										width: '100%'
									}}
								>
									<span
										onMouseDown={(e) => e.stopPropagation()}
										onClick={(e) => {
											e.stopPropagation();
											handleProgramClick();
										}}
										style={{ cursor: 'pointer', flexGrow: 1, fontSize: '20px', fontWeight: 600 }}
									>
										{marketName ? marketName : programName || 'Select Dashboard'}
									</span>

									<img
										src={DownArrowIcon}
										style={{ cursor: 'pointer', height: '25px' }}
										onClick={(e) => {
											e.stopPropagation();
											setOpen((prev) => !prev);
										}}
									/>
								</div>
							)}
						>
							<MenuItem disableRipple>
								<TextField
									inputRef={searchInputRef}
									fullWidth
									autoFocus
									placeholder="Search"
									variant="outlined"
									value={searchText}
									onChange={(e) => {
										setSearchText(e.target.value);
										setOpen(true);
									}}
									onKeyDown={(e) => e.stopPropagation()}
									onMouseDown={(e) => e.stopPropagation()}
									onClick={(e) => e.stopPropagation()}
									InputProps={{
										startAdornment: <SearchIcon style={{ marginRight: 8 }} />
									}}
								/>
							</MenuItem>
							{filteredDashboardList.map((dashboard) => {
								const dashboardKey = dashboard.Dashboard_Name.toLowerCase();
								const matchedKey = Object.keys(dashboardUrlMappings).find((key) => dashboardKey.includes(key));
								const dashboardLink = matchedKey ? dashboardUrlMappings[matchedKey] : dashboardUrl.MDIPLandingPage;
								// const isSelected = selectedDashboard?.Custom_Dashboard_Id === dashboard.Custom_Dashboard_Id;

								return (
									<MenuItem
										key={dashboard.Custom_Dashboard_Id}
										onClick={(event) => {
											event.preventDefault();
											setSelectedDashboard(dashboard);
											setDashboardUrlState(dashboardLink);
											setSearchText('');
											setOpen(false);
										}}
										component="div"
										// style={{
										// 	backgroundColor: isSelected ? '#009BD8' : 'transparent'
										// }}
									>
										<a
											href={dashboardLink}
											target="_blank"
											rel="noopener noreferrer"
											style={{
												textDecoration: 'none',
												color: 'inherit',
												display: 'block',
												width: '100%',
												height: '100%',
												padding: '8px 16px'
											}}
											onClick={(event) => {
												if (!event.ctrlKey && !event.metaKey && event.button === 0) {
													event.preventDefault();
													setSelectedDashboard(dashboard);
													setDashboardUrlState(dashboardLink);
													setSearchText('');
													setOpen(false);
												}
											}}
										>
											{dashboard.Dashboard_Name}
										</a>
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</span>
			</div>

			<div className="program-list-container">{loading ? <Loader /> : analyticsVisualizationComponent}</div>
		</div>
	);
};

export default CustomDashboard;
