import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	Grid,
	MenuItem,
	Select,
	IconButton,
	Typography,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
	Autocomplete
} from '@mui/material';
import 'components/AlertKpi/alert-kpi.scss';
import PageHeader from 'components/Header/Page';
import KPIIcon from '../../assets/kpi-white.svg';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import { checkRole } from 'utils/roleUtils';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { getKPIFilterOptions, getKpiFrameworkConfigList } from 'service/service';
import Loader from 'components/Loader/loader';
import { KPIConfigList } from './kpiConfigList';
import DialogueModal from 'components/Modal/DialogModal';
import { KPIForm } from './kpiForm';
import SuccessConfirmationDialog from './SuccessModal';
import moment from 'moment';

const KPIFramework = () => {
	const [filterData, setFilterData] = useState<any>({})
	const [loading, setLoading] = useState(false)
	const [kpiConfigs, setKpiConfigs] = useState([])
	const [count, setCount] = useState(0)
	const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [showCreateModal, setShowCreateModal] = useState(false);
    const [openDialogSuccess, setOpenDialogSuccess] = useState(false)
	const [controller] = usePepwiseAuthController();
	const { role } = controller;
	const allFilters = ['Program','Project','Sector','KPI Name','Status']
	const filterOptions :any = {
		'Program': { columnName: 'program', data: filterData.programs || []},
		'Project': { columnName: 'project', data: filterData.projects || []},
		'Sector': { columnName: 'sector', data: filterData.sectors || []},
		'KPI Name':  { columnName: 'name'},
		'Status': { columnName: 'isActive', data: ['Active','Inactive']},
	}
	const [filters, setFilters] = useState([
		{ uuid: crypto.randomUUID(), field: 'Program', value: '' }
	]);

	const isAllowed = checkRole(role, ['Data Operations Admin', 'PepWise System User']);

	const handleAddFilter = () => {
		setFilters([...filters, { uuid: crypto.randomUUID(), field: '', value: '' }]);
	};

	const handleRemoveFilter = (uuid: string) => {
		setFilters(filters.filter((filter: any) => filter.uuid !== uuid));
	};

	const handleFilterChange = (uuid: string, name: string, value: string|null) => {
		setFilters(filters.map((filter:any) => {
			if(filter.uuid === uuid){
				filter[name] = value
			}
			return filter
		}))
	}

	const handleSorting = (newSortingInfo: any) => {
		setSortingInfo(newSortingInfo);
		fetchKPIConfigs({ ...defaultBody, sortColumn: newSortingInfo.sortBy, sortDir: newSortingInfo.sortDir });
	};
    
	const defaultBody = {page: 1, pageSize: 10, sortColumn: sortingInfo.sortBy , sortDir: sortingInfo.sortDir}

	const fetchKPIConfigs = async ({page, pageSize, sortColumn, sortDir} :any, clearFilters = false) => {
		const filterObj = clearFilters ? {} : filters.filter(fl => !!fl.value).reduce((filObj:any, fil) => {
			const columnName = filterOptions[fil.field].columnName
			if(columnName === 'isActive'){
				filObj[columnName] = fil.value === 'Active' ? true : false
			}else{
				filObj[columnName] = fil.value
			}
			return filObj
		}, {})
		setLoading(true)
		const response = await getKpiFrameworkConfigList({filter: filterObj, page, pageSize, sortColumn: sortColumn === 'createdDt' ? 'createdAt' : sortColumn, sortDir})
		setLoading(false)
		if(response?.data){
			response.data.data = response.data.data.map((item:any) => {
				item.update_frequency = `${item.update_frequency_value} ${item.update_frequency_unit}`
				item.source_type = item.source_config ? item.source_config.type : ''
				item.createdDt = item.createdAt ? moment(item.createdAt).format('yyyy-MM-DD HH:mm') : ''
				item.createdByUser = item.createdByUser?.[0] || ''
				item.updatedByUser = item.updatedByUser?.[0] || ''
				return item
			})
			setKpiConfigs(response.data.data)
			setCount(response.data.totalCount)
		}
	}

	const fetchOptions = async () => {
		const response = await getKPIFilterOptions()
		if(response.data){
			setFilterData(response.data)
		}
	}

	const clearFilters = () => {
		setFilters([{ uuid: crypto.randomUUID(), field: 'Program', value: '' }])
		fetchKPIConfigs(defaultBody, true)
	}

	useEffect(() => {
		fetchKPIConfigs(defaultBody)
		fetchOptions()
	},[])

	return (
		<Box bgcolor="blue.darker" mt='45px'>
			
			<PageHeader title="KPIs" icon={KPIIcon}>
				{isAllowed && (
					<Button variant="pepwiseSecondary" onClick={() => setShowCreateModal(true)} sx={{ height: '40px' }}>
						+ Add KPI Config
					</Button>
				)}
			</PageHeader>
			<DialogueModal  width='xl' title='Add KPI List' isOpen={showCreateModal} 
				handleClose={(ev, reason?: string) => {
					if(reason !== 'backdropClick'){
						setShowCreateModal(false)
					}
				}}>
				<KPIForm onCancel={() => setShowCreateModal(false)} 
					onCreateKPI={() => {setShowCreateModal(false); setOpenDialogSuccess(true) ;fetchKPIConfigs(defaultBody)}}/>
			</DialogueModal>
			<SuccessConfirmationDialog open={openDialogSuccess} onClose={() => {setOpenDialogSuccess(false)}} />

			<Box sx={{ background: '#003771', margin: 2,  padding: 2, borderRadius: 2 }}>
				<Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
					<Typography color="white" variant="h6" gutterBottom>
						Filters
					</Typography>
					<Box display="flex" gap={2}>
						<Button variant="outlined" color='secondary' sx={{ color: '#009BD8', border: '009BD8' }} onClick={clearFilters}>
							CLEAR
						</Button>
						<Button variant="outlined" color='secondary' sx={{ color: '#009BD8', border: '009BD8' }} onClick={fetchKPIConfigs}>
							RUN QUERY
						</Button>
					</Box>
				</Box>
				<hr style={{ width: '100%', margin: '8px 10px', borderColor: '#009BD8' }} />

				<Grid container spacing={2} alignItems="center">
					<Grid container item spacing={2} alignItems="center">
						<Grid item xs={5.5}>
							<Typography color="white">Field</Typography>
						</Grid>
						<Grid item xs={5}>
							<Typography color="white">Values</Typography>
						</Grid>
						<Grid item xs={1.5}>
							<Button
								variant="contained"
								color="primary"
								startIcon={<AddIcon />}
								onClick={handleAddFilter}
								sx={{ mt: 2 }}
							>
								Add Clause
							</Button>
						</Grid>
					</Grid>
					{filters.map((filter) => (
						<Grid container item spacing={2} alignItems="center" key={filter.uuid}>
							<Grid item xs={5.5}>
								<Autocomplete
									id="size-small-outlined"
									size="small"
									value={filter.field || ''}
									onChange={(e, option) => handleFilterChange(filter.uuid, 'field', option)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Select type"
											variant="outlined"											
											sx={{
												// width: 300,
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: '#A9ADCB'
													},
													'&:hover fieldset': {
														borderColor: '#A9ADCB'
													},
													'&.Mui-focused fieldset': {
														borderColor: '#A9ADCB'
													}
												}
											}}
										/>
									)}
									options={allFilters.filter((option) => !filters.map(f => f.field).includes(option))}
								/>									
							</Grid>
							{!!filter.field && 
							<Grid item xs={5.5}>
								{filterOptions[filter.field]?.data ? 
								<Autocomplete
									id="size-small-outlined"
									size="small"
									value={filter.value}
									onChange={(e, option) => handleFilterChange(filter.uuid, 'value', option)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Select value"
											variant="outlined"
											sx={{
												// width: 300,
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: '#A9ADCB'
													},
													'&:hover fieldset': {
														borderColor: '#A9ADCB'
													},
													'&.Mui-focused fieldset': {
														borderColor: '#A9ADCB'
													}
												}
											}}
										/>
									)}
									options={filterOptions[filter.field]?.data || []}
								/> :
								<TextField
									label="Enter value"
									variant="outlined"
									onChange={(e) => handleFilterChange(filter.uuid, 'value', e.target.value)}
									sx={{
										width: '100%',
										'& .MuiOutlinedInput-root': {
											'& fieldset': {
												borderColor: '#A9ADCB'
											},
											'&:hover fieldset': {
												borderColor: '#A9ADCB'
											},
											'&.Mui-focused fieldset': {
												borderColor: '#A9ADCB'
											}
										},
										'& .MuiOutlinedInput-input': {
											padding: '8px 14px'
										}
									}}
								/>}
							</Grid>}
							<Grid item xs={1}>
								<IconButton onClick={() => handleRemoveFilter(filter.uuid)} color="error">
									<DeleteIcon />
								</IconButton>
								<IconButton onClick={handleAddFilter} color="error">
									<AddOutlinedIcon />
								</IconButton>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Box>
			{loading ? <Loader/> : 
            <KPIConfigList kpiConfigs={kpiConfigs} totalCount={count} updateConfigs={fetchKPIConfigs} 
                page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} sortingInfo = {sortingInfo} 
                setSortingInfo= {setSortingInfo} handleSorting = {handleSorting}/>}
		</Box>
	);
};

export default KPIFramework;
