import React, { useState } from 'react';
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from '@mui/material';

const ChannelModal = ({ channelOptions, handleClose, onAddChannel, selectedChannel }: any) => {
	const [channelType, setChannelType] = useState(
		selectedChannel ? selectedChannel.channel : channelOptions.length ? channelOptions[0] : ''
	);
	const [channelConfig, setChannelConfig] = useState<any>(selectedChannel ?? {});
	const [errorText, setErrorText] = useState<string|null>(null);

	const handleChange = (event: any) => {
		setChannelType(event.target.value);
		setChannelConfig({});
	};

    const checkMandatoryFields = () => {
        if((channelType === 'Email' && (!channelConfig.templateName || !channelConfig.emailSubject || !channelConfig.emailRecipients || !channelConfig.htmlTemplate)) || 
        (channelType === 'Service Now' && (!channelConfig.templateName || !channelConfig.payload )) ||
        (channelType === 'Teams' && (!channelConfig.templateName || !channelConfig.title || !channelConfig.bodyTemplate || !channelConfig.webhookUrl))){
            setErrorText('Please fill up all required fields.')
            return false
        }
        return true
    }

	const handleSaveChannel = () => {
        const isValid = checkMandatoryFields()
        if(!isValid) return
		if (channelType === 'Email') {
			const emailRecipientsString = typeof channelConfig.emailRecipients === 'string' 
				? channelConfig.emailRecipients 
				: "";
	
			const emailRecipientsArray = emailRecipientsString
				.split(',')
				.map((email: string) => email.trim())
				.filter((email: string) => email.length > 0);
			onAddChannel(channelType, { ...channelConfig, emailRecipients: emailRecipientsArray, isEnabled: true });
		} else {
			onAddChannel(channelType, { ...channelConfig, isEnabled: true });
		}
	};

	const handleSourceConfigChange = ({ name, value }: any) => {
        setErrorText(null)
		setChannelConfig({ ...channelConfig, [name]: value });
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
			<FormControl fullWidth required>
				<FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' }, textAlign: 'left' }}>
					Select Channel
				</FormLabel>
				<RadioGroup row name="channelType" value={channelType} onChange={handleChange}>
					{selectedChannel ? (
						<FormControlLabel
							value={selectedChannel.channel}
							control={<Radio variant="pepwiseRadio" />}
							label={selectedChannel.channel}
						/>
					) : (
						channelOptions.map((channel: string) => (
							<FormControlLabel
								key={channel}
								value={channel}
								control={<Radio variant="pepwiseRadio" />}
								label={channel}
							/>
						))
					)}
				</RadioGroup>
			</FormControl>

			{channelType === 'Email' ? (
				<>
                    <div style={{display: 'flex', flexDirection: 'row', gap: 16}}>
                        <FormControl>
                            <TextField
                                label="Template name*"
                                variant="outlined"
                                size="small"
                                value={channelConfig.templateName}
                                onChange={(e) => handleSourceConfigChange({ name: 'templateName', value: e.target.value })}
                                sx={{
                                    width: 300,
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#66B2FF'
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#66B2FF'
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#66B2FF'
                                        }
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <TextField
                                label="Email Subject*"
                                variant="outlined"
                                size="small"
                                value={channelConfig.emailSubject}
                                onChange={(e) => handleSourceConfigChange({ name: 'emailSubject', value: e.target.value })}
                                sx={{
                                    width: 412,
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#66B2FF'
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#66B2FF'
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#66B2FF'
                                        }
                                    }
                                }}
                            />
                        </FormControl>
                    </div>
					<FormControl required>
                        <FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' }, textAlign: 'left' }}>
                            Email Body
                        </FormLabel>
						<TextField
							fullWidth
							multiline
							rows={5}
							variant="outlined"
							value={channelConfig.htmlTemplate}
							placeholder="Email <body>"
							onChange={(e) => handleSourceConfigChange({ name: 'htmlTemplate', value: e.target.value })}
							InputProps={{
								sx: {
									width: 730,
									marginTop: 2,
									color: 'white',
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: '#1E3A5F'
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: '#3A506B'
									},
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#66B2FF'
									}
								}
							}}
						/>
					</FormControl>
					<FormControl>
						<TextField
							label="Select Recipient Emails or DL*"
							variant="outlined"
							size="small"
							value={channelConfig.emailRecipients}
							onChange={(e) => handleSourceConfigChange({ name: 'emailRecipients', value: e.target.value })}
							sx={{
								width: 730,
								marginTop: 2,
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderColor: '#66B2FF'
									},
									'&:hover fieldset': {
										borderColor: '#66B2FF'
									},
									'&.Mui-focused fieldset': {
										borderColor: '#66B2FF'
									}
								}
							}}
						/>
					</FormControl>
				</>
			) : channelType === 'Service Now' ? (
				// Fields for Service Now
				<>
					<FormControl>
						<TextField
							label="Template name*"
							variant="outlined"
							size="small"
							value={channelConfig.templateName}
							onChange={(e) => handleSourceConfigChange({ name: 'templateName', value: e.target.value })}
							sx={{
								width: 300,
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderColor: '#66B2FF'
									},
									'&:hover fieldset': {
										borderColor: '#66B2FF'
									},
									'&.Mui-focused fieldset': {
										borderColor: '#66B2FF'
									}
								}
							}}
						/>
					</FormControl>
					<FormControl required>
                        <FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' }, textAlign: 'left' }}>
                             Payload
                        </FormLabel>
						<TextField
							fullWidth
							multiline
							rows={5}
							variant="outlined"
							placeholder="Payload"
							value={channelConfig.payload}
							onChange={(e) => handleSourceConfigChange({ name: 'payload', value: e.target.value })}
							InputProps={{
								sx: {
									width: 730,
									marginTop: 2,
									color: 'white',
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: '#1E3A5F'
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: '#3A506B'
									},
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#66B2FF'
									}
								}
							}}
						/>
					</FormControl>
					<FormControl>
						<TextField
							label="Service Now URL"
							variant="outlined"
							size="small"
							value={channelConfig.serviceNowUrl}
							onChange={(e) => handleSourceConfigChange({ name: 'serviceNowUrl', value: e.target.value })}
							sx={{
								width: 730,
								marginTop: 2,
								'& .MuiOutlinedInput-root': {
									'& fieldset': {
										borderColor: '#66B2FF'
									},
									'&:hover fieldset': {
										borderColor: '#66B2FF'
									},
									'&.Mui-focused fieldset': {
										borderColor: '#66B2FF'
									}
								}
							}}
						/>
					</FormControl>
				</>
			) : (
				// Default fields
				<>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
						<FormControl>
							<TextField
								label="Template name*"
								variant="outlined"
								size="small"
								value={channelConfig.templateName}
								onChange={(e) => handleSourceConfigChange({ name: 'templateName', value: e.target.value })}
								sx={{
									width: 300,
									'& .MuiOutlinedInput-root': {
										'& fieldset': { borderColor: '#66B2FF' },
										'&:hover fieldset': { borderColor: '#66B2FF' },
										'&.Mui-focused fieldset': { borderColor: '#66B2FF' }
									}
								}}
							/>
						</FormControl>
						<FormControl>
							<TextField
								label="Title*"
								variant="outlined"
								size="small"
								value={channelConfig.title}
								onChange={(e) => handleSourceConfigChange({ name: 'title', value: e.target.value })}
								sx={{
									width: 300,
									'& .MuiOutlinedInput-root': {
										'& fieldset': { borderColor: '#66B2FF' },
										'&:hover fieldset': { borderColor: '#66B2FF' },
										'&.Mui-focused fieldset': { borderColor: '#66B2FF' }
									}
								}}
							/>
						</FormControl>
					</Box>
					<FormControl required>
                        <FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' }, textAlign: 'left' }}>
                            Message Body
                        </FormLabel>
						<TextField
							fullWidth
							multiline
							rows={5}
							variant="outlined"
							placeholder="Body Template"
							value={channelConfig.bodyTemplate}
							onChange={(e) => handleSourceConfigChange({ name: 'bodyTemplate', value: e.target.value })}
							InputProps={{
								sx: {
									width: 730,
									marginTop: 2,
									color: 'white',
									'& .MuiOutlinedInput-notchedOutline': {
										borderColor: '#1E3A5F'
									},
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: '#3A506B'
									},
									'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
										borderColor: '#66B2FF'
									}
								}
							}}
						/>
					</FormControl>
					<FormControl>
						<TextField
							label="Webhook URL*"
							variant="outlined"
							size="small"
							value={channelConfig.webhookUrl}
							onChange={(e) => handleSourceConfigChange({ name: 'webhookUrl', value: e.target.value })}
							sx={{
								width: 730,
								marginTop: 2,
								'& .MuiOutlinedInput-root': {
									'& fieldset': { borderColor: '#66B2FF' },
									'&:hover fieldset': { borderColor: '#66B2FF' },
									'&.Mui-focused fieldset': { borderColor: '#66B2FF' }
								}
							}}
						/>
					</FormControl>
				</>
			)}

			<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Typography
					style={{
						color: '#880000',
						cursor: 'pointer',
						marginTop: '6px',
						marginRight: '25px'
					}}					
				>
					{errorText ? `*${errorText}` : ''}
				</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Typography
                        style={{
                            color: 'white',
                            cursor: 'pointer',
                            marginTop: '6px',
                            marginRight: '25px'
                        }}
                        onClick={handleClose}
                    >
                        CANCEL
                    </Typography>
                    <Button variant="pepwiseSecondary" onClick={handleSaveChannel}>
                        SAVE
                    </Button>
                </Box>
			</Box>
		</div>
	);
};

export default ChannelModal;
