import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { getCustomDashboard } from 'service/service';
import CustomDashboardIcon from '../../assets/Custom_Dashboard.svg';
import useStyles from '../landingScreen/headerStyles';
import RightArrowIcon from '../../assets/ic_round-keyboard-arrow-right.svg';
import IBPIcon from '../../assets/IBP_small.svg';
import Loader from 'components/Loader/loader';
import DataOpsIcon from '../../assets/DataOps_small.svg';

export default function MarketDashboard() {
	const classes = useStyles();
	const navigate = useNavigate();
	const { programName } = useParams<{ programName: string }>();
	const [markets, setMarkets] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const activeId = document.getElementById('root');
		console.log(window.location.pathname);
		if (window.location.pathname.includes('/data-pipeline/programOps')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
	}, []);

	useEffect(() => {
		async function fetchMarkets() {
			try {
				setLoading(true);
				const result = await getCustomDashboard({ tags: programName });
				const dashboards = result?.data?.list || [];

				const marketSet = new Set<string>();
				dashboards.forEach((dashboard: any) => {
					if (dashboard.Market) {
						marketSet.add(dashboard.Market);
					}
				});

				setMarkets(Array.from(marketSet));
			} catch (err) {
				console.error('Error fetching markets:', err);
				setError('Failed to load markets.');
			} finally {
				setLoading(false);
			}
		}

		fetchMarkets();
	}, [programName]);

	const handleMarketClick = (event: React.MouseEvent, type: string, marketName: string) => {
		event.preventDefault();
		const encodedProgram = encodeURIComponent(programName || '');

		const route = `/data-pipeline/programOps/${encodedProgram}/${marketName}/list`;

		if (type === 'blank') {
			window.open(route, '_blank');
		} else {
			navigate(route);
		}
	};

	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			<div className="breadcrumb" style={{ marginTop: '-50px' }}>
				<img
					src={CustomDashboardIcon}
					className={classes.jobIcon}
					style={{ marginTop: '-5px' }}
					alt="Custom Dashboards Icon"
				/>

				<span
					className="titleLink"
					onClick={() => (window.location.href = '/data-pipeline/programOpsDashboard')}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%',
						cursor: 'pointer',
						whiteSpace: 'nowrap'
					}}
				>
					Program Ops Dashboards
				</span>

				<img src={RightArrowIcon} style={{ margin: '0 8px', height: '16px' }} alt="Arrow Icon" />

				<span
					className="titleLink"
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						width: '100%'
					}}
				>
					<Typography style={{ color: 'white', fontSize: '20px', fontWeight: 600, fontFamily: 'Montserrat !important' }}>
						{programName}
					</Typography>
				</span>
			</div>

			{/* <h2 className="market-header">Markets for {programName}</h2> */}

			{loading ? (
				<div className="loading-container">
					<Loader />
				</div>
			) : (
				<div className="program-list-container">
					<Grid container spacing={1}>
						{markets.map((market) => (
							<Grid
								key={market}
								item
								xs={4}
								style={{ cursor: 'pointer' }}
								onClick={(e) => handleMarketClick(e, 'no-blank', market)}
								onContextMenu={(e) => {
									e.preventDefault();
									handleMarketClick(e, 'blank', market);
								}}
							>
								<div className="programOpsScreens" style={{ marginTop: '25px' }}>
									<div className="title">
										<img
											src={market.includes('IBP') ? IBPIcon : DataOpsIcon}
											alt={`${market} logo`}
											className="obs_logo"
											style={{ maxWidth: '100%', height: 'auto', marginBottom: '8px' }}
										/>
										<h3 className="obsTitle" style={{ margin: '0' }}>
											{market}
										</h3>
									</div>
								</div>
							</Grid>
						))}
					</Grid>
				</div>
			)}
		</div>
	);
}
