import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Chip,
	IconButton,
	Menu,
	MenuItem,
	FormControlLabel,
	Switch,
	Typography
} from '@mui/material';
import { CustomizedDataGrid } from '../customized_data_grid';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import DeleteConfirmationDialog from 'components/AlertKpi/DeleteModal';
import { activateKPIConfig, deactivateKPIConfig, deleteKPIConfig } from 'service/service';
import DialogueModal from 'components/Modal/DialogModal';
import { KPIForm } from './kpiForm';
import { exportToXlsx } from 'components/exportUtils';
import { checkRole } from 'utils/roleUtils';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';

export const KPIConfigList = ({ kpiConfigs, totalCount, updateConfigs, page, setPage, pageSize, setPageSize ,sortingInfo, setSortingInfo, handleSorting}: any) => {
    const [filterData, setFilterData] = useState<any[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any | null>(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [controller] = usePepwiseAuthController();
    const defaultColumns = ['ID', 'KPI Name', 'Program', 'Project', 'Sector', 'Status', 'Frequency', 'Source', 'Last Run', 'Next Run',
         'Created By', 'Updated By', 'Created At', 'Updated At', 'Action'];

    const { role } = controller;

	const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
		setAnchorEl(event.currentTarget);
		setSelectedRow(row);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
    const handleSortingFun = useCallback((sortingInfo: any) => {
        handleSorting({ sortBy: sortingInfo.sortBy, sortDir: sortingInfo.sortDir });
    }, [updateConfigs]);


    const handleToggleStatus = async() => {
		if (selectedRow) {
			if(selectedRow.isActive){
                await deactivateKPIConfig(selectedRow._id)
            }else{
                await activateKPIConfig(selectedRow._id)
            }
            updateConfigs({page, pageSize})
		}        
		handleClose();
	};

    const handleDeleteConfirm = async() => {
        if(selectedRow){
            await deleteKPIConfig(selectedRow._id)
            updateConfigs({page, pageSize})
        }
        setOpenDialog(false);
    };

    const handlePageChange = (data:any, page:any) => {
        setPage(page)
        updateConfigs({page, pageSize})
    }

    const handlePageSizeChange = (event:any) => {
        setPageSize(parseInt(event.target.value))
        updateConfigs({page, pageSize: event.target.value})
    }

    const navigate = useNavigate();
    const isAllowed = checkRole(role, ['Data Operations Admin', 'PepWise System User']);

    function getColumns() {
        const columns = [
            { key: "_id", name: "ID", minWidth: 50, width: 180, sortable: false,
            formatter({ row }: any) {
                return (
                    <Typography
                        sx={{
                            fontSize: 12,
                            color: "blue",
                            cursor: "pointer",
                            textDecoration: "underline",
                            marginTop: '4px'
                        }}
                        onClick={() => navigate(`/kpiFramework/configs/${row._id}`)}
                    >
                        {row._id}
                    </Typography>
                );
            }},
            { key: "name", name: "KPI Name", width: 200, minWidth: 150, sortable: false },
            { key: "program", name: "Program", minWidth: 95, sortable: true, },
            { key: "project", name: "Project", minWidth: 95, sortable: true, },
            { key: "sector", name: "Sector", minWidth: 95, sortable: true, },
            {
                key: "isActive",
                name: "Status",
                minWidth: 50,
                sortable: false,
                formatter({ row }: any) {
                    return (
                        <Chip
                            style={{ minWidth: 64, backgroundColor: row.isActive ? "#01C00826" : "#D82B5133", marginTop: 2 }}
                            label={row.isActive ? "ACTIVE" : "INACTIVE"}
                        />
                    );
                },
            },
            { key: "update_frequency", name: "Frequency", minWidth: 95 },
            { key: "source_type", name: "Source", minWidth: 95 },
            { key: "last_run_time", name: "Last Run", minWidth: 95 ,
                formatter({row}:any){
                    return (row.last_run_time ? moment(row.last_run_time).format('yyyy-MM-DD HH:mm') : '')        
                }
            },
            { key: "next_run_time", name: "Next Run", minWidth: 95 ,
                formatter({row}:any){
                    return (row.next_run_time ? moment(row.next_run_time).format('yyyy-MM-DD HH:mm') : '')        
                }
            },
            { key: "createdByUser", name: "Created By", minWidth: 95,sortable: false },
            { key: "updatedByUser", name: "Updated By", minWidth: 95,sortable: false },
            { key: "createdDt", name: "Created At", minWidth: 95},
            { key: "updatedAt", name: "Updated At", minWidth: 95 ,
                formatter({row}:any){
                    return (row.updatedAt ? moment(row.updatedAt).format('yyyy-MM-DD HH:mm') : '')        
                }
            },            
        ];

        if (isAllowed) {
            columns.push({
                key: "action",
                name: "Action",
                minWidth: 95,  
                sortable: false,
                formatter({ row }: any) {                    
                    const handleDeleteClick = () => { 
                        setOpenDialog(true); 
                        handleClose();
                    };
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                                aria-label="edit"
                                size="small"
                                onClick={() => {
                                    setSelectedRow(row)
                                    setShowCreateModal(true)
                                }}
                                sx={{ color: "#71758F" }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                                
                            <IconButton
                                aria-label="more"
                                size="small"
                                onClick={(event) => handleClick(event, row)}
                                sx={{ color: "#71758F" }}
                            >
                                <MoreVertIcon fontSize="small" />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                sx={{ "& .MuiMenu-paper": { backgroundColor: "#003771 !important", width: "12%" } }}
                            >
                                <MenuItem onClick={handleDeleteClick} sx={{ marginLeft: "25px" }}>
                                    <DeleteIcon />
                                    <span style={{ fontSize: "1.0rem", marginLeft: "6px" }}>Delete</span>
                                </MenuItem>
                                <MenuItem onClick={handleToggleStatus} sx={{ marginLeft: '25px' }}>
                                    <FormControlLabel
                                        control={<Switch checked={selectedRow?.isActive} size="small" color="default" />}
                                        label={selectedRow?.isActive ? 'Deactivate' : 'Activate'}
                                    />
                                </MenuItem>
                            </Menu>
                        </Box>
                    );
                }
            });
        }
    return columns;
}
    
    return (
		<Box sx={{ mt: 3, padding: '20px', borderRadius: '8px' }}>
			<Typography variant="body2" sx={{ opacity: 0.7 }}>
				All KPI Configs
			</Typography>
            <DialogueModal width='xl' title='Add KPI Config' isOpen={showCreateModal} 
                handleClose={(ev, reason?: string) => {
                    if(reason !== 'backdropClick'){
                        setShowCreateModal(false)
                    }
                }}>
                {!!selectedRow && <KPIForm kpiConfig={selectedRow} onCancel={() => setShowCreateModal(false)} 
                    onCreateKPI={() => {setShowCreateModal(false); updateConfigs({page, pageSize})}}/>}
            </DialogueModal>
			<CustomizedDataGrid
				tableColumns={getColumns()}
				tableData={kpiConfigs || []}
				tableType="KPIList"
				defaultColumnNames={defaultColumns}
				filterDataOptions={filterData}
				listLength={totalCount || 0}
				page={page}
				pageSize={pageSize}
				paginationData={{totalElements: totalCount, totalPages: Math.ceil(totalCount / pageSize)}}
				onSorting={handleSortingFun}
				currSorting={sortingInfo}
				// onFilterCheck={handleFilter}
				// currFilter={filterInfo}
				showExport={true}
				fileName={`KPIConfigs-${moment().format()}.xlsx`}
                exportTableData={() => exportToXlsx(`KPIConfigs-${moment().format()}.xlsx`, kpiConfigs, getColumns())}
				// searchDataValues={searchDetails}
				// onFilterSearch={handleSearch}
				// onCellEdit={handleEditCell}
				handlepageSizeChange={handlePageSizeChange}
				handlePageChange={handlePageChange}
				isDateRangeFilter={true}
				isDisabled={false}
			/>
			{/* Delete Confirmation Dialog */}
			<DeleteConfirmationDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onConfirm={handleDeleteConfirm}
				identifierName={selectedRow?.ruleName || ""}
				type={''}
			/>
		</Box>
	);
};
