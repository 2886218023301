import { lazy } from 'react';
import PrivateRoute from 'utils/privateRoute';
import AlertFramework from 'components/AlertKpi/Alertframework';
import CreateAlertFramework from 'components/AlertKpi/createAlert';
import AlertDetails from 'components/AlertKpi/AlertDetail';
import KPIDetails from 'components/KpiFramework/kpiDetails';
import { KPIForm } from 'components/KpiFramework/kpiForm';
import KPIFramework from 'components/KpiFramework/KpiFramework';

// const Dashboard = lazy(() => import('components/dashboard/dashboard'));

const AlertKpiRoutes = [
	{
		key: 'framework_alert',
		route: '/alertFramework/alert',
		component: (
			<PrivateRoute
				Component={AlertFramework}
				restrictedRoles={[
					'Platform Support',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
    {
		key: 'framework_alert',
		route: '/alertFramework/createalert',
		component: (
			<PrivateRoute
				Component={CreateAlertFramework}
				restrictedRoles={[
					'Platform Support',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'framework_alert',
		route: '/alertFramework/:id',
		component: (
			<PrivateRoute
				Component={CreateAlertFramework}
				restrictedRoles={[
					'Platform Support',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'framework_alert',
		route: '/alert-details/:id',
		component: (
			<PrivateRoute
				Component={AlertDetails}
				restrictedRoles={[
					'Platform Support',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'framework_alert',
		route: '/kpiFramework/configs',
		component: (
			<PrivateRoute
				Component={KPIFramework}
				restrictedRoles={[
					'Platform Support',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
    {
		key: 'framework_alert',
		route: '/kpiFramework/configs/:id',
		component: (
			<PrivateRoute
				Component={KPIDetails}
				restrictedRoles={[
					'Platform Support',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
    {
		key: 'framework_alert',
		route: '/kpiFramework/configs/new',
		component: (
			<PrivateRoute
				Component={KPIForm}
				restrictedRoles={[
					'Platform Support',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
];

export default AlertKpiRoutes;
