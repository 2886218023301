import { Box, Checkbox, TableCell, TableRow } from '@mui/material';
import { Add, Clear, FormatIndentIncrease } from '@mui/icons-material';
import { add, move, remove, update } from 'react-querybuilder';
import OutlinedButton from 'components/Button/OutlinedButton';
import CustomDropdown from 'components/Dropdown';
import {
	autocompleteSxProps,
	textfieldSxProps,
	defaultRuleValue,
	dropdownSxProps,
	ruleUnGroupOnRemove,
	ungroupRules
} from './utilities';
import CustomAutocomplete from 'components/Inputs/CustomAutocomplete';
import CustomTextField from 'components/Inputs/CustomTextField';
import AsyncAutoComplete from './AsyncAutoComplete';
import { useRef } from 'react';

const RuleRow = ({
	fields,
	rule,
	query,
	setQuery,
	combinator = null,
	path,
	combinatorPath,
	groupedRules,
	onCheckboxChange,
	isChecked,
	groupPaths,
	handleFieldChange,
	fieldValueOptionsCallback,
	disableBtn
}: any) => {
	const isFirstRule = path.at(-1) === 0;
	const isLastRule = path.at(-1) === groupedRules.length - 1;
	const groupPath = [...path].slice(0, -1);
	const groupPathString = groupPath.join('.');
	const maxGroupLength = Math.max(...groupPaths.current.map((path: any) => path.length));
	const currGroupLengthDiff = maxGroupLength - groupPath.length;
	const subscriptionRef = useRef('');

	const handleChange = (field: any) => {
		const subQuery = query?.rules?.find?.((rule: any) => 
			typeof rule === 'object' && rule?.field === 'Subscription'
		);
		const subscription = query.rules[0]?.field === 'Subscription' ? query.rules[0]?.value : (subQuery ? subQuery.value : '');
		subscriptionRef.current = subscription;
		handleFieldChange(field, subscription);
	};
	
	return (
		<TableRow key={rule.id}>
			<TableCell>
				<Checkbox sx={{ color: 'white.main' }} checked={isChecked} onChange={() => onCheckboxChange(path)} disabled = {disableBtn} />
			</TableCell>
			{!!groupPaths.current.length && (
				<TableCell sx={{ width: '1rem' }}>
					<Box display="flex" columnGap="1.2rem">
						{groupPaths.current.map((gPath: number[], idx: number) => {
							const pathString = gPath.join('.');
							const isGroup = groupPathString.startsWith(pathString);
							const isSameGroup = groupPathString === pathString;
							let isMultiNested = false;
							if (gPath.length < groupPath.length && isGroup) {
								const filledPath = [...gPath];
								while (filledPath.length < groupPath.length) {
									filledPath.push(0);
								}
								isMultiNested = filledPath.join('.') === groupPathString;
							}
							return isGroup && isFirstRule && (isSameGroup || isMultiNested) ? (
								<Box
									key={pathString}
									className="query-rule-row-head-icon"
									color="info.main"
									sx={{ cursor: 'pointer' }}
									onClick={() => {
										setQuery(ungroupRules(query, groupPath));
									}}
									width={20}
								>
									<FormatIndentIncrease />
								</Box>
							) : isGroup ? (
								<Box
									key={pathString}
									width={20}
									sx={{
										position: 'relative',
										// vertical line
										'&::before': {
											content: '""',
											position: 'absolute',
											left: '0.6rem',
											top: isFirstRule && isSameGroup ? '1.9rem' : '-3rem',
											bottom: 0,
											width: '0.14rem',
											backgroundColor: 'info.main'
										},
										...(path.length > 1 &&
											(!isFirstRule || (isFirstRule && !isMultiNested)) && {
												// horizontal line
												'&::after': {
													content: '""',
													position: 'absolute',
													left: `0.60rem`,
													top: '50%',
													height: '0.14rem',
													width: `${(maxGroupLength - gPath.length + 1) * 2.5}rem`,
													backgroundColor: 'info.main'
												}
											})
									}}
								/>
							) : (
								<></>
							);
						})}
					</Box>
				</TableCell>
			)}
			<TableCell>
				<CustomDropdown
					options={['and', 'or'].map((item) => ({
						value: item,
						label: item
					}))}
					handleChange={(e) => {
						setQuery(update(query, 'combinator', e.target.value, combinatorPath));
					}}
					value={combinator ?? ''}
					{...(!combinator && {
						boxProps: {
							visibility: 'hidden'
						}
					})}
					selectComponentProps={{
						className: 'query-rule-row-combinator-dropdown',
						sx: dropdownSxProps,
						disabled: !combinator || disableBtn
					}}
				/>
			</TableCell>

			<TableCell>
				<CustomDropdown
					options={Object.values(fields).map((item: any) => ({
						value: item.name,
						label: item.label
					}))}
					handleChange={(e) => {
						handleChange(e.target.value);
						setQuery(update(query, 'field', e.target.value, path));
					}}
					value={rule.field}
					selectComponentProps={{
						sx: dropdownSxProps
					}}
					disabled = {disableBtn}
				/>
			</TableCell>

			<TableCell>
				<CustomDropdown
					options={fields[rule.field]?.fieldOptions?.operators?.map((item: { value: string; label: string }) => ({
						value: item.value,
						label: item.label
					}))}
					handleChange={(e) => {
						setQuery(update(query, 'operator', e.target.value, path));
					}}
					value={rule.operator}
					selectComponentProps={{
						sx: dropdownSxProps
					}}
					disabled = {disableBtn}
				/>
			</TableCell>

			<TableCell>
				{/* conditional rendering of input field based on the input type */}
				{fields[rule.field]?.fieldOptions?.valueType === 'boolean' ? (
					<CustomDropdown
						options={[
							{
								value: 'false',
								label: 'False'
							},
							{
								value: 'true',
								label: 'True'
							}
						]}
						handleChange={(e) => {
							const booleanValue = e.target.value === 'true';
							setQuery(update(query, 'value', booleanValue, path));
						}}
						value={String(rule.value)}
						selectComponentProps={{
							displayEmpty: true,
							sx: dropdownSxProps
						}}
					/>
				) : fields[rule.field]?.fieldOptions?.fieldInput === 'textfield' ||
				  fields[rule.field]?.fieldOptions?.valueType === 'number' ? (
					<CustomTextField
						onChange={(e) => {
							const value = e.target.value;
							if (fields[rule.field]?.fieldOptions?.valueType === 'number') {
								// Allow only number inputs
								if (/^\d*\.?\d*$/.test(value)) {
									setQuery(update(query, 'value', Number(value), path));
								}
							} else {
								setQuery(update(query, 'value', value, path));
							}
						}}
						value={rule.value}
						sx={textfieldSxProps}
					/>
				) : (
					<AsyncAutoComplete
						query={query}
						fields={fields}
						path={path}
						rule={rule}
						setQuery={setQuery}
						fieldValueOptionsCallback={fieldValueOptionsCallback}
						subscription = {subscriptionRef.current}
						disabled = {disableBtn && ['PepWiseProgram', 'PepWiseProject'].includes(rule.field)}
					/>
				)}
			</TableCell>

			<TableCell align="center">
				<Box display="flex" justifyContent="center" gap={1}>
					{!disableBtn && <OutlinedButton
						sx={{ color: 'error.main', borderColor: 'error.main', width: 30, height: 30 }}
						onClick={() => {
							const newQuery = remove(query, path);
							setQuery(ruleUnGroupOnRemove(newQuery, groupedRules, path));
						}}
					>
						<Clear />
					</OutlinedButton>}
					{!disableBtn && <OutlinedButton
						sx={{ color: 'info.main', borderColor: 'info.main', width: 30, height: 30 }}
						onClick={() => {
							const movePath = combinatorPath.length === 0 ? [0] : combinatorPath;
							const newQuery = add(query, defaultRuleValue, []);
							setQuery(move(newQuery, [newQuery.rules.length - 1], movePath), true);
						}}
					>
						<Add />
					</OutlinedButton>}
				</Box>
			</TableCell>
		</TableRow>
	);
};

export default RuleRow;
