import React, { createContext, Dispatch, ReactNode, useContext, useMemo, useReducer } from 'react';
import { ActionTypes, AUTH_ACTIONS, PepwiseAuthContextType, StateTypes } from './cacheData/types/AuthContext';

const PepwiseAuth = createContext<PepwiseAuthContextType | undefined>(undefined);

// Setting custom name for the context which is visible on react dev tools
PepwiseAuth.displayName = 'PepwiseAuthContext';

function clearState(state: StateTypes) {
	return {
		...state,
		user: null,
		profile: null,
		authType: null,
		accessToken: null,
		isAuthenticated: false,
		role: null,
		capabilities: null
	};
}

function userAuthSettings(state: StateTypes, action: ActionTypes): StateTypes {
	switch (action.type) {
		case 'CLEAR_AUTH_CACHE':
			return clearState(state);
		case 'SET_USER':
			return {
				...state,
				user: action.value
			};
		case 'SET_PROFILE':
			return {
				...state,
				profile: action.value
			};
		case 'SET_TOKEN':
			return {
				...state,
				accessToken: action.value
			};
		case 'SET_AUTHENTICATED':
			return {
				...state,
				isAuthenticated: action.value
			};
		case 'SET_ROLE':
			return {
				...state,
				role: action.value
			};
		case 'SET_CAPABILITIES':
			return {
				...state,
				capabilities: action.value
			};
	}
	return state;
}

function reducer(state: StateTypes, action: ActionTypes): StateTypes {
	const atype = action.type;
	if (AUTH_ACTIONS.includes(atype)) {
		return userAuthSettings(state, action);
	}
	throw new Error(`Unhandled action type: ${action.type}`);
}

function PepwiseAuthControllerProvider({ children }: Readonly<{ children: ReactNode }>): React.JSX.Element {
	const initialState: StateTypes = {
		authType: null,
		user: null,
		profile: null,
		isAuthenticated: false,
		role: null,
		capabilities: null,
		accessToken: null
	};

	const [controller, dispatch] = useReducer(reducer, initialState);

	const value = useMemo(() => [controller, dispatch] as PepwiseAuthContextType, [controller, dispatch]);

	return <PepwiseAuth.Provider value={value}>{children}</PepwiseAuth.Provider>;
}

function usePepwiseAuthController(): PepwiseAuthContextType {
	const context = useContext(PepwiseAuth);

	if (!context) {
		throw new Error('usePepwiseAuthController should be used inside the PepwiseAuthControllerProvider.');
	}
	return context;
}

// Context module functions
const setAuthType = (dispatch: Dispatch<ActionTypes>, value: StateTypes['authType']) =>
	dispatch({ type: 'SET_AUTH_TYPE', value });

const setProfile = (dispatch: Dispatch<ActionTypes>, value: StateTypes['profile']) =>
	dispatch({ type: 'SET_PROFILE', value });

const setAuthToken = (dispatch: Dispatch<ActionTypes>, value: StateTypes['accessToken']) =>
	dispatch({ type: 'SET_TOKEN', value });

const setRole = (dispatch: Dispatch<ActionTypes>, value: StateTypes['role']) => dispatch({ type: 'SET_ROLE', value });

const setCapabilities = (dispatch: Dispatch<ActionTypes>, value: StateTypes['capabilities']) =>
	dispatch({ type: 'SET_CAPABILITIES', value });

const setUser = (dispatch: Dispatch<ActionTypes>, value: StateTypes['user']) => dispatch({ type: 'SET_USER', value });

const setAuthenticated = (dispatch: Dispatch<ActionTypes>, value: StateTypes['isAuthenticated']) =>
	dispatch({ type: 'SET_AUTHENTICATED', value });

const clearCache = (dispatch: React.Dispatch<ActionTypes>) => {
	dispatch({
		type: 'CLEAR_AUTH_CACHE'
	});
};

export {
	PepwiseAuthControllerProvider,
	usePepwiseAuthController,
	clearCache,
	setAuthType,
	setAuthenticated,
	setAuthToken,
	setRole,
	setCapabilities,
	setUser,
	setProfile
};
