import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Popover, Select, Stack } from '@mui/material';

import '../DataEstate/data-estate-dashboard.scss';
import React, { useEffect, useRef, useState } from 'react';
import { fetchServiceRequestSummary, getDecodedToken, getSector, programSummaryDetail, getServiceReq, getDataEstateSector } from '../../service/service';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';

import useStyles from '../jobs/jobs_styles';
import moment from 'moment';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';
import Loader from '../Loader/loader';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column, Row } from 'react-data-grid';
import { getSectorSql } from '../../service/service';
import { isEnvTypePROD } from '../../service/config';
import NoPrgramFound from '../../assets/no-program-found.svg';
import { convertDecimalToTime, convertShortDateToActualDateFormat, formatNumberWithCommas } from '../../utils/common-util';
import serviceRequestIcon from '../../assets/service-request.svg';
import NumberWidget from '../Widgets/NumberWidget';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import PageHeader from 'components/Header/Page';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import SectorFilter from 'components/Dropdown/SectorFilter';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import { sectorListDetails } from 'Context/contexts/cacheData/DataEstate/type';

export default function serviceRequestSummary() {

	const [controller] = useCacheDataController();
	const { deMetricsLastSyncDate } = controller;
	const [sectorName, setSectorName] = useState('all');
	const [MTTRData, SetMTTRData] = useState([]);
	const [MTTRDataObject, SetMTTRDataObject] = useState<any>({});
	const [MTBFData, SetMTBFData] = useState([]);
	const [MTBFDataObject, SetMTBFDataObject] = useState<any>({});
	const [IncidentsData, SetIncidentsData] = useState([]);
	const [IncidentsDataObject, SetIncidentsDataObject] = useState<any>({});
	const [RequestsData, SetRequestsData] = useState<any>({});
	const [OutagesData, SetOutagesData] = useState<any>({});
	
	const [allSectorOptionsList, setAllSectorOptionsList] = useState<sectorListDetails | null>(null);
	const [sectorTagList, setSectorTagList] = useState<any>({ incident: true, outage: true});

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	
	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [filterMonth, setFilterMonth] = useState("0M");
	const [filterDefaultMonth, setFilterDefaultMonth] = useState("0M");
	const [customYear, setCustomYear] = useState(moment().format("YYYY"));
	const [isDateOpen, setIsDateOpen] = useState(false);
	const isCustomMonth = useRef(false);
	const dataFetchedRef = useRef(false);
	const [loading, setLoading] = useState(false);
	// server filter
	const [dateFilter, setDateFilter] = useState<any>('Apr 2024');
	const [customMonth, setCustomMonth] = useState('');

	// Server filter code
	const handleDateFilter = (field: any, selectedDate: any) => {	
		selectedDate = selectedDate.target.value	
		if (field === 'dateFilter') {
			isCustomMonth.current = false;
			setFilterMonth(selectedDate);
			setCustomMonth('');
			if(selectedDate === "2023-01") {
				isCustomMonth.current = true;
				setIsDateOpen(true)
				return;
			}
		}
		setFilterDefaultMonth(selectedDate)
		setFilterMonth(selectedDate);
		applyDateFilter(selectedDate);
	};
	const handleMonthOnclick = (value:any) => {
		isCustomMonth.current = false;
		setIsDateOpen(false);
		const selectedDate = customYear + "-" + value;
		setCustomMonth(selectedDate);
		applyDateFilter(selectedDate);
		setDateFilterMenu(null);
	}
	const handleYearAdd = () => {
		let selectedYear = Number(customYear);
		selectedYear++;
		setCustomYear(selectedYear.toString());
	}
	const handleYearMinus = () => {
		let selectedYear = Number(customYear);
		selectedYear--;
		setCustomYear(selectedYear.toString());
	}
	const handleCustomOnclick = (e:any) => {
		if (e.target.textContent == 'Custom Month') {
			isCustomMonth.current = true;
			dateFilterMenuClick(e);
		}
		if(isCustomMonth.current)
			setIsDateOpen(true);
		else
			setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth)
			}
		}
	}

	const applyDateFilter = (selectedDate: any) => {
		getServiceRequestSummary(selectedDate);
		setDateFilter(selectedDate);
};

	const dateFilterMenuClick = (event: any) => {
		setDateFilterMenu(
			dateFilterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 260,
						mouseY: parseInt(event.clientY) - 50
				  }
				: null
		);
	};

	const handleDateFilterMenuClose = (event: any) => {
		setDateFilterMenu(null);
	};

	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleSectorClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllSectorCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleSectorChange = (value: string) => {
		setLoading(true);
		setSectorName(value); 
		sectorTagUpdatefun(value);
	};

	const sectorTagUpdatefun = (sector: string) => {
		if(sector != 'all'){
			if(sector == 'Blank')
				sector = '';

			const isInIncident = allSectorOptionsList?.incident.some(item => item.sector === sector);
			const isInOutage = allSectorOptionsList?.outages.some(item => item.sector === sector);

			const filteredData = {
				incident: isInIncident || false,
				outage: isInOutage || false
			};
			setSectorTagList(filteredData);
			getServiceRequestSummary(filterMonth, filteredData.incident == true ? sector : 'all');
		} else {
			getServiceRequestSummary(filterMonth, sector);
		}
	}

	const handleSectorOkClick = (event: any) => {
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		console.log(checkedList)
		setCurrSector(checkedList);
		getServiceRequestSummary(filterMonth);
		handleSectorClose(event);
	};

	async function fetchSectorOptionsData() {
		let sectorKpi = [];
		sectorKpi.push("incident","outage");
		const sectorRes = await getDataEstateSector(sectorKpi);
		if (sectorRes.success) {
			if(sectorRes.data) {
				setAllSectorOptionsList(sectorRes.data);
				const allSectors = [
					...sectorRes.data.incident.map((item: any) => item.sector),
					...sectorRes.data.outages.map((item: any) => item.sector)
				];
				const uniqueSectors = Array.from(new Set(allSectors));
				const sectorNameList = uniqueSectors.map(sector => {
					return sector === "" ? "Blank" : sector ;
				});
				setSectorOptionsList(sectorNameList.sort());
			}
		}
	}
	
	async function getServiceRequestSummary(selectedDate?: string, sector?: string) {
		setLoading(true);
		if(sector == 'Blank')
			sector = '';

		const formattedDate = convertShortDateToActualDateFormat(selectedDate);
		fetchServiceRequestSummary('MTTR', selectedDate, sector, formattedDate).then(res => {
			SetMTTRData(res?.data?.result);
			const mttrFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
			res?.data?.result.filter((mttr: any) => {
				if (mttr.report_dimension == 'EDW') {
					mttrFiltered['edw'] = mttr.count;
				} else if (mttr.report_dimension == 'Azure') {
					mttrFiltered['azure'] = mttr.count;
				} else if (mttr.report_dimension == 'Databricks') {
					mttrFiltered['databricks'] = mttr.count;
				} else if (mttr.report_dimension == 'Presto') {
					mttrFiltered['presto'] = mttr.count;
				}
			})
			SetMTTRDataObject(mttrFiltered);
			setLoading(false);
		}).catch(error => { 
			console.error(error);
		})
		fetchServiceRequestSummary('MTBF', selectedDate, sector, formattedDate).then(res => {
			SetMTBFData(res?.data?.result);
			const mtbfFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
			let edwCount = 0, azureCount = 0, databricksCount = 0, prestoCount = 0;
			let edwDateList: any = [], azureDateList: any = [], databricksDateList: any = [], prestoDateList: any = [];
			res?.data?.result.filter((mtbf: any) => {
				if (mtbf.report_dimension == 'EDW') {
					edwDateList.push(moment(mtbf.date).format('yyyy-MM-DD'));
				} else if (mtbf.report_dimension == 'Azure') {
					azureDateList.push(moment(mtbf.date).format('yyyy-MM-DD'));
				} else if (mtbf.report_dimension == 'Databricks') {
					databricksDateList.push(moment(mtbf.date).format('yyyy-MM-DD'));
				} else if (mtbf.report_dimension == 'Presto') {
					prestoDateList.push(moment(mtbf.date).format('yyyy-MM-DD'));
				}
			});
			for (let index = 0; index < edwDateList.length; index++) {
				if (index) {
					edwCount += moment(edwDateList[index]).diff(edwDateList[index-1], 'hours')
				}
			}
			for (let index = 0; index < databricksDateList.length; index++) {
				if (index) {
					databricksCount += moment(databricksDateList[index]).diff(databricksDateList[index-1], 'hours')
				}
			}
			for (let index = 0; index < azureDateList.length; index++) {
				if (index) {
					azureCount += moment(azureDateList[index]).diff(azureDateList[index-1], 'hours')
				}
			}
			for (let index = 0; index < prestoDateList.length; index++) {
				if (index) {
					prestoCount += moment(prestoDateList[index]).diff(prestoDateList[index-1], 'hours')
				}
			}
			if (edwDateList.length == 1) {
				mtbfFiltered.edw = 999999
			} else if (edwDateList.length > 1) {
				mtbfFiltered.edw = edwCount / edwDateList.length
			}
			if (databricksDateList.length == 1) {
				mtbfFiltered.databricks = 999999
			} else if (databricksDateList.length > 1) {
				mtbfFiltered.databricks = databricksCount / databricksDateList.length
			}
			if (azureDateList.length == 1) {
				mtbfFiltered.azure = 999999
			} else if (azureDateList.length > 1) {
				mtbfFiltered.azure = azureCount / azureDateList.length
			}
			if (prestoDateList.length == 1) {
				mtbfFiltered.presto = 999999
			} else if (prestoDateList.length > 1) {
				mtbfFiltered.presto = prestoCount / prestoDateList.length
			}
			
			console.log(mtbfFiltered)

			// res?.data?.result.filter((mtbf: any) => {
			// 	if (mtbf.report_dimension == 'EDW') {
			// 		mtbfFiltered['edw'] = mtbf.count;
			// 	} else if (mtbf.report_dimension == 'Azure') {
			// 		mtbfFiltered['azure'] = mtbf.count;
			// 	} else if (mtbf.report_dimension == 'Databricks') {
			// 		mtbfFiltered['databricks'] = mtbf.count;
			// 	} else if (mtbf.report_dimension == 'Presto') {
			// 		mtbfFiltered['presto'] = mtbf.count;
			// 	}
			// })
			SetMTBFDataObject(mtbfFiltered);
		}).catch(error => { 
			console.error(error);
		})
		fetchServiceRequestSummary('incidents', selectedDate, sector, formattedDate).then(res => {
			SetIncidentsData(res?.data?.result);
			const incidentFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
			res?.data?.result.filter((incident: any) => {
				if (incident.report_dimension == 'EDW') {
					incidentFiltered['edw'] = incident.count;
				} else if (incident.report_dimension == 'Azure') {
					incidentFiltered['azure'] = incident.count;
				} else if (incident.report_dimension == 'Databricks') {
					incidentFiltered['databricks'] = incident.count;
				} else if (incident.report_dimension == 'Presto') {
					incidentFiltered['presto'] = incident.count;
				}
			})
			SetIncidentsDataObject(incidentFiltered);
		}).catch(error => { 
			console.error(error);
		})
		// fetchServiceRequestSummary('serviceRequest', selectedDate, checkedList, formattedDate).then(res => {
		// 	const requestFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
		// 	res?.data?.result.filter((request: any) => {
		// 		if (request.REQUEST_TITLE.toLowerCase().includes('edw')) {
		// 			requestFiltered['edw'] = request.count;
		// 		} else if (request.REQUEST_TITLE.toLowerCase().includes('azure')) {
		// 			requestFiltered['azure'] = request.count;
		// 		} else if (request.REQUEST_TITLE.includes('databricks')) {
		// 			requestFiltered['databricks'] = request.count;
		// 		} else if (request.REQUEST_TITLE.toLowerCase().includes('presto')) {
		// 			requestFiltered['presto'] = request.count;
		// 		}
		// 	})
		// 	SetRequestsData(requestFiltered);
		// }).catch(error => { 
		// 	console.error(error);
		// }) 
		fetchServiceRequestSummary('outage', selectedDate, sector).then(res => {
			const outageFiltered: any = {edw: null, azure: null, databricks: null, presto: null};
			res?.data?.result.filter((request: any) => {
				if (request.GRP_NM.toLowerCase().includes('edw')) {
					outageFiltered['edw'] += request.count;
				} else if (request.TTL_TXT.toLowerCase().includes('azure')) {
					outageFiltered['azure'] += request.count;
				} else if (request.CI_NM.toLowerCase().includes('databrick')) {
					outageFiltered['databricks'] += request.count;
				} else if (request.TTL_TXT.toLowerCase().includes('presto')) {
					outageFiltered['presto'] += request.count;
				}
			})
			SetOutagesData(outageFiltered);
		}).catch(error => { 
			console.error(error);
		})
	}
	
	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/service-request') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		if (userSector === 'all') fetchSectorOptionsData();
		getServiceRequestSummary(filterMonth, 'all');
	}, []);

	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isTableVisible, setIsTableVisible] = useState(false);
	const [tableDataList, setTableData] = useState<Row[]>([]);
	const [tableHeading, setTableHeading] = useState('');
	const [typeName, setTypeName] = useState('');
	
	interface MTBF{
		incidentID: string;
		incidentTitle: string;
		priority: string;
		status: string;
		MTBF: string;
	}
	interface MTTR{
		incidentID: string;
		incidentTitle: string;
		priority: string;
		status: string;
		MTTR: string;
	}
	interface Incident{
		incidentID: string;
		priority: string;
		incidentTitle: string;
        groupName: string;
		serviceName:string;
		incidentSector:string;
		openedBy: string;
		resolvedBy: string;
		updatedBy: string;
		closedBy: string;
		assignedTo:string;
		status: string;
		MTTR: string;
	}
	interface Request{
		RequestID: string;
		requestTitle: string;
		requestAge: string;
        groupName: string;
		serviceName:string;
		incidentSector:string;
		openedBy: string;
		updatedBy: string;
		createdBy: string;
		closedBy: string;
		openDate: string;
		closedDate: string;
		dueDate: string;
		status: string;
	}
	interface Outage{
		incidentID: string;
        groupName: string;
		criticality: string;
		outagedurationInDays: string;
		outageDurationInHours: string;
		outageStartDatetime: string;
		outageEndDatetime: string;
	}
	interface Row{};
	function getColumns(): Array<Column<Row>> {
		if(typeName === 'MTBF'){
			return [
				{
					key: 'incidentID',
					name: 'Incident ID',
					minWidth: 180
				},
				{
					key: 'priority',
					name: 'Priority',
					minWidth: 170
				},
				{
					key: 'incidentTitle',
					name: 'Incident Title',
					minWidth: 170
				},
				{
					key: 'status',
					name: 'Status',
					minWidth: 170
				},
				{
					key: 'MTBF',
					name: 'MTBF',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'MTTR'){
			return [
				{
					key: 'incidentID',
					name: 'Incident ID',
					minWidth: 180
				},
				{
					key: 'priority',
					name: 'Priority',
					minWidth: 170
				},
				{
					key: 'incidentTitle',
					name: 'Incident Title',
					minWidth: 170
				},
				{
					key: 'status',
					name: 'Status',
					// formatter(props:any) {
					// 	return <div>{props.row.status}</div>
					// },
					minWidth: 170
				},
				{
					key: 'MTTR',
					name: 'MTTR',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'Incident')
		{
			return [
				{
					key: 'incidentID',
					name: 'Incident ID',
					minWidth: 180
				},
				{
					key: 'priority',
					name: 'Priority',
					minWidth: 170
				},
				{
					key: 'incidentTitle',
					name: 'Incident Title',
					minWidth: 170
				},
				{
					key: 'incidentAge',
					name: 'Incident Age',
					minWidth: 170
				},
				{
					key: 'groupName',
					name: 'Group name',
					minWidth: 170
				},
				{
					key: 'serviceName',
					name: 'Service name',
					minWidth: 170
				},
				{
					key: 'incidentSector',
					name: 'Incident sector',
					minWidth: 170
				},
				{
					key: 'openedBy',
					name: 'Opened by',
					minWidth: 170
				},
				{
					key: 'resolvedBy',
					name: 'Resolved by',
					minWidth: 170
				},
				{
					key: 'updatedBy',
					name: 'Updated by',
					minWidth: 170
				},
				{
					key: 'closedBy',
					name: 'Closed by',
					minWidth: 170
				},
				{
					key: 'assignedTo',
					name: 'Assigned to',
					minWidth: 170
				},
				{
					key: 'status',
					name: 'Status',
					minWidth: 170
				},
				{
					key: 'MTTR',
					name: 'MTTR',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'Request'){
			return [
				{
					key: 'requestID',
					name: 'Request ID',
					minWidth: 180
				},
				{
					key: 'requestTitle',
					name: 'Request Title',
					minWidth: 170
				},
				{
					key: 'requestAge',
					name: 'Request Age',
					minWidth: 170
				},
				{
					key: 'groupName',
					name: 'Group name',
					minWidth: 170
				},
				{
					key: 'serviceName',
					name: 'Service name',
					minWidth: 170
				},
				{
					key: 'assignedTo',
					name: 'Assigned To',
					minWidth: 170
				},
				{
					key: 'openedBy',
					name: 'Opened by',
					minWidth: 170
				},
				{
					key: 'updatedBy',
					name: 'Updated by',
					minWidth: 170
				},
				{
					key: 'createdBy',
					name: 'Created by',
					minWidth: 170
				},
				
				{
					key: 'closedBy',
					name: 'Closed by',
					minWidth: 170
				},
				{
					key: 'openDate',
					name: 'Open Date',
					minWidth: 170
				},
				{
					key: 'closedDate',
					name: '	Closed Date',
					minWidth: 170
				},
				{
					key: 'dueDate',
					name: '	Due Date',
					minWidth: 170
				},
				{
					key: 'status',
					name: 'Status',
					minWidth: 170
				},
			];
		}
		else if(typeName === 'Outage'){
			return [
				{
					key: 'incidentID',
					name: 'Incident ID',
					minWidth: 180
				},
				{
					key: 'groupName',
					name: 'Group name',
					minWidth: 170
				},
				{
					key: 'criticality',
					name: 'Criticality',
					minWidth: 170
				},
				{
					key: 'outagedurationInDays',
					name: 'Outage duration in Days',
					minWidth: 170
				},
				{
					key: 'outageDurationInHours',
					name: 'Outage duration in Hours',
					minWidth: 170
				},
				{
					key: 'outageStartDatetime',
					name: 'Outage Start Datetime',
					minWidth: 170
				},
				{
					key: 'outageEndDatetime',
					name: 'Outage End Datetime',
					minWidth: 170
				},
			];
		}
		else{
			return[];
		}
	}
	const MTBFColumns =['Incident ID','Priority','Incident Title','Status','MTBF']
	const MTTRColumns =['Incident ID','Priority','Incident Title','Status','MTTR']
	const MTBFIncidentColumns =['Incident ID', 'Priority','Incident Title','Group name','Service name','Incident sector','Opened by','Resolved by','Updated by','Closed by','Assigned to','Status','MTTR']
	const RequestColumns =['Request ID', 'Request Title','Request Age','Group name','Service name','Assigned To','Opened by','Closed by','Open Date','Closed Date','Due Date','Status']
	const OutageColumns =['Incident ID', 'Group name','Criticality','Outage duration in Days','Outage duration in Hours','Outage Start Datetime','Outage End Datetime']
	async function sectorListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		const res = await getSectorSql(pageNumber.toString(), size.toString(), sortingInfo);
		if (res.success) {
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}
	const handleSorting = (sortingInfo: any) => {
		sectorListingFunc(page, pageSize, sortingInfo);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		sectorListingFunc(value, pageSize, sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		sectorListingFunc(page, event.target.value, sortingInfo);
	};
	async function programListingFunc(pageNumber: any, size: any, sortingInfo: any,diamension:string,month:string) {
		const kpiName:string = "serviceRequest";
		const sector:any =[];
		const res = await getServiceReq(kpiName,diamension,sector,month,pageNumber.toString(), size.toString());
		if (res.success) {
			setTableData(res.data.summary);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
		console.log(defaultColumnNames,"default final")
	}
	const handleClick = (typeName:any) => {
		setDynamicValue(['']);
		setTableData([]);
		setIsTableVisible(true);
		let diamension='';
		if(typeName === 'EDWMTBF'){
			setDynamicValue(MTBFColumns);
			setTableHeading("Legacy EDW MTBF");
			type Row = keyof MTBF;
			setTypeName('MTBF')
			diamension = "legacy_MTBF"
		}
		if(typeName === 'AzureMTBF'){
			setDynamicValue(MTBFColumns);
			setTableHeading("Azure MTBF");
			type Row = keyof MTBF;
			setTypeName('MTBF')
			diamension = "azure_MTBF"
		}
		if(typeName === 'DatabricksMTBF'){
			setDynamicValue(MTBFColumns);
			setTableHeading("Databricks MTBF");
			type Row = keyof MTBF;
			setTypeName('MTBF')
			diamension = "databricks_MTBF"
		}
		if(typeName === 'PrestoMTBF'){
			setDynamicValue(MTBFColumns);
			setTableHeading("Presto MTBF");
			type Row = keyof MTBF;
			setTypeName('MTBF')
			diamension = "presto_MTBF"
		}
		if(typeName === 'EDWMTTR'){
			setDynamicValue(MTTRColumns);
			setTableHeading("Legacy EDW MTTR");
			type Row = keyof MTTR;
			setTypeName('MTTR')
			diamension = "legacy_MTTR"
		}
		if(typeName === 'AzureMTTR'){
			setDynamicValue(MTTRColumns);
			setTableHeading("Azure MTTR");
			type Row = keyof MTTR;
			setTypeName('MTTR')
			diamension = "azure_MTTR"
		}
		if(typeName === 'DatabricksMTTR'){
			setDynamicValue(MTTRColumns);
			setTableHeading("Databricks MTTR");
			type Row = keyof MTTR;
			setTypeName('MTTR')
			diamension = "databricks_MTTR"	
		}
		if(typeName === 'PrestoMTTR'){
			setDynamicValue(MTTRColumns);
			setTableHeading("Presto MTTR");
			type Row = keyof MTTR;
			setTypeName('MTTR')
			diamension = "presto_MTTR"
		}
		if(typeName === 'EDWIncident'){
			setDynamicValue(MTBFIncidentColumns);
			setTableHeading("Legacy EDW Incidents");
			type Row = keyof Incident;
			setTypeName('Incident')
			diamension = "legacy_incidents"
		}
		if(typeName === 'AzureIncident'){
			setDynamicValue(MTBFIncidentColumns);
			setTableHeading("Azure Incidents");
			type Row = keyof Incident;
			setTypeName('Incident')
			diamension = "azure_incidents"	
		}
		if(typeName === 'DatabricksIncident'){
			setDynamicValue(MTBFIncidentColumns);
			setTableHeading("Databricks Incidents");
			type Row = keyof Incident;
			setTypeName('Incident')
			diamension = "databricks_incidents"
		}
		if(typeName === 'PrestoIncident'){
			setDynamicValue(MTBFIncidentColumns);
			setTableHeading("Presto Incidents");
			type Row = keyof Incident;
			setTypeName('Incident')
			diamension = "presto_incidents";
		}if(typeName === 'LegacyEDWRequests'){
			setDynamicValue(RequestColumns);
			setTableHeading("Legacy EDW Requests");
			type Row = keyof Request;
			setTypeName('Request')
			diamension = "legacy_request";
		}if(typeName === 'azureRequests'){
			setDynamicValue(RequestColumns);
			setTableHeading("Azure Requests");
			type Row = keyof Request;
			setTypeName('Request');
			diamension = "azure_request";
		}
		if(typeName === 'databricksRequests'){
			setDynamicValue(RequestColumns);
			setTableHeading("Databricks Requests");
			type Row = keyof Request;
			setTypeName('Request');
			diamension = "databricks_request";
		}
		if(typeName === 'prestoRequests'){
			setDynamicValue(RequestColumns);
			setTableHeading("Presto Requests");
			type Row = keyof Request;
			setTypeName('Request');
			diamension = "presto_request";
		}if(typeName === 'legacyEDWOutage'){
			setDynamicValue(OutageColumns);
			setTableHeading("Legacy EDW Outage");
			type Row = keyof Outage;
			setTypeName('Outage')
			diamension = "legacy_outage";
		}if(typeName === 'azureOutage'){
			setDynamicValue(OutageColumns);
			setTableHeading("Azure Outage");
			type Row = keyof Outage;
			setTypeName('Outage');
			diamension = "azure_outage";
		}
		if(typeName === 'databricksOutage'){
			setDynamicValue(OutageColumns);
			setTableHeading("Databricks Outage");
			type Row = keyof Outage;
			setTypeName('Outage');
			diamension = "databricks_outage";
		}
		if(typeName === 'prestoOutage'){
			setDynamicValue(OutageColumns);
			setTableHeading("Presto Outage");
			type Row = keyof Outage;
			setTypeName('Outage');
			diamension = "presto_outage";
		}
		programListingFunc(page,pageSize,sortingInfo,diamension,filterMonth)
	}

	const majorConfigs = [
		{ title: "Legacy EDW", dataKey: "edw", incident : "EDWIncident", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Azure", dataKey: "azure", incident : "AzureIncident", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Databricks", dataKey: "databricks", incident : "DatabricksIncident", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Presto", dataKey: "presto", incident : "PrestoIncident", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' }
	  ];
	  const outageConfigs = [
		{ title: "Legacy EDW", dataKey: "edw", incident : "legacyEDWOutage", sectorLabel : sectorTagList?.outage == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Azure", dataKey: "azure", incident : "azureOutage", sectorLabel : sectorTagList?.outage == true? sectorName == 'all' ? '' : sectorName : ''  },
		{ title: "Databricks", dataKey: "databricks", incident : "databricksOutage", sectorLabel : sectorTagList?.outage == true? sectorName == 'all' ? '' : sectorName : ''  },
		{ title: "Presto", dataKey: "presto", incident : "prestoOutage", sectorLabel : sectorTagList?.outage == true? sectorName == 'all' ? '' : sectorName : ''  }
	  ];
    const mttrConfig = [
		{ title: "Legacy EDW", dataKey: "edw", incident : "EDWMTTR", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Azure", dataKey: "azure", incident : "AzureMTTR", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Databricks", dataKey: "databricks", incident : "DatabricksMTTR", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Presto", dataKey: "presto", incident : "PrestoMTTR", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' }
	]
	const mtbfConfig = [
		{ title: "Legacy EDW", dataKey: "edw", incident : "EDWMTBF", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Azure", dataKey: "azure", incident : "AzureMTBF", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Databricks", dataKey: "databricks", incident : "DatabricksMTBF", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' },
		{ title: "Presto", dataKey: "presto", incident : "PrestoMTBF", sectorLabel : sectorTagList?.incident == true? sectorName == 'all' ? '' : sectorName : '' }
	]

	interface InfoWidgetProps {
		widtitle: string;
		title: string;
		dataObject: Record<string, any>;
		dataKey: string;
		clickHandler: string;
		isEnvTypePROD: boolean;
		formatNumberWithCommas: (num: number) => string;
		units: string;
		defaultValue: string;
		sectorLabel: string;
	  }
	  
	  const InfoWidget: React.FC<Readonly<InfoWidgetProps>> = ({
		widtitle,
		title,
		dataObject,
		dataKey,
		clickHandler,
		isEnvTypePROD,
		formatNumberWithCommas,
		units,
		defaultValue,
		sectorLabel = ''
	  }) => {

		let numberCount = [];
	    let unitLabels = [];

		if (widtitle === 'Mean Time To Resolve (MTTR)' || widtitle === 'Mean Time Between Failures (MTBF)') {
			const splitHour = convertDecimalToTime(dataObject[dataKey]);
			// if (dataObject[dataKey] == 999999) {
			// 	splitHour.Days = 0;
			// 	splitHour.Hours = 0;
			// 	splitHour.Minutes = 0;
			// 	numberCount.push('Insufficient Data')
			// }
			if (splitHour.Days > 0) {
				if(splitHour.Days == 1){
				numberCount.push(splitHour.Days);
				unitLabels.push('Day');
				} else {
				numberCount.push(splitHour.Days);
				unitLabels.push('Days');
				}
			}
			
			if (splitHour.Hours > 0) {
				numberCount.push(splitHour.Hours);
				unitLabels.push('Hrs');
			}
			
			if (splitHour.Minutes > 0) {
				numberCount.push(splitHour.Minutes);
				unitLabels.push('Min');
			} 
            
		
		} else {
			numberCount = [formatNumberWithCommas(dataObject[dataKey])];
		}
		
		return (
		  <Grid item xs={12} sm={6} md={6} lg={3} onClick={() =>{ !isEnvTypePROD ? handleClick(clickHandler) : <span className="noDataTextColor">No Data</span> }}>
			<NumberWidget
			  title={title}
			  number={dataObject[dataKey] == 999999 ? ' ' : numberCount.length > 0 ? numberCount : defaultValue }
			  units={dataObject[dataKey] == 999999 ? 'Insufficient Data' : unitLabels.length > 0 ? unitLabels : units}
			  isLoading={false}
			  tooltipLabel="" 
			  sectorLabel = {sectorLabel}
			/>
		  </Grid>
		);
	  };

	  const configSections = [
		{ widtitle: "Major INC Details", configs: majorConfigs, dataObject: IncidentsDataObject, units: "", defaultValue : '0'},
		{ widtitle: "Outages", configs: outageConfigs, dataObject: OutagesData, units: "", defaultValue : '0'},
		{ widtitle: "Mean Time To Resolve (MTTR)", configs: mttrConfig, dataObject: MTTRDataObject, units: "Hrs", defaultValue : '0'},
		{ widtitle: "Mean Time Between Failures (MTBF)", configs: mtbfConfig, dataObject: MTBFDataObject, units: "Hrs" ,  defaultValue : '0'}
	  ];
	return (
		<Box mt="45px">
			<PageHeader title="Major INC Summary" icon={serviceRequestIcon} lastSync={deMetricsLastSyncDate}>
				<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={applyDateFilter}
				/>
				<SectorFilter
					sectorName={sectorName}
					setSectorName={setSectorName}
					sectorOptionsList={sectorOptionsList}
					handleChange={handleSectorChange}
				/>
			</PageHeader>
			<Grid container spacing={2} mt={1.5} p={2}>
				{configSections.map(({ widtitle, configs, dataObject, units, defaultValue }) => (
					<Grid container item xs={12}>
						<WidgetCardContainer title={widtitle}>
							{configs.map(({ title, dataKey, incident, sectorLabel }) => (
								<InfoWidget
									widtitle={widtitle}
									key={dataKey}
									title={title}
									dataObject={dataObject}
									dataKey={dataKey}
									clickHandler={incident}
									isEnvTypePROD={isEnvTypePROD}
									formatNumberWithCommas={formatNumberWithCommas}
									units={units}
									defaultValue={defaultValue}
									sectorLabel={sectorLabel}
								/>
							))}
						</WidgetCardContainer>
					</Grid>

				))}
			</Grid>
			<br /><br />

			{/* <Grid container spacing={0} className="headerIPB mb-10 small-boxes"> */}
			{/* <Grid item xs={12} className="">
					<div className="panel-legend mr-10">
						<h2 className="panel-legend--title">Unplanned Downtime</h2>
						<Grid container spacing={0} className="inner-grid-parent">
							<Grid item xs={3}>
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Legacy EDW</h2>
									<div className="panel-box--count">4 <span className="conversion">hrs</span></div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Azure</h2>
									<div className="panel-box--count">3 <span className="conversion">hrs</span></div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Databricks</h2>
									<div className="panel-box--count">7 <span className="conversion">hrs</span></div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mb-10">
									<h2 className="panel-box--title">Presto</h2>
									<div className="panel-box--count">2 <span className="conversion">hrs</span></div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid> */}

			{/* descoped Planned downtime */}
			{/* <Grid item xs={6} className="">
					<div className="panel-legend mr-10">
						<h2 className="panel-legend--title">Planned Downtime</h2>
						<Grid container spacing={0} className="inner-grid-parent">
							<Grid item xs={3}>
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Legacy EDW Outages</h2>
									<div className="panel-box--count">4</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Azure Outages</h2>
									<div className="panel-box--count">3</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mr-10 mb-10">
									<h2 className="panel-box--title">Databricks Outages</h2>
									<div className="panel-box--count">7</div>
								</div>
							</Grid>

							<Grid item xs={3} className="">
								<div className="panel-box mb-10">
									<h2 className="panel-box--title">Presto Outages</h2>
									<div className="panel-box--count">2</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Grid> */}
			{/* </Grid> */}
			{isTableVisible && (
				<>
					<h2 className="panel-legend--title mt-35 ">{tableHeading}</h2>
					<div className=" finop_tabledata detail_sum_tbl">
						<Grid>
							{tableDataList && tableDataList.length > 0 ? (
								<CustomizedDataGrid
									tableColumns={getColumns()}
									tableData={tableDataList}
									tableType="sector"
									defaultColumnNames={defaultColumnNames}
									onSorting={handleSorting}
									currSorting={sortingInfo}
									page={page}
									pageSize={pageSize}
									paginationData={paginationData}
									listLength={tableDataList.length}
									handlepageSizeChange={handlepageSizeChange}
									handlePageChange={handlePageChange}
									isDateRangeFilter={false}
									isDisabled={false}
								/>
							) : (
								<Loader />
							)}
						</Grid>
					</div>
				</>
			)}
		</Box>
	);
}
