import { Delete, FilePresentOutlined, InsertDriveFile, UploadFile } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, FormControlLabel, Grid, IconButton, Input, MenuItem, Select, Switch, TextareaAutosize, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import Loader from "components/Loader/loader"
import { useEffect } from "react"
import { useState } from "react"
import { createKPIConfig, getAlertKpiProgramsSectors, updateKPIConfig } from "service/service"
import FileUploadIcon from '../../assets/kpi-file.svg'
import DeleteIcon from '../../assets/kpi-delete.svg'

interface KPIConfig {
    _id: string,
    name: string,
    description: string,
    update_frequency_unit: string|null,
    update_frequency_value: number,
    isActive: boolean,
    source_config: any,
    target_config: any,
    program: string|null,
    project: string|null,
    sector: string|null
}

export const KPIForm = ({onCreateKPI, kpiConfig = {}, onCancel}: any) => {
    const [kpi, setKpi] = useState<KPIConfig>(kpiConfig)
    const [selectedFile, setSelectedFile] = useState<any>(null)
    const [programOptions, setProgramOptions] = useState([]);
	const [sectorOptions, setSectorOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState<string|null>(null);

    const parseFile = (file: any) => {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => {
            const text = reader.result as string
            if(text){
                const jsonData : any = JSON.parse(text)
                if(jsonData.source_config){
                    setKpi(kpi => ({...kpi, source_config: jsonData.source_config}))
                }
                if(jsonData.target_config){
                    setKpi(kpi => ({...kpi, target_config: jsonData.target_config}))
                }
            }
        }
    }

    const handleFile = (e: any) => {
        setErrorText(null)
        if(e.target?.files?.length){
            setSelectedFile(e.target.files[0])
            parseFile(e.target?.files?.[0])
        }
    }

    const saveKPIConfig = async () => {
        if(!kpi.program || !kpi.project || !kpi.sector || !kpi.name || !kpi.update_frequency_unit || !kpi.update_frequency_value){
            setErrorText('Please fill up all required fields.')
            return
        }
        if(!kpi.source_config || !kpi.source_config.type || (!kpi.source_config.queries?.length && !kpi.source_config.query)){
            return setErrorText('Please upload a valid config file with source type and query')
        }
        setLoading(true)
        const result = kpi._id ? await updateKPIConfig(kpi) : await createKPIConfig(kpi)
        setLoading(false)
        if(result.statusCode === 200){
            onCreateKPI()
        }else{
            setErrorText(result.data.message)
        }
    }

    const fetchData = async () => {
		setLoading(true);
		const result: any = await getAlertKpiProgramsSectors();
        if(result.data){
            setProgramOptions(result.data.programs)
            setSectorOptions(result.data.sectors)
        }
		setLoading(false);
	};

    const handleChange = (name: string, value: any) => {
        setErrorText(null)
        setKpi({...kpi, [name] : value})
    }

	useEffect(() => {
		fetchData();
	}, []);

    return (
        <Box sx={{ mt: 1, borderRadius: '8px' }}>
            {!!loading && <Loader/> }
            <Grid container spacing={2}>
                <Grid item xs={6}>
					<TextField
						label="KPI name*"
						variant="outlined"
						size="small"
						value={kpi?.name}
						onChange={(e) => handleChange('name', e.target.value)}
                        InputProps={{
						sx: {
                                width: 400,
                                color: 'white',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#1E3A5F'
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#3A506B'
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#66B2FF'
                                }
                            }}
                        }
					/>
				</Grid>
                <Grid item xs={3}>
					<TextField
						label="Update frequency*"
						variant="outlined"
						size="small"
                        type={'number'}
						value={kpi?.update_frequency_value}
						onChange={(e) => handleChange('update_frequency_value', parseInt(e.target.value))}
						InputProps={{
                            sx: {
							width: 200,
							color: 'white',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1E3A5F'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#3A506B'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#66B2FF'
                            }
                        }
						}}
					/>
				</Grid>
                <Grid item xs={3}>
					<Autocomplete
						id="size-small-outlined"
						size="small"
						options={['Minutes','Hours','Days','Weeks','Months']}
						value={kpi?.update_frequency_unit}
						onChange={(e, option) => handleChange('update_frequency_unit', option)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Select unit*"
								variant="outlined"
								size="small"
								sx={{
									width: 180,
									color: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1E3A5F'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3A506B'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#66B2FF'
                                    }
								}}
							/>
						)}
					/>
				</Grid>
				
            </Grid>
            <Grid mt={1} container spacing={2}>
				<Grid item xs={6}>
					<Autocomplete
						id="size-small-outlined"
						size="small"
						options={sectorOptions}
						value={kpi?.sector}
						onChange={(e, option) => handleChange('sector', option)}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Select Sector*"
								variant="outlined"
								size="small"
								sx={{
									width: 400,
									color: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1E3A5F'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3A506B'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#66B2FF'
                                    }
								}}
							/>
						)}
					/>
				</Grid>
                <Grid item xs={6}>
					<Autocomplete
						id="size-small-outlined"
						size="small"
						options={programOptions}
						value={kpi?.program}
						onChange={(e, option) => handleChange('program', option )}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Select Program*"
								variant="outlined"
								sx={{
									width: 400,
									color: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#1E3A5F'
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#3A506B'
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#66B2FF'
                                    }
								}}
							/>
						)}
					/>
				</Grid>
			</Grid>
            <Grid mt={1} container spacing={2}>
            <Grid item xs={6}>
					<TextField
						label="Project name*"
						variant="outlined"
						size="small"
						value={kpi?.project}
						onChange={(e) => handleChange('project', e.target.value)}
						InputProps={{
							sx: {
							width: 400,
							color: 'white',
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: '#1E3A5F'
							},
							'&:hover .MuiOutlinedInput-notchedOutline': {
								borderColor: '#3A506B'
							},
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: '#66B2FF'
							}
						}
					}}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Description"
						variant="outlined"
						size="small"
                    
                      
						value={kpi?.description}
						onChange={(e) => handleChange('description', e.target.value)}
                        InputProps={{
                            sx: {
							width: 400,
							color: 'white',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1E3A5F'
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#3A506B'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#66B2FF'
                            }
                        }
						}}
					/>
				</Grid>
			</Grid>
            <Typography
                style={{
                color: '#880000',
                cursor: 'pointer',
                marginTop: '6px',
                marginRight: '25px'
                }}					
            >
                {errorText ? `*${errorText}` : ''}
            </Typography>
            <div style={{marginTop: 32, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                {!!selectedFile ? 
                <div style={{display: 'flex', flexDirection: 'row', gap: 8}}>
                    <img src={FileUploadIcon} alt="CCLogo" style={{ width: 24, display: 'block', marginBottom: '8px'}}/>
                    <p style={{textAlign: 'left', textDecoration: 'underline', color: '#009BD8'}}>{selectedFile?.name}</p>
                    <p style={{textAlign: 'left', color: '#71758F', fontWeight: 500, fontSize: '16px'}}>{Math.ceil(selectedFile?.size/1000)} KB</p>
                    <IconButton size="small" onClick={() => setSelectedFile(null)}>
                        <img src={DeleteIcon} alt="CCLogo" style={{ display: 'block', margin: '0 auto' }}/>
                    </IconButton>
                </div> :
                <>
                 <input type='file' accept=".json" style={{display: 'none'}} id='upload-file' onChange={handleFile}/>
                 <label htmlFor="upload-file">
                    <Typography
                        style={{
                            color: '#009BD8',
                            cursor: 'pointer',
                            marginTop: '0px',
                            marginRight: '25px'
                        }}
                    >
                        Upload file (.json)
                    </Typography>
                </label>
                </>}
                 <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Typography
                        style={{
                            color: 'white',
                            cursor: 'pointer',
                            marginTop: '6px',
                            marginRight: '25px'
                        }}
                        onClick={onCancel}
                    >
                        CANCEL
                    </Typography>
                    <Button variant="pepwiseSecondary" onClick={saveKPIConfig}>
                        SAVE
                    </Button>
                </Box>
             </div>
             
        </Box>
        // <div style={{display: 'flex', flexDirection: 'column', width: 600}}>
        //     <div style={{display: 'flex', flexDirection: 'row', gap: 16}}>
        //         <TextField variant='filled' required label='KPI Name' style={{flex: 8}} type="text" value={kpi?.name} onChange={(e) => setKpi({...kpi, name: e.target.value})}/>
        //         <FormControlLabel style={{flex: 4}}  label='Active' control={<Switch checked={kpi?.isActive} onChange={(e) => setKpi({...kpi, isActive: e.target.checked})}/>}/>
        //     </div>
        //     <div style={{display: 'flex', flexDirection: 'row', flex: 6, gap: 2}}>
        //         <TextField variant='filled' required label='Frequency' style={{flex: 4}} type='number' 
        //             value={kpi?.update_frequency_value} onChange={(e) => setKpi({...kpi, update_frequency_value: parseInt(e.target.value)})}/>
        //         <Select variant="filled" style={{flex: 8, textAlign: 'left'}} 
        //             value={kpi?.update_frequency_unit} onChange={(e) => setKpi({...kpi, update_frequency_unit: e.target.value})}>
        //             <MenuItem value='days'>Days</MenuItem>
        //             <MenuItem value='weeks'>Weeks</MenuItem>
        //             <MenuItem value='months'>Months</MenuItem>
        //         </Select>
        //     </div>
        //     <div style={{marginTop: 16, display: 'flex', flexDirection: 'row',}}>
        //         <TextField style={{flex: 12}} variant='filled' label="Description" value={kpi?.description} onChange={(e) => setKpi({...kpi, description: e.target.value})}/>
        //     </div>
        //     <div style={{marginTop: 32, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        //         <input type='file' style={{display: 'none'}} id='upload-file' onChange={handleFile}/>
        //         <label htmlFor="upload-file">
        //             <Button variant="contained" component='span'>
        //                 Upload JSON
        //             </Button>
        //         </label>                
        //         <ButtonGroup>
        //             <Button>Cancel</Button>
        //             <Button variant='contained' onClick={saveKPIConfig}>Save</Button>
        //         </ButtonGroup>
        //     </div>
        //     {!!selectedFile && <p style={{textAlign: 'left', marginLeft: 16}}>{selectedFile?.name}</p>}
        // </div>
    )
}