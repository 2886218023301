import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import UnderDevelopment from '../../assets/under-construction.svg';
import ToolTipIcon from '../../assets/info-icon.svg';

// Define the component props
interface WidgetProps {
	title: string;
	tooltipLabel: string;
}

const InprogressWidget: React.FC<WidgetProps> = ({ title, tooltipLabel}) => {
	return (
		<Box
			sx={{
				backgroundColor: '#003771',
				display: 'flex',
				flexDirection: 'row',
				color: 'white',
				padding: '16px',
				height: '170px'
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
				<Typography variant="body1" fontWeight={600}>
					{title}
				</Typography>
				<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'normal' }}>
					<Box component="img" src={UnderDevelopment} alt={tooltipLabel} maxHeight={'20%'}/>
					<Typography sx={{marginLeft: '5px'}}>{tooltipLabel}</Typography>
					<Tooltip title={tooltipLabel} arrow>
						<img src={ToolTipIcon} alt={tooltipLabel} style={{height: '15px', width: '15px', margin:'5px 10px'}}/>
					</Tooltip>
				</Box>
			</Box>
		</Box>
	);
};

export default InprogressWidget;
