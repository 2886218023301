import { Box, Typography, Tooltip, Stack, debounce } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import NoPrgramFound from '../../assets/no-program-found.svg';
import LoaderComponent from 'components/Loader';
import { defaultOptions, getBarChartOptions } from './chartWidgetUtility';
import { useEffect, useRef, useState } from 'react';
import { BarChartWidgetProps } from './type';
import { ECElementEvent } from 'echarts';

const defaultHeight = 350;
const getHeightPx = (value: number, heightDifferencePercent: number) => {
	return `${value + value * (heightDifferencePercent / 100)}px`;
};

const BarChartWidget = ({
	isLoading = false,
	isCost = false,
	isPBI = false,
	isPercentage = false,
	isOverlayWidget = false,
	isInProgress = false,
	title = '',
	data,
	seriesData,
	minTitleHeight = '50.5px',
	handleEvent,
	legendLabel = '',
	yAxisOptions = {},
	xAxisOptions = {},
	heightDifferencePercent = 0,
	containerProps,
	children,
	isEvent = true,
	dateRange = [],
}: Readonly<BarChartWidgetProps>) => {
	const [windowSize, setWindowSize] = useState(window.innerWidth);
	const [gridLeft, setGridLeft] = useState('5%');
	const [minChartHeight, setMinChartHeight] = useState(getHeightPx(defaultHeight, heightDifferencePercent));
	const chartContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// re-render chart to fix alignment when resizing the browser
		const handleResize = debounce(() => {
			if (chartContainerRef.current) {
				const containerWidth = chartContainerRef.current.offsetWidth;
				const calculatedLeft =
					containerWidth < 420 ? '5%' : containerWidth < 920 ? '9%' : containerWidth < 1260 ? '7%' : '5%';
				const calculatedChartHeight =
					containerWidth < 420
						? getHeightPx(680, heightDifferencePercent)
						: containerWidth < 500
						? getHeightPx(600, heightDifferencePercent)
						: containerWidth < 685
						? getHeightPx(550, heightDifferencePercent)
						: containerWidth < 920
						? getHeightPx(450, heightDifferencePercent)
						: getHeightPx(defaultHeight, heightDifferencePercent);
				setGridLeft(calculatedLeft);
				setMinChartHeight(calculatedChartHeight);
				setWindowSize(window.innerWidth);
			}
		}, 200);

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const otherOptions = {
		isCost,
		isPBI,
		legendLabel,
		isPercentage,
		yAxisOptions,
		xAxisOptions
	};

	const result = getBarChartOptions(data ?? [], seriesData, otherOptions, isEvent, dateRange, chartContainerRef?.current?.offsetWidth);

	const adjustedOption: any = !data?.length
		? defaultOptions
		: {
				...result,
				grid: {
					...result.grid,
					left: gridLeft
				},
				...(chartContainerRef?.current?.offsetWidth &&
					chartContainerRef.current.offsetWidth < 650 && {
						xAxis: {
							...result.xAxis,
							axisLabel: {
								...result.xAxis.axisLabel,
							}
						}
					})
		  };

	return (
		<Box display="flex" flexGrow={1} height="100%" position="relative">
			<Stack
				minHeight="100px"
				p={2}
				bgcolor="primary.main"
				fontFamily="Montserrat"
				borderRadius="5px"
				flexGrow={1}
				// {...(handleEvent && { onClick: handleEvent, sx: { cursor: 'pointer' } })}
				{...containerProps}
			>
				<Stack direction="row" gap={1} justifyContent="space-between" alignItems="center" pb={1}>
					<Typography variant={isOverlayWidget ? 'overlayWidgetTitle' : 'widgetTitle'}>{title}</Typography>
					<Box display='flex' gap={1}>
						{children}
					</Box>
				</Stack>
				<Stack height={minChartHeight} flexGrow={1} justifyContent="center" key={windowSize} ref={chartContainerRef}>
					{!isLoading ? (
						<ReactECharts
							option={adjustedOption}
							style={{ width: '100%', height: '99%' }}
							opts={{ renderer: 'canvas', width: 'auto', height: 'auto' }}
							notMerge={true}
							lazyUpdate={true}
							onChartReady={() => {
								console.log('chart')
							}}
							onEvents={{
								click: (params: ECElementEvent) => {
									if(handleEvent){
										handleEvent(params);
									}
								}
							}}
						/>
					) : (
						<LoaderComponent />
					)}
				</Stack>
				{isInProgress && (
					<Box position="absolute" right={15} bottom={10}>
						<Tooltip title="In Progress/Validation">
							<img
								src={NoPrgramFound}
								style={{
									height: '20px',
									width: '20px'
								}}
							/>
						</Tooltip>
					</Box>
				)}
			</Stack>
		</Box>
	);
};

export default BarChartWidget;
