import { Box, Typography, Stack } from '@mui/material';
import LoaderComponent from 'components/Loader';
import ReactECharts from 'echarts-for-react';
import { useEffect, useRef } from 'react';

type MultiLineWidgetProps = {
	color?: string;
	isLoading: boolean;
	title: string;
	data?: any | null;
	minTitleHeight?: `${number}px`;
	sectorLabel?: string;
};

const defaultOptions = {
    backgroundColor: "#003771",
  };

const MultiLineWidget = ({
	color = '#1b1e35',
	isLoading = false,
	title = '',
	minTitleHeight = '38px',
	data = null,
	sectorLabel = ''
}: Readonly<MultiLineWidgetProps>) => {
	const chartRef = useRef<any>(null);

	useEffect(() => {
		const handleResize = () => {
			if (chartRef.current) {
				chartRef.current.getEchartsInstance().resize();
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<Box display="flex" flexGrow={1} height="100%" position="relative">
			<Stack minHeight="100px" p={2} bgcolor="primary.main" fontFamily="Montserrat" borderRadius="5px" flexGrow={1}>
				<Stack direction="row" gap={2} alignItems="start" minHeight={minTitleHeight}>
					<Typography variant="widgetTitle">{title}</Typography>
				</Stack>
				{sectorLabel != '' && (
					<Stack sx={{
						display: 'flow-root !important'
					}}>
						<Box bgcolor="blue.darker" sx={{
							borderRadius: '16px',
							width: 'auto',
							float: 'right',
							display: 'flex',
							marginTop: '-36px',
							height: 'auto',
							alignItems: 'center',
							padding: '5px'
						}}>
							<Typography variant="widgetTitle" sx={{ fontSize: '11px', textAlign: 'center', fontWeight: '500 !important' }}>{sectorLabel}</Typography>
						</Box>
					</Stack>
				)}
				<Box flexGrow={1}>
					{!isLoading ? (
						<ReactECharts
							ref={chartRef}
							option={data && Object.keys(data) ? data : defaultOptions}
							theme={{
								backgroundColor: { color }
							}}
						/>
					) : (
						<LoaderComponent />
					)}
				</Box>
			</Stack>
		</Box>
	);
};

export default MultiLineWidget;
