import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as WarningImage } from '../../../assets/delete-img-modal.svg';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import RowTypography from 'components/Table/RowTypography';
import RowChip from '../../Table/RowChip';

type ModalWidth = 'sm' | 'md' | 'lg' | 'xl';

interface FormData {
	PepwiseProgram: string;
	PepwiseProject: string;
	PepwiseSector: string;
	PepwiseEnvironment: string;
    [key: string]: any; 
	// DNAI_Value: string;
}

interface Column {
    key: keyof FormData | any; // Allow both known keys and other dynamic keys
}
interface BulkUpdateConflictsModalProps {
	onTitleChange: (newtitle: string) => void;
	onWidthChange: (newWidth: ModalWidth) => void;
	onCancel: () => void;
    handleSave: (
        bulkSelectedRows: Record<string, any>, // Improved type: use Record for better structure
        override: 'N' | 'Y',
        skipconflict: 'N' | 'Y'
      ) => void;
    bulkEditResponse: any;
    bulkSelectedRows: any;
	queryPreference: any;
    formData: FormData;
	allocationType: {
		key: string;
		label: string;
	};
}

const BulkUpdateConflictsModal: React.FC<BulkUpdateConflictsModalProps> = ({
	onTitleChange,
	onWidthChange,
	onCancel,
    bulkEditResponse,
    bulkSelectedRows,
    formData,
	queryPreference,
    handleSave,
	allocationType
}) => {
	const isNestedTreeView = queryPreference.split('_')[0] === 'ResourceGroup';
	const isTreeView = queryPreference.split('_')[1] === 'tree';
	const defaultColumnNames = [ 'Sectors', 'Environment', 'Programs', 'Projects', 'Current Allocation %'];

	const columnNames = [
		{ name: 'sNo', label: 'S.No' },
		{ name: 'Resource', label: 'Resource' },
		{ name: 'PepwiseSector', label: 'Sectors' },
		{ name: 'PepwiseEnvironment', label: 'Environment' },
		{ name: 'PepwiseProgram', label: 'Programs' },
		{ name: 'PepwiseProject', label: 'Projects' },
		{ name: 'AllocPercent', label: 'Current Allocation %' }
	];

	const [resourceCount, setResourceCount] = useState<number>(0);
	const [expandedRows, setExpandedRows] = useState<{
		[key: number]: boolean;
	}>({});
	const [parentRows, setParentRows] = useState<any[]>([]);

	const toggleRowExpansion = (id: number) => {
		setExpandedRows((prev) => ({ ...prev, [id]: !prev[id] }));
	};

    useEffect(() => {
		if (bulkEditResponse?.data?.groupIds) {
			setResourceCount(bulkEditResponse.data.groupIds.length);
		}
	}, [bulkEditResponse]);
	// useEffect(() => {
	// 	setParentRows(
	// 		Object.values(bulkSelectedRows)
	// 			.flatMap((item: any) => {
	// 				if (isNestedTreeView) {
	// 					return item.values;
	// 				}
	// 				return item;
	// 			})
	// 			.filter((item: any) => item?.values)
	// 	);
	// }, [bulkSelectedRows]);

	useEffect(() => {
		// Conflicting item move to top
		const groupIds = bulkEditResponse?.data?.groupIds || [];
		if (isNestedTreeView) {
			const allRows = Object.values(bulkSelectedRows).filter((item: any) => item.isResource === true);

			const matchingRows = allRows.filter((item: any) => groupIds.includes(item.groupId));

			const nonMatchingRows = allRows.filter((item: any) => !groupIds.includes(item.groupId));
			const prioritizedRows = [...matchingRows, ...nonMatchingRows]
				.filter((item: any) => item)
				.map((item: any, index: number) => ({
					...item,
					sNo: index + 1, // Assign serial number
				}));
			// .flatMap(
			// 	(item: any) => {
			// 		if (isNestedTreeView) {
			// 			return item.values; // Flatten nested rows if needed
			// 		}
			// 		return item; // Keep flat structure
			// 	}
			// );

			setParentRows(prioritizedRows.filter((item: any) => item));
		} else {
			const allRows = Object.values(bulkSelectedRows);
			const matchingRows = allRows.filter((item: any) => groupIds.includes(item.groupId));
			const nonMatchingRows = allRows.filter((item: any) => !groupIds.includes(item.groupId));

			const prioritizedRows = [...matchingRows, ...nonMatchingRows]
				.flatMap((item: any) => (isNestedTreeView ? item.values : item))
				.filter((item: any) => item?.values)
				.map((item: any, index: number) => ({
					...item,
					sNo: index + 1, // Assign serial number
				}));
			setParentRows(prioritizedRows.filter((item: any) => item?.values));
		}
	}, [bulkSelectedRows, bulkEditResponse]);


    const filterBulkSelectedRows = (
			bulkSelectedRows: Record<string, { groupId: string; values: [] }>,
			bulkEditResponse: { data: { groupIds: string[] } }
		) => {
			const excludedGroupIds = new Set(bulkEditResponse.data.groupIds);
			// Filter out rows where the groupId matches any in the excludedGroupIds
			const filteredRows = Object.keys(bulkSelectedRows).reduce((acc, rowId) => {
				const row = bulkSelectedRows[rowId];
				// Check if row's groupId is not in excludedGroupIds
				if (!excludedGroupIds.has(row.groupId) && !row.values) {
					acc[rowId] = row;
				}
				return acc;
			}, {} as Record<string, { groupId: string }>);

			return filteredRows;
		};

        // cancel button handler
		const handleCancel = () => {
			onCancel();
		};
        // Force update button handler
		const handleForceUpdate = () => {
			handleSave(bulkSelectedRows, 'Y', 'N');
		};

        // resource conflict button handler
    const handleSkipConflict = () => {
        const filteredRows = filterBulkSelectedRows(bulkSelectedRows, bulkEditResponse);
        handleSave(filteredRows, 'N', 'Y');
    }

	const handleResourceClick = (resourceCount: any) => {
		//add logic for clicking the resourcecount
	};

	function getColumns(isAfterUpdate: boolean): Array<any> {
		return columnNames.map((col: any, idx: number) => {
			const columnKey =
				col.label === 'Current Allocation %' && isAfterUpdate
					? 'AllocPercent'
					: col.label === 'Current Allocation %'
					? 'AllocPercent'
					: col.name;

			return {
				key: columnKey,
				name: isAfterUpdate && col.label === 'Current Allocation %' ? 'Allocation %' : col.label,
				editable: false,
				formatter({ row, column }: any) {
					if (!isAfterUpdate) {
						if (column.key === 'AllocPercent') {
							return (
								<Stack direction="row" justifyContent="space-between" alignItems="center">
									<RowTypography isSuccessor={!row[column.key]}>
										{row[column.key] ? `${row[column.key]}%` : ''}
									</RowTypography>
								</Stack>
							);
						}

						if (['PepwiseSector', 'PepwiseProject', 'PepwiseProgram'].includes(column.key)) {
							if (column.key === 'PepwiseSector') {
								if (row['SectorCount'] > 1) {
									return (
										<Stack direction="row" justifyContent="space-between" alignItems="center">
											{/* <RowTypography isSuccessor={!row[column.key]}> */}
											<RowChip
												value={`${row['SectorCount']} SECTORS`}
												rowId={row.id}
												// isExpanded={expandedRows[row.id]}
												// onToggle={toggleRowExpansion}
											/>
											{/* </RowTypography> */}
										</Stack>
									);
								} else {
									if (row[column.key]) {
										return (
											<Stack direction="row" justifyContent="space-between" alignItems="center">
												{/* <RowTypography isSuccessor={!row[column.key]}> */}
												<RowChip
													value={row[column.key]}
													rowId={row.id}
													// isExpanded={expandedRows[row.id]}
													// onToggle={toggleRowExpansion}
												/>
												{/* </RowTypography> */}
											</Stack>
										);
									}
								}
							}

							if (column.key === 'PepwiseProject') {
								if (row['ProjectCount'] > 1) {
									return (
										<Stack direction="row" justifyContent="space-between" alignItems="center">
											{/* <RowTypography isSuccessor={!row[column.key]}> */}
											<RowChip
												value={`${row['ProjectCount']} PROJECTS`}
												rowId={row.id}
												// isExpanded={expandedRows[row.id]}
												// onToggle={toggleRowExpansion}
											/>
											{/* </RowTypography> */}
										</Stack>
									);
								} else {
									if (row[column.key]) {
										return (
											<Stack direction="row" justifyContent="space-between" alignItems="center">
												{/* <RowTypography isSuccessor={!row[column.key]}> */}
												<RowChip
													value={row[column.key]}
													rowId={row.id}
													// isExpanded={expandedRows[row.id]}
													// onToggle={toggleRowExpansion}
												/>
												{/* </RowTypography> */}
											</Stack>
										);
									}
								}
							}

							if (column.key === 'PepwiseProgram') {
								if (row['ProgramCount'] > 1) {
									return (
										<Stack direction="row" justifyContent="space-between" alignItems="center">
											{/* <RowTypography isSuccessor={!row[column.key]}> */}
											<RowChip
												value={`${row['ProgramCount']} PROGRAMS`}
												rowId={row.id}
												// isExpanded={expandedRows[row.id]}
												// onToggle={toggleRowExpansion}
											/>
											{/* </RowTypography> */}
										</Stack>
									);
								} else {
									if (row[column.key]) {
										return (
											<Stack direction="row" justifyContent="space-between" alignItems="center">
												{/* <RowTypography isSuccessor={!row[column.key]}> */}
												<RowChip
													value={row[column.key]}
													rowId={row.id}
													// isExpanded={expandedRows[row.id]}
													// onToggle={toggleRowExpansion}
												/>
												{/* </RowTypography> */}
											</Stack>
										);
									}
								}
							}
						}
					} else {
						// after update table
						// after update allocation percentage will be changed to 100% for each allocation
						if (column.key === 'AllocPercent') {
							return (
								<Stack direction="row" justifyContent="space-between" alignItems="center">
									<RowTypography isSuccessor={!row[column.key]}>{'100%'}</RowTypography>
								</Stack>
							);
						}

						if (['PepwiseSector', 'PepwiseProject', 'PepwiseProgram'].includes(column.key)) {
							if (row[column.key] || formData[column.key]) {
								return (
									<Stack direction="row" justifyContent="space-between" alignItems="center">
										{/* <RowTypography isSuccessor={!row[column.key]}> */}
										<RowChip
											value={formData[column.key] ? formData[column.key] : row[column.key]}
											rowId={row.id}
											// isExpanded={expandedRows[row.id]}
											// onToggle={toggleRowExpansion}
										/>
										{/* </RowTypography> */}
									</Stack>
								);
							}
						}

						return (
							<Stack direction="row" justifyContent="space-between" alignItems="center">
								<RowTypography isSuccessor={!row[column.key]}>
									{formData[column.key] ?? (row[column.key] || '')}
								</RowTypography>
							</Stack>
						);
					}

					return (
						<Stack direction="row" justifyContent="space-between" alignItems="center">
							<RowTypography isSuccessor={!row[column.key]}>{row[column.key] || ''}</RowTypography>
						</Stack>
					);
				},
				width: 168
			};
		});
	}
    
	return (
		<Stack
			spacing={2}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				px: 2,
				pb: 2,
				pt: 1
			}}
		>
			<Stack direction="row" alignItems="center" spacing={1}>
				<WarningImage className="warning-image" style={{ width: '4.5rem', height: '4.5rem' }} />

				<Stack
					sx={{
						alignItems: 'flex-start',
						justifyContent: 'center',
						pl: '1rem'
					}}
				>
					<Typography variant="pageTitle" sx={{ textAlign: 'left', pb: '0.5rem' }}>
						<Box component="span" sx={{ mr: 1 }}>
							{resourceCount}
						</Box>
						Conflicts Found
					</Typography>
					<Typography sx={{ textAlign: 'left', color: 'light.diff' }}>
						Are you sure you want to force update and override the below{' '}
						<Box
							component="span"
							sx={{ color: 'info.main', cursor: 'pointer' }}
							onClick={() => handleResourceClick(resourceCount)}
						>
							{resourceCount} Resources
						</Box>{' '}
						to 100% allocation?
					</Typography>
				</Stack>
			</Stack>
			<Stack sx={{ flexDirection: 'column', gap: 1, textAlign: 'left' }}>
				<CustomizedDataGrid
					title="Allocation Before Update"
					tableColumns={getColumns(false)}
					tableData={parentRows}
					defaultColumnNames={defaultColumnNames}
					autoSetDraggedColumns={false}
					isRenderPagination={false}
					isShowMoreButton={false}
					style={{ maxHeight: '200px', marginBottom: '1rem' }}
				/>
				<CustomizedDataGrid
					title="Allocation After Update"
					tableColumns={getColumns(true)}
					tableData={parentRows}
					defaultColumnNames={defaultColumnNames}
					autoSetDraggedColumns={false}
					isRenderPagination={false}
					isShowMoreButton={false}
					style={{ maxHeight: '200px' }}
				/>
			</Stack>
			<Stack direction="row" justifyContent="space-between">
				<Button
					variant="pepwiseSecondaryOutline2"
					sx={{ color: 'info.main', width: '16rem' }}
					onClick={() => handleSkipConflict()}
					disabled={resourceCount===parentRows.length}
				>
					SKIP CONFLICT RESOURCES
				</Button>
				<Stack direction="row">
					<Button variant="pepwiseOutline" sx={{ width: '10rem', color: 'error.main' }} onClick={handleCancel}>
						CANCEL
					</Button>
					<Button
						variant="pepwiseSecondary"
						sx={{ width: '10rem', backgroundColor: 'error.main' }}
						onClick={handleForceUpdate}
					>
						FORCE UPDATE
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default BulkUpdateConflictsModal;
