import {
	Box,
	Dialog,
	Grid,
	FormControl,
	FormControlLabel,
	Checkbox,
	debounce,
	Typography,
	FormLabel,
	Stack,
	TextField,
	InputAdornment,
	Tooltip,
	Switch, Select, MenuItem, InputLabel, Radio, RadioGroup, SelectChangeEvent,
	styled
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import InfoIcon from '../../assets/lucide_info.svg';
import { ReactComponent as SLAInfoIcon } from '../../assets/dp-info.svg';
import { ReactComponent as AddIcon } from '../../assets/add-blue.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import RowTypography from 'components/Table/RowTypography';
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteImage from '../../assets/delete-img-modal.svg';
import type { } from '@mui/x-date-pickers/themeAugmentation';
import '../DataPipeline/dataPipeline.scss';
import { useState, useEffect, useMemo, ReactElement } from 'react';
import {
	createDataPipelineProgram,
	createDataPipelineProject,
	deleteDataPipelineProgram,
	deleteDataPipelineProject,
	deleteDataPipelineSchedule,
	editDataPipelineProgram,
	editDataPipelineProject,
	getClarityProjectList,
	getProgramById,
	getProgramSectorList,
	getProjectOwnerList
} from '../../service/service';
import { useNavigate } from 'react-router-dom';
import CustomTextField from 'components/Inputs/CustomTextField';
import CustomAutocomplete from 'components/Inputs/CustomAutocomplete';
import eventEmitter from 'SnackBarEventEmitter';
import { getHoursList } from 'utils/common-util';
import CustomTimePicker from 'components/Inputs/CustomTimePicker';

export function DataPipelineModal({ handleEditClose, programData, getToastData, passInfoToParent }: any) {
	const [sectorList, setSectorList] = useState<any[]>([]);
	const [loading, setLoading] = useState<any>({
		sector: false,
		project: false
	});
	const [saveBtnLoader, setSaveBtnLoader] = useState(false);
	const [isDisabled, setIsDisabled] = useState<any>({
		description: false,
		owner: false
	});
	const [inputValue, setInputValue] = useState('');
	const [projectOwner, setProjectOwner] = useState('');
	const [value, setValue] = useState(null);
	const [titleLoader, setTitleLoader] = useState(true);
	const [ownerList, setOwnerList] = useState<any>([]);
	const [weeklyDaysArr, setWeeklyDaysArr] = useState<any[]>([]);
	const [isClarityProject, setIsClarityProject] = useState(false);
	const [validSlaTime, setValidSlaTime] = useState(true);
	const [projectList, setProjectList] = useState([]);
	const [open, setOpen] = useState(false);
	const [isSlaChanged, setIsSlaChanged] = useState(false);
	const [projectOptionList, setProjectOptionList] = useState([]);
	//const [openModal, setOpenModal] = useState<any>({ isOpen: false, message: '' });

	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
	// const [isSlaChanged, setIsSlaChanged] = useState(false);
	const [dialogData, setDialogData] = useState({ slaProName: '', slaDt: '', slaTe: '' });


	const handleOpenConfirmationDialog = (slaProName: any, slaDt: any, slaTe: any, e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setOpenConfirmationDialog(true);
		setDialogData({
			slaProName,
			slaDt,
			slaTe
		});
	};

	const handleCloseConfirmationDialog = () => {
		setOpenConfirmationDialog(false);
	};

	const handleForceUpdate = (event: any) => {
		setOpenConfirmationDialog(false);
		setIsSlaChanged(true)
		let slaDataChange = true
		onSubmit(event, true, slaDataChange);
	};

	const [dropdownLoading, setDropdownLoading] = useState<any>({
		project: false,
		owner: false
	});
	const [formData, setFormData] = useState<any>({
		id: '',
		Sectors: [],
		ProgramName: '',
		projectName: '',
		projectDescription: '',
		projectOwner: '',
		clarityId: '',
		slaDate: 0,
		slaTime: '',
		reqSlaTime: '',
		friendlyName: '',
		isSlaConfigured: false,
		slaType: '',
		slaData: []
	});
	const [formErrors, setFormErrors] = useState({
		estimatedDurationError: ''
	});
	const [toastData, setToastData] = useState<any>({
		message: ''
	});

	//toggle
	const [toggleState, setToggleState] = useState(false);
	const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setToggleState(event.target.checked);
		//set data to form data 
		setFormData((prevData: any) => ({
			...prevData,
			isSlaConfigured: event.target.checked,
		}));
		if (!event.target.checked) {
			setSelectedValue("");
		} else {
			setSelectedValue("daily");
		}
	}
	//dropdown
	const [selectedValue, setSelectedValue] = useState("daily");
	const [formDatadd, setFormDatadd] = useState<{
		selectedDays: string[];
		slaDate: string;
		slaTime: string | null;
	}>({
		selectedDays: [],
		slaDate: '',
		slaTime: null,
	});

	const handleChangedd = (e: SelectChangeEvent<string[]>, label: string, field: string) => {

		const { value }: any = e.target;
		console.log("VALLLL", value)
		if (value.includes('Mon, Tue, Wed, Thu, Fri, Sat, Sun')) {
			setFormDatadd((prevState) => ({
				...prevState,
				[field]: ['Mon, Tue, Wed, Thu, Fri, Sat, Sun'],
			}));
		} else {
			let reqValue = value.filter((val: any) => val != 'Mon, Tue, Wed, Thu, Fri, Sat, Sun')
			setFormDatadd((prevState) => ({
				...prevState,
				[field]: reqValue,
			}));
		}

	};

	// table data 
	const [tableData, setTableData] = useState<any[]>([]);

	// const handleSLAOpen = (e:any) => {
	// 	console.log("Inside HANDLE OPENEEE")
	// 	 e.preventDefault();
	// 	 e.stopPropagation();
	// 	// data = {
	// 	// 	...data,
	// 	// 	//ProgramName: programDetail?.program_Name,
	// 	// 	ProgramID: data.Program_ID
	// 	// };
	// 			setOpenModal({
	// 				...openModal,
	// 				isOpen: true,
	// 				type: module,
	// 				action: 'SLA',
	// 				data: ''
	// 			});

	// };

	const handleAddRow = () => {
		console.log("ROOOOOOOOOOOo", formDatadd.selectedDays)
		const newRow = {
			id: tableData.length + 1,
			days: formDatadd.selectedDays.join(', '),
			slaTime: formData.reqSlaTime,
			slaDate: formData.slaDate,
		};

		setFormData((prevData: any) => ({
			...prevData,
			slaTime: '',
			slaDate: 0
		}));

		setTableData(prevData => [...prevData, newRow]);
		let dayArr = newRow.days.split(",").map(day => day.trim())
		weeklyDaysArr.length ? setWeeklyDaysArr(prevData => [...prevData, ...dayArr]) : setWeeklyDaysArr([...dayArr])

		setFormDatadd({
			selectedDays: [],
			slaDate: '',
			slaTime: null,
		});

	};

	const [editingRow, setEditingRow] = useState<number | null>(null);

	const handleEditRow = (row: any) => {
		setFormDatadd({
			selectedDays: row.days.split(', '),
			slaTime: row.slaTime,
			slaDate: row.slaDate,
		});
		setEditingRow(row.id);
	};

	const handleDeleteRow = (rowData: any) => {
		let dayArr = rowData.days.split(",").map((day: any) => day.trim())
		setTableData(prevData => prevData.filter(row => row.id !== rowData.id));
		dayArr.map((data: any) => {
			setWeeklyDaysArr(prevData => prevData.filter(row => row !== data))
		})

		setFormData((prevData: any) => ({
			...prevData,
			slaData: prevData?.slaData?.filter((row: any) => row.id !== rowData.id),
		}));
	};


	function getColumns(): Array<any> {
		return [
			{
				key: 'id',
				name: 'SNo.',
				formatter({ row, column }: any) {
					return (
						<RowTypography>{row.id}</RowTypography>
					);
				},
				minWidth: 150
			},
			{
				key: 'days',
				name: 'Days',
				formatter({ row, column }: any) {
					return (
						<RowTypography>{row.days}</RowTypography>
					);
				},
				minWidth: 390
			},
			{
				key: 'slaTime',
				name: 'SLA Time (CST)',
				formatter({ row, column }: any) {
					return (
						<RowTypography>{row.slaTime && row.slaTime >= 0 ? formatTime(row.slaTime) : ''}</RowTypography>
					);
				},
				minWidth: 150
			},
			{
				key: 'slaDate',
				name: 'SLA Date (Sch.Date+)',
				formatter({ row, column }: any) {
					return (
						<RowTypography>{row.slaDate}</RowTypography>
					);
				},
				minWidth: 150
			},
			{
				key: 'action',
				name: 'Actions',
				formatter({ row, column }: any) {
					return (
						<div>
							{/* <EditIcon onClick={() => handleEditRow(row)} /> */}
							<DeleteIcon onClick={() => handleDeleteRow(row)} />
						</div>
					);
				},
				minWidth: 130
			}
		];
	}

	const defaultColumnNames = [
		'SNo.',
		'Days',
		'SLA Time (CST)',
		'SLA Date (Sch.Date+)',
		'Actions',

	];


	//radio button
	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedValue(event.target.value);
		setFormData((prevData: any) => ({
			...prevData,
			slaType: event.target.value,
		}));

		if (event.target.value == 'weekly') {
			setFormData((prevData: any) => ({
				...prevData,
				slaDate: 0,
				slaTime: null
			}));
		} else if (event.target.value == 'daily' && programData.data?.slaType == 0) {
			const reqslatime = `${programData.data?.slaTime ? programData.data?.slaTime
				? programData.data?.slaTime < 10
					? `000${programData.data?.slaTime}`.replace(/(.{2})$/, ':$1')
					: programData.data?.slaTime < 100
						? `00${programData.data?.slaTime}`.replace(/(.{2})$/, ':$1')
						: programData.data?.slaTime.toString().replace(/(.{2})$/, ':$1')
				: programData.data?.slaTime == 0 ? '00:00' : 'NA' : programData.data?.SLA_Time
				? programData.data?.SLA_Time < 10
					? `000${programData.data?.SLA_Time}`.replace(/(.{2})$/, ':$1')
					: programData.data?.SLA_Time < 100
						? `00${programData.data?.SLA_Time}`.replace(/(.{2})$/, ':$1')
						: programData.data?.SLA_Time.toString().replace(/(.{2})$/, ':$1')
				: programData.data?.SLA_Time == 0 ? '00:00' : 'NA'}`;
			setFormData((prevData: any) => ({
				...prevData,
				slaTime: `2024-07-21T${reqslatime}`,
				slaDate: programData.data?.dateValue || 0,
			}));
		}


	};
	//infor icon
	const BulletList = () => {
		return (
			<Box sx={{ minWidth: '19rem' }}>
				<Typography sx={{ fontWeight: "bold", mb: 2 }}>
					Cyclic Projects can execute multiple times in a day, does not need SLA Configuration
					and the SLA time will be calculated based on end time of the last job execution.
				</Typography>
			</Box>
		);
	};

	const navigate = useNavigate();

	eventEmitter.on('showSnackbar', (message: string) => {
		setLoading(false);
		setDropdownLoading({
			project: false,
			owner: false
		});
	});

	const debouncedFetchOptions = useMemo(
		() =>
			debounce(async (value, type) => {
				setLoading({
					...loading,
					project: true
				});
				if (type === 'projectName') {
					getProjectList(value);
				} else {
					getOwnerList(value);
				}
				setLoading({
					...loading,
					project: false
				});
			}, 500),
		[]
	);

	useEffect(() => {
		if (programData.type === 'Program') {
			if (programData.action === 'EDIT') {
				setTitleLoader(true);
				getProgramDetails();
			}
		} else if (programData.type === 'Project') {
			if (programData.action === 'EDIT') {
				setTitleLoader(true);
				getProjectDetails();
				console.log("PROGGG", programData)
				setIsClarityProject(programData.data.Clarity_Project_Id ? true : false);
			}
			getOwnerList(programData?.data?.Project_Owner ? (programData?.data?.Project_Owner)?.split(' ')[0]?.trim() : '');
			getSectorData();
		}
		if (programData.action == 'CREATE' || programData.action == 'DELETE') {
			setTitleLoader(false);
		}
	}, []);

	// useEffect(() => {
	// 	if (programData.type === 'Project') {
	// 		debouncedFetchOptions(projectOwner, 'projectOwner');
	// 	}
	// }, [projectOwner, debouncedFetchOptions]);

	useEffect(() => {
		if (programData.type === 'Project') {
			debouncedFetchOptions(inputValue, 'projectName');
		}
	}, [inputValue, debouncedFetchOptions]);

	const getProjectDetails = () => {
		const reqslatime = `${programData.data?.slaTime ? programData.data?.slaTime
			? programData.data?.slaTime < 10
				? `000${programData.data?.slaTime}`.replace(/(.{2})$/, ':$1')
				: programData.data?.slaTime < 100
					? `00${programData.data?.slaTime}`.replace(/(.{2})$/, ':$1')
					: programData.data?.slaTime.toString().replace(/(.{2})$/, ':$1')
			: programData.data?.slaTime == 0 ? '00:00' : 'NA' : programData.data?.SLA_Time
			? programData.data?.SLA_Time < 10
				? `000${programData.data?.SLA_Time}`.replace(/(.{2})$/, ':$1')
				: programData.data?.SLA_Time < 100
					? `00${programData.data?.SLA_Time}`.replace(/(.{2})$/, ':$1')
					: programData.data?.SLA_Time.toString().replace(/(.{2})$/, ':$1')
			: programData.data?.SLA_Time == 0 ? '00:00' : 'NA'}`;
		let SLAData: any = []
		let days: any = []
		if (programData.data?.slaType == 1) {
			programData.data?.slaData.length && programData.data?.slaData.map((obj: any, index: number) => {
				let reqObj = {
					id: index + 1,
					days: obj.SLA_Week_Days,
					slaTime: obj.SLA_Time,
					slaDate: obj.SLA_Date,
				}
				SLAData.push(reqObj)
				let weekdays = obj.SLA_Week_Days.split(",").map((day: any) => day.trim())
				days.push(...days, ...weekdays)
			})
		}
		setTableData(SLAData)
		console.log("DAYYYYY", days)
		setWeeklyDaysArr(days)
		setFormData({
			projectName: programData.data.Project_Name,
			projectDescription: programData.data.Project_Description,
			projectOwner: programData.data.Project_Owner,
			projectId: programData.data.Project_Id,
			Sectors: programData.data.Sectors ? programData.data.Sectors.split(', ') : [],
			friendlyName: programData.data.Friendly_Name,
			slaTime: programData.data?.slaType == 1 ? null : `2024-07-21T${reqslatime}`,
			slaDate: programData.data?.slaType == 1 ? 0 : programData.data?.dateValue || 0,
			reqSlaTime: programData.data?.slaType == 1 ? null : programData.data?.SLA_Time,
			slaType: programData.data?.slaType == 1 ? 'weekly' : 'daily',
			isSlaConfigured: programData.data?.Is_Sla_Configured == 1 ? true : false
		});
		setSelectedValue(programData.data?.slaType == 1 ? 'weekly' : 'daily');
		setToggleState(programData.data?.Is_Sla_Configured == 1 ? true : false)
		setTitleLoader(false);
	};

	const getOwnerList = async (name = '') => {
		setDropdownLoading({
			...dropdownLoading,
			owner: true
		});
		let result = await getProjectOwnerList(name);
		setDropdownLoading({
			...dropdownLoading,
			owner: false
		});
		let owner: any = [];
		result.data.projectOwnerNames.map((obj: string) => {
			let formattedData = (obj.includes('null') ? obj.replace('(null)', '') : obj).trim();
			owner.push(formattedData);
		});
		setOwnerList(owner);
	};

	const getProjectList = async (name = '') => {
		setDropdownLoading({
			...dropdownLoading,
			project: true
		});
		let result = await getClarityProjectList(name);
		setDropdownLoading({
			...dropdownLoading,
			project: false
		});
		setProjectList(result.data.projectList);
		setProjectOptionList(
			result.data.projectList.map((obj: any) => {
				return { label: obj.Project_Name, id: obj.Project_Code };
			})
		);
	};

	const handleInputChange = (event: any, name: any, newInputValue: any) => {
		if (name === 'projectName') {
			setInputValue(newInputValue);
			setFormData({
				...formData,
				[name]: newInputValue
			});
		} else if (name === 'projectOwner') {
			setProjectOwner(newInputValue);
			setFormData({
				...formData,
				[name]: newInputValue
			});
			if (event && event.type == 'change') {
				debouncedFetchOptions(newInputValue, name);
			}
		} else {
			setFormData({
				...formData,
				[name]: newInputValue
			});
		}
	};

	const getProgramDetails = async () => {
		let result = await getProgramById(programData.data.Program_id || programData.data.Program_ID);
		programData.data = result.data.programData[0];
		setFormData({
			id: programData.data.Program_ID,
			// SectorName: result.data.programData[0].Sector,
			ProgramName: result.data.programData[0].program_Name
		});
		setTitleLoader(false);
	};

	const getSectorData = async () => {
		setLoading({
			...loading,
			sector: true
		});
		let sectors: any = await getProgramSectorList();
		setSectorList(sectors.data.sectors);
		setLoading({
			...loading,
			sector: false
		});
	};

	const editProgram = async () => {
		let data = {
			id: formData.id,
			// sector: formData.SectorName,
			programName: formData.ProgramName
		};
		await editDataPipelineProgram(data);
	};

	const editProject = async (slaDataChange?: boolean) => {
		let index = ownerList.findIndex((obj: any) => obj === formData.projectOwner)
		let data = {
			id: formData.projectId,
			projectOwner: formData.projectOwner,
			projectName: index >= 0 || isClarityProject ? formData.projectName : formData.projectName,
			projectDescription: formData.projectDescription,
			mulSector: formData.Sectors,
			friendlyName: formData.friendlyName,
			isSlaConfigured: formData?.isSlaConfigured,
			slaType: formData?.slaType == 'weekly' ? 1 : formData?.slaType == 'daily' ? 0 : '',
			slaData: formData?.slaType == 'daily' ? [] : tableData,
			slaDate: formData.slaDate,
			slaTime: formData.reqSlaTime,
			isSlaChanged: slaDataChange || isSlaChanged
		};
		let sectorIds: string[] = [];
		sectorList.filter((sector: any) => {
			if (formData.Sectors.includes(sector.Sector_Name)) {
				sectorIds.push(sector.Sector_Id);
			}
		})
		data['mulSector'] = sectorIds;
		await editDataPipelineProject(data);
	};

	const createProgram = async (data: any) => {
		await createDataPipelineProgram(data);

		setSaveBtnLoader(false);
	};

	const createProject = async (data: any) => {
		let result = await createDataPipelineProject(data);

		setSaveBtnLoader(false);
		return result;
	};

	const validateAliasInput = (event: any) => {
		const allowedChars = /^[a-zA-Z0-9\s_-]*$/;
		if (!allowedChars.test(event.key)) {
			event.preventDefault();
		}
	};

	const handlePaste = (event: any) => {
		event.preventDefault();
	};

	function findDuplicatesAndNonDuplicates(arr1: any, arr2: any, key1: any, key2: any, key3: any, key4: any) {
		const seen = new Set();
		const duplicates: any[] = [];
		const nonDuplicates: any[] = [];

		arr1.forEach((obj1: any) => {
			const keyCombo = `${obj1[key1]}|${obj1[key2]}|${obj1[key3]}|${obj1[key4]}`;
			seen.add(keyCombo);
		});

		arr2.forEach((obj2: any) => {
			const keyCombo = `${obj2[key1]}|${obj2[key2]}|${obj2[key3]}|${obj2[key4]}`;
			if (seen.has(keyCombo)) {
				// Compare values to check if the actual data is the same
				const matchingObj = arr1.find((obj1: any) => `${obj1[key1]}|${obj1[key2]}|${obj1[key3]}|${obj1[key4]}` === keyCombo);
				if (JSON.stringify(matchingObj) === JSON.stringify(obj2)) {
					duplicates.push(obj2);  // Identical objects, considered duplicates
				} else {
					nonDuplicates.push(obj2);  // Different values
				}
			} else {
				nonDuplicates.push(obj2); // Not found in arr1
			}
		});

		return { duplicates, nonDuplicates };
	}

	const slaChangeValid = () => {
		if ((programData.data?.slaType === (formData.slaType === 'daily' ? 0 : formData.slaType))
			&& ((formData.slaType === 0) || formData.slaType === 'daily')
			&& (programData.data?.Is_Sla_Configured === (formData.isSlaConfigured === true ? 1 : formData.isSlaConfigured === false ? 0 : formData.isSlaConfigured))
		) {
			return (programData.data?.SLA_Time === formData.reqSlaTime) &&
				(programData.data?.Is_Sla_Configured === (formData.isSlaConfigured === true ? 1 : formData.isSlaConfigured)) &&
				((programData.data?.dateValue || 0) === formData.slaDate);
		} else if ((programData.data?.slaType === (formData.slaType === 'weekly' ? 1 : formData.slaType))
			&& ((formData.slaType === 1) || formData.slaType === 'weekly')
			&& (programData.data?.Is_Sla_Configured === (formData.isSlaConfigured === true ? 1 : formData.isSlaConfigured === false ? 0 : formData.isSlaConfigured))
		) {
			if (programData.data?.slaData.length && programData.data?.slaData.length === tableData.length) {
				const result = findDuplicatesAndNonDuplicates(programData.data?.slaData, tableData, 'SLA_Week_Days', 'SLA_Time', 'SLA_Date', 'SLA_Type');
				return result.nonDuplicates.length === programData.data?.slaData.length
			} else {
				return false;
			}
		}
		//changing from daily to weekly or weekly to daily
		else if ((!(programData.data?.slaType === (formData.slaType === 'daily' ? 0 : formData.slaType === 'weekly' ? 1 : formData.slaType)))
			&& ((programData.data?.Is_Sla_Configured === 1) && ((formData.isSlaConfigured === true) || (formData.isSlaConfigured === 1)))
		) {
			return false
		}

		else if (programData.data?.Is_Sla_Configured === (formData.isSlaConfigured === true ? 1 : formData.isSlaConfigured === false ? 0 : formData.isSlaConfigured)) {
			return true;
		}
		//sla not configured for first time and updating without sla configuration
		else {
			true;
		}
	}


	const onSubmit = async (event: any, isforceUpdate?: boolean, slaDataChange?: boolean) => {
		if (programData.action === 'EDIT' && programData.type === 'Project' && !isforceUpdate && !slaChangeValid()) {
			handleOpenConfirmationDialog(formData.projectName, formData.slaDate, formData.reqSlaTime, event);
			return
		}

		if (saveBtnLoader) return;
		setSaveBtnLoader(true);
		event.preventDefault();
		let formPayload;
		let result: any;
		if (programData.action === 'CREATE') {
			if (programData.type === 'Program') {
				formPayload = {
					programName: formData?.ProgramName
					// sector: formData?.SectorName
				};
				result = await createProgram(formPayload);
			} else {
				let index = ownerList.findIndex((obj: any) => obj === formData.projectOwner);
				formPayload = {
					programID: programData?.data?.ProgramID || window.location.pathname.split('/')[3].replace(/%20/g, ' '),
					projectName: formData?.projectName?.label || formData?.projectName,
					clarityProId: formData?.clarityId || '',
					projectDescription: formData?.projectDescription,
					projectOwner: index >= 0 || isClarityProject ? formData?.projectOwner : '',
					mulSector: formData?.Sectors,
					friendlyName: formData.friendlyName,
					isSlaConfigured: formData?.isSlaConfigured,
					slaType: formData?.slaType == 'weekly' ? 1 : formData?.slaType == 'daily' ? 0 : '',
					slaData: formData?.slaType == 'daily' ? [] : tableData,
					slaDate: formData.slaDate,
					slaTime: formData.reqSlaTime
				};
				let sectorIds: string[] = [];
				sectorList.filter((sector: any) => {
					if (formData.Sectors.includes(sector.Sector_Name)) {
						sectorIds.push(sector.Sector_Id);
					}
				})
				formPayload['mulSector'] = sectorIds;

				result = await createProject(formPayload);
				if (result.data?.message) {
					getToastData(result);
				} else if (result.response?.status == 400) {
					getToastData({ data: { message: result.response.data.data.message } });
				}
			}
		} else if (programData.action === 'EDIT') {
			if (programData.type === 'Program') {
				result = await editProgram();
			} else {
				//handleSLAOpen(event);
				//handleOpenConfirmationDialog(formData.projectName, formData.slaDate, formData.reqSlaTime,event);
				// if (inputValue) {
				// 	formData['projectName'] = inputValue;
				// }
				result = await editProject(slaDataChange);
			}
		} else if (programData.action === 'DELETE') {
			if (programData.type === 'Program') {
				result = await deleteDataPipelineProgram(programData.data.Program_ID || programData.data.Program_id);
			} else if (programData.type === 'Project') {
				result = await deleteDataPipelineProject(programData.data.Project_Id);
			} else {
				const jobIds = programData.data.map((d: any) => d.jobId);
				const reqBody = {
					bulkProjectId: programData.data[0].Project_Id,
					bulkJobId: jobIds,
					scheduler: programData.data[0].schedulerName
				};
				result = await deleteDataPipelineSchedule(reqBody);
			}
		}
		setSaveBtnLoader(false);

		handleClose('updated');
		if (programData['from'] === 'view' && programData.type === 'Program') {
			navigate('/data-pipeline/programs');
		} else if (programData['from'] === 'delete' && programData.type === 'Project') {
			navigate(
				`/data-pipeline/programs/${window.location.pathname.split('/')[3]}/${window.location.pathname
					.split('/')[5]
					.replace(/%20/g, ' ')}`
			);
		} else if (programData['from'] === 'view' && programData.type === 'Project') {
			navigate(
				`/data-pipeline/programs/${window.location.pathname.split('/')[3]}/${window.location.pathname
					.split('/')[5]
					.replace(/%20/g, ' ')}`
			);
		} else if (programData['from'] === 'delete' && programData.type === 'Schedule') {
			passInfoToParent();
		}
	};

	const isFormValid = () => {
		// return formData.ProgramName && formData.ProgramName.length <= 100 && formData.SectorName;
		return formData.ProgramName && formData.ProgramName.length <= 64;
	};

	const isProjectFormValid = () => {
		// return true
		//formData.clarityId ? true :
		let index = ownerList.findIndex((obj: any) => obj === formData.projectOwner);
		console.log("FORRR", formData, validSlaTime, isClarityProject, index)
		return (
			formData.projectName &&
			formData.projectName?.length <= 64 &&
			formData.projectDescription &&
			formData.projectOwner &&
			formData.Sectors &&
			formData.Sectors?.length &&
			formData.friendlyName?.length <= 45 &&
			validSlaTime
			&&
			(isClarityProject || true)
		);
	};

	const handleOnBlur = (event: any, type: string) => {
		if (type === 'friendlyName') {
			setFormData({
				...formData,
				[type]: event.target.value.trim()
			});
		}
	};

	const handleChange = (event: any, newValue: any = '', type: string = '') => {
		console.info("HERERRE", newValue)
		if (projectOptionList.length && newValue?.id) {
			setIsClarityProject(true)
		}
		if (newValue === null) {
			setIsDisabled({
				description: false,
				owner: false
			});
			if (!isClarityProject && type === 'projectName') {
				setFormData({
					...formData,
					projectName: '',
					projectOwner: '',
					projectDescription: ''
				});
				// setIsDisabled({
				// 	description: true,
				// 	owner: true
				// });
			}

			return;
		}
		if (type) {
			if (type === 'projectOwner') {
				setFormData({
					...formData,
					projectOwner: ''
				});
			} else if (type === 'projectDescription') {
				setFormData({
					...formData,
					projectDescription: ''
				});
			}

			if (type === 'projectName') {
				setIsDisabled({
					description: false,
					owner: false
				});
				if (newValue?.id) {
					let data: any = projectList.filter((obj: any) => obj.Project_Code === newValue.id);
					setFormData({
						...formData,
						projectDescription: data[0].Project_Description || '',
						projectOwner: data[0].EMPLOYEE_NAME,
						projectName: data[0].Project_Name,
						clarityId: data[0].Project_Code
					});
					setIsDisabled({
						description: true,
						owner: true
					});
				}
			} else if (type === 'projectOwner') {
				setFormData({
					...formData,
					[type]: newValue
				});
			} else if (type === 'Sectors') {
				let selectedSectors = formData.Sectors;
				if (event.target.checked) {
					selectedSectors.push(newValue);
				} else {
					selectedSectors.splice(selectedSectors.indexOf(newValue), 1);
				}
				setFormData({
					...formData,
					[type]: selectedSectors
				});
			} else if (type === 'slaTime') {
				// if (!newValue) {
				// this condition is not working for choosing the sla time so comment this.
				// 	// Handle clearing of the SLA time
				// 	setFormData({
				// 	  ...formData,
				// 	  slaTime: null,
				// 	  reqSlaTime: null,
				// 	});
				// 	return;
				//   }

				const dateObj = new Date(event);

				const hours = dateObj.getHours().toString().padStart(2, '0'); // 18 (UTC time)
				const minutes = dateObj.getMinutes().toString().padStart(2, '0'); // 40
				if (isNaN(Number(hours)) && isNaN(Number(minutes))) {
					setValidSlaTime(false)
				} else {
					setValidSlaTime(true)
				}
				// Prevent default value of 05:30
				if (hours === '05' && minutes === '30') {
					setFormData({
						...formData,
						slaTime: null,
						reqSlaTime: null,
					});
					return;
				}

				if (Number(hours) >= 0 && Number(minutes) >= 0) {
					const formattedDate = dateObj?.toISOString().split('T')[0];
					const reqTime = Number(`${hours}${minutes}`);
					const time = `${formattedDate}T${hours}:${minutes}`;
					setFormData({
						...formData,
						slaTime: time,
						reqSlaTime: reqTime
					});
				} else {
					setFormData({
						...formData,
						slaTime: null, // Clear the time if invalid
						reqSlaTime: null,
					});
				}
			} else if (type === 'friendlyName') {
				setFormData({
					...formData,
					[type]: event.target.value
				});
			} else if (type === 'slaDate') {
				setFormData({
					...formData,
					slaDate: event.target.value
				});
			}
		} else {
			const { name, value } =
				event && event.target
					? event.target.type === 'checkbox'
						? { name: event.target.name, value: event.target.checked }
						: event.target
					: '';
			setFormData({
				...formData,
				[name]: value
			});
		}

		setFormErrors({ estimatedDurationError: '' });
	};

	const handleClose = (type: string) => {
		handleEditClose(type);
	};

	const getTitle = () => {
		switch (programData.action) {
			case 'DELETE':
				return `Deleting ${programData.type == 'Schedule' && programData.data.length > 1 ? 'Jobs' : ''} ${programData.type === 'Program'
					? (programData.data?.Program_Name || programData.data?.program_Name)
					: programData.type === 'Project'
						? programData.data?.Project_Name
						: programData.data.length > 1
							? ''
							: programData.data[0].jobName
					}`;
			case 'EDIT':
				return `Edit ${programData.type}: ${titleLoader ? '' : programData.type === 'Program' ? programData.data?.program_Name : programData.data?.Project_Name}`;

			default:
				return programData.type === 'Program' ? `Create New ${programData.type}` : 'Add Project';
		}
	};

	const getMsg = () => {
		return (
			<Box component="span">
				project from{' '}
				<Box component="span" sx={{ fontWeight: '800' }}>
					{programData.data.ProgramName}
				</Box>
				?
			</Box>
		);
	};

	const onCreatenewPrj = (e: any) => {
		const { checked } = e.target;
		setOpen(false)
		setIsClarityProject(false)
		setFormData({
			...formData,
			projectName: formData.projectName || '',
			projectOwner: '',
			projectDescription: ''
		});
		if (checked) {
			setIsDisabled({
				description: false,
				owner: false
			});
		} else {
			setIsDisabled({
				description: true,
				owner: true
			});
		}
	};

	const getLabel = () => {
		return (
			<Box>
				Project Name*{' '}
				<Box component="span" sx={{ fontStyle: 'italic' }}>
					{' '}
					(Search & Select from Clarity)
				</Box>
			</Box>
		);
	};

	const AntSwitch = styled(Switch)(({ theme }) => ({
		width: '32px',
		height: '18px',
		padding: 0,
		display: 'flex',
		'&:active': {
			'& .MuiSwitch-thumb': {
				width: 15,
			},
			'& .MuiSwitch-switchBase.Mui-checked': {
				transform: 'translateX(9px)',
			},
		},
		'& .MuiSwitch-switchBase': {
			padding: 3,
			color: 'rgba(0, 30, 62, 1)',
			'&.Mui-checked': {
				transform: 'translateX(12px)',
				color: 'rgba(0, 30, 62, 1)',

				'& + .MuiSwitch-track': {
					opacity: 1,
					backgroundColor: '#1890ff',
					...theme.applyStyles('dark', {
						backgroundColor: '#177ddc',
					}),
				},
			},
		},
		'& .MuiSwitch-thumb': {
			boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
			width: 12,
			height: 12,
			borderRadius: 6,
			transition: theme.transitions.create(['width'], {
				duration: 200,
			}),
		},
		'& .MuiSwitch-track': {
			borderRadius: 16 / 2,
			opacity: 1,
			backgroundColor: 'rgba(113, 117, 143, 1)',
			boxSizing: 'border-box',
			...theme.applyStyles('dark', {
				backgroundColor: 'rgba(255,255,255,.35)',
			}),
		},
	}));


	function formatTime(number: number) {
		let str = number?.toString().padStart(4, "0"); // Ensure it's 4 digits (e.g., "0115")
		let hours = str.slice(0, 2); // Get the first two digits as hours
		let minutes = str.slice(2, 4); // Get the last two digits as minutes
		return `${hours}:${minutes}`;
	}

	const getContent = () => {
		switch (programData.action) {
			case 'EDIT':
			case 'CREATE':
				if (programData.type === 'Program') {
					return (
						<Box component="form" onSubmit={onSubmit} noValidate p={1} bgcolor="blue.darker" width="1000px">
							<DialogContent>
								<Stack spacing={2}>
									<CustomTextField
										error={formData.ProgramName?.length > 64}
										label={
											<Box>
												Program Name *
												<Box component="span" fontSize="0.75rem">
													{' '}
													(Max 64 characters)
												</Box>
											</Box>
										}
										id="ProgramName"
										name="ProgramName"
										variant="outlined"
										autoComplete="off"
										value={formData.ProgramName}
										//onChange={handleChange}
										onChange={(e) => {
											const regex = /^[A-Za-z0-9\s\-_(){}\[\]',.]*$/;
											let newValue = e.target.value;
											newValue = newValue.replace(/^[\s-_]+|[\s-_]+$/g, '');
											newValue = newValue.split('')
												.filter(char => regex.test(char))
												.join('');
											handleChange({
												target: {
													name: e.target.name,
													value: newValue,
												},
											});
										}}
										fullWidth
										helperText="Program name can't contain more than 64 characters."
									/>
									{/* <FormControl fullWidth>
										<FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' } }}>
											Select Sector *
										</FormLabel>
										<RadioGroup name="SectorName" value={formData.SectorName} onChange={handleChange}>
											<Grid container maxWidth="sm">
												{loading.sector ? (
													<LoaderComponent />
												) : (
													sectorList.map((sector) => (
														<Grid item xs={3} key={sector}>
															<FormControlLabel
																value={sector}
																control={<Radio variant="pepwiseRadio" />}
																label={sector}
															/>
														</Grid>
													))
												)}
											</Grid>
										</RadioGroup>
									</FormControl> */}
								</Stack>
							</DialogContent>
							<DialogActions>
								<Button onClick={(e) => handleClose('close')} variant="pepwiseOutline">
									CANCEL
								</Button>
								<Button
									// onClick={(e) => handleSLAOpen(e)} 
									//onClick={(e)=>handleOpenConfirmationDialog()} 
									type='submit'
									disabled={titleLoader || !isFormValid()} variant="pepwiseSecondary">
									{saveBtnLoader ? 'SAVING...' : programData.action === 'EDIT' ? 'UPDATE & SAVE' : 'SAVE'}
								</Button>
							</DialogActions>
						</Box>
					);
				} else {
					return (
						<Box component="form" onSubmit={onSubmit} noValidate p={2} bgcolor="blue.darker">
							<DialogContent>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Box sx={{ position: 'relative', height: '22px', marginBottom: '5px', '&:before': { backgroundColor: 'rgba(0, 155, 216, 1)', content: '""', height: '1px', left: 0, position: 'absolute', top: '50%', transform: 'translateY(0)', width: '100%', zIndex: 1 } }}><Typography variant="pageTitle" color="dark.main" style={{ backgroundColor: '#001e3e', color: 'rgba(0, 155, 216, 1)', display: 'inline-block', fontSize: '18px', fontWeight: '600', paddingRight: '20px', position: 'absolute', zIndex: 2 }}>Select Sectors to Project</Typography></Box>
									</Grid>
									<Grid item xs={8}>
										<FormControl fullWidth>
											<FormLabel
												sx={{ marginBottom: '10px', fontWeight: '500', color: 'rgba(245, 245, 245, 1)', '&.Mui-focused': { color: 'white.main' } }}
											>
												Select Sectors *
											</FormLabel>
											<Grid container maxWidth="sm">
												{loading.sector ? (
													'Loading...'
												) : (
													sectorList
														.filter((sector) => sector.Sector_Name !== "SODASTREAM")
														.map((sector) => (
															<Grid item xs={3} key={sector.Sector_Id}>
																<FormControlLabel
																	label={sector.Sector_Name}
																	control={
																		<Checkbox
																			checked={formData.Sectors.includes(sector.Sector_Name)}
																			onChange={(e) => {
																				handleChange(e, sector.Sector_Name, 'Sectors');
																			}}
																		/>
																	}
																/>
															</Grid>
														))
												)}
											</Grid>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<Box sx={{ position: 'relative', height: '22px', marginBottom: '5px', '&:before': { backgroundColor: 'rgba(0, 155, 216, 1)', content: '""', height: '1px', left: 0, position: 'absolute', top: '50%', transform: 'translateY(0)', width: '100%', zIndex: 1 } }}><Typography variant="pageTitle" color="dark.main" style={{ backgroundColor: '#001e3e', color: 'rgba(0, 155, 216, 1)', display: 'inline-block', fontSize: '18px', fontWeight: '600', paddingRight: '20px', position: 'absolute', zIndex: 2 }}>Project Details</Typography></Box>
									</Grid>
									{/* {programData.action != 'EDIT' && (
										<Grid item xs={12}>
											<FormControlLabel
												label="Create New Project"
												control={
													<Checkbox
														sx={{
															color: 'white.main',
															'&.Mui-checked': {
																color: 'primary.focus',
																'& .MuiSvgIcon-root': {
																	color: 'white.main'
																}
															}
														}}
														value="all"
														checked={isClarityProject}
														onChange={(e) => {
															onCheckBoxChange(e);
														}}
													/>
												}
											/>
										</Grid>
									)} */}
									{/* {!isClarityProject ? ( */}
									<Grid item xs={6}>
										<FormControl fullWidth>
											<CustomAutocomplete
												textFieldLabel={getLabel()}
												loading={dropdownLoading.project}
												options={projectOptionList}
												open={open && programData.action !== 'EDIT'}
												onOpen={() => setOpen(true)}
												onClose={() => setOpen(false)}
												value={formData.projectName || ''}
												disabled={programData.action == 'EDIT' && isClarityProject ? true : false}
												//onInputChange={(e, val) => handleInputChange(e, 'projectName', val)}
												onInputChange={(e, val) => {
													if (val) {
													  const trimmedValue = val.replace(/^\s+/, '');
													  handleInputChange(e, 'projectName', trimmedValue);
													}
												  }}
												  
												onChange={(e, val) => handleChange(e, val, 'projectName')}
												//noOptionsText="No project found"
												noOptionsText={
													<span
														style={{ cursor: 'pointer' }}
														onClick={() => {
															onCreatenewPrj({ target: { checked: true } });
														}}
													>
														No project found <br />
														Create New Project
													</span>
												}
											/>
										</FormControl>
									</Grid>
									{/* ) : (
										<Grid item xs={6}>
											<CustomTextField
												id="ProjectName"
												label={
													<Box display="flex" alignItems="center">
														Project Name *
														<Typography variant="body2" fontSize="0.75rem" sx={{ marginLeft: 1 }}>
															(Max 64 characters)
														</Typography>
													</Box>
												}
												name="projectName"
												variant="outlined"
												autoComplete="off"
												value={formData.projectName}
												error={formData.projectName?.length > 64}
												helperText="Project name can't contain more that 64 characters."
												onChange={(e) => {
													const regex = /^[A-Za-z0-9\s\-_(){}\[\]',.]*$/;
													let newValue = e.target.value;
													newValue = newValue.replace(/^[\s-_]+|[\s-_]+$/g, '');
													newValue = newValue.split('')
														.filter(char => regex.test(char))
														.join('');
													handleChange({
														target: {
															name: e.target.name,
															value: newValue,
														},
													});
												}}
											//onChange={handleChange}
											// InputProps={{
											// 	endAdornment: (
											// 		<ul style={{ listStyle: 'none', padding: 0, margin: 0, maxHeight: '150px', overflowY: 'auto' }}>
											// 			{projectOptionList.map((option, index) => (
											// 				<li
											// 					key={index}
											// 					style={{ padding: '5px', cursor: 'pointer', borderBottom: '1px solid #ddd' }}
											// 					onClick={() => handleChange(null, option, 'projectName')}
											// 				>
											// 					{option}
											// 				</li>
											// 			))}
											// 		</ul>
											// 	),
											// }}
											/>
										</Grid>
									)} */}
									<Grid item xs={6}>
										<CustomTextField
											id="FriendlyName"
											label={
												<Box display="flex" alignItems="center">
													Project Alias
													<Typography variant="body2" fontSize="0.75rem" sx={{ marginLeft: 1 }}>
														(Friendly Name)
													</Typography>
												</Box>
											}
											name="friendlyName"
											variant="outlined"
											autoComplete="off"
											value={formData.friendlyName}
											error={formData.friendlyName?.length > 45}
											helperText="Friendly Name can't contain more that 45 characters."
											onChange={(e) => handleChange(e, 'friendlyName')}
											onBlur={(e) => handleOnBlur(e, 'friendlyName')}
											onKeyDown={validateAliasInput}
											onPaste={handlePaste}
										/>
									</Grid>

									{isClarityProject && programData.action != 'EDIT' ? (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomTextField
													id="ProjectOwner"
													label="Project Owner"
													name="projectOwner"
													variant="outlined"
													autoComplete="off"
													value={formData.projectOwner}
													disabled
													required
													onChange={handleChange}
												/>
											</FormControl>
										</Grid>
									) : (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomAutocomplete
													loading={dropdownLoading.owner}
													options={ownerList}
													noOptionsText="No user found"
													value={formData.projectOwner}
													onChange={(e, val) => handleChange(e, val, 'projectOwner')}
													onInputChange={(e, val) => handleInputChange(e, 'projectOwner', val)}
													disabled={isDisabled.owner || isClarityProject && programData.action == 'EDIT' ? true : false}
													textFieldLabel="Project Owner *"
												/>
											</FormControl>
										</Grid>
									)}

									{/* {!isClarityProject && programData.action != 'EDIT' ? (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomTextField
													id="ProjectOwner"
													label="Project Owner"
													name="projectOwner"
													variant="outlined"
													autoComplete="off"
													value={formData.projectOwner}
													disabled
													required
													onChange={handleChange}
												/>
											</FormControl>
										</Grid>
									) : (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomAutocomplete
													loading={dropdownLoading.owner}
													options={ownerList}
													noOptionsText="No user found"
													value={formData.projectOwner}
													onChange={(e, val) => handleChange(e, val, 'projectOwner')}
													onInputChange={(e, val) => handleInputChange(e, 'projectOwner', val)}
													disabled={isDisabled.owner}
													textFieldLabel="Project Owner *"
												/>
											</FormControl>
										</Grid>
									)} */}
									<Grid item xs={6}>
										<CustomTextField
											id="ProjectDescription"
											label="Project Description"
											name="projectDescription"
											variant="outlined"
											autoComplete="off"
											disabled={isDisabled.description || isClarityProject ? true : false}
											value={formData.projectDescription}
											required
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xs={12}>
										<Box sx={{ position: 'relative', height: '22px', marginBottom: '5px', '&:before': { backgroundColor: 'rgba(0, 155, 216, 1)', content: '""', height: '1px', left: 0, position: 'absolute', top: '80%', transform: 'translateY(0)', width: '100%', zIndex: 1 } }}>
											<Typography variant="pageTitle" color="dark.main" style={{ backgroundColor: '#001e3e', color: 'rgba(0, 155, 216, 1)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px', fontWeight: '600', paddingRight: '20px', position: 'absolute', zIndex: 2, marginBottom: 0 }}> SLA Configurations
												<AntSwitch
													checked={toggleState}
													onChange={handleToggleChange}
													color="primary"
													sx={{
														zIndex: 2,
														margin: '0px 10px'
													}}
												/>
												<Tooltip sx={{ marginLeft: '10px' }} title={<BulletList />} arrow componentsProps={{
													tooltip: {
														sx: {
															maxWidth: '19rem',

														},
													},
												}}>
													<SLAInfoIcon style={{ height: '20px', width: '20px' }} />
												</Tooltip>

											</Typography>
										</Box>
										{toggleState && (
											<Box sx={{ marginTop: '10px', color: 'white' }}>
												<RadioGroup
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',

													}}
													value={selectedValue}
													onChange={handleRadioChange}
												>
													<FormControlLabel sx={{ marginRight: '77px' }} value="daily" control={<Radio sx={{
														'&.Mui-checked': {
															color: 'rgba(0, 155, 216, 1)',
														},
														color: 'rgba(245, 245, 245, 1)',
														// '& .MuiSvgIcon-root': { color: 'rgba(245, 245, 245, 1)' },

													}} />} label="Daily SLA" />
													<FormControlLabel sx={{ marginRight: '77px' }} value="weekly" control={<Radio sx={{
														color: 'rgba(245, 245, 245, 1)',
														'&.Mui-checked': {
															color: 'rgba(0, 155, 216, 1)',
														},
													}} />} label="Weekly SLAs" />
													<FormControlLabel
														value="monthly"
														control={
															<Radio
																sx={{
																	color: selectedValue === "monthly" ? 'blue' : 'white',
																	'&.Mui-checked': {
																		color: 'blue',
																	},
																}}
																disabled
															/>
														}
														label="Monthly SLA"
													/>
												</RadioGroup>
											</Box>
										)}

									</Grid>

									{selectedValue === 'daily' && toggleState && (
										<>
											<Grid item xs={4}>
												<FormControl fullWidth>
													<CustomTextField
														id="slaDate"
														label="SLA Date (Sch. Date+)"
														name="slaDate"
														variant="outlined"
														autoComplete="off"
														value={formData.slaDate == null ? 0 : formData.slaDate}
														type="number"
														inputProps={{
															max: 1,
															min: 0,
															step: 1,
															maxLength: 1,
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Tooltip title="Add" arrow>
																		<Box style={{ height: '20px', width: '20px' }} component="img" src={InfoIcon} alt="" />
																	</Tooltip>
																</InputAdornment>
															),
														}}
														onChange={(e) => {
															let value = e.target.value;
															if (value === '' || value === '0' || value === '1') {
																handleChange(e, '', 'slaDate');
															} else {
																e.target.value = '1';
																handleChange(e, '', 'slaDate');
															}
														}}
													/>
												</FormControl>
											</Grid>

											<Grid item xs={2}>
												<FormControl fullWidth>
													<CustomTimePicker
														label={'SLA Time'}
														onChange={(e) => handleChange(e, '', 'slaTime')}
														ampm={false}
														value={formData.slaTime || null}
														renderInput={(params) => (
															<TextField
																{...params}
																InputProps={{
																	...params.InputProps,
																	sx: {
																		'& .MuiOutlinedInput-notchedOutline': {
																			borderColor: 'white.main',
																			borderWidth: '1px',
																			borderStyle: 'solid',
																		},
																		'&:hover .MuiOutlinedInput-notchedOutline, &.Mui-error .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
																			borderColor: 'white.main',
																		},
																		'& .MuiSvgIcon-root': {
																			color: 'white',
																		},
																	},
																}}
																sx={{
																	background: 'primary.darker',
																	borderRadius: '8px',
																	input: { color: 'white' },
																	label: { color: 'rgba(255, 255, 255, 0.7)' },
																	fieldset: { borderColor: 'rgba(255, 255, 255, 0.3)' }, // Border color
																	'& .MuiOutlinedInput-root': {
																		color: '#fff', // White text color
																		borderRadius: '5px',
																		border: '1px solid white.main',
																	},
																	'& .MuiInputLabel-root, & .MuiSvgIcon-root': {
																		color: 'white.main', // Light label color
																	},
																	'& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
																		borderColor: 'white.main',
																	},
																}}
															/>
														)}
													/>
												</FormControl>
											</Grid>
										</>
									)}
									{selectedValue === 'weekly' && toggleState && (
										<>
											<Grid item xs={6}>
												<FormControl fullWidth>
													<InputLabel id="select-days-label-txt" sx={{ color: 'white.main' }}>
														Select Days
													</InputLabel>
													<Select
														labelId="select-days-label"
														multiple
														variant='outlined'
														label="Select Days"
														value={formDatadd.selectedDays}
														onChange={(e) => handleChangedd(e, '', 'selectedDays')}
														renderValue={(selected) => selected.join(', ')}
														sx={{
															width: '480px',
															height: '57px',
															background: 'primary.darker',
															borderRadius: '4px',
															color: 'white',
															'& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
																borderColor: 'white.main',

															},
															'& .MuiSvgIcon-root': {
																color: 'white',
															},

														}}
													>
														{[{ label: "All days", value: "Mon, Tue, Wed, Thu, Fri, Sat, Sun" }, { label: "Monday", value: "Mon" }, { label: "Tuesday", value: "Tue" }, { label: "Wednesday", value: "Wed" }, { label: "Thursday", value: "Thu" }, { label: "Friday", value: "Fri" }, { label: "Saturday", value: "Sat" }, { label: "Sunday", value: "Sun" }].map((day) => (
															<MenuItem key={day.value} disabled={day.value == 'Mon, Tue, Wed, Thu, Fri, Sat, Sun' ? tableData.length > 0 ? true : false : weeklyDaysArr.includes(day.value)} value={day.value} sx={{
																color: 'white',
																"&.Mui-disabled": {
																	color: 'rgba(113, 117, 143, 1)', // Change text color
																}
															}}>
																<Checkbox sx={{
																	"&.Mui-disabled": {
																		color: 'rgba(113, 117, 143, 1)', // Change text color
																	}
																}} disabled={day.value == 'Mon, Tue, Wed, Thu, Fri, Sat, Sun' ? tableData.length > 0 ? true : false : weeklyDaysArr.includes(day.value)} checked={formDatadd.selectedDays.filter((obj) => obj == 'Mon, Tue, Wed, Thu, Fri, Sat, Sun').length ? true : formDatadd.selectedDays.includes(day.value)} />
																{day.label}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Grid>
											<Grid item xs={3}>
												<FormControl fullWidth>
													<CustomTextField
														id="slaDate"
														label="SLA Date (Sch. Date+)"
														name="slaDate"
														variant="outlined"
														autoComplete="off"
														value={formData.slaDate == null ? 0 : formData.slaDate}
														type="number"
														inputProps={{
															max: 1, // Maximum limit
															min: 0, // Optional: Minimum limit
															step: 1,
															maxLength: 1,
														}}
														InputProps={{
															endAdornment: (
																<InputAdornment position="end">
																	<Tooltip title="Add" arrow>
																		<Box style={{ height: '20px', width: '20px' }} component="img" src={InfoIcon} alt="" />
																	</Tooltip>
																</InputAdornment>
															),
														}}
														onChange={(e) => {
															let value = e.target.value;
															if (value === '' || value === '0' || value === '1') {
																handleChange(e, '', 'slaDate');
															} else {
																e.target.value = '1';
																handleChange(e, '', 'slaDate');
															}
														}}
													/>
												</FormControl>
											</Grid>

											<Grid item xs={2}>
												<FormControl fullWidth>
													<CustomTimePicker
														label={'SLA Time'}
														onChange={(e) => handleChange(e, '', 'slaTime')}
														ampm={false}
														value={formData.slaTime || null}
														renderInput={(params) => (
															<TextField
																{...params}
																InputProps={{
																	...params.InputProps,
																	sx: {
																		'& .MuiOutlinedInput-notchedOutline': {
																			borderColor: 'white.main',
																			borderWidth: '1px',
																			borderStyle: 'solid',
																		},
																		'&:hover .MuiOutlinedInput-notchedOutline, &.Mui-error .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
																			borderColor: 'white.main',
																		},
																		'& .MuiSvgIcon-root': {
																			color: 'white', // Change the icon color to white
																		},
																	},
																}}
																sx={{
																	background: 'primary.darker', // Background color to match design
																	borderRadius: '8px',
																	input: { color: 'white' }, // Text color
																	label: { color: 'rgba(255, 255, 255, 0.7)' },
																	fieldset: { borderColor: 'rgba(255, 255, 255, 0.3)' }, // Border color
																	'& .MuiOutlinedInput-root': {
																		color: '#fff', // White text color
																		borderRadius: '5px',
																		border: '1px solid white.main',
																	},
																	'& .MuiInputLabel-root, & .MuiSvgIcon-root': {
																		color: 'white.main', // Light label color
																	},
																	'& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
																		borderColor: 'white.main',
																	},
																}}
															/>
														)}
													/>
												</FormControl>
											</Grid>
											<Grid item xs={1}>
												<Button disabled={!formDatadd.selectedDays?.length ? true : false} onClick={handleAddRow} variant="pepwiseSecondaryOutline2"
													sx={{
														height: '57px',
														width: '20px'
													}}
												>
													<AddIcon />
												</Button>
											</Grid>
										</>
									)}

									{tableData.length > 0 && selectedValue === 'weekly' && toggleState && (
										<Stack>
											<Box>
												<CustomizedDataGrid
													tableColumns={getColumns()}
													tableData={tableData}
													tableType="history-list"
													defaultColumnNames={defaultColumnNames}
													autoSetDraggedColumns={false}
													listLength={tableData.length}
													showExport={false}
													isDateRangeFilter={false}
													isDisabled={false}
													isRenderPagination={false}
													isShowMoreButton={false}
													style={{ maxHeight: '250px', marginLeft: '15px', marginTop: '15px', }}
													rowHeight={40}
												/>
											</Box>
										</Stack>
									)
									}


								</Grid>
							</DialogContent>

							<DialogActions>
								<Button onClick={(e) => handleClose('close')} variant="pepwiseOutline">
									CANCEL
								</Button>
								<Button disabled={!isProjectFormValid()} type="submit" variant="pepwiseSecondary">
									{saveBtnLoader ? 'SAVING...' : programData.action === 'EDIT' ? 'UPDATE & SAVE' : 'SAVE'}
								</Button>
							</DialogActions>
						</Box>
					);
				}

			case 'DELETE':
				return (
					<Box p={2} bgcolor="blue.darker">
						<DialogContent>
							<Stack direction="row" spacing={2}>
								<Box component="img" src={DeleteImage} alt="" />
								<Stack spacing={2}>
									<Typography fontSize="28px" fontWeight={600}>
										Are you sure to delete{' '}
										{programData.type === 'Program' ? (
											'this ' + programData.type + '?'
										) : programData.type === 'Project' ? (
											<Box component="span">
												this project from {' '}
												<Box component="span" sx={{ fontWeight: '800' }}>
													{programData.data.ProgramName}
												</Box>
												?
											</Box>
										) : programData.data.length > 1 ? (
											programData.data.length + ' jobs from the project?'
										) : (
											' this job from the project?'
										)}
									</Typography>
									<Typography variant="body1">Once confirmed, the action can't be undone.</Typography>
								</Stack>
							</Stack>
						</DialogContent>

						<DialogActions>
							<Button onClick={(e) => handleClose('close')} variant="pepwiseOutline">
								NO, I WANT IT!
							</Button>
							<Button onClick={onSubmit} variant="pepwiseError">
								{saveBtnLoader ? 'DELETING...' : 'DELETE'}
							</Button>
						</DialogActions>
					</Box>
				);

			default:
				break;
		}
	};

	const getToastMsg = () => {
		return (
			<Box p={2} bgcolor="blue.darker">
				<DialogContent>
					<Typography>{toastData.message}</Typography>
				</DialogContent>

				<DialogActions>
					<Button onClick={(e) => handleClose('close')} variant="pepwiseOutline">
						NO, I WANT IT!
					</Button>
					<Button onClick={onSubmit} variant="pepwiseError">
						{saveBtnLoader ? 'Deleting...' : 'Delete'}
					</Button>
				</DialogActions>
			</Box>
		);
	};

	return (
		<>
			<DialogTitle bgcolor="primary.main" sx={{ p: '8px 16px !important' }}>
				<Box display="flex" alignItems="center">
					<Typography variant="widgetTitle" flexGrow={1}>
						{getTitle()}
					</Typography>
					<Box>
						<IconButton onClick={(e) => handleClose('close')} sx={{ color: 'white.main' }}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>

			{!loading.project ? (
				toastData.message ? (
					getToastMsg()
				) : (
					getContent()
				)
			) : (
				<Box sx={{ padding: '40px 20px', textAlign: 'center', fontSize: '14px', backgroundColor: '#0c0f25' }}>
					Fetching Data...
				</Box>
			)}
			<Dialog open={openConfirmationDialog} onClose={handleCloseConfirmationDialog}
				fullWidth={true} maxWidth="md">
				<Box p={2} bgcolor="blue.darker">
					<DialogTitle bgcolor="primary.main" sx={{ p: '8px 16px !important', marginBottom: '15px' }}>
						<Box display="flex" alignItems="center">
							<Typography variant="widgetTitle" flexGrow={1}>
								{'Updating SLA Time To: ScheduleDate + ' + (dialogData?.slaDt) + (dialogData?.slaTe ? ' | ' + formatTime(Number(dialogData?.slaTe)) : '')}
							</Typography>
							<Box>
								<IconButton onClick={handleCloseConfirmationDialog} sx={{ color: 'white.main' }}>
									<CloseIcon />
								</IconButton>
							</Box>
						</Box>
					</DialogTitle>
					<DialogContent>
						<Stack direction="row" spacing={2}>
							<Box component="img" src={DeleteImage} alt="" />
							<Stack spacing={2}>
								<Typography fontSize="28px" fontWeight={600}>
									{'Are you sure to update SLA time for '}
									<Box component="span">
										<Box component="span" sx={{ fontWeight: '800' }}>
											{dialogData?.slaProName}
										</Box>
										{' Project?'}
									</Box>
								</Typography>
								<Typography variant="body1">Once confirmed, the action can't be undone.</Typography>
							</Stack>
						</Stack>
					</DialogContent>

					<DialogActions sx={{ margin: '5px 0px' }}>
						<Button onClick={handleCloseConfirmationDialog} color='primary' sx={{ color: 'rgba(0, 155, 216, 1)' }}>
							CANCEL
						</Button>

						{/* Force Update Button */}
						<Button onClick={handleForceUpdate} variant="pepwiseError">
							FORCE UPDATE
						</Button>
					</DialogActions>
				</Box>
			</Dialog>
		</>
	);
}
