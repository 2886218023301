import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import { colorsDark } from 'assets/theme/colorUtil';
import LoaderComponent from 'components/Loader';

interface ColourProps extends BoxProps {
	value: number | string;
	title: string;
	isLoading?: boolean;
	boxColor?: keyof typeof colorsDark.boxColor;
	labelProps?: TypographyProps;
	valueProps?: TypographyProps;
}

const DataInfoColorWidget = ({ value, title, boxColor, labelProps, valueProps, isLoading, ...props }: ColourProps) => {
	return (
		<Box
			{...props}
			sx={{
				display: 'flex',
				flexGrow: 1,
				borderRadius: '4px',
				padding: '12px 8px',
				backgroundColor: !!boxColor ? colorsDark.boxColor[boxColor].bgColor : 'primary.main',
				position: 'relative',
				overflow: 'hidden',
				...props.sx
			}}
		>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
          flexGrow: 1,
					flexDirection: 'column',
					justifyContent: 'center',
					gap: 0.5,
					zIndex: 1
				}}
			>
				<Typography fontWeight={500} fontSize="16px" lineHeight="normal" {...labelProps}>
					{title}
				</Typography>
				{!!isLoading ? (
					<LoaderComponent size={20}/>
				) : (
					<Typography fontWeight={700} fontSize="24px" lineHeight="24px" {...valueProps}>
						{value}
					</Typography>
				)}
			</Box>
			<Box
				sx={{
					position: 'absolute',
					right: 0,
					top: 0,
					bottom: 0,
					width: '8px',
					backgroundColor: !!boxColor ? colorsDark.boxColor[boxColor].color : 'primary.main',
					opacity: 0.5
				}}
				className='colorIntimation'
			/>
		</Box>
	);
};

export default DataInfoColorWidget;
