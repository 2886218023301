import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import { Grid, IconButton, Box, Paper, Divider, Chip, Menu, MenuItem, Typography } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import moment from 'moment-timezone';
import { ThemeProvider, styled } from '@mui/material/styles';
import DialogModal from '../Modal/DialogModal'

import '../DataPipeline/dataPipeline.scss';
import '../DataEstate/data-estate.scss';
import { getKpiFrameworkConfigDetails, getKpiFrameworkIngestData } from '../../service/service';
import CustomDashboardIcon from '../../assets/Custom_Dashboard.svg';
import SortAscending from '../../assets/heroicons-solid_sort-descending-3.png';
import SortDecending from '../../assets/heroicons-solid_sort-descending-1.png';
import useStyles from '../landingScreen/headerStyles';
import Loader from '../Loader/loader';
import theme from './theme';
import { useParams } from 'react-router-dom';
import { applyValue, deleteValue, JsonViewer, JsonViewerOnAdd, JsonViewerOnChange, JsonViewerOnDelete } from '@textea/json-viewer';
import { useCallback } from 'react';
import PageHeader from 'components/Header/Page';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import { exportToXlsx } from 'components/exportUtils';
import { id } from 'date-fns/locale';
import DeleteConfirmationDialog from './DeleteModal';
import DateTimeFilter from 'components/AlertKpi/DateTimeFilter';

interface KPIConfig extends GridValidRowModel {
	_id: string;
	name: string;
	description: string;
	last_run_time: string;
	next_run_time: string;
	frequency: string;
	isActive: boolean;
}

interface KPIResult extends GridValidRowModel {
	id: string;
	metricName: string;
	metricValue: string;
}

const KPIDetails = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [configDetails, setConfigDetails] = useState<KPIConfig>();
	const [kpiResults, setKpiResults] = useState<KPIResult[]>([]);
	const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const {id: configId} = useParams<{id: string}>()
	const [showSourceDialog, setShowSourceDialog] = useState(false)
	const [showTargetDialog, setShowTargetDialog] = useState(false)
	const [sourceConfig, setSourceConfig] = useState<any>({})
	const [totalCount, setTotalCount] = useState(0)
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
	const initStartDate = moment().subtract(1, 'months')
    const initEndDate = moment()
    const [fromDateTime, setFromDateTime] = useState<any>(initStartDate);
    const [toDateTime, setToDateTime] = useState<any>(initEndDate)
	const defaultBody = {page: 1, pageSize: 10, sortColumn: sortingInfo.sortBy , sortDir: sortingInfo.sortDir, filter: {startDate: initStartDate, endDate: initEndDate}}

	let defaultColumns: any = [];

	const handleSorting = (sortingInfo: any) => {
		if(sortingInfo.sortBy === 'createdDt' || sortingInfo.sortBy === ''){
			setSortingInfo({ sortBy: sortingInfo.sortBy, sortDir: sortingInfo.sortDir });
			fetchKpiResult({ ...defaultBody, sortColumn: sortingInfo.sortBy, sortDir: sortingInfo.sortDir, filter: {startDate: fromDateTime, endDate: toDateTime} });
		}
	};

	const handlePageChange = (data:any, page:any) => {
        setPage(page)
        fetchKpiResult({page, pageSize, sortColumn: sortingInfo.sortBy, sortDir: sortingInfo.sortDir, filter: {startDate: fromDateTime, endDate: toDateTime}})
    }

    const handlePageSizeChange = (event:any) => {
        setPageSize(parseInt(event.target.value))
        fetchKpiResult({page, pageSize: event.target.value, sortColumn: sortingInfo.sortBy, sortDir: sortingInfo.sortDir, filter: {startDate: fromDateTime, endDate: toDateTime}})
    }

	const handleTimeFilter = () => {
		setPage(1)
        fetchKpiResult({page: 1, pageSize, sortColumn: sortingInfo.sortBy, sortDir: sortingInfo.sortDir, filter: {startDate: fromDateTime, endDate: toDateTime}})
    }

    const clearTimeFilter = () => {
        setFromDateTime(initStartDate)
        setToDateTime(initEndDate)
        setPage(1)
        fetchKpiResult({page: 1, pageSize, sortColumn: sortingInfo.sortBy, sortDir: sortingInfo.sortDir, filter: {startDate: initStartDate, endDate: initEndDate}})
    }

	function getColumns() {
		const columnNames = kpiResults?.length ? Object.keys(kpiResults[0]) : []
		const columns:any = kpiResults?.length ? columnNames.map(metricName => {
			const name = metricName === 'createdDt' ? 'Created At' : metricName === 'ingestId' ? 'Ingest ID' : metricName
			defaultColumns.push(name)
			return {
				key: metricName,
				name,
				minWidth: 100,
				width: 200,
				sortable: false,
				formatter({row}:any){
					const data = row[metricName]
					return (Array.isArray(data) ? data.length : data)
				}
			}
		}) : []
		// columns.push({ key: "_id", name: "Ingest ID", minWidth: 50, width: 180, sortable: false})
		// defaultColumns.push('Created At')
		// columns.push({ key: "createdAt", name: "Created At", minWidth: 95 ,
		// 	formatter({row}:any){
		// 		return (moment(row.createdAt).format('yyyy-MM-DD HH:mm'))        
		// 	}
		// })
		return columns
	}
	const fetchKpiResult = async (body: any) => {
		if(body.sortColumn === 'createdDt'){
			body.sortColumn = 'createdAt'
		}
		setLoading(true)
		const response = configId ? await getKpiFrameworkIngestData(body, configId?.toString()) : {data : []}
		setLoading(false)
		if(response.data?.data?.length){
			const data = response.data.data.reduce((finalData: any, item: any) => {
				finalData.push({ ingestId: item._id, ...item.kpiResult, createdDt: moment(item.createdAt).format()})
				return finalData
			},[])
			setKpiResults(data)
			setTotalCount(response.data.totalCount)
		}	
	}

	useEffect(() => {
		const fetchKpiConfigDetails = async () => {
			setLoading(true);
			try {
				const result = configId ? await getKpiFrameworkConfigDetails(configId?.toString()) : {data : []};
				setConfigDetails(result.data?.kpiConfig || {});
				if(result.data?.kpiConfig?.source_config){
					setSourceConfig(result.data.kpiConfig.source_config)
				}				
			} catch (error) {
				console.error('Error fetching dashboard data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchKpiConfigDetails();
		fetchKpiResult(defaultBody)
	}, []);

	const CardItem = ({label, text}: any) => {
		return (
			<Grid item xs style={{ cursor: 'pointer', paddingLeft: 8, marginLeft: 8 }}>
				<div style={{ height: '100%'}}>
					<Typography variant="subtitle1" style={{ opacity: 0.7 }}>
						{label}
					</Typography>
					<Typography style={{ fontSize: '11px' }}>{text}</Typography>
				</div>
			</Grid>
		)
	}

	return (
		<Box bgcolor="blue.darker" mt="80px" mb={2}>			
			<PageHeader
				title={[{ text: 'KPIs', link: '/kpiFramework/configs' }, { text: `${configDetails?.name}` }]}
				icon={CustomDashboardIcon}
			>
				<DateTimeFilter 
					fromDateTime={fromDateTime} 
					setFromDateTime={setFromDateTime} 
					toDateTime={toDateTime} 
					setToDateTime={setToDateTime} 
					handleTimeFilter={handleTimeFilter}
					clearTimeFilter={clearTimeFilter}/>
			</PageHeader>
			<DialogModal title="Source Config" isOpen={showSourceDialog} handleClose={() => setShowSourceDialog(false)}>
				<JsonViewer
					editable
					style={{ textAlign: 'left' }}
					theme={'dark'}
					value={sourceConfig}
					onAdd={useCallback<JsonViewerOnAdd>((path) => {
						const key = prompt('Key:');
						if (key === null) return;
						const value = prompt('Value:');
						if (value === null) return;
						setSourceConfig((src: any) => applyValue(src, [...path, key], value));
					}, [])}
					onChange={useCallback<JsonViewerOnChange>((path, oldValue, newValue) => {
						setSourceConfig((src: any) => applyValue(src, path, newValue));
					}, [])}
					onDelete={useCallback<JsonViewerOnDelete>((path, value) => {
						setSourceConfig((src: any) => deleteValue(src, path, value));
					}, [])}
				/>
			</DialogModal>
			<DialogModal title="Target Config" isOpen={showTargetDialog} handleClose={() => setShowTargetDialog(false)}>
				<JsonViewer style={{ textAlign: 'left' }} theme={'dark'} value={configDetails?.target_config} />
			</DialogModal>

			<Grid container spacing={1} style={{ marginTop: '0', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto',  backgroundColor: '#003771' }}>
				<CardItem label={'ID'} text={configDetails?._id}/>
				<CardItem label={'Sector'} text={configDetails?.sector}/>
				<CardItem label={'Program'} text={configDetails?.program}/>
				<CardItem label={'Project'} text={configDetails?.project}/>
				<CardItem label={'Frequency'} text={`${configDetails?.update_frequency_value} ${configDetails?.update_frequency_unit}`}/>
				
				<Grid item xs style={{ cursor: 'pointer', paddingLeft: 8, marginLeft: 8 }}>
					<div style={{ display: 'flex', alignItems: 'center',  backgroundColor: '#003771', height: '100%'}}>
						<div style={{ marginRight: '8px' }}>
							<Typography variant="subtitle1" style={{ opacity: 0.7 }}>
							  Source
							</Typography>
							<Typography style={{ fontSize: '11px' }}>{configDetails?.source_config?.type}</Typography>
						</div>
						<IconButton onClick={() => setShowSourceDialog(true)}>
							<PreviewIcon />
						</IconButton>
					</div>
				</Grid>
				<Grid item xs style={{ cursor: 'pointer', paddingLeft: 8, marginLeft: 8}}>
					<div style={{ display: 'flex', alignItems: 'center',  backgroundColor: '#003771', height: '100%'}}>
						<div style={{ marginRight: '8px' }}>
							<Typography variant="subtitle1" style={{ opacity: 0.7 }}>
								Target Type
							</Typography>
							<Typography style={{ fontSize: '11px' }}>{configDetails?.target_config?.type}</Typography>
						</div>
						<IconButton onClick={() => setShowTargetDialog(true)}>
							<PreviewIcon />
						</IconButton>
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={1} style={{ marginTop: '0', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto',  backgroundColor: '#003771' }}>				
				<CardItem label={'Status'} text={configDetails?.isActive ? 'Active' : 'Inactive'}/>
				<CardItem label={'Last run time'} text={configDetails?._id}/>
				<CardItem label={'Next run time'} text={configDetails?.sector}/>				
				<CardItem label={'Created by'} text={configDetails?.createdByUser?.[0] || ''}/>
				<CardItem label={'Updated by'} text={configDetails?.updatedByUser?.[0] || ''}/>
				<CardItem label={'Created at'} text={configDetails?.createdAt ? moment(configDetails.createdAt).format() : ''}/>
				<CardItem label={'Updated at'} text={configDetails?.updatedAt ? moment(configDetails.updatedAt).format() : ''}/>
			</Grid>
			<Grid container spacing={1} style={{ marginTop: '0', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto',  backgroundColor: '#003771' }}>				
				<CardItem label={'Description'} text={configDetails?.description}/>
			</Grid>
			<p style={{marginLeft: 8, marginTop: 32, marginBottom: -32, padding: 0}}>KPI Output</p>
			{loading ? (
				<Loader />
			) : (
				<CustomizedDataGrid
					tableColumns={getColumns()}
					tableData={kpiResults || []}
					tableType="KPIResults"
					defaultColumnNames={defaultColumns}
					listLength={totalCount || 0}
					page={page}
					pageSize={pageSize}
					paginationData={{ totalElements: totalCount, totalPages: Math.ceil(totalCount / pageSize) }}
					onSorting={handleSorting}
					currSorting={sortingInfo}
					// onFilterCheck={handleFilter}
					// currFilter={filterInfo}
					showExport={true}
					fileName={`KPIResults-${moment().format()}.xlsx`}
					exportTableData={() => exportToXlsx(`KPIResults-${moment().format()}.xlsx`, kpiResults, getColumns())}
					// searchDataValues={searchDetails}
					// onFilterSearch={handleSearch}
					// onCellEdit={handleEditCell}
					handlepageSizeChange={handlePageSizeChange}
					handlePageChange={handlePageChange}
					isDateRangeFilter={true}
					isDisabled={false}
				/>
			)}
		</Box>
	);
};
	
export default KPIDetails;
