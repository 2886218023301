import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './dataPipeline.scss';
import '../LandingPage/LandingPage.scss';
import '../DataPipeline/dataPipeline.scss';
import { Box, Chip, Grid, LinearProgress, TextField, Tooltip, Typography } from '@mui/material';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import moment, { utc } from 'moment';
import { ReactComponent as AlertIndicator } from '../../assets/alert-indeicator.svg';
import { ReactComponent as OnTractIndicator } from '../../assets/on-tract-indicator.svg';
import { ReactComponent as ComingSoonInfo } from '../../assets/coming-soon-info.svg';
import SectorFilter from 'components/Dropdown/SectorFilter';
import { getContactDetails, getExecutiveProgramIdData, getExecutiveTimelineData, getProgramSectorList } from 'service/service';
import ProgramDetailsCard from './program-details-card';
import Loader from 'components/Loader/loader';
import { isEnvTypeUAT } from 'service/config';

export default function ExecutiveOps() {
    const [startDate, setStartDate] = useState(moment().tz("America/Chicago").startOf('day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().tz("America/Chicago").endOf('day').format('YYYY-MM-DD'));
    const [sectorName, setSectorName] = useState('PBNA');
    const [sectorList, setSectorList] = useState<any[]>([]);
    const hiddenSector = "SODASTREAM";
    const filteredSectorList = sectorList.filter(sector => sector !== hiddenSector);
    const [sectorMasterList, setSectorMasterList] = useState<any[]>([]);
    const [progress, setProgress] = useState<any>(0)
    const [timeLineInput, setTimeLineInput] = useState<any>([])
    const [timelineLoader, setTimelineLoader] = useState<any>(true)
    const [programId, setProgramId] = useState<number>();
    const [statusCount, setStatusCount] = useState<any[]>();
    const [contactDetails, setContactDetails] = useState<any[]>([]);
    const [indicatorStatus, setIndicatorStatus] = useState<any>('On Track');

    const [programContactData, setProgramContactData] = useState<any>();
    const [exeLastSync, setExeLastSync] = useState('');
    let currentTime = moment().tz('America/Chicago').format()
    const navigate = useNavigate();

    useEffect(() => {
        const date = new Date();
        date.setMinutes(date.getMinutes() - 10);

        const options: Intl.DateTimeFormatOptions = {
            weekday: 'short', // "Fri"
            day: '2-digit', // "01"
            month: 'short', // "Nov"
            year: 'numeric', // "2024"
            hour: '2-digit', // "10"
            minute: '2-digit', // "00"
            hour12: true, // 12-hour format
            timeZone: 'America/Chicago'
        };

        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
        const [dayOfWeek, day, month, year, time] = formattedDate.split(' ');
        // Format the time to append AM/PM
        const [hours, minutes] = time.split(':');
        const period = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';

        const exelastsync = `${dayOfWeek} ${day} ${month} ${year} ${hours}:${minutes} ${period}`;

        setExeLastSync(exelastsync);

        const activeId = document.getElementById('root');
        if (window.location.pathname.includes('/data-pipeline/executiveOps')) {
            if (activeId) {
                document.head.classList.add('theme-bg-blue');
                document.body.classList.add('theme-bg-blue');
            }
        }

        if (localStorage.getItem('filterSectors') && localStorage.getItem('sectorsMasterData')) {
            setSectorList(JSON.parse(localStorage.getItem('filterSectors') ?? ''));
            setSectorMasterList(JSON.parse(localStorage.getItem('sectorsMasterData') ?? ''));
        } else {
            getSectorData();
        }
        getContactDetailsData()
        getExecutiveProgramId()
    }, []);

    const getExecutiveProgramId = async () => {
        try {
            let response = await getExecutiveProgramIdData();
            const pgrmId = response.data.programData[0]?.program_id;
            setProgramId(pgrmId)
            setStatusCount(JSON.parse(response.data.programData[0]?.statusCount));
            getTimelineData('', pgrmId)
        } catch (error) {
            throw error
        }
    }

    const getContactDetailsData = async () => {
        try {
            let response = await getContactDetails();
            setContactDetails(response.data.details)
            setProgramContactData(response.data.details.find((d: any) => d.type == 'program'))
        } catch (error) {
            throw error
        }
    }

    const getTimelineData = async (dateValue?: string, pgrmId?: any) => {
        setTimelineLoader(true)
        try {

            let response = await getExecutiveTimelineData(pgrmId, dateValue || startDate, sectorName)
            setTimelineLoader(false)
            if (response.success) {
                let data = [response.data.result[0], {
                    "Program_ID": 77,
                    "projectId": 1495,
                    "slaStatus": "Scheduled",
                    "Schedule_Date": "2025-03-26T00:00:00.000Z",
                    "projectName": "MDT",
                    "sectors": "PBNA",
                    "slaTime": "2025-03-27 08:30",
                    "colorCode": "rgba(113, 117, 143, 1)",
                    "timePosition": 29.166666666666668
                },
                response.data.result[1], response.data.result[2]]
                let pbnaHasSLAMet = false;
                data.map((d) => {
                    if (d.projectName === 'PBNA EDW' && d.slaStatus === 'SLA Met') {
                        pbnaHasSLAMet = true;
                    }
                    if (d.slaStatus == 'In Progress' || d.slaStatus == 'SLA Pending') {
                        d.colorCode = 'rgba(236, 185, 20, 1)'
                    }
                    return d
                })
                setTimeLineInput(data)
                let timeLine = data
                let statusData = timeLine.map((t) => t.slaStatus)
                // let statusData = ['SLA Missed']
                if (pbnaHasSLAMet) {
                    setIndicatorStatus('On Track')
                } else if (statusData.includes('SLA Missed')) {
                    setIndicatorStatus('At Risk')
                }
                if (timeLine && timeLine.length) {
                    console.log("HEREEEE", timeLine[timeLine.length - 1].slaTime, timeLine[0].slaTime)
                    let timelineRange = moment(timeLine[timeLine.length - 1].slaTime).diff(timeLine[0].slaTime);
                    let currentTimeRange = moment(timeLine[timeLine.length - 1].slaTime).diff(moment().tz("America/Chicago").format('YYYY-MM-DD HH:mm'))
                    // // let currentTimeRange = moment().tz("America/Chicago").diff(timeLine[0].slaTime)
                    // // let currentTimeRange = moment('2025-03-27T06:31:00.000Z').utc().diff(timeLine[0].slaTime)
                    // let progressData = (currentTimeRange / timelineRange) * 100
                    // let timelineRange = moment(timeLine[timeLine.length - 1].slaTime).diff(moment(timeLine[0].slaTime));
                    // let currentTimeRange = moment(timeLine[timeLine.length - 1].slaTime).diff(moment().tz("America/Chicago"));

                    let progressData = ((timelineRange - currentTimeRange) / timelineRange) * 100;

                    setProgress(progressData <= 0 ? 0 : progressData >= 100 ? 100 : progressData)
                }
            }
        } catch (error) {
            setTimelineLoader(false)
            throw error
        }

    }

    const getSectorData = async () => {
        let sectors: any = await getProgramSectorList();
        const filterSectors = sectors.data.sectors.map((sec: any) => sec.Sector_Name);
        localStorage.setItem('filterSectors', JSON.stringify(filterSectors));
        localStorage.setItem('sectorsMasterData', JSON.stringify(sectors.data.sectors));
        setSectorList(filterSectors);
        setSectorMasterList(sectors.data.sectors);
    };

    const handleSectorChange = (value: string) => {
        setSectorName(value);

        localStorage.setItem('selectedSector', value);
        let sectorId;
        if (value != 'all') {
            sectorId = sectorMasterList.filter((s: any) => s.Sector_Name == value)[0].Sector_Id
        }
        getTimelineData('', programId)
    };

    return (
        <Box bgcolor="blue.darker" mt="60px">
            <PageHeader title="PBNA Supply Chain" icon={DashboardIcon} isDataRefresh={false} executiveData={true} executiveContact={programContactData} exeLastSync={exeLastSync}>
                <SectorFilter
                    sectorName={sectorName}
                    setSectorName={setSectorName}
                    sectorOptionsList={filteredSectorList}
                    handleChange={handleSectorChange}
                    boxProps={{
                        sx: { minWidth: '200px', position: 'static' }, disabled: true
                    }} />

            </PageHeader>

            {
                timelineLoader ? <Loader /> : <Box p={3} mt={2} pb={.4}>
                    <Box className='timeline-class'>
                        <Grid container>
                            <Grid style={{ display: 'flex' }} item xs={4}>
                                <Typography style={{ fontSize: '16px', fontWeight: '700', margin: '0px 10px 25px 0px', color: 'rgba(229, 229, 229, 1)' }}>Current Status</Typography>
                                {indicatorStatus == 'On Track' ? <OnTractIndicator className='on-track-indicator' style={{ marginTop: '2px' }} height='20px' width='20px' /> : <AlertIndicator className='alert-indicator' style={{ marginTop: '2px' }} height='20px' width='20px' />}
                            </Grid>
                            <Grid item xs={8}>
                                <Box display="flex" className="legend">
                                    {/* <Typography className="legend-item"><span className="soon-bg-color color-box"></span>Coming Soon</Typography> */}
                                    <Typography className="legend-item"><span className="success-bg-color color-box"></span>SLA Met</Typography>
                                    <Typography className="legend-item"><span className="risk-bg-color color-box"></span>SLA To Be Determined</Typography>
                                    <Typography className="legend-item"><span className="fail-bg-color color-box"></span>SLA Missed</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ position: "relative", width: "100%" }}>
                            <LinearProgress variant="determinate" value={progress} sx={{
                                height: 15,
                                borderRadius: "4px",
                                backgroundColor: "rgba(0, 35, 73, 1)",
                                "& .MuiLinearProgress-bar": {
                                    backgroundColor: "rgba(0, 155, 216, 1)",
                                },
                            }} />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    position: "relative",

                                }}
                            >

                                {
                                    timeLineInput.map((data: any, index: number) => {
                                        return (


                                            <Box
                                                key={index}
                                                sx={{
                                                    position: "absolute",
                                                    top: "-5px",
                                                    textAlign: index === 0 ? 'start' : index === timeLineInput.length - 1 ? 'end' : 'center',
                                                    transform: index === 0 || index === timeLineInput.length - 1 ? 'none' : "translateX(-50%)",
                                                    ...(index === timeLineInput.length - 1
                                                        ? { right: "0" }
                                                        : { left: index === 0 ? "0" : `${data.timePosition}%` }),

                                                    marginTop: "5px",
                                                    width: 'max-content'
                                                }}
                                            >
                                                <Box>
                                                    {/* <Typography style={{ rotate: '90' }}>-</Typography> */}
                                                    {data.projectName != 'MDT' && <Box
                                                        sx={{
                                                            position: "absolute",
                                                            top: "0",
                                                            left: `${data.timePosition}%`,
                                                            width: "2px",
                                                            height: "5px",

                                                            backgroundColor: "#fff",
                                                            transform: "translateX(-50%)",
                                                        }}
                                                    />}
                                                    <Typography fontSize={14} style={{ fontFamily: 'Montserrat', fontSize: '12px', marginTop: '5px', fontWeight: '600', color: 'rgba(255, 255, 255, 1)' }}>
                                                        {data.projectName == 'MDT' ? ' - ' : 'SLA Time: ' + moment(data.slaTime).format("hh:mm A") + ' ' + 'CST'}</Typography>
                                                    <Typography fontSize={14} style={{ fontWeight: '600', fontSize: '12px', color: 'rgba(255, 255, 255, 1)' }}> {data.actualCompletionTime ? 'Actual Completion Time: ' + moment(data.actualCompletionTime).utc().format("hh:mm A") + ' ' + 'CST' : ' '}</Typography>
                                                    <Chip style={{ margin: data.actualCompletionTime ? '5px 0px 12px' : '25px 0px 12px', maxWidth: 'inherit' }} label={
                                                        <Box display="flex" alignItems="center">
                                                            {data.projectName}
                                                            {data.projectName == 'MDT' && <Tooltip title='Coming Soon' arrow><ComingSoonInfo style={{ marginLeft: 4 }} /></Tooltip>}
                                                        </Box>
                                                    } sx={{ backgroundColor: `${data.colorCode} !important`, color: "#fff", fontSize: '16px !important', fontWeight: '700 !important' }} />
                                                    {/* {
                                                        data.projectName != 'MDT' ?
                                                            :
                                                            <Chip deleteIcon={<ComingSoonInfo />} style={{ margin: data.actualCompletionTime ? '5px 0px 12px' : '25px 0px 12px', maxWidth: 'inherit' }} label={data.projectName} sx={{ backgroundColor: `${data.colorCode} !important`, color: "#fff", fontSize: '16px !important', fontWeight: '700 !important' }} />
                                                    } */}

                                                    {/* <Box className='heatlth-chip' sx={{ display: 'flex', justifyContent: 'center', padding: '5px', alignItems: 'center', borderRadius: '20px' }}>
                                                        <Typography style={{ fontSize: '12px', fontWeight: '600', color: 'rgba(169, 173, 203, 1)', marginRight: '5px' }}>HEALTH: COMING SOON</Typography>
                                                        <CommingSoonIcon />
                                                    </Box> */}
                                                </Box>
                                            </Box>

                                        )
                                    })
                                }


                            </Box>

                            {/* {
                                moment().tz('America/Chicago').format("YYYY-MM-DD") == moment(startDate).format("YYYY-MM-DD") ? <Box
                                    sx={{
                                        position: "absolute",
                                        top: "-5px",
                                        left: `${progress}%`,
                                        width: "2px",
                                        height: "5px",

                                        backgroundColor: "#fff",
                                        transform: "translateX(-50%)",
                                    }}
                                /> : ''
                            } */}



                            {/* <Box
                                sx={{
                                    position: "absolute",
                                    top: "-21px",
                                    left: `${progress < 6 ? (5 + progress) : progress > 88 ? (progress - 5) : progress > 94 ? (progress - 20) : progress}%`,
                                    transform: "translateX(-50%)",
                                }}
                            >

                                <Typography fontSize={12} style={{ width: 'max-content', fontWeight: '500' }}>
                                    {
                                        moment().tz('America/Chicago').format("YYYY-MM-DD") == moment(startDate).format("YYYY-MM-DD") ? `Current Time ${moment().tz('America/Chicago').format("hh:mm A")} CST` : ''
                                    }

                                </Typography>
                            </Box> */}

                        </Box>

                    </Box>
                </Box>
            }



            <Box p={3} mt={1} pt={0}>
                {programId && <ProgramDetailsCard programId={programId} contactDetails={contactDetails} statusCount={statusCount} />}
            </Box>
        </Box>
    )
}