import { Box, Divider, Grid, Typography } from '@mui/material';
import PageHeader from 'components/Header/Page';
import React, { useEffect, useRef, useState } from 'react';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import SectorFilter from 'components/Dropdown/SectorFilter';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import PieWidget from 'components/Widgets/PieWidget';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import LoaderComponent from 'components/Loader';
import { Column } from 'react-data-grid';
import MimsFilter from 'components/Dropdown/MimsFilter';
import Insights from '../DataEstate/Insights';
import ExecutiveTabs from '../DataEstate/ExecutiveTabs';
import { addOrUpdateInsights, deleteInsights, getInsights } from 'service/service';

const platformExecutiveView = () => {
    const [sectorName, setSectorName] = useState('all');
    const [sectorList, setSectorList] = useState<any[]>([]);
    const [customMonth, setCustomMonth] = useState('');
    const [filterMonth, setFilterMonth] = useState('1M');
    const handleDateChange = () => {};
    const handleSectorChange = () => {};
    const [majorProblemLoading, setMajorProblemLoading] = useState(true);
    const [majorProblemSeriesData, setMajorProblemSeriesData] = useState<any>([]);
    const [insights, setInsights] = useState<Insights[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    interface Insights {
        insightId: number;
        title: string;
        description: string;
        opsType: string;
        createdBy: string;
        updatedBy: string;
        createdDate: string;
        updatedDate: string;
    }

    interface Row {
        technology: string;
        regularmim: string;
        preventivemim: string;
    }

    function getColumns(): Array<Column<Row>> {
        return [
            { key: 'technology', name: 'Technology', resizable: true, width: 'auto' },
            { key: 'regularmim', name: 'Regular MIM', resizable: true, width: 'auto' },
            { key: 'preventivemim', name: 'Preventive MIM', resizable: true, width: 'auto' }
        ];
    }
    const defaultColumnNames = ['Technology', 'Regular MIM', 'Preventive MIM'];

    const mockData = [
        { id: 1, technology: 'AI', regularmim: '5', preventivemim: '3' },
        { id: 2, technology: 'Cloud', regularmim: '7', preventivemim: '4' },
        { id: 3, technology: 'Cybersecurity', regularmim: '6', preventivemim: '2' }
    ];

    //Function to fetch insights
    const fetchInsights = async () => {
        setLoader(true);
        try {
            const response = await getInsights('Platform Summary');
            if (response.success) {
                setInsights(response.data.data);
                setLoader(false);
                console.log('response', response);
            } else {
                setLoader(false);
            }
        } catch (error) {
            console.error('Failed to get insights:', error);
        }
    };

    // Fetch insights
    useEffect(() => {
        fetchInsights();
    }, []);

    // Function to handle adding a new insight
    const handleAddInsight = async (newInsight: any) => {
        try {
            setLoader(true);
            const response = await addOrUpdateInsights({
                title: newInsight?.title,
                xopsType: 'Platform Summary',
                description: newInsight?.description
            });
            if (response.success) {
                console.log('response', response);
                fetchInsights();
            } else {
                setLoader(false);
            }
        } catch (error) {
            console.error('Failed to add insight:', error);
        }
    };

    // Function to handle updating an insight
    const handleUpdateInsight = async (updatedInsight: any) => {
        try {
            setLoader(true);
            const response = await addOrUpdateInsights({
                title: updatedInsight?.title,
                xopsType: 'Platform Summary',
                description: updatedInsight?.description,
                insight_id: updatedInsight?.id
            });
            if (response.success) {
                console.log('response', response);
                fetchInsights();
            } else {
                setLoader(false);
            }
        } catch (error) {
            console.error('Failed to update insight:', error);
        }
    };

    // Function to handle deleting an insight
    const handleDeleteInsight = async (insightId: number) => {
        try {
            setLoader(true);
            const response = await deleteInsights(insightId);
            if (response.success) {
                console.log('response', response);
                fetchInsights();
            } else {
                setLoader(false);
            }
        } catch (error) {
            console.error('Failed to delete insight:', error);
        }
    };

    // Mock data for PieWidget
    useEffect(() => {
        const fetchData = async () => {
            setMajorProblemLoading(true);
            // Simulating data fetch delay
            setTimeout(() => {
                setMajorProblemSeriesData([
                    { name: 'Root cause Ananlysis', value: 40 },
                    { name: 'Fix In Progress', value: 30 },
                    { name: 'Closed', value: 20 }
                ]);
                setMajorProblemLoading(false);
            }, 1000);
        };
        fetchData();
    }, []);
    return (
        <Box bgcolor="blue.darker" mt="90px">
            {/* <ExecutiveTabs /> */}
            {/* <PageHeader
                title={[
                    {
                        text: 'Executive Summary',
                        link: '/D&AIXOps/executiveView'
                    },
                    {
                        text: decodeURIComponent(window.location.pathname.split('/')[3].replace(/%20/g, ' '))
                    }
                ]}
                icon={DashboardIcon}
                isDataRefresh={true}
            >
                <MimsFilter
                    sectorName={sectorName}
                    setSectorName={setSectorName}
                    sectorOptionsList={sectorList}
                    handleChange={handleSectorChange}
                    boxProps={{
                        sx: { minWidth: '200px', position: 'static' }
                    }}
                />
                <DateFilterDropdown
                    customMonth={customMonth}
                    filterMonth={filterMonth}
                    setCustomMonth={setCustomMonth}
                    setFilterMonth={setFilterMonth}
                    handleChange={handleDateChange}
                />
                <SectorFilter
                    sectorName={sectorName}
                    setSectorName={setSectorName}
                    sectorOptionsList={sectorList}
                    handleChange={handleSectorChange}
                    boxProps={{
                        sx: { minWidth: '200px', position: 'static' }
                    }}
                />
            </PageHeader> */}
            {/* <Grid item container mt={2} xs={12} p={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <PieWidget
                            title="Major Problem Backlog"
                            isLoading={majorProblemLoading}
                            seriesData={majorProblemSeriesData}
                            customSize="300px"
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ width: '100%', overflow: 'hidden' }}>
                            <CustomizedDataGrid
                                tableColumns={getColumns()}
                                defaultColumnNames={defaultColumnNames}
                                tableData={mockData}
                                autoSetDraggedColumns={false}
                                showExport={false}
                                isDateRangeFilter={false}
                                isDisabled={false}
                                isRenderPagination={false}
                                isShowMoreButton={false}
                                listLength={mockData.length}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid> */}
            <Box marginTop={4} pl={3} pr={3}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography sx={{ color: '#009BDB', fontSize: '18px', fontWeight: 600 }}>Insights/Actions</Typography>
                    <Divider sx={{ flexGrow: 1, backgroundColor: '#009BDB', marginLeft: 2 }} />
                </Box>
                <Typography
                    variant="body2"
                    sx={{ fontStyle: 'italic', color: '#71758F', fontSize: '16px', fontWeight: '500', marginBottom: 5 }}
                >
                    You can display and update Insights/Actions if any.
                </Typography>
            </Box>
            {loader ? (
                <LoaderComponent />
            ) : (
                <Box sx={{ mb: 3 }}>
                    <Insights
                        insights={insights}
                        setInsights={setInsights}
                        onAdd={handleAddInsight}
                        onUpdate={handleUpdateInsight}
                        onDelete={handleDeleteInsight}
                    />
                </Box>
            )}
        </Box>
    );
};
export default platformExecutiveView;
