import {  Grid } from '@mui/material';
import { useEffect, useState, lazy, Suspense } from 'react';
// import { getDecodedToken, role, capabilities } from '../../service/service';
import landingBackArrow from '../../assets/landingBackArrow.svg';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import TeradataLogo from '../../assets/simple-icons_teradata.svg';
import ResourceMappingLogo from '../../assets/Resource Metadata Update.svg';
import TableauLogo from '../../assets/tableau-filled.svg';
import DatabricksLogo from '../../assets/databricks.svg';
import PrestoLogo from '../../assets/simple-icons_presto.svg';
import PowerBILogo from '../../assets/simple-icon_powerbi.svg';
import MachineLearningLogo from '../../assets/simple-icon_machineLearning.svg';
import DataGrowthPowerBI from '../../assets/material-symbols_chart-data_growth.svg';
import DMFLogo from '../../assets/simple-icons_DMF.svg';
import CCLogo from '../../assets/simple-icons_cc.svg';
import VVLogo from '../../assets/simple-icons_vv.svg';

// Lazy load components
const Header = lazy(() => import('./Header'));
const Footer = lazy(() => import('../LandingPage/ModuleLandingFooter'));
const capabilitiesNew : any = localStorage.getItem('capabilities');


export default function PlatFormCostObservabilityLanding() {
const [memberData, setMemberData] = useState<any>(null);
	const [controller, _] = usePepwiseAuthController();
	const { accessToken, capabilities } = controller;
	const capabilitiesArray = capabilitiesNew ? capabilitiesNew.split(',') : [];

	// to display data
	useEffect(() => {
		// console.log(moment(sd).diff(end, 'days'))
		// const tokenData = getDecodedToken();
		// console.log('token', accessToken);
		setMemberData(accessToken);
	}, []);

	//navigate to data-pipeline observability
	const handleDataPipelineClick = (event: any, type: string, url: string) => {
		event.preventDefault(); // Prevent the default context menu from showing up
		if (type === 'blank') {
			// Open the URL in a new tab if type is 'blank'
			window.open(url, '_blank');
		} else {
			// Navigate to the provided URL in the current tab
			window.location.href = url;
		}
	};

	const handleBackClick = () => {
		window.location.href = '/LandingPage';
	};


	return (
		<div className="outerLandingPageContainer">
			<Header memberData={memberData} />
			<div style={{ maxWidth: '86%', margin: '0 auto' }}>
				<div className="landingMessage-container">
					<img src={landingBackArrow} alt="CCLogo" className="landingMessageImage" onClick={handleBackClick} />
					<p className="landingMessage">{`Platform Observability`}</p>
				</div>

				<div className="welcome_text">
					<p>Click on the module below to monitor!</p>
				</div>
				<Grid container spacing={1} style={{ marginTop: '0px' }}>
					{capabilitiesArray.includes('EDW Jobs') ? (
						<Grid
							item
							xs={4}
							style={{ cursor: 'pointer' }}
							onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/dashboard/business')}
							onContextMenu={(e) => {
								e.preventDefault();
								handleDataPipelineClick(e, 'blank', '/dashboard/business');
							}}
						>
							<div className="observalbility_screens" style={{ textAlign: 'center' }}>
								<div className="title">
									<img
										src={TeradataLogo}
										alt="CCLogo"
										className="obs_logo"
										style={{ display: 'block', margin: '0 auto' }}
									/>
								</div>
								<div>
									<h3 className="obsTitle" style={{ margin: '10px 0' }}>
									Teradata Jobs
									</h3>
								</div>
								<p
									className="message1"
									title="Monitor jobs scheduled from 2 different schedulers: CTRL-m and Autosys."
									style={{ margin: '0', textAlign: 'center' }}
								>
									Monitor jobs scheduled from 2 different schedulers: CTRL-m and Autosys.
								</p>
							</div>
						</Grid>
					) : (
						''
					)}
					{capabilitiesArray.includes('Tableau') ? (
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/tableau/summary/dashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/tableau/summary/dashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={TableauLogo}
									alt="tableauLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
								Tableau
								</h3>
							</div>
							<p
								className="message1"
								title="Monitor and manage Tableau servers across PepsiCo for performance, growth and usage."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Monitor and manage Tableau servers across PepsiCo for performance, growth and usage.
							</p>
						</div>
					</Grid>
					) : (
						''
					)}
					{capabilitiesArray.includes('Data Growth') ? (
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/dataGrowth/dashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/dataGrowth/dashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={DataGrowthPowerBI}
									alt="platformLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
								 Data Growth
								</h3>
							</div>
							<p
								className="message1"
								title="Analyze data growth trends to help teams understand, monitor, and manage their data across the
											full purview of Teradata."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Analyze data growth trends to help teams understand, monitor, and manage their data across the
								full purview of Teradata.
							</p>
						</div>
					</Grid>
					) : (
						''
					)}
					{/* {capabilitiesArray.includes('Databricks') ? (
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/databricks/summary/dashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/databricks/summary/dashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={DatabricksLogo}
									alt="platformLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
								Databricks
								</h3>
							</div>
							<p
								className="message1"
								title="Monitor Databricks clusters across PepsiCo to identify usage trends, cost spikes and workspace
											health."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Monitor Databricks clusters across PepsiCo to identify usage trends, cost spikes and workspace
								health.
							</p>
						</div>
					</Grid>
					) : (
						''
					)} */}
					{capabilitiesArray.includes('Presto') ? (
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/presto/dashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/presto/dashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={PrestoLogo}
									alt="platformLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
								 Presto
								</h3>
							</div>
							<p
								className="message1"
								title="Monitor Presto usage by identifying long running queries trends of query execution and keep track
											of usage of data over a period."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Monitor Presto usage by identifying long running queries trends of query execution and keep track
								of usage of data over a period.
							</p>
						</div>
					</Grid>
					) : (
						''
					)}
					{capabilitiesArray.includes('Power BI') ? (
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/powerBI/capacity/dashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/powerBI/capacity/dashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={PowerBILogo}
									alt="platformLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
								Power BI
								</h3>
							</div>
							<p
								className="message1"
								title="Monitor and Manage Power BI capacities across PepsiCo for performance, growth and usage."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Monitor and Manage Power BI capacities across PepsiCo for performance, growth and usage.
							</p>
						</div>
					</Grid>
					) : (
						''
					)}
					{capabilitiesArray.includes('Data Management Framework') ? (
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/dataManagementFramework/healthSummary/dashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/dataManagementFramework/healthSummary/dashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={DMFLogo}
									alt="platformLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
								Data Management Framework
								</h3>
							</div>
							<p
								className="message1"
								title="Monitor the health of storage accounts across PepsiCo to identify duplicate, redundant and stale
											data with respect to files and tables. Verify the quality and completeness of data across sectors
											and projects."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Monitor the health of storage accounts across PepsiCo to identify duplicate, redundant and stale
											data with respect to files and tables. Verify the quality and completeness of data across sectors
											and projects.
							</p>
						</div>
					</Grid>
					) : (
						''
					)}
					{capabilitiesArray.includes('Controls & Compliance') ? (
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/controlsCompliance/dashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/controlsCompliance/dashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={CCLogo}
									alt="platformLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
								Controls & Compliance
								</h3>
							</div>
							<p
								className="message1"
								title="Automated reports that help monitor, manage and ensure compliance of various legal and regulatory
											obligations across tools."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Automated reports that help monitor, manage and ensure compliance of various legal and regulatory
								obligations across tools.
							</p>
						</div>
					</Grid>
					) : (
						''
					)}
					{capabilitiesArray.includes('Verification & Validation') ? (
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/verificationValidation/dashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/verificationValidation/dashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={VVLogo}
									alt="platformLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
								Verification & Validation
								</h3>
							</div>
							<p
								className="message1"
								title="Report the status of various automated checks on tools to meet the necessary configuration and
											system requirements and specifications."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Report the status of various automated checks on tools to meet the necessary configuration and
								system requirements and specifications.
							</p>
						</div>
					</Grid>
					) : (
						''
					)}
				</Grid>
			</div>
			<Footer />
		</div>
	);
}
