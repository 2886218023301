import { ReactComponent as Vector } from '../../assets/Vector.svg';
import CustomDropdown from '.';
import { BoxProps } from '@mui/material';

type SectorFilterProps = {
  sectorName: string;
  setSectorName: React.Dispatch<React.SetStateAction<string>>;
  sectorOptionsList: string[];
  handleChange?: (value: string) => void;
  boxProps?: any;
  disabled?: boolean;
};

const sectorFilter = {
  backgroundColor: '#003771',
  right: '35px',
  minWidth: '220px !important',
  width: '220px',
  zIndex: 999,
  '& fieldset': {
    display: 'none',
  },
  '& .MuiSelect-icon': {
    top: '0px !important',
    transition: 'none !important',
    height: '40px',
    width: '40px',
    right: '0px',
    position: 'absolute',
  },
  '& .MuiSelect-iconOpen': {
    transform: 'none',
  },
};

const Hierarchy3 = ({
  sectorName,
  setSectorName,
  sectorOptionsList,
  handleChange,
  boxProps,
  disabled = true,
}: SectorFilterProps) => {
  const { sx, ...props } = boxProps || {};

  const handleSectorChange = (e: any) => {
    const value = e.target.value;
    setSectorName(value);
    handleChange?.(value);
  };

  const options = [
    { label: 'Hierarchy3: All', value: 'all' },
    ...sectorOptionsList.map((option) => ({
      label: option,
      value: option,
    })),
  ];

  const VectorIcon = (props: any) => (
    <Vector {...props} style={{ width: 16, height: 16, transform: 'translate(-13px, 13px)' }} />
  );

  return (
    <CustomDropdown
      value={sectorName}
      handleChange={handleSectorChange}
      options={options}
      dropdownIcon={VectorIcon}
      disabled={disabled}
      boxProps={{
        sx: { ...sectorFilter, ...sx },
        ...props,
      }}
    />
  );
};

export default Hierarchy3;
