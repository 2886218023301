import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Popover,
	Select,
	Stack,
	Typography
} from '@mui/material';
import { powerBIAdoptionMetricsData, powerBICapacityGrowth, powerBICapacityMetricsData, powerBIGatewayGrowth, powerBIReportGrowth, powerBIUserGrowth} from '../../service/service';
import { powerBIActivityDetailData,powerBIActivityDetailPagination, powerBIActivityDetailFilters, powerBIActivitySearch } from '../../service/service';
import '../PowerBIObservability/powerBIObs.scss';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import { useSearchParams } from 'react-router-dom';
import { powerBICapacitySearch } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import Loader from 'components/Loader';
import NumberWidget from 'components/Widgets/NumberWidget';
import LoaderComponent from 'components/Loader';
import RowTypography from 'components/Table/RowTypography';
import StatusChip from 'components/Table/StatusChip';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import SectorCheckFilter from 'components/Dropdown/SectorCheckFilter';
import PageHeader from 'components/Header/Page';
import AdoptionMetricsLogo from 'assets/dataset_obs.svg';
import CustomDropdown from 'components/Dropdown';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import { formatNumberWithCommas } from 'utils/common-util';

const AdoptionMetrics = () => {
	interface Row {
		sector: string;
		capacityName: string;
		capacitySKU: string;
		workspaceName: string;
		reportName: string;
		userName: string;
		GroupUserAccessRight: string;
		workspaceOwner: string;
		reportOwner: string;
		lastLogin: string;
		capacityOwner: string; 
		firstSeenDate: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'sector',
				name: 'Sector',
				width: 190,
				minWidth: 240,
			},
			{
				key: 'capacityName',
				name: 'Capacity Name',
				width: 150,
				minWidth: 150,
			},
			{
				key: 'capacitySKU',
				name: 'Capacity SKU',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'workspaceName',
				name: 'Workspace',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'reportName',
				name: 'Report Name',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'userName',
				name: 'User Name',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'GroupUserAccessRight',
				name: 'Group User Access',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'lastLogin',
				name: 'Last Login',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'workspaceOwner',
				name: 'Workspace Owner',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'reportOwner',
				name: 'Report Owner',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'capacityOwner',
				name: 'Capacity Owner',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'firstSeenDate',
				name: 'First Seen Date',
				width: 230,
				minWidth: 230,
			},
		];
	}

	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [drillDownStatusfilterInfo, setDrilldownStatusFilterInfo] = useState([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState<{ [key: string]: any }>({});
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const hasDrillDown = searchParams.has('isDrillDowned');
	const [chartLoadingCapacity, setChartLoadingCapacity] = useState(false);
	const [chartLoadingGateway, setChartLoadingGateway] = useState(false);
	const [chartLoadingReports, setChartLoadingReports] = useState(false);
	const [chartLoadingUsers, setChartLoadingUsers] = useState(false);
	const [chartOptionCapacity, setChartOptionCapacity] = useState<any>();
	const [chartOptionGateway, setChartOptionGateway] = useState<any>();
	const [chartOptionReports, setChartOptionReports] = useState<any>();
	const [chartOptionUsers, setChartOptionUsers] = useState<any>();
	const [timePeriodCapacity, setTimePeriodCapacity] = useState('Monthly');
	const [timePeriodGateway, setTimePeriodGateway] = useState('Monthly');
	const [timePeriodReports, setTimePeriodReports] = useState('Monthly');
	const [timePeriodUsers, setTimePeriodUsers] = useState('Monthly');
	const [adoptionDetails, setAdoptionDetails] = useState<Row[]>([]);
	const checkedListRef = useRef<any>([]);
	const [customMonth, setCustomMonth] = useState('');
	const [filterMonth, setFilterMonth] = useState('3M');
	const [selectedMonth, setSelectedMonth] = useState('Last 3 Months');
	const [metricsData, setMetricsData] = useState<any>({
			totalPowerBIUsers: 0,
			totalPowerBIUsersGrowth: 0,
			totalPowerBIReports: 0,
			totalPowerBIReportsGrowth: 0,
			activeUsers: 0,
			activeUsersGrowth: 0,
		});

		const seriesColor = [
			'#5470c6', 
			'#a2d9ce', 
			'#fac858', 
			'#ee6666',
			'#73c0de', 
			'#3ba272', 
			'#fc8452', 
			'#9a60b4', 
			'#ea7ccc', 
			'#4a90E2', 
			'#91cc75', 
		];
		interface SeriesData {
			name: string;
			dataKey: string;
			color: string;
		}
		const [barSeriesData, setBarSeriesData] = useState<SeriesData[]>([]);	
		const [barSeriesDataGateway, setBarSeriesDataGateway] = useState<SeriesData[]>([]);
		const [barSeriesDataReport, setBarSeriesDataReport] = useState<SeriesData[]>([]);
		const [barSeriesDataUser, setBarSeriesDataUser] = useState<SeriesData[]>([]);

	const defaultColumnNames = [
		'Sector',
		'Capacity Name',
		'Capacity SKU',
		'Workspace',
		'Report Name',
		'User Name',
		'Group User Access',
		'Workspace Owner',
		'Report Owner',
		'Last Login',
		'Capacity Owner',
		'First Seen Date'
	];


	const handleOkClick = () => {
		const updatedCheckedList = [...checkedListRef.current]; // Just copying the checkedList

		if (updatedCheckedList.length > 0) {
			setCurrSectorFilter(updatedCheckedList);
			filterInfo['sector']=updatedCheckedList;
		} else {
			setCurrSectorFilter([]);
			filterInfo['sector']='';
		}
		Object.keys(filterInfo).forEach((key: any) => {
			if(key !== 'sector'){
				delete filterInfo[key];
			}
		});
	    fetchData(selectedMonth);
		setFilterInfo(filterInfo);
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';
		adoptionDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
		// fetchChartData();
		fetchCapacityGrowthChartData(timePeriodCapacity,selectedMonth);
		fetchGatewayGrowthChartData(timePeriodGateway,selectedMonth);
		fetchReportGrowthChartData(timePeriodReports,selectedMonth);
		fetchUserGrowthChartData(timePeriodUsers,selectedMonth);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		adoptionDetailsFunc(value, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
	};

	const handlepageSizeChange = (event: any) => {
		adoptionDetailsFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo),selectedMonth);
	};
	const handleSorting = (sortingInfo: any) => {
		adoptionDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		adoptionDetailsFunc(1, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
		
	};
	const handleSearch = (searchDetails: any, columnKey: any) => {
		if (searchDetails?.length >= 3) powerBIAtivitySearchFunc(searchDetails, columnKey);
	};
	async function powerBIAtivitySearchFunc(searchDetails: any, columnKey: any) {
			const res = await powerBIActivitySearch(
				searchDetails,
				columnKey,
				parseFilter(filterInfo),
				checkedListRef.current,
				drillDownStatusfilterInfo,
				selectedMonth
			);
			if (res.success) {
				setFilterSearchInfo(res.data);
			}
		}

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};


	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}

	async function fetchData(dateLabel: string) {
			try {
				let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
					? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
					: null;
				setLoading(true);
				let selectedDate = dateLabel ? dateLabel : null
				const response = await powerBIAdoptionMetricsData(storedSectorFilter,selectedDate);
	
				// Transform the data to match the expected structure
				if (response && response.data) {
					const transformedData = {
						totalPowerBIUsers: response.data.data.powerBIUsers[0].totalPowerBIUsers || 0,
			            totalPowerBIUsersGrowth: response.data.data.powerBIUsers[0].userGrowthPercentage || 0,
			            totalPowerBIReports: response.data.data.powerBIReports[0].totalPowerBIReports || 0,
			            totalPowerBIReportsGrowth: response.data.data.powerBIReports[0].reportGrowthPercentage || 0,
			            activeUsers: 0,
			            activeUsersGrowth: 0,
					};
					setMetricsData(transformedData);
				} else {
					console.error('No data returned from API');
				}
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoading(false);
			}
		}

	async function adoptionDetailsFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any,dateLabel: string) {
		if (pageSize !== size) {
			pageNumber = 1;
		}
	
			try {
				// Retrieve stored sector filter from localStorage
				let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
				: null;
	
			setTableLoading(true);
	
			let selectedDate = dateLabel ? dateLabel : null;
	
			// Use Promise.all to run the async functions concurrently
			const [response, pageResponse, filterResponse] = await Promise.all([
				// Call the capacity detail API (filtered by status if available)
				powerBIActivityDetailData(
					pageNumber.toString(),
					size.toString(),
					storedSectorFilter,
					sortingInfo,
					jobFilterInfo ? jobFilterInfo : null,
					selectedDate
				),
				// Call the capacity pagination API
				powerBIActivityDetailPagination(
					pageNumber.toString(),
					size.toString(),
					storedSectorFilter,
					sortingInfo,
					jobFilterInfo ? jobFilterInfo : null,
					selectedDate
				),
				// Call the capacity filters API
				powerBIActivityDetailFilters(
					pageNumber.toString(),
					size.toString(),
					storedSectorFilter,
					sortingInfo,
					jobFilterInfo ? jobFilterInfo : null,
					selectedDate
				)
			]);
	
			// Handle the response if successful
			if (response?.success) {
				getActivityDetails(response);
			}
	
			// Handle pagination data
			if (pageResponse?.data?.page) {
				getActivityPagination(pageResponse, pageNumber, size);
			}
	
			// Handle filter data
			if (filterResponse?.data?.filterOptions) {
				getActivityFilters(filterResponse);
			}
	
			// Update pagination data based on the response
			const paginationData = pageResponse.data.page;
			setPaginationData(paginationData); // Set pagination data directly from response
	
			// Set sorting info
			setSortingInfo({ sortBy: sortingInfo.sortBy, sortDir: sortingInfo.sortDir });
	
			} catch (error) {
				console.error('Error fetching capacity data:', error);
			} finally {
				setTableLoading(false);
			}
		// 	console.log(jobFilterInfo, 'jobFilterInfo');
		// // Reset page number if page size has changed
		// if (pageSize !== size) {
		// 	pageNumber = 1;
		// }

		// const dummyAdoptionDetailsData = [
        //     {
                                                                                                                                                                                                                                                                      
		// 		capacityOwner: 'Capacity Owner 2',
        //     },
		// 	{
		// 		sector: 'PBNA',
        //         capacity: 'Capacity 3',
        //         capacitySKU: 'F3',
		// 		workspace: 'Workspace 3',
		// 		reportName: 'Report Name 3',
		// 		userName: 'User Name 3',
		// 		groupUserAccess: 'Group User Access 3',
		// 		workspaceOwner: 'Workspace Owner 3',
		// 		reportOwner: 'Report Owner 3',
		// 		lastLogin: 'Last Login 3',
		// 		capacityOwner: 'Capacity Owner 3',
        //     },
		// 	{
		// 		sector: 'PBNA',
        //         capacity: 'Capacity 4',
        //         capacitySKU: 'F4',
		// 		workspace: 'Workspace 4',
		// 		reportName: 'Report Name 4',
		// 		userName: 'User Name 4',
		// 		groupUserAccess: 'Group User Access 4',
		// 		workspaceOwner: 'Workspace Owner 4',
		// 		reportOwner: 'Report Owner 4',
		// 		lastLogin: 'Last Login 4',
		// 		capacityOwner: 'Capacity Owner 4',
        //     },
		// 	{
		// 		sector: 'PBNA',
        //         capacity: 'Capacity 5',
        //         capacitySKU: 'F5',
		// 		workspace: 'Workspace 5',
		// 		reportName: 'Report Name 5',
		// 		userName: 'User Name 5',
		// 		groupUserAccess: 'Group User Access 5',
		// 		workspaceOwner: 'Workspace Owner 5',
		// 		reportOwner: 'Report Owner 5',
		// 		lastLogin: 'Last Login 5',
		// 		capacityOwner: 'Capacity Owner 5',
        //     },
		// 	{
		// 		sector: 'PBNA',
        //         capacity: 'Capacity 6',
        //         capacitySKU: 'F6',
		// 		workspace: 'Workspace 6',
		// 		reportName: 'Report Name 6',
		// 		userName: 'User Name 6',
		// 		groupUserAccess: 'Group User Access 6',
		// 		workspaceOwner: 'Workspace Owner 6',
		// 		reportOwner: 'Report Owner 6',
		// 		lastLogin: 'Last Login 6',
		// 		capacityOwner: 'Capacity Owner 6',
        //     },
		// 	{
		// 		sector: 'PBNA',
        //         capacity: 'Capacity 7',
        //         capacitySKU: 'F7',
		// 		workspace: 'Workspace 7',
		// 		reportName: 'Report Name 7',
		// 		userName: 'User Name 7',
		// 		groupUserAccess: 'Group User Access 7',
		// 		workspaceOwner: 'Workspace Owner 7',
		// 		reportOwner: 'Report Owner 7',
		// 		lastLogin: 'Last Login 7',
		// 		capacityOwner: 'Capacity Owner 7',
        //     },
		// 	{
		// 		sector: 'PBNA',
        //         capacity: 'Capacity 8',
        //         capacitySKU: 'F8',
		// 		workspace: 'Workspace 8',
		// 		reportName: 'Report Name 8',
		// 		userName: 'User Name 8',
		// 		groupUserAccess: 'Group User Access 8',
		// 		workspaceOwner: 'Workspace Owner 8',
		// 		reportOwner: 'Report Owner 8',
		// 		lastLogin: 'Last Login 8',
		// 		capacityOwner: 'Capacity Owner 8',
        //     },
		// 	{
		// 		sector: 'PBNA',
        //         capacity: 'Capacity 9',
        //         capacitySKU: 'F9',
		// 		workspace: 'Workspace 9',
		// 		reportName: 'Report Name 9',
		// 		userName: 'User Name 9',
		// 		groupUserAccess: 'Group User Access 9',
		// 		workspaceOwner: 'Workspace Owner 9',
		// 		reportOwner: 'Report Owner 9',
		// 		lastLogin: 'Last Login 9',
		// 		capacityOwner: 'Capacity Owner 9',
        //     },
		// 	{
		// 		sector: 'PBNA',
        //         capacity: 'Capacity 10',
        //         capacitySKU: 'F10',
		// 		workspace: 'Workspace 10',
		// 		reportName: 'Report Name 10',
		// 		userName: 'User Name 10',
		// 		groupUserAccess: 'Group User Access 10',
		// 		workspaceOwner: 'Workspace Owner 10',
		// 		reportOwner: 'Report Owner 10',
		// 		lastLogin: 'Last Login 10',
		// 		capacityOwner: 'Capacity Owner 10',
        //     },
        // ];
        // setAdoptionDetails(dummyAdoptionDetailsData);
        // paginationData.totalElements = 1;
        //     paginationData.totalPages = 1;
        //     setPage(pageNumber);
        //     setPageSize(size);
        //     setSortingInfo(sortingInfo);
	}

	function formatNumber(number: any, isDecimal: boolean = false) {
		if (number != null && !isNaN(number)) {
			const num = Number(number);
	
			if (isDecimal) {
				// Check if the number is a whole number
				if (num % 1 === 0) {
					return num.toLocaleString('en-US'); // No decimal places for whole numbers
				}
				return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
			} else {
				return num.toLocaleString('en-US');
			}
		} else {
			return isDecimal ? "0.00" : "0";
		}
	}
	

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		localStorage.removeItem('ml-summary-sector-filter');
       
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/powerBI/adoptionMetrics/dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.remove('root');
			}
		}
		fetchData(selectedMonth);
		adoptionDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
		fetchCapacityGrowthChartData(timePeriodCapacity,selectedMonth);
		fetchGatewayGrowthChartData(timePeriodGateway,selectedMonth);
		fetchReportGrowthChartData(timePeriodReports,selectedMonth);
		fetchUserGrowthChartData(timePeriodUsers,selectedMonth);
	}, [timePeriodCapacity,timePeriodGateway,timePeriodReports,timePeriodUsers]);

	const infoCardData = [
		{
			title: 'Total Power BI Users',
			// handleEvent: () => handleCapacityClick('RISK'),
			rawNumber: metricsData.totalPowerBIUsers,
			number: metricsData.totalPowerBIUsers >= 1000 ? formatNumber((metricsData.totalPowerBIUsers) / 1000, true) : metricsData.totalPowerBIUsers,
            totalNumber: '',
			growthRate: metricsData.totalPowerBIUsersGrowth
		},
		{
			title: 'Total Power BI Reports',
			// handleEvent: () => handleCapacityClick('RISK'),
			rawNumber: metricsData.totalPowerBIReports, 
			number: metricsData.totalPowerBIReports >= 1000 ? formatNumber((metricsData.totalPowerBIReports) / 1000, true) : metricsData.totalPowerBIReports,
            totalNumber: '',
			growthRate: metricsData.totalPowerBIReportsGrowth
		},
        // {
		// 	title: 'Power BI Active Users',
		// 	// handleEvent: () => handleCapacityClick('RISK'),
		// 	rawNumber: metricsData.activeUsers,
		// 	number: metricsData.activeUsers >= 1000 ? formatNumber((metricsData.activeUsers) / 1000, true) : metricsData.activeUsers,
        //     totalNumber: '',
		// 	growthRate: metricsData.activeUsersGrowth
		// },
	];

	const formatDate = (isoDate: string) => {
		const date = new Date(isoDate);
		const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
		const day = date.getDate().toString().padStart(2, '0');
		const year = date.getFullYear();
		return `${month}-${day}-${year}`;
	};

	function formatWeekToDate(week: string) {
		// Extract year and week number from the TimePeriod (YYYYWW format)
		const year = parseInt(week.slice(0, 4), 10);  // Convert the year part to a number
		const weekNumber = parseInt(week.slice(4, 6), 10); // Extract week number
	  
		// Get the start date of the given week number in the year
		const startDate = new Date(year, 0, 1); // Start from the first day of the year
		const daysInMillis = (weekNumber - 1) * 7 * 24 * 60 * 60 * 1000; // Calculate milliseconds for the given week
		startDate.setTime(startDate.getTime() + daysInMillis); // Set the start date for the week
		
		// Get the month name (in long format)
		const monthName = startDate.toLocaleString('en-GB', { month: 'long' });
	  
		// Get the day (to show the start of the week, which is typically the Monday of the week)
		const day = startDate.getDate();
	  
		// Format the date as "weekNumberMonthName year" (e.g., "12March 2025")
		const formattedDate = `${weekNumber}${monthName} ${year}`;
	  
		return formattedDate;
	}
	
	const formatMonthToDate = (monthString: string) => {
		const year = monthString.substring(0, 4); // Extract year
		const month = parseInt(monthString.substring(4, 6), 10) - 1; // Convert MM to 0-based index
		const date = new Date(parseInt(year, 10), month);
		return date.toLocaleString('en-US', { month: 'long', year: 'numeric' }); // 'Mar 2025'
	};

	const formatYearToDate = (yearString: string) => {
		return `${yearString}`;  // Just return the year (e.g., '2025')
	};
	  
	const fetchCapacityGrowthChartData = async (timePeriod: string, dateLabel: string) => {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
						? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '') : null;
						setChartLoadingCapacity(true);	
						
						let selectedDate = dateLabel ? dateLabel : null
						const response = await powerBICapacityGrowth(storedSectorFilter,timePeriod,selectedDate);

						if (response && response.data && response.data.monthlyGrowth && timePeriod == 'Monthly') {
							type MonthlyGrowthItem = {
								TimePeriod: string;
								Sector: string;
								CapacityCount: number;
							  };
  						const formattedData = response.data.monthlyGrowth.reduce(
							(acc: { [key: string]: any }, { TimePeriod, Sector, CapacityCount }: MonthlyGrowthItem) => {
								const formattedDate = formatMonthToDate(TimePeriod); 
								if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
      							}
								acc[formattedDate][Sector] = (acc[formattedDate][Sector] || 0) + CapacityCount;
      							acc[formattedDate].totalUsers += CapacityCount;
								return acc;
							},
							{}
						);
						const chartDataArray = Object.keys(formattedData).map((date) => ({
							[date]: formattedData[date],
						}));
						const teams: string[] = Array.from(
    						new Set(response.data.monthlyGrowth.map((item: MonthlyGrowthItem) => item.Sector))
  						);
  						const series = teams.map((team, index) => ({
							name: team,
    						dataKey: team,
    						color: seriesColor[index % seriesColor.length],
  						}));
  						setBarSeriesData(series);
						setChartOptionCapacity(chartDataArray);
						}
						else if (response && response.data && response.data.dailyGrowth && timePeriod === 'Daily') {
							type DailyGrowthItem = {
    							TimePeriod: string;
    							Sector: string;
    							CapacityCount: number;
  							};
							const formattedData = response.data.dailyGrowth.reduce(
								(acc: { [key: string]: any }, { TimePeriod, Sector, CapacityCount }: DailyGrowthItem) => {
								  const formattedDate = formatDate(TimePeriod); 
								  if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
								  }
								  acc[formattedDate][Sector] = (acc[formattedDate][Sector] || 0) + CapacityCount;
								  acc[formattedDate].totalUsers += CapacityCount;
								  return acc;
								},{}
							);
  							const chartDataArray = Object.keys(formattedData).map((date) => ({
    							[date]: formattedData[date],
  							}));
  							const teams: string[] = Array.from(
    							new Set(response.data.dailyGrowth.map((item: DailyGrowthItem) => item.Sector))
  							);
  							const series = teams.map((team, index) => ({
    							name: team,
    							dataKey: team,
    							color: seriesColor[index % seriesColor.length],
  							}));
  							setBarSeriesData(series);
  							setChartOptionCapacity(chartDataArray);
						}
						else if (response && response.data && response.data.weeklyGrowth && timePeriod == 'Weekly') {
  							type WeeklyGrowthItem = {
    							TimePeriod: string;
    							Sector: string;
    							CapacityCount: number;
  							};
							const formattedData = response.data.weeklyGrowth.reduce(
								(acc: { [key: string]: any }, { TimePeriod, Sector, CapacityCount }: WeeklyGrowthItem) => {
								  const formattedDate = formatWeekToDate(TimePeriod); 
								  if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
								  }
								  acc[formattedDate][Sector] = (acc[formattedDate][Sector] || 0) + CapacityCount;
								  acc[formattedDate].totalUsers += CapacityCount;
								  return acc;
								},{}
							  );
  							const chartDataArray = Object.keys(formattedData).map((date) => ({
    							[date]: formattedData[date],
  							}));
  							const teams: string[] = Array.from(
    							new Set(response.data.weeklyGrowth.map((item: WeeklyGrowthItem) => item.Sector))
  							);
  							const series = teams.map((team, index) => ({
    							name: team,
    							dataKey: team,
    							color: seriesColor[index % seriesColor.length],
  							}));
  							setBarSeriesData(series);
  							setChartOptionCapacity(chartDataArray);  
						}
						else if (response && response.data && response.data.yearlyGrowth && timePeriod == 'Yearly') {
							type YearlyGrowthItem = {
							  TimePeriod: string;
							  Sector: string;
							  CapacityCount: number;
							};
						  const formattedData = response.data.yearlyGrowth.reduce(
							  (acc: { [key: string]: any }, { TimePeriod, Sector, CapacityCount }: YearlyGrowthItem) => {
								const formattedDate = formatYearToDate(TimePeriod); 
								if (!acc[formattedDate]) {
								  acc[formattedDate] = { totalUsers: 0 };
								}
								acc[formattedDate][Sector] = (acc[formattedDate][Sector] || 0) + CapacityCount;
								acc[formattedDate].totalUsers += CapacityCount;
								return acc;
							  },{}
							);
							const chartDataArray = Object.keys(formattedData).map((date) => ({
							  [date]: formattedData[date],
							}));
							const teams: string[] = Array.from(
							  new Set(response.data.yearlyGrowth.map((item: YearlyGrowthItem) => item.Sector))
							);
							const series = teams.map((team, index) => ({
							  name: team,
							  dataKey: team,
							  color: seriesColor[index % seriesColor.length],
							}));
							setBarSeriesData(series);
							setChartOptionCapacity(chartDataArray);  
					    }
						else{
							console.error('No data returned from API');
						}
		} catch (error) {
			console.error('Error fetching chart data:', error);
		}finally {
			setChartLoadingCapacity(false);
		}
	};

	const fetchGatewayGrowthChartData = async (timePeriod: string, dateLabel: string) => {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
						? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '') : null;
						setChartLoadingGateway(true);	
						
						let selectedDate = dateLabel ? dateLabel : null
						const response = await powerBIGatewayGrowth(storedSectorFilter,timePeriod,selectedDate);

						if (response && response.data && response.data.monthlyGrowth && timePeriod == 'Monthly') {
							type MonthlyGrowthItem = {
								TimePeriod: string;
								sector: string;
								GatewayCount: number;
							  };
  						const formattedData = response.data.monthlyGrowth.reduce(
							(acc: { [key: string]: any }, { TimePeriod, sector, GatewayCount }: MonthlyGrowthItem) => {
								const formattedDate = formatMonthToDate(TimePeriod); 
								if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
      							}
								acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + GatewayCount;
      							acc[formattedDate].totalUsers += GatewayCount;
								return acc;
							},
							{}
						);
						const chartDataArray = Object.keys(formattedData).map((date) => ({
							[date]: formattedData[date],
						}));
						const teams: string[] = Array.from(
    						new Set(response.data.monthlyGrowth.map((item: MonthlyGrowthItem) => item.sector))
  						);
  						const series = teams.map((team, index) => ({
							name: team,
    						dataKey: team,
    						color: seriesColor[index % seriesColor.length],
  						}));
  						setBarSeriesDataGateway(series);
						setChartOptionGateway(chartDataArray);
						}
						else if (response && response.data && response.data.dailyGrowth && timePeriod === 'Daily') {
							type DailyGrowthItem = {
    							TimePeriod: string;
    							sector: string;
    							GatewayCount: number;
  							};
							const formattedData = response.data.dailyGrowth.reduce(
								(acc: { [key: string]: any }, { TimePeriod, sector, GatewayCount }: DailyGrowthItem) => {
								  const formattedDate = formatDate(TimePeriod); 
								  if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
								  }
								  acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + GatewayCount;
								  acc[formattedDate].totalUsers += GatewayCount;
								  return acc;
								},{}
							);
  							const chartDataArray = Object.keys(formattedData).map((date) => ({
    							[date]: formattedData[date],
  							}));
  							const teams: string[] = Array.from(
    							new Set(response.data.dailyGrowth.map((item: DailyGrowthItem) => item.sector))
  							);
  							const series = teams.map((team, index) => ({
    							name: team,
    							dataKey: team,
    							color: seriesColor[index % seriesColor.length],
  							}));
  							setBarSeriesDataGateway(series);
  							setChartOptionGateway(chartDataArray);
						}
						else if (response && response.data && response.data.weeklyGrowth && timePeriod == 'Weekly') {
  							type WeeklyGrowthItem = {
    							TimePeriod: string;
    							sector: string;
    							GatewayCount: number;
  							};
							const formattedData = response.data.weeklyGrowth.reduce(
								(acc: { [key: string]: any }, { TimePeriod, sector, GatewayCount }: WeeklyGrowthItem) => {
								  const formattedDate = formatWeekToDate(TimePeriod); 
								  if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
								  }
								  acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + GatewayCount;
								  acc[formattedDate].totalUsers += GatewayCount;
								  return acc;
								},{}
							  );
  							const chartDataArray = Object.keys(formattedData).map((date) => ({
    							[date]: formattedData[date],
  							}));
  							const teams: string[] = Array.from(
    							new Set(response.data.weeklyGrowth.map((item: WeeklyGrowthItem) => item.sector))
  							);
  							const series = teams.map((team, index) => ({
    							name: team,
    							dataKey: team,
    							color: seriesColor[index % seriesColor.length],
  							}));
  							setBarSeriesDataGateway(series);
  							setChartOptionGateway(chartDataArray);  
						}
						else if (response && response.data && response.data.yearlyGrowth && timePeriod == 'Yearly') {
							type YearlyGrowthItem = {
							  TimePeriod: string;
							  sector: string;
							  GatewayCount: number;
							};
						  const formattedData = response.data.yearlyGrowth.reduce(
							  (acc: { [key: string]: any }, { TimePeriod, sector, GatewayCount }: YearlyGrowthItem) => {
								const formattedDate = formatYearToDate(TimePeriod); 
								if (!acc[formattedDate]) {
								  acc[formattedDate] = { totalUsers: 0 };
								}
								acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + GatewayCount;
								acc[formattedDate].totalUsers += GatewayCount;
								return acc;
							  },{}
							);
							const chartDataArray = Object.keys(formattedData).map((date) => ({
							  [date]: formattedData[date],
							}));
							const teams: string[] = Array.from(
							  new Set(response.data.yearlyGrowth.map((item: YearlyGrowthItem) => item.sector))
							);
							const series = teams.map((team, index) => ({
							  name: team,
							  dataKey: team,
							  color: seriesColor[index % seriesColor.length],
							}));
							setBarSeriesDataGateway(series);
							setChartOptionGateway(chartDataArray);  
					    }
						else{
							console.error('No data returned from API');
						}
		} catch (error) {
			console.error('Error fetching chart data:', error);
		}finally {
			setChartLoadingGateway(false);
		}
	};

	const fetchReportGrowthChartData = async (timePeriod: string, dateLabel: string) => {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
						? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '') : null;
						setChartLoadingReports(true);	
						
						let selectedDate = dateLabel ? dateLabel : null
						const response = await powerBIReportGrowth(storedSectorFilter,timePeriod,selectedDate);

						if (response && response.data && response.data.monthlyGrowth && timePeriod == 'Monthly') {
							type MonthlyGrowthItem = {
								TimePeriod: string;
								sector: string;
								reportCount: number;
							  };
  						const formattedData = response.data.monthlyGrowth.reduce(
							(acc: { [key: string]: any }, { TimePeriod, sector, reportCount }: MonthlyGrowthItem) => {
								const formattedDate = formatMonthToDate(TimePeriod); 
								if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
      							}
								acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + reportCount;
      							acc[formattedDate].totalUsers += reportCount;
								return acc;
							},
							{}
						);
						const chartDataArray = Object.keys(formattedData).map((date) => ({
							[date]: formattedData[date],
						}));
						const teams: string[] = Array.from(
    						new Set(response.data.monthlyGrowth.map((item: MonthlyGrowthItem) => item.sector))
  						);
  						const series = teams.map((team, index) => ({
							name: team,
    						dataKey: team,
    						color: seriesColor[index % seriesColor.length],
  						}));
  						setBarSeriesDataReport(series);
						setChartOptionReports(chartDataArray);
						}
						else if (response && response.data && response.data.dailyGrowth && timePeriod === 'Daily') {
							type DailyGrowthItem = {
    							TimePeriod: string;
    							sector: string;
    							reportCount: number;
  							};
							const formattedData = response.data.dailyGrowth.reduce(
								(acc: { [key: string]: any }, { TimePeriod, sector, reportCount }: DailyGrowthItem) => {
								  const formattedDate = formatDate(TimePeriod); 
								  if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
								  }
								  acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + reportCount;
								  acc[formattedDate].totalUsers += reportCount;
								  return acc;
								},{}
							);
  							const chartDataArray = Object.keys(formattedData).map((date) => ({
    							[date]: formattedData[date],
  							}));
  							const teams: string[] = Array.from(
    							new Set(response.data.dailyGrowth.map((item: DailyGrowthItem) => item.sector))
  							);
  							const series = teams.map((team, index) => ({
    							name: team,
    							dataKey: team,
    							color: seriesColor[index % seriesColor.length],
  							}));
  							setBarSeriesDataReport(series);
  							setChartOptionReports(chartDataArray);
						}
						else if (response && response.data && response.data.weeklyGrowth && timePeriod == 'Weekly') {
  							type WeeklyGrowthItem = {
    							TimePeriod: string;
    							sector: string;
    							reportCount: number;
  							};
							const formattedData = response.data.weeklyGrowth.reduce(
								(acc: { [key: string]: any }, { TimePeriod, sector, reportCount }: WeeklyGrowthItem) => {
								  const formattedDate = formatWeekToDate(TimePeriod); 
								  if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
								  }
								  acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + reportCount;
								  acc[formattedDate].totalUsers += reportCount;
								  return acc;
								},{}
							  );
  							const chartDataArray = Object.keys(formattedData).map((date) => ({
    							[date]: formattedData[date],
  							}));
  							const teams: string[] = Array.from(
    							new Set(response.data.weeklyGrowth.map((item: WeeklyGrowthItem) => item.sector))
  							);
  							const series = teams.map((team, index) => ({
    							name: team,
    							dataKey: team,
    							color: seriesColor[index % seriesColor.length],
  							}));
  							setBarSeriesDataReport(series);
  							setChartOptionReports(chartDataArray);  
						}
						else if (response && response.data && response.data.yearlyGrowth && timePeriod == 'Yearly') {
							type YearlyGrowthItem = {
							  TimePeriod: string;
							  sector: string;
							  reportCount: number;
							};
						  const formattedData = response.data.yearlyGrowth.reduce(
							  (acc: { [key: string]: any }, { TimePeriod, sector, reportCount }: YearlyGrowthItem) => {
								const formattedDate = formatYearToDate(TimePeriod); 
								if (!acc[formattedDate]) {
								  acc[formattedDate] = { totalUsers: 0 };
								}
								acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + reportCount;
								acc[formattedDate].totalUsers += reportCount;
								return acc;
							  },{}
							);
							const chartDataArray = Object.keys(formattedData).map((date) => ({
							  [date]: formattedData[date],
							}));
							const teams: string[] = Array.from(
							  new Set(response.data.yearlyGrowth.map((item: YearlyGrowthItem) => item.sector))
							);
							const series = teams.map((team, index) => ({
							  name: team,
							  dataKey: team,
							  color: seriesColor[index % seriesColor.length],
							}));
							setBarSeriesDataReport(series);
							setChartOptionReports(chartDataArray);  
					    }
						else{
							console.error('No data returned from API');
						}
		} catch (error) {
			console.error('Error fetching chart data:', error);
		}finally {
			setChartLoadingReports(false);
		}
	};

	const fetchUserGrowthChartData = async (timePeriod: string, dateLabel: string) => {
		try {
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
						? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '') : null;
						setChartLoadingUsers(true);	
						
						let selectedDate = dateLabel ? dateLabel : null
						const response = await powerBIUserGrowth(storedSectorFilter,timePeriod,selectedDate);

						if (response && response.data && response.data.monthlyGrowth && timePeriod == 'Monthly') {
							type MonthlyGrowthItem = {
								TimePeriod: string;
								sector: string;
								userCount: number;
							  };
  						const formattedData = response.data.monthlyGrowth.reduce(
							(acc: { [key: string]: any }, { TimePeriod, sector, userCount }: MonthlyGrowthItem) => {
								const formattedDate = formatMonthToDate(TimePeriod); 
								if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
      							}
								acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + userCount;
      							acc[formattedDate].totalUsers += userCount;
								return acc;
							},
							{}
						);
						const chartDataArray = Object.keys(formattedData).map((date) => ({
							[date]: formattedData[date],
						}));
						const teams: string[] = Array.from(
    						new Set(response.data.monthlyGrowth.map((item: MonthlyGrowthItem) => item.sector))
  						);
  						const series = teams.map((team, index) => ({
							name: team,
    						dataKey: team,
    						color: seriesColor[index % seriesColor.length],
  						}));
  						setBarSeriesDataUser(series);
						setChartOptionUsers(chartDataArray);
						}
						else if (response && response.data && response.data.dailyGrowth && timePeriod === 'Daily') {
							type DailyGrowthItem = {
    							TimePeriod: string;
    							sector: string;
    							userCount: number;
  							};
							const formattedData = response.data.dailyGrowth.reduce(
								(acc: { [key: string]: any }, { TimePeriod, sector, userCount }: DailyGrowthItem) => {
								  const formattedDate = formatDate(TimePeriod); 
								  if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
								  }
								  acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + userCount;
								  acc[formattedDate].totalUsers += userCount;
								  return acc;
								},{}
							);
  							const chartDataArray = Object.keys(formattedData).map((date) => ({
    							[date]: formattedData[date],
  							}));
  							const teams: string[] = Array.from(
    							new Set(response.data.dailyGrowth.map((item: DailyGrowthItem) => item.sector))
  							);
  							const series = teams.map((team, index) => ({
    							name: team,
    							dataKey: team,
    							color: seriesColor[index % seriesColor.length],
  							}));
  							setBarSeriesDataUser(series);
  							setChartOptionUsers(chartDataArray);
						}
						else if (response && response.data && response.data.weeklyGrowth && timePeriod == 'Weekly') {
  							type WeeklyGrowthItem = {
    							TimePeriod: string;
    							sector: string;
    							userCount: number;
  							};
							const formattedData = response.data.weeklyGrowth.reduce(
								(acc: { [key: string]: any }, { TimePeriod, sector, userCount }: WeeklyGrowthItem) => {
								  const formattedDate = formatWeekToDate(TimePeriod); 
								  if (!acc[formattedDate]) {
									acc[formattedDate] = { totalUsers: 0 };
								  }
								  acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + userCount;
								  acc[formattedDate].totalUsers += userCount;
								  return acc;
								},{}
							  );
  							const chartDataArray = Object.keys(formattedData).map((date) => ({
    							[date]: formattedData[date],
  							}));
  							const teams: string[] = Array.from(
    							new Set(response.data.weeklyGrowth.map((item: WeeklyGrowthItem) => item.sector))
  							);
  							const series = teams.map((team, index) => ({
    							name: team,
    							dataKey: team,
    							color: seriesColor[index % seriesColor.length],
  							}));
  							setBarSeriesDataUser(series);
  							setChartOptionUsers(chartDataArray);  
						}
						else if (response && response.data && response.data.yearlyGrowth && timePeriod == 'Yearly') {
							type YearlyGrowthItem = {
							  TimePeriod: string;
							  sector: string;
							  userCount: number;
							};
						  const formattedData = response.data.yearlyGrowth.reduce(
							  (acc: { [key: string]: any }, { TimePeriod, sector, userCount }: YearlyGrowthItem) => {
								const formattedDate = formatYearToDate(TimePeriod); 
								if (!acc[formattedDate]) {
								  acc[formattedDate] = { totalUsers: 0 };
								}
								acc[formattedDate][sector] = (acc[formattedDate][sector] || 0) + userCount;
								acc[formattedDate].totalUsers += userCount;
								return acc;
							  },{}
							);
							const chartDataArray = Object.keys(formattedData).map((date) => ({
							  [date]: formattedData[date],
							}));
							const teams: string[] = Array.from(
							  new Set(response.data.yearlyGrowth.map((item: YearlyGrowthItem) => item.sector))
							);
							const series = teams.map((team, index) => ({
							  name: team,
							  dataKey: team,
							  color: seriesColor[index % seriesColor.length],
							}));
							setBarSeriesDataUser(series);
							setChartOptionUsers(chartDataArray);  
					    }
						else{
							console.error('No data returned from API');
						}
		} catch (error) {
			console.error('Error fetching chart data:', error);
		}finally {
			setChartLoadingUsers(false);
		}
	};
 

	const handleTimePeriodChange = (type: string) => (event: { target: { value: string } }) => {
		const newTimePeriod: string = event.target.value;
		if(type === 'Capacity'){
			setTimePeriodCapacity(newTimePeriod);
			fetchCapacityGrowthChartData(newTimePeriod,selectedMonth);
		}
		else if(type === 'Gateway'){
			setTimePeriodGateway(newTimePeriod);
			fetchGatewayGrowthChartData(newTimePeriod,selectedMonth);
		}
		else if(type === 'Reports'){
			setTimePeriodReports(newTimePeriod);
			fetchReportGrowthChartData(newTimePeriod,selectedMonth);
		}
		else if(type === 'Users'){
			setTimePeriodUsers(newTimePeriod);
			fetchUserGrowthChartData(newTimePeriod,selectedMonth);
		}
	};
	
	const handleDateChange = (selectedDate: string) => {
		let dateLabel = '';
		switch(selectedDate){
			case '0T': dateLabel = 'Today';
				break;
			case '1T': dateLabel = 'Previous Day';
				break;
			case '0W': dateLabel = 'This Week';
				break;
			case '1W': dateLabel = 'Last Week';
				break;
			case '0M': dateLabel = 'This Month';
				break;
			case '1M': dateLabel = 'Last Month';
				break;
			case '3M': dateLabel = 'Last 3 Months';
				break;
			case '6M': dateLabel = 'Last 6 Months';
				break;	
			case '9M': dateLabel = 'Last 9 Months';
				break;
			case '0Y': dateLabel = 'This Year';
				break;
			case '1Y': dateLabel = 'Last Year';
				break;
			case '2Y': dateLabel = 'Last 2 Years';
				break;	
			default: dateLabel = selectedDate;
				break;	
		}
		setSelectedMonth(dateLabel);
		fetchData(dateLabel);
		// Reset page and page size
		const resetPage = 1; const resetPageSize = 100;
		setFilterInfo([]);
		adoptionDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),dateLabel);
		fetchCapacityGrowthChartData(timePeriodCapacity,dateLabel);
		fetchGatewayGrowthChartData(timePeriodGateway,dateLabel);
		fetchReportGrowthChartData(timePeriodReports,dateLabel);
		fetchUserGrowthChartData(timePeriodUsers,dateLabel);
	};
		async function getActivityDetails(capacityDetails:any) {
			try{
				const capacityData = capacityDetails?.data?.result || []; // Assuming 'result' contains the relevant data
		
				// Transform the fetched data into the desired structure
				const incidentData = capacityData.map((item: any) => {
					
					// Convert timePoint to MM-DD-YYYYTHH:mm:ss.SSSZ format
					const dateObj = new Date(item.firstSeenDate);
					const formattedTimePoint = `${(dateObj.getMonth() + 1)
						.toString()
						.padStart(2, '0')}-${dateObj
						.getDate()
						.toString()
						.padStart(2, '0')}-${dateObj.getFullYear()}T${dateObj
						.toISOString()
						.split('T')[1]}`;
		
					return {
						capacityName: item.capacityName,
						capacityOwner: item.capacityOwner,
						workspaceName: item.workspaceName,
						reportName: item.reportName,
						sector: item.sector,
					    workspaceOwner: item.capacityOwner,
						reportOwner: item.capacityOwner,
						capacitySKU: item.capacitySKU,
						userName: item.userName,
						GroupUserAccessRight: item.GroupUserAccessRight,
						firstSeenDate: item.firstSeenDate,
						lastLogin: item.lastLogin
					};
				});
				setAdoptionDetails(incidentData);
			}catch (error) {
				console.error('Error fetching capacity data:', error);
			} finally {
				setTableLoading(false);
			}
		}
		async function getActivityPagination(pageResponse:any,pageNumber:any,size:any) {
			try{
				const paginationData = pageResponse.data.page;
				setPaginationData(paginationData); // Set pagination data directly from response
				// Update state for current page and size
				if (pageNumber > pageResponse.data.page.totalPages) {
					setPage(pageResponse.data.page.currentPage);
				} else {
					setPage(pageNumber);
				}
				setPageSize(size);
			}catch (error) {
				console.error('Error fetching capacity data:', error);
			} finally {
				setTableLoading(false);
			}
		}
		async function getActivityFilters(filterResponse:any) {
			try{
				setFilterData(filterResponse?.data?.filterOptions);
			}catch (error) {
				console.error('Error fetching capacity data:', error);
			} finally {
				setTableLoading(false);
			}
		}
	return (
		<Stack mt="80px" width="100%">
			<PageHeader title="Adoption Metrics" icon={AdoptionMetricsLogo} lastSync={''} iconSize={30}>
			<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={handleDateChange}
					boxProps={{ sx: { backgroundColor: 'primary.main',
						minHeight: '50px', 
					 } }}
					 showTodayOption={true}
					 showPreviousDayOption={true}
					 showThisWeekOption={true}
					 showLastWeekOption={true}
				/>
				<SectorCheckFilter
					sectorStorageName="ml-summary-sector-filter"
					handleOkClick={handleOkClick}
					checkedListRef={checkedListRef}
				/>
			</PageHeader>
			<Stack p={2} spacing={2}>
				<Grid container spacing={1}>
					<Grid item container xs={12} spacing={1}>
						{infoCardData.map((info, idx) => (
							/**<Grid key={info.title} item xs={12} md={4}> **/
							<Grid key={info.title} item xs={12} md={6}>
                                <NumberWidget
									isLoading={loading}
									title={info.title}
									tooltipLabel=""
									// handleEvent={info.handleEvent}
									ratioNumber={{
										number: info.number,
										totalNumber: info.totalNumber,
										growthRate: Math.abs(info.growthRate),
									}}
									pbiUnits={info.rawNumber >= 1000 ? "k" : ""}
									containerProps={{
										minHeight: '50px',
									}}
                                    unitIcon={info.growthRate >= 0 ? 'upArrow' : info.growthRate < 0 ? 'downArrow' : '' }
                                    unitIconColor={info.growthRate >= 0 ? 'green' : 'red' }
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
				<Grid container spacing={1}>
				<Grid item container xs={12} md={6}>
					<BarChartWidget
							title="Capacity Growth"
							isLoading={chartLoadingCapacity}
							seriesData={barSeriesData}
							data={chartOptionCapacity}
							yAxisOptions={{ name: 'Capacities', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-16}
							containerProps={{
								pt: 0.5
							}}
							isPBI
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange('Capacity')}
								value={timePeriodCapacity}
								options={[
									{label: 'Monthly', value: 'Monthly'},
									{label: 'Daily', value: 'Daily'},
									{label: 'Weekly', value: 'Weekly'},
									{label: 'Yearly', value: 'Yearly'},
								]}
							/>
					</BarChartWidget>
				</Grid>
				<Grid item container xs={12} md={6}>
				<BarChartWidget
							title="Gateway Growth"
							isLoading={chartLoadingGateway}
							seriesData={barSeriesDataGateway}
							data={chartOptionGateway}
							yAxisOptions={{ name: 'Gateways', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-16}
							containerProps={{
								pt: 0.5
							}}
							isPBI
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange('Gateway')}
								value={timePeriodGateway}
								options={[
									{label: 'Monthly', value: 'Monthly'},
									{label: 'Daily', value: 'Daily'},
									{label: 'Weekly', value: 'Weekly'},
									{label: 'Yearly', value: 'Yearly'},
								]}
							/>
				</BarChartWidget>
				</Grid>
				</Grid>
				<Grid container spacing={1}>
				<Grid item container xs={12} md={6}>
					<BarChartWidget
							title="Reports Growth"
							isLoading={chartLoadingReports}
							seriesData={barSeriesDataReport}
							data={chartOptionReports}
							yAxisOptions={{ name: 'Reports', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-16}
							containerProps={{
								pt: 0.5
							}}
							isPBI
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange('Reports')}
								value={timePeriodReports}
								options={[
									{label: 'Monthly', value: 'Monthly'},
									{label: 'Daily', value: 'Daily'},
									{label: 'Weekly', value: 'Weekly'},
									{label: 'Yearly', value: 'Yearly'},
								]}
							/>
					</BarChartWidget>
				</Grid>
				<Grid item container xs={12} md={6}>
				<BarChartWidget
							title="Users Growth"
							isLoading={chartLoadingUsers}
							seriesData={barSeriesDataUser}
							data={chartOptionUsers}
							yAxisOptions={{ name: 'Users', nameLocation: 'middle', nameGap: 40, splitLine: { show: false } }}
							heightDifferencePercent={-16}
							containerProps={{
								pt: 0.5
							}}
							isPBI
						>
							<CustomDropdown
								handleChange={handleTimePeriodChange('Users')}
								value={timePeriodUsers}
								options={[
									{label: 'Monthly', value: 'Monthly'},
									{label: 'Daily', value: 'Daily'},
									{label: 'Weekly', value: 'Weekly'},
									{label: 'Yearly', value: 'Yearly'},
								]}
							/>
				</BarChartWidget>
				</Grid>
				</Grid>
				{!tableLoading ? (
					<CustomizedDataGrid
						title="Details"
						tableColumns={getColumns()}
						tableData={adoptionDetails}
						tableType="adoption-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={adoptionDetails.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={tableLoading}
					/>
				) : (
					<Stack justifyContent="center" alignItems="center" minHeight="100px">
						<LoaderComponent />
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default AdoptionMetrics;
