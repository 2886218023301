import PrivateRoute from 'utils/privateRoute';
import PlatformCostObsDashboard from 'components/PlatformCostObs/dashboard';
import PlatformCostObsResouceMapping from 'components/PlatformCostObs/resourceMapping';
import ResourceGroupDetails from 'components/PlatformCostObs/resourceGroups/details';
import platformExecutiveView from 'components/PlatformCostObs/platformExecutiveView';

const PlatformCostObsRoutes = [
	{
		key: 'plat_dash',
		route: '/platformCostObs/dashboard',
		component: (
			<PrivateRoute
				Component={PlatformCostObsDashboard}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'plat_res',
		route: '/platformCostObs/resourceMapping',
		component: (
			<PrivateRoute
				Component={PlatformCostObsResouceMapping}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'plat_res',
		route: '/platformCostObs/executiveView',
		component: (
			<PrivateRoute
				Component={platformExecutiveView}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'data_est_plat_resmap',
		route: '/dataestate/platform/resourceMapping',
		component: (
			<PrivateRoute
				Component={PlatformCostObsResouceMapping}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'data_est_plat_res_group',
		route: '/dataestate/platform/resourceGroups/:resourceGroup',
		component: (
			<PrivateRoute
				Component={ResourceGroupDetails}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	},
	{
		key: 'plat_res_group',
		route: '/platformCostObs/resourceGroups/:resourceGroup',
		component: (
			<PrivateRoute
				Component={ResourceGroupDetails}
				restrictedRoles={['Platform Operations Admin/SRE', 'Data Operations Admin', 'Data Operations User']}
			/>
		)
	}
];

export default PlatformCostObsRoutes;
