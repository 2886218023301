import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Stack, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-img.svg';
import { ReactComponent as ViewIcon } from '../../../assets/view-detail-img.svg';
import { CustomizedDataGrid } from '../../customized_data_grid';
import Loader from '../../Loader/loader';
import { Link } from 'react-router-dom';
import PageHeader from 'components/Header/Page';
import { ReactComponent as AddIcon } from '../../../assets/plus-icon.svg';
import DashboardIcon from '../../../assets/material-symbols_dashboard.svg';
import '../../DataEstate/data-estate-dashboard.scss';
import { ResourceMappingModal } from './modalComponent';
import { fetchResourceMappingPrograms } from 'service/service';
import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import { Column } from 'react-data-grid';
import SearchFilter from 'components/Dropdown/SearchFilter';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SubscriptTag from 'components/Widgets/SubscriptTag';
import LoaderComponent from 'components/Loader';
import { exportTableData } from 'service/service';
import eventEmitter from 'SnackBarEventEmitter';

const Programs = () => {
	const programExpandList = useRef<Row[]>([]);
	const [noRefresh, setNoRefresh] = useState(false);
	const [programList, setProgramList] = useState<any[]>([]);
	const [openModal, setOpenModal] = useState<any>({ isOpen: false });
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ currentPage: 0, totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [errorMessage, setErrorMessage] = useState('');
	const defaultColumnNames = ['Program & Project', 'Owner', 'Description', 'Actions'];

	interface Row {
		id: string;
		programId: any;
		programName: string;
		programDescription: string;
		programOwner: string;
		projectId: any;
		projects:[];
		createdAt: string;
		updatedAt: string;
		projectName:any,
		projectDescription: string;
		projectOwner: string;
		isExpanded?: boolean;
		isExpandedChild?: boolean;
		children: Row[];
		ischildren?: boolean;
		selected: boolean;
	}

	let dynamicWidth: any;
	useEffect(() => {
		const handleResize = () => {
		  const screenWidth = window.innerWidth;
		  dynamicWidth = (screenWidth / 3) - 100;
		};
	
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	  }, []);

	function getColumns(): Array<Column<Row>> {
		const isNew = (updatedAt: any) => {
			const createdDate: any = new Date(updatedAt);
			const now: any = new Date();
			return (now - createdDate)/(1000 * 60 * 60 * 24) < 1;
		}
		return [
			{
				key: 'programName',
				name: 'Program & Project',
				// minWidth: 200,
				// maxWidth: 500,
				width: dynamicWidth,
				formatter({ row, isCellSelected }) {
					const hasChildren = row.projects;
					const hasChildrenofChild = row.projectName;
					const prgId = row.programId;
					const prjId = row.projectId;
					const prgName = row.programName;
					const prjName = row.projectName;
					return (
						<>
							{hasChildren && row.projects.length > 0 && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.programName, row.projects);
									}}
								/>
							)}
							{'   '}
							<Link
								to={`/finops/resourcemapping/programs/${prgId}/${encodeURIComponent(prgName)}`}
								state={row}
								style={{ paddingLeft: hasChildren ? '5px' : '10px', 
									paddingRight: '10px',
									color: '#000',
    								fontWeight: 500
								 }}
							>
								 {hasChildren ? row.programName : ''}
							</Link>
							<Link
								to={`/finops/resourcemapping/projects/${prgId}/${prjId}/${encodeURIComponent(prgName)}/${encodeURIComponent(prjName)}`}
								state={row}
								style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px',
									color: '#000',
    								fontWeight: 400
								 }}
							>
								 {row.projectName}
							</Link>
							{isNew(row?.updatedAt) && <SubscriptTag label="New" />}
						</>
					);
				}
			},
			{
				key: 'programOwner',
				name: 'Owner',
				// minWidth: 200,
				// maxWidth: 500,
				width: dynamicWidth,
				formatter({ row }) {
					const hasChildren = row.projects;
					const hasChildrenofChild = row.projectName;
					return (
						<>
							{'   '}
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildren ? '5px' : '10px',
									paddingRight: '10px',
									color: '#000',
    								fontWeight: 500
								 }}
							>
								 {row.programOwner}
							</Link>
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px',
									color: '#000',
									fontWeight: 400
								 }}
							>
								 {row.projectOwner}
							</Link>
						</>
					);
				}
				
			},
			{
				key: 'programDescription',
				name: 'Description',
				// minWidth: 200,
				// // width: 460,
				width: dynamicWidth,
				formatter({ row }) {
					const hasChildren = row.projects;
					const hasChildrenofChild = row.projectName;
					return (
						<>
							{'   '}
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildren ? '5px' : '10px',
									paddingRight: '10px',
									color: '#000',
    								fontWeight: 500
								 }}
							>
								 {row.programDescription}
							</Link>
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px',
									color: '#000',
    								fontWeight: 400
								 }}
							>
								 {row.projectDescription}
							</Link>
						</>
					);
				}
			},
			{
				key: 'actions',
				name: 'Actions',
				width: 100,
				resizable: false,
				formatter: (props: any) => {
					const hasChildren = props.row.projects;
					return (
						<Stack direction="row" alignItems="center" height="100%">
							<EditIcon title="Edit" style={{ cursor: 'pointer' }} onClick={(e) => handleAction('EDIT', e, props, hasChildren)}/>
							<DeleteIcon title="Delete" style={{ cursor: 'pointer' }} onClick={(e) => handleAction('DELETE', e, props, hasChildren)} />
						</Stack>
					);
				}
			}
		];
	};

	const handleSorting = (e: any) => {
		programListFunc(page, pageSize, e);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		programListFunc(value, pageSize, sortingInfo);
	};

	const handlepageSizeChange = (event: any) => {
		programListFunc(page, event.target.value, sortingInfo);
	};

	const handleAction = (action: any, e: any, props: any, hasChildren: any) => {
		e.preventDefault();
		e.stopPropagation();
		setOpenModal({
			isOpen: true,
			type: (hasChildren ? 'Program' : 'Project'),
			action,
			data: props.row
		});
	};

	const handleEditClose = (isCanceled: boolean = false) => {
		setOpenModal({
			isOpen: false
		});
		setNoRefresh(isCanceled);
	};

	const handleAddProgramClick = useCallback(() => {
		setOpenModal({
			isOpen: true,
			type: 'Program',
			action: 'ADD',
			data: ''
		});
	}, []);

	async function programListFunc(pageNumber: any, size: any, sortingInfo: any, searchText?: any) {
		try {
			setLoading(true);
			if (pageSize !== size) {
				pageNumber = 1;
			}
			const res = await fetchResourceMappingPrograms(pageNumber.toString(), size.toString(), sortingInfo, searchText);
			if (res.success) {
				setLoading(false);
				setProgramList(res.data.programs);
				programExpandList.current = res.data.programs;
				setPaginationData(res.data.page);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
			}
		} catch (error) {
			console.error(error);
			if (typeof error === "object" && error !== null && "response" in error) {
				const err = error as { response: { status: number } };
				if (err.response.status === 503)
					eventEmitter.emit("showSnackbar", "Service temporarily unavailable. Please try again later.", "info");
				else if (err.response.status === 504)
					eventEmitter.emit("showSnackbar", "Gateway Timeout", "info");
			}
		}
	}

	let progName: any = '';
	function toggleSubRow(name: string,resourceTypes:any) {
		const rows = programExpandList.current;
		const rowIndex = rows.findIndex((r: any) => r.programName === name);
		const row = rows[rowIndex];
		const newRows = [...rows];
		let children: Row[] = [];
		let childRows: Row[] = [];
		progName = name;
		if (!row.isExpanded) {
			if (resourceTypes.length>0) {
				childRows = resourceTypes;
			}
			children = childRows.map((x: any) => {
				return { ...x, isChildren: true };
			});
			newRows.splice(Number(rowIndex) + 1, 0, ...children);
			newRows[rowIndex] = { ...row, isExpanded: true, children };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			row.isExpanded = true;
		}
		else
		{
			children = row.children;
			newRows.splice(Number(rowIndex) + 1, children?.length);
			newRows[rowIndex] = { ...row, isExpanded: false };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			row.isExpanded = false;
		}
		programExpandList.current = newRows;
		setProgramList([...newRows]);
	}

	const handleSearchData = (serachText: string) => {
		try {
			programListFunc(page, pageSize, sortingInfo, serachText);
		} catch (error) {
			console.error(error);
		}
	};
	
	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/finops/resourcemapping/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		if(!openModal.isOpen && !noRefresh) 
		{
			setSortingInfo({ sortBy : '', sortDir : '' });
			sortingInfo.sortBy = sortingInfo.sortDir = "";
			programListFunc(page, pageSize, sortingInfo);
		}
		setNoRefresh(false);
	}, [openModal]);


	// export
		const exportProgramList = async () => {
			let tableName="finopsProgramTable";
			let formatType="EXCEL";
			let exportColumns = ["Program_Project", "Owner", "Description"];
			await exportTableData({tableName, formatType, exportColumns});
		};
	
	return (
		<Box bgcolor="blue.darker" mt="60px">
			{/* {loading && <Loader />} */}
			<PageHeader title="Programs" icon={DashboardIcon}>
				<Stack direction="row" gap={1} alignItems="center">
					<SearchFilter handleSearchData={handleSearchData} />
					<Button variant="pepwiseSecondary" onClick={handleAddProgramClick} startIcon={<AddIcon />}>
						ADD PROGRAM
					</Button>
				</Stack>
			</PageHeader>
			<Stack p={2}>
				<Dialog open={openModal.isOpen} onClose={() => handleEditClose(true)} fullWidth={true} maxWidth="md">
					<ResourceMappingModal
						handleEditClose={handleEditClose}
						programData={openModal}
						errorMessage={errorMessage}
						setErrorMessage={setErrorMessage}
					/>
				</Dialog>
				<Dialog open={!!errorMessage} maxWidth="sm">
					<DialogContent
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '8px'
						}}
					>
						<ErrorOutlineIcon sx={{ color: 'red', fontSize: '22px' }} />
						<Typography>{errorMessage}</Typography>
					</DialogContent>
					<DialogActions
						sx={{
							pr: '1rem',
							pb: '1rem'
						}}
					>
						<Button onClick={() => setErrorMessage('')} variant="pepwiseSecondary">
							OK
						</Button>
					</DialogActions>
				</Dialog>
				<Grid item xs={12}>
					{loading ? (
						<LoaderComponent pt={20} />
					) : (
						<CustomizedDataGrid
							tableColumns={getColumns()}
							tableData={programList}
							tableType="programs-list"
							defaultColumnNames={defaultColumnNames}
							onSorting={handleSorting}
							currSorting={sortingInfo}
							page={page}
							pageSize={pageSize}
							paginationData={paginationData}
							listLength={programList.length}
							showExport={true}
							fileName={`programs_Page_${paginationData.currentPage}_of_${paginationData.totalPages}.xlsx`}
							handlepageSizeChange={handlepageSizeChange}
							handlePageChange={handlePageChange}
							isDateRangeFilter={false}
							isDisabled={false}
							isShowMoreButton={true}
							// isShowDisabledButton={false}
							exportTableData = {() => exportProgramList()}
							isFinopsProgramsList={true}
							childRowClass={true}
						/>
					)}
				</Grid>
			</Stack>
		</Box>
	);
};

export default Programs;
