import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Box
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import CloseIcon from "@mui/icons-material/Close";
import {ReactComponent as SuccessImage} from "../../assets/success.svg"

const SuccessConfirmationDialog = ({ open, onClose}: any) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#022b5f", 
          color: "white",
          width: "400px", 
          borderRadius: "8px",
        },
      }}
    >
      {/* Header Section */}
      <DialogTitle
        sx={{
            bgcolor: 'primary.main',
            color: 'white.main',
            p: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
      >
         <span>
    Alert Successfully Configured
  </span>
        <IconButton onClick={onClose} sx={{ color: "white"}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Content Section */}
      <DialogContent sx={{ textAlign: "center", padding: "20px" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>  {/* Changed justifyContent to space-between */}
                <SuccessImage style={{width: 60, height: 60, padding: 8, marginTop: 8}}/>
                <Box textAlign="left"> {/* Added a container to align text to the left */}
                    <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        AlertName has been Configured Successfully
                    </Typography>
                    <Typography variant="body2" sx={{ opacity: 0.8 }}>
                        Would you like to configure more alerts?
                    </Typography>
                </Box>
            </Box>
        </DialogContent>

      {/* Actions Section */}
      <DialogActions sx={{ justifyContent: "space-between", padding: "20px" }}>
        <Button onClick={onClose} sx={{ color: "#00A3E0", fontWeight: "bold", textTransform: "uppercase" }}>
          CONFIRM & CLOSE
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{ textTransform: "uppercase" }}
        >
          ADD MORE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessConfirmationDialog;