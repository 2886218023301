import React, { useEffect, useRef, useState } from 'react';
import { Grid, Stack, Box, GridProps } from '@mui/material';
import { isEnvTypePROD } from '../../service/config';
import '../TableauObs/Summary/Summary.scss';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { getDataEstateSector, getDecodedToken, getSector } from '../../service/service';
import StorageIcon from '../../assets/storage-summary.svg';

import '../DataEstate/data-estate.scss';
import { fetchDEStorageSummary, fetchDEStorageTrends, getSectorSql, fetchDEStorageTable } from '../../service/service';
import { getPercentageOfTwoNumbers, formatByByte, convertShortDateToActualDateFormat } from '../../utils/common-util';
import { CustomizedDataGrid } from '../customized_data_grid';
import { type Column } from 'react-data-grid';
import NumberWidget from 'components/Widgets/NumberWidget';
import ChartWidget from 'components/Widgets/ChartWidget';
import ConversionUnitDropdown from 'components/Dropdown/ConversionUnit';
import PageHeader from 'components/Header/Page';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import { getChartOptions } from 'components/Widgets/chartWidgetUtility';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import LoaderComponent from 'components/Loader';
import SectorFilter from 'components/Dropdown/SectorFilter';
import { sectorListDetails } from 'Context/contexts/cacheData/DataEstate/type';

const gridChildProps: GridProps = {
	minWidth: '260px',
	flexGrow: 1,
	flexBasis: 0,
	maxWidth: '100%'
};

const StorageSummary = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [controller] = useCacheDataController();
	const { teradataStorageLastSyncDate, conversionUnit } = controller;
	const [sectorName, setSectorName] = useState('all');
	const hasDrillDown = searchParams.has('isDrillDowned');
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [loading, setLoading] = useState(false);
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);

	const [isLoading, setIsLoading] = useState(false);
	const [TeradataSize, SetTeradataSize] = useState('-');
	const [AdlsSize, SetAdlsSize] = useState('-');
	const [AdlsSizeNoData, SetAdlsSizeNoData] = useState(false);
	const [AzureSqlSize, SetAzureSqlSize] = useState('-');
	const [AzureSqlSizeNoData, SetAzureSqlSizeNoData] = useState(false);
	const [AzureSynapse, SetAzureSynapse] = useState('-');
	const [AzureSynapseNoData, SetAzureSynapseNoData] = useState(false);
	const [AzureCosmos, SetAzureCosmos] = useState('-');
	const [AzureCosmosNoData, SetAzureCosmosNoData] = useState(false);
	const [TeradataSizeRaw, SetTeradataSizeRaw] = useState(0);
	const [AdlsSizeRaw, SetAdlsSizeRaw] = useState(0);
	const [AzureSqlSizeRaw, SetAzureSqlSizeRaw] = useState(0);
	const [AzureSynapseRaw, SetAzureSynapseRaw] = useState(0);
	const [AzureCosmosRaw, SetAzureCosmosRaw] = useState(0);

	const [grafanaAzureLoading, setGrafanaAzureLoading] = useState(true);
	const [grafanaAzureOptions, setGrafanaAzureOptions] = useState<any>();
	const [grafanaTeradataLoading, setGrafanaTeradataLoading] = useState(true);
	const [grafanaTeradataOptions, setGrafanaTeradataOptions] = useState<any>(null);
	const [filterMonth, setFilterMonth] = useState('0M');
	const selectedByte = useRef('PB');
	const [lastUpdatedDateTeradata, setLastUpdatedDateTeradata] = useState(''); 
	const [lastUpdatedDateAzure, setLastUpdatedDateAzure] = useState(''); 

	const [allSectorOptionsList, setAllSectorOptionsList] = useState<sectorListDetails | null>(null);
	const [sectorTagList, setSectorTagList] = useState<any>({ azure: true});


	const [teradataRes, setTeradataRes] = useState<any[]>([]);
	const [azureRes, setAzureRes] = useState<any[]>([]);

	function formatNumber(number: any) {
		console.info(number, 'number==');
		if (number == null || isNaN(number)) {
			return '0.00'; 
		}
		// if (number >= 10000000) {
		// 	const scaledNumber = number / 1000000;
		// 	return Number(scaledNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		// } else if (number >= 100000) {
		// 	const scaledNumber = number / 10000;
		// 	return Number(scaledNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		// } else if (number >= 10000) {
		// 	const scaledNumber = number / 1000;
		// 	return Number(scaledNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		// } else if (number >= 100) {
		// 	const scaledNumber = number / 10;
		// 	return Number(scaledNumber).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		// } else if (number >= 1) {
		// 	return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		// } 
		else {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		}
	}

	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if (sectorOptionsList.length === checkedList.length + newlyChecked.length) {
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		clearMetricsSummary();
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		getStorageSummaryDetails(checkedList, filterMonth, '');
		setCurrSector(checkedList);
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		let sectorKpi = [];
		sectorKpi.push("azure");
		const sectorRes = await getDataEstateSector(sectorKpi);
		if (sectorRes.success) {
			if(sectorRes.data) {
				setAllSectorOptionsList(sectorRes.data);
				const allSectors = [
					...sectorRes.data.azure.map((item: any) => item.sector),
				];
				const uniqueSectors = Array.from(new Set(allSectors));
				const sectorNameList = uniqueSectors.map(sector => {
					return sector === "" ? "Blank" : sector ;
				});
				setSectorOptionsList(sectorNameList.sort());
			}
		}
	}

	const teradataTrend = (resData: any) => {
		setGrafanaTeradataOptions(resData.data);
		setLastUpdatedDateTeradata(resData.lastUpdatedDate)
		setGrafanaTeradataLoading(false);
	};

	const azureTrend = (resData: any) => {
		setGrafanaAzureOptions(resData.data);
		setLastUpdatedDateAzure(resData.lastUpdatedDate)
		setGrafanaAzureLoading(false);
	};

	async function getStorageSummaryDetails(sectors: any, month: any, from?: string	) {
		setIsLoading(true);
		// let sector = sectors.length == sectorOptionsList.length ? [] : sectors;
		// Trend APIs
		if (from == '') {
			SetTeradataSize('-');
			setTeradataRes([]);
		}
		
		SetAdlsSize('-');
		SetAzureSqlSize('-');
		SetAzureSynapse('-');
		SetAzureCosmos('-');

		setAzureRes([]);
		if(sectors == 'Blank')
			sectors = '';

		Promise.all([
			from == '' ? fetchDEStorageTrends('storage', 'teradata', sectors, month) : '',
			fetchDEStorageTrends('storage', 'azure', sectors, month),
			fetchDEStorageSummary(sectors, month)
		])
			.then(([teradataRes, azureRes, summaryApiRes]) => {
				// Teradata
				if (from == '') {
					setTeradataRes(teradataRes.data);
					teradataTrend(teradataRes.data);
				}
				
				// Azure
				setAzureRes(azureRes.data);
				azureTrend(azureRes.data);

				// Summary APIs
				let teradataSize = summaryApiRes?.data?.dataStoredInTeradata?.[0]?.['VALUE'] ?? 0;
				let teradataLastYearSize = summaryApiRes?.data?.dataStoredInTeradataLastYear?.[0]?.['VALUE'] ?? 0;

				// const resData: any = Object.entries(summaryApiRes?.data?.dataStoredInTeradata[0].VALUE).filter(([date]) => new Date(date));
				// // let teradataCurrentMonthSize = resData.length ? resData[0][1].dev + resData[0][1].uat + resData[0][1].prod : 0;
				// let teradataCurrentMonthSize = resData.length ? resData[0][1].prod : 0;
				SetTeradataSize(formatByByte(conversionUnit, teradataSize));
				SetTeradataSizeRaw(teradataSize);

				// let teradataSize = summaryApiRes?.data?.dataStoredInTeradata?.[0]?.["VALUE"] ?? 0;
				// SetTeradataSize(teradataSize.toFixed(2).toString());
				if(summaryApiRes?.data?.dataStoredInADLS != 'no data') {
					let adlsSize = summaryApiRes?.data?.dataStoredInADLS?.[0]?.['VALUE'] ?? 0;
					SetAdlsSize(formatByByte(conversionUnit, adlsSize));
					SetAdlsSizeRaw(adlsSize);
				} else {
					SetAdlsSizeNoData(true)
				}
				if(summaryApiRes?.data?.dataStoredInSQL != 'no data') {
					let azureSize = summaryApiRes?.data?.dataStoredInSQL?.[0]?.['VALUE'] ?? 0;
					SetAzureSqlSize(formatByByte(conversionUnit, azureSize));
					SetAzureSqlSizeRaw(azureSize);
				} else {
					SetAzureSqlSizeNoData(true)
				}
				const selectedDateTrans = convertShortDateToActualDateFormat(month)
				if(summaryApiRes?.data?.dataStoredInSynapse != 'no data') {
					let azureSynaps = summaryApiRes?.data?.dataStoredInSynapse?.[0]?.['VALUE'] ?? 0
					SetAzureSynapse(formatByByte(conversionUnit, azureSynaps));
					SetAzureSynapseRaw(azureSynaps);
				} else {
					SetAzureSynapseNoData(true)
				}
				if(summaryApiRes?.data?.dataStoredInCosmos != 'no data') {
					let azureCosmos = summaryApiRes?.data?.dataStoredInCosmos?.[0]?.['VALUE'] ?? 0;
					SetAzureCosmos(formatByByte(conversionUnit, azureCosmos));
					SetAzureCosmosRaw(azureCosmos);
				} else {
					SetAzureCosmosNoData(true)
				}
			})
			.catch((error: any) => {
				console.error(error);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}

	useEffect(() => {
		if (hasDrillDown) {
			const selectedSector = localStorage.getItem('selectedSector');
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : '';
				setCheckedList(selectedSec);
				setCurrSectorFilter(selectedSec);
			}
		} else {
			localStorage.removeItem('selectedSector');
		}

		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/storagesummary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
				document.body.classList.add('byte');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getStorageSummaryDetails(sectorName, filterMonth, '');
	}, []);

	async function programListingFunc(pageNumber: any, size: any, sortingInfo: any, dimension: any) {
		console.log(dimension, 'data');
		let month = customMonth ? customMonth : filterMonth;
		const res = await fetchDEStorageTable('storage', dimension.current, sectorName, month);
		if (res.success) {
			setTableData(res.data.summary);
			setPaginationData(res.data.page);
			// if (res.data.page){
			//     setPaginationData(res.data.page);
			// }
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setTimeout(() => {
				if (tableRef.current) {
					tableRef.current.scrollIntoView({ behavior: 'smooth' });
				}
			}, 1000);
		}
		console.log(defaultColumnNames, 'default final');
	}
	interface teradata {
		dataBaseName: string;
		usedSpaceInGB: string;
		maxSpaceInGB: string;
		percentageUsed: string;
		remainingSpaceInGB: string;
	}
	interface ADLS {
		azureSubscription: string;
		resourceGroup: string;
		storageAccountName: string;
		fileName: string;
		usedCapacityInGB: string;
	}
	interface SQL {
		azureSubscription: string;
		resourceGroup: string;
		sqlDatabase: string;
		usedSpaceInGB: string;
		dataSpaceAllocatedInGB: string;
		dataSpaceAllocatedButUnused: string;
		dataMaxSize: string;
	}

	interface Row {}
	function getColumns(): Array<Column<Row>> {
		if (typeName === 'Data Stored in Teradata') {
			return [
				{
					key: 'dataBaseName',
					name: 'Database name',
					minWidth: 180
				},
				{
					key: 'usedSpaceInGB',
					name: 'Used space in GB',
					minWidth: 170
				},
				{
					key: 'maxSpaceInGB',
					name: 'Max space in GB',
					minWidth: 170
				},
				{
					key: 'percentageUsed',
					name: 'Percentage used',
					minWidth: 170
				},
				{
					key: 'remainingSpaceInGB',
					name: 'Remaining space in GB',
					minWidth: 170
				}
			];
		} else if (typeName === 'Data Stored in ADLS  & Blob') {
			return [
				{
					key: 'azureSubscription',
					name: 'Azure subscription',
					minWidth: 180
				},
				{
					key: 'resourceGroup',
					name: 'Resource group',
					minWidth: 170
				},
				{
					key: 'storageAccountName',
					name: 'Storage account name',
					minWidth: 170
				},
				{
					key: 'fileName',
					name: 'File name',
					minWidth: 170
				},
				{
					key: 'usedCapacityInGB',
					name: 'Used Capacity in GB',
					minWidth: 170
				}
			];
		} else if (typeName === 'Data Stored in Azure SQL') {
			return [
				{
					key: 'azureSubscription',
					name: 'Azure subscription',
					minWidth: 180
				},
				{
					key: 'resourceGroup',
					name: 'Resource group',
					minWidth: 170
				},
				{
					key: 'sqlDatabase',
					name: 'SQL database',
					minWidth: 170
				},
				{
					key: 'usedSpaceInGB',
					name: 'Used space in GB',
					minWidth: 170
				},
				{
					key: 'dataSpaceAllocatedInGB',
					name: 'Data space allocated in GB',
					minWidth: 170
				},
				{
					key: 'dataSpaceAllocatedButUnused',
					name: 'Data space allocated but unused',
					minWidth: 170
				},
				{
					key: 'dataMaxSize',
					name: 'Data max size',
					minWidth: 170
				}
			];
		} else {
			return [];
		}
	}
	const TeradataColumns = [
		'Database name',
		'Used space in GB',
		'Max space in GB',
		'Percentage used',
		'Remaining space in GB'
	];
	const ADLSColumns = [
		'Azure subscription',
		'Resource group',
		'Storage account name',
		'File name',
		'Used Capacity in GB'
	];
	const SQLColumns = [
		'Azure subscription',
		'Resource group',
		'SQL database',
		'Used space in GB',
		'Data space allocated in GB',
		'Data space allocated but unused',
		'Data max size'
	];
	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isTableVisible, setIsTableVisible] = useState(false);
	const tableRef = useRef<HTMLDivElement>(null);
	const [tableDataList, setTableData] = useState<Row[]>([]);
	const [tableHeading, setTableHeading] = useState('');
	const [typeName, setTypeName] = useState('');
	const dimension = useRef('');
	const [customMonth, setCustomMonth] = useState('');
	async function sectorListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		const res = await getSectorSql(pageNumber.toString(), size.toString(), sortingInfo);
		if (res.success) {
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setTableData(res.data.sectorName);
		}
	}
	const handleSorting = (sortingInfo: any) => {
		sectorListingFunc(page, pageSize, sortingInfo);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		sectorListingFunc(value, pageSize, sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		sectorListingFunc(page, event.target.value, sortingInfo);
	};
	const handleClick = (typeName: any) => {
		setDynamicValue(['']);
		setTableData([]);
		setIsTableVisible(true);
		if (typeName === 'Data Stored in Teradata') {
			console.log('default before', defaultColumnNames);
			setDynamicValue(TeradataColumns);
			setTableHeading('Data Stored in Teradata');
			type Row = keyof teradata;
			setTypeName('Data Stored in Teradata');
			dimension.current = 'teradata';
			console.log('default', defaultColumnNames);
		}
		if (typeName === 'Data Stored in ADLS  & Blob') {
			console.log('default before', defaultColumnNames);
			setDynamicValue(ADLSColumns);
			setTableHeading('Data Stored in ADLS  & Blob');
			type Row = keyof ADLS;
			setTypeName('Data Stored in ADLS  & Blob');
			dimension.current = 'storage';
			console.log('default', defaultColumnNames);
		}
		if (typeName === 'Data Stored in Azure SQL') {
			console.log('default before', defaultColumnNames);
			setDynamicValue(SQLColumns);
			setTableHeading('Data Stored in Azure SQL');
			type Row = keyof SQL;
			setTypeName('Data Stored in Azure SQL');
			dimension.current = 'azure_sql';
			console.log('default', defaultColumnNames);
		}
		programListingFunc(page, pageSize, sortingInfo, dimension);
	};

	const clearMetricsSummary = () => {
		setTableData([]);
		setIsTableVisible(false);
	};

	const handleByteChange = (value: string) => {
		selectedByte.current = value;
		SetTeradataSize(formatByByte(value, TeradataSizeRaw.toString()));
		SetAdlsSize(formatByByte(value, AdlsSizeRaw.toString()));
		SetAzureSqlSize(formatByByte(value, AzureSqlSizeRaw.toString()));
		SetAzureSynapse(formatByByte(value, AzureSynapseRaw.toString()));
		SetAzureCosmos(formatByByte(value, AzureCosmosRaw.toString()));
		teradataTrend(teradataRes);
		azureTrend(azureRes);
	};

	const handleDateChange = (selectedDate: string) => {
		clearMetricsSummary();
		getStorageSummaryDetails(sectorName, selectedDate, '');
	};

	const handleSectorChange = (value: string) => {
		setLoading(true);
		setSectorName(value); 
		sectorTagUpdatefun(value);
	};

	const sectorTagUpdatefun = (sector: string) => {
		if(sector != 'all') {
			if(sector == 'Blank')
				sector = '';

			const isInAzure = allSectorOptionsList?.azure.some(item => item.sector === sector);
			const filteredData = {
				azure: isInAzure || false
			};
			setSectorTagList(filteredData);
			getStorageSummaryDetails(filteredData.azure == true ? sector : 'all', filterMonth, 'sector');
		} else {
			const filteredData = {
				azure: true
			};
			setSectorTagList(filteredData);
			getStorageSummaryDetails(sector, filterMonth, 'sector');
		}
	}


	return (
		<Box mt="45px">
			<PageHeader title="Storage Summary" icon={StorageIcon} lastSync={teradataStorageLastSyncDate}>
				<ConversionUnitDropdown handleChange={handleByteChange} />
				{/* <DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={handleDateChange}
				/> */}
				<SectorFilter  
					sectorName={sectorName}
					setSectorName={setSectorName}
					sectorOptionsList={sectorOptionsList}
					handleChange={handleSectorChange} 
				/>
			</PageHeader>
			<Grid container spacing={2} p={2} mt={1}>
				<Grid item container xs={12}>
					<WidgetCardContainer title='Data Stored in'>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6}>
								<NumberWidget
									title="Teradata"
									tooltipLabel=""
									number={formatNumber(TeradataSize)}
									units={conversionUnit}
									isLoading={TeradataSize === '-'}
									handleEvent={!isEnvTypePROD ? () => handleClick('Data Stored in Teradata') : undefined}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<NumberWidget
									title="ADLS & Blob"
									tooltipLabel=""
									number={AdlsSizeNoData == true ? '': formatNumber(AdlsSize)}
									units={conversionUnit}
									isLoading={AdlsSizeNoData == true ? false : AdlsSize === '-'}
									handleEvent={!isEnvTypePROD ? () => handleClick('Data Stored in ADLS  & Blob') : undefined}
									sectorLabel={sectorTagList?.azure == true ? sectorName == 'all' ? '' : sectorName : ''}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<NumberWidget
									title="Azure SQL"
									tooltipLabel=""
									number={AzureSqlSizeNoData == true ? '' : formatNumber(AzureSqlSize)}
									units={conversionUnit}
									isLoading={AzureSqlSizeNoData == true ? false : AzureSqlSize === '-'}
									handleEvent={!isEnvTypePROD ? () => handleClick('Data Stored in Azure SQL') : undefined}
									sectorLabel={sectorTagList?.azure == true ? sectorName == 'all' ? '' : sectorName : ''}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<NumberWidget
									title="Synapse"
									tooltipLabel=""
									number={AzureSynapseNoData == true ? '' : formatNumber(AzureSynapse)}
									units={conversionUnit}
									isLoading={AzureSynapseNoData == true ? false : AzureSynapse === '-'}
									sectorLabel={sectorTagList?.azure == true ? sectorName == 'all' ? '' : sectorName : ''}
								/>
							</Grid>
							<Grid item xs={12} lg={4}>
								<NumberWidget
									title="Cosmos"
									tooltipLabel=""
									number={AzureCosmosNoData == true ? '' : formatNumber(AzureCosmos)}
									units={conversionUnit}
									isLoading={AzureCosmosNoData == true ? false : AzureCosmos === '-'}
									sectorLabel={sectorTagList?.azure == true ? sectorName == 'all' ? '' : sectorName : ''}
								/>
							</Grid>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid item container xs={12}>
					<WidgetCardContainer title='Data Trend'>
						<Grid container spacing={1}>
						<Grid item xs={12} md={6}>
							<ChartWidget
								title="Teradata Trend"
								data={grafanaTeradataOptions}
								isLoading={grafanaTeradataOptions == null}
								conversionUnitProp='byte' 
								lastUpdatedDate={lastUpdatedDateTeradata}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<ChartWidget
								title="Azure Trend"
								data={grafanaAzureOptions}
								isLoading={isLoading}
								conversionUnitProp='byte'
								lastUpdatedDate={lastUpdatedDateAzure}
								sectorLabel={sectorTagList?.azure == true ? sectorName == 'all' ? '' : sectorName : ''}
							/>
						</Grid>
						</Grid>
					</WidgetCardContainer>
				</Grid>
			</Grid>
			{isTableVisible && (
				<>
					<h2 className="panel-legend--title mt-35 ">{tableHeading}</h2>
					<div className=" finop_tabledata detail_sum_tbl" ref={tableRef}>
						<Grid>
							{tableDataList && tableDataList.length > 0 ? (
								<CustomizedDataGrid
									tableColumns={getColumns()}
									tableData={tableDataList}
									tableType="sector"
									defaultColumnNames={defaultColumnNames}
									onSorting={handleSorting}
									currSorting={sortingInfo}
									page={page}
									pageSize={pageSize}
									paginationData={paginationData}
									listLength={tableDataList.length}
									handlepageSizeChange={handlepageSizeChange}
									handlePageChange={handlePageChange}
									isDateRangeFilter={false}
									isDisabled={false}
									isFetching={true}
								/>
							) : tableDataList ? (
								<LoaderComponent />
							) : (
								<span style={{ display: 'flex', justifyContent: 'center' }}>No Records Found</span>
							)}
						</Grid>
					</div>
				</>
			)}
		</Box>
	);
};

export default StorageSummary;
