import { Box, Grid, Tab, Tabs } from '@mui/material';
import SectorFilter from 'components/Dropdown/SectorFilter';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PieInfoWidget from 'components/Widgets/PieInfoWidget';
import { Link } from 'react-router-dom';
import TechnologyFilter from 'components/Dropdown/TechnologyFilter';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import ExecutiveTabs from './ExecutiveTabs';

function ExecutiveView() {

	const [activeTab, setActiveTab] = useState(0);
	// Sample Response Data (Replace with actual API response)
	const responseData = {
		mim: [
			{
				totalCount: 7,
				preventiveCount: 4,
				mimsCount: 3,
				lastMonthCount: 2,
				currentMonthCount: 5
			}
		],
		p3Incidents: [
			{
				totalCount: 42,
				openCount: 18,
				resolvedCount: 24,
				lastMonthCount: 4,
				currentMonthCount: 2
			}
		],
		incidents: [
			{
				totalCount: 1247,
				openCount: 453,
				resolvedCount: 794,
				lastMonthCount: 2,
				currentMonthCount: 5
			}
		],
		changeRequests: [
			{
				totalCount: 384,
				pendingCount: 170,
				implementedCount: 214,
				lastMonthCount: 6,
				currentMonthCount: 2
			}
		],
		serviceRequests: [
			{
				totalCount: 284,
				pendingCount: 120,
				completedCount: 162,
				lastMonthCount: 7,
				currentMonthCount: 5
			}
		],
		availability: [
			{
				totalCount: 99.99,
				lastMonthCount: 3,
				currentMonthCount: 4
			}
		]
	};

	const [sectorName, setSectorName] = useState('all');
	const [sectorList, setSectorList] = useState<any[]>([]);
	const hiddenSector = 'SODASTREAM';
	const filteredSectorList = sectorList.filter((sector) => sector !== hiddenSector);

	// Function to transform response data into widget-friendly format
	const transformData = (data: any) => {
		return Object.keys(data).map((key) => {
			const item = data[key][0]; // Extract first object in the array
			const change = ((item.currentMonthCount - item.lastMonthCount) / item.lastMonthCount) * 100;
			const changeType = item.currentMonthCount > item.lastMonthCount ? 'increase' : 'decrease';

			let widgetTitle = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()); // Convert camelCase to Title

			let chartData: any[] = [];

			// Mapping specific categories to pie chart structure
			if (key === 'mim') {
				chartData = [
					{ name: 'MIMs', value: item.mimsCount },
					{ name: 'Preventive MIMs', value: item.preventiveCount }
				];
			} else if (key === 'p3Incidents' || key === 'incidents') {
				chartData = [
					{ name: 'Open', value: item.openCount },
					{ name: 'Resolved', value: item.resolvedCount }
				];
			} else if (key === 'changeRequests') {
				chartData = [
					{ name: 'Pending', value: item.pendingCount },
					{ name: 'Implemented', value: item.implementedCount }
				];
			} else if (key === 'serviceRequests') {
				chartData = [
					{ name: 'Pending', value: item.pendingCount },
					{ name: 'Completed', value: item.completedCount }
				];
			} else if (key === 'availability') {
				chartData = [{ name: 'Availability %', value: item.totalCount }];
			}

			return {
				title: widgetTitle,
				value: item.totalCount,
				data: chartData,
				change: Math.abs(Number(change.toFixed(2))), // Absolute value, rounded to 2 decimals
				changeType: changeType
			};
		});
	};

	const widgetData = transformData(responseData);

	const handleSectorChange = (value: string) => {
		setSectorName(value);
		localStorage.setItem('selectedSector', value);
		// getProgramData(getSectorId());
		// setProjectList([]);
	};

    const [technology, setTechnology] = useState<string>("all");
    const [optionsList, setOptionsList] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [customMonth, setCustomMonth] = useState('');
    const [filterMonth, setFilterMonth] = useState('1M');
    const handleDateChange = () => {};

  // Simulating API call
  useEffect(() => {
    setLoading(true);
    // Simulated API fetch
    setTimeout(() => {
      setOptionsList(["ADB", "PBI", "Azure", "Teradata"]); // Replace with API response
      setLoading(false);
    }, 1000);
  }, []);

  const handleTechnologyChange = (event: any, autoValue: string) => {
    const { target: { value } } = event;
    const newValue = autoValue ? autoValue : (value === '0' ? '' : value);
    
    setTechnology(newValue);
    let technologyArr = optionsList.filter((tech: any) => tech === newValue);

    // Additional logic can be added based on selected technology
    
    if (technologyArr.length > 0 || newValue === 'all') {
        console.log(`Fetching data for ${newValue}, ${sectorName}`);
    }
};

	// Define the paths for only the clickable widgets
	const widgetPaths: { [key: string]: string } = {
		Availability: '/D&AIXOps/executiveView/Availability',
		Mim: '/D&AIXOps/executiveView/MIMs',
		Incidents: '/D&AIXOps/executiveView/Incidents'
	};
	// Define tabs array before the return statement
	const tabs: { label: string; path: string }[] = [
		{ label: 'MIMs', path: '/D&AIXOps/executiveView' },
		{ label: 'Incidents', path: '/D&AIXOps/executiveView/Incidents' },
		{ label: 'Change Requests', path: '/D&AIXOps/executiveView' },
		{ label: 'Service Requests', path: '/D&AIXOps/executiveView' }
	];


	return (
		<Box bgcolor="blue.darker" mt="80px">
			<ExecutiveTabs />
			{/* Tabs for navigation */}
			{/* <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, ml: 2 }}>
				<Tabs
					value={activeTab}
					onChange={(_, newValue) => setActiveTab(newValue)}
					indicatorColor="primary"
					textColor="inherit"
					sx={{
						'.MuiTab-root': {
							background: 'rgba(0, 55, 113, 1)'
						}
					}}
				>
					{tabs.map((tab, index) => (
						<Tab
							key={index}
							label={tab.label}
							component={Link}
							to={tab.path}
							sx={{
								color: '#FFF',
								textTransform: 'none',
								flexGrow: 1,
								'&.Mui-selected': {
									color: 'rgba(0, 155, 216, 1) !important', 
									backgroundColor: '#1b1e35 !important',
									borderBottom: '3px solid rgba(0, 155, 216, 1) !important'
								}
							}}
						/>
					))}
				</Tabs>
			</Box> */}
			{/* <Grid container spacing={2} marginTop="1rem" px={2}>
				{widgetData.map((widget, index) => {
					const isClickable = widget.title in widgetPaths; // Check if the widget should be clickable
					const path = widgetPaths[widget.title] || '/D&AIXOps/executiveView'; // Get path or default to "#"

					return (
						<Grid item xs={12} md={6} lg={4} key={index}>
							{isClickable ? (
								<Link to={path} style={{ textDecoration: 'none', color: '#FFF', cursor: 'pointer' }}>
									<PieInfoWidget
										title={widget.title}
										value={widget.value}
										data={widget.data}
										change={widget.change}
										changeType={widget.changeType}
									/>
								</Link>
							) : (
								<PieInfoWidget
									title={widget.title}
									value={widget.value}
									data={widget.data}
									change={widget.change}
									changeType={widget.changeType}
								/>
							)}
						</Grid>
					);
				})}
			</Grid> */}
		</Box>
	);
}

export default ExecutiveView;
