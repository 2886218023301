import React from 'react';
import { getDecodedToken, landingPage } from '../service/service';
import { Navigate, useLocation } from 'react-router-dom';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';

function PrivateRoute(props: any) {
	const { Component, restrictedRoles } = props;
	const auth = getDecodedToken();
	const location = useLocation();
	const [controller] = usePepwiseAuthController();
    const { role } = controller;
	
	const roleString = Array.isArray(role) && role.length == 1 ? role[0] : "";

	if (!auth || (restrictedRoles && restrictedRoles.includes(roleString))){
		// Store the current URL in localStorage if not authenticated
		localStorage.setItem('redirectAfterLogin', window.location.href);
		// Redirect to the login page or role-specific landing page
		return <Navigate to={landingPage[role] || '/'} state={{ from: location }} />;
	}

	return <Component />;
}

export default PrivateRoute;
