import { Box, BoxProps, CircularProgress } from '@mui/material';

type LoaderComponentProps = {
	size?: number;
	bgColor?: string;
} & BoxProps;

const LoaderComponent = ({ size = 40, bgColor = 'transparent', ...props }: LoaderComponentProps) => (
	<Box
		{...props}
		sx={{
			display: 'inline-block',
			alignItems: 'center',
			alignContent: 'center',
			textAlign: 'center',
			width: '100%',
			backgroundColor: bgColor,
			...props.sx
		}}
		minHeight={`${size}px`}
	>
		<CircularProgress size={size} color="info" />
	</Box>
);

export default LoaderComponent;
