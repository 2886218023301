import { useEffect, useState } from 'react';
import {
	Box,
	Grid,
	Stack,
} from '@mui/material';
import '../TableauObs/Summary/Summary.scss';
import moment from 'moment';
import { fetchFinOpsExecutiveSummary, getDetailSummarySector } from '../../service/service';
import '../FineOps/finops.scss';
import '../DataEstate/data-estate-dashboard.scss';
import ArrowIconGreen from '../../assets/growth arrow down_green.svg';
import ArrowIconRed from '../../assets/growth arrow up_red.svg';
import { formatNumberWithCommas, getPercentageOfTwoNumbers } from '../../utils/common-util';
import { useNavigate } from 'react-router-dom';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import NumberWidget from 'components/Widgets/NumberWidget';
import ChartWidget from 'components/Widgets/ChartWidget';
import PieWidget from 'components/Widgets/PieWidget';
import ProgressChartWidget from 'components/Widgets/ProgressChart';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import ProgramFilter from 'components/Dropdown/ProgramFilter';
import SectorFilter from 'components/Dropdown/SectorFilter';
import UnderDevelopmentWidget from 'components/Widgets/UnderDevlopmentWidget';

const ExecutiveSummary = () => {
	const [programName, setProgramName] = useState('all');
	const [sectorName, setSectorName] = useState('all');
	const [lastSyncDate, setLastSyncDate] = useState('');
	const [dateInfo, setDateInfo] = useState<any>({ year: null, month: null });

	const [CloudCostYTD, SetCloudCostYTD] = useState('-');
	const [CloudCostMTD, SetCloudCostMTD] = useState('-');
	const [CostChange, SetCostChange] = useState('-');
	const [costGrowth, SetCostGrowth] = useState('-');
	const [chartTitle, setChartTitle] = useState('Top 10 Programs by Cost (YTD) by Environment');
	const [pieTitle, setPieTitle] = useState('Cost % Distribution Across Teams (YTD)');

	const [grafanaCloudCostTrendLoading, setGrafanaCloudCostTrendLoading] = useState(true);
	const [grafanaCloudCostTrendOptions, setGrafanaCloudCostTrendOptions] = useState<any>();
	const [grafanaProgramByCostByEnvironmentLoading, setProgramByCostByEnvironmentLoading] = useState(true);
	const [grafanaDATeamsLoading, setGrafanaDATeamsLoading] = useState(true);
	const [grafanaServicesByCostLoading, setGrafanaServicesByCostLoading] = useState(true);

	const [yoyTrendLoading, setYoYTrendLoading] = useState(true);
	const [yoyTrendOptions, setYoYTrendOptions] = useState<any>();
	const [isNoAnnualYoYTrend, setIsNoAnnualYoYTrend] = useState(false);

	const [cloudCostTrendBubbleValue, SetCloudCostTrendBubbleValue] = useState(0);
	const [cloudCostTrendLastDec, SetCloudCostTrendLastDec] = useState(0);
	const [cloudCostTrendCurrent, SetCloudCostTrendCurrent] = useState(0);
	const [isNoAnnualCloudCostTrend, setIsNoAnnualCloudCostTrend] = useState(false);

	const [programByCostByEnvData, setProgramByCostByEnvData] = useState<any>([]);
	const [programByCostByEnvNames, setProgramByCostByEnvNames] = useState<any>([]);
	const [programByCostByEnvSeriesData, setProgramByCostByEnvSeriesData] = useState<any>([]);
	const [serviceByCostSeriesData, setServiceByCostSeriesData] = useState<any>([]);
	const [DATeamsSeriesData, setDATeamsSeriesData] = useState<any>([]);
    const [searchInputLoading, setSearchInputLoading] = useState<boolean>(false);

	const [ytdUnit, setYtdUnit] = useState('');
	const [mtdUnit, setMtdUnit] = useState('');

	const navigate = useNavigate();

	// sector filter
	const [sectorOptionsList, setSectorOptionsList] = useState<string[]>([]);
	const [programOptionsList, setProgramOptionsList] = useState<string[]>([]);
	
	async function fetchLastSyncData() {
		fetchFinOpsExecutiveSummary('lastSync')
		.then((res) => {
			let lastSyncData = res?.data?.result[0].REFRESHDATE ?? '';
			setLastSyncDate(lastSyncData);
			const refDate = new Date(res?.data?.result[0].refDate);
			const monthName = refDate.toLocaleString("en-US", { month: "long" });
            setDateInfo({
                year: refDate.getFullYear(),
                month: monthName, 
            });
		})
		.catch((error) => {
			console.error(error);
		});
	}

	async function fetchSectorOptionsData(filter: string, searchQuery?: string, filterBy?: any) {
		if (filter === 'Sectors') {
			const sectorRes = await getDetailSummarySector(filter, searchQuery);
			if (sectorRes.success) {
				if (filter === 'Sectors') {
					setSectorOptionsList(
						sectorRes.data.result.Sectors.map((x: any) => {
							return x;
						})
					);
				}
			}
		} else {
			const programRes = await fetchFinOpsExecutiveSummary('program', filterBy);
			if (programRes.success) {
					setProgramOptionsList(
						programRes.data.result.sort().map((x: any) => {
							return x;
						})
					);
				}
		}
	}

	const grafanaLoader = (value: boolean) => {
		setProgramByCostByEnvironmentLoading(value);
		setGrafanaCloudCostTrendLoading(value);
		setGrafanaServicesByCostLoading(value);
		setGrafanaDATeamsLoading(value);
	}

	const handleSectorChange = async (value: string) => {
		grafanaLoader(true);
		setSectorName(value);
		setChartTitle('Top 10 Programs by Cost (YTD) by Environment');
		setPieTitle('Cost % Distribution Across Teams (YTD)');
		let prgName = programName;
		if(programName !== 'all' && value !== 'all') {
			const selectedProgram = {
				Program: [programName],
				Sector: [value]
			};
			prgName = await getExistProgram(selectedProgram);
		}
		getExecutiveDashboardDetails(prgName, value);
	};

	async function getExistProgram(selectedProgram: any): Promise<string> {
		const res = await fetchFinOpsExecutiveSummary('programexist', selectedProgram);
		return res.success && res.data.result > 0 ? programName : 'all';
	}

	const handleNavigateTeamDetails = (e: any) => {
		let programFilter = programName;
		let sectorFilter = sectorName;
		if (programFilter == 'EDF') return;

		let path = '/finops/details?programName=' + programFilter + '&sector=' + sectorFilter 
					+ '&team=' + e.name + '&year=' + dateInfo.year;
		navigate(path);
	};

	const handleNavigateServiceDetails = (e: any) => {
		if(e.name === 'OTHERS') return;
		let programFilter = programName;
		let sectorFilter = sectorName;
		let path = '/finops/details?programName=' + programFilter + '&sector=' + sectorFilter 
					+ '&consumedService=' + e.name + '&year=' + dateInfo.year;
		navigate(path);
	};

	const handleNavigateDetails = (param: any, fullProgramName: string) => {
		const envName = param.seriesName;
		let sectorFilter = sectorName;
		let environment = 'QA';
		if (envName == 'DEV') environment = 'Development';
		else if (envName == 'PROD') environment = 'Production';
		else if (envName == 'PREPROD') environment = 'Pre Production';
		else if (envName == 'SANDBOX') environment = 'Sandbox';
		else if (envName == 'UNKNOWN') environment = 'Unknown';
		let path = '/finops/details?programName=' + fullProgramName + '&environmentName=' 
				+ environment + '&sector=' + sectorFilter + '&year=' + dateInfo.year;
		navigate(path);
	};

	const handleDefault = () => {
		let path = '';
		let programFilter = programName;
		let sectorFilter = sectorName;

		path = '/finops/details?programName=' + programFilter + '&sector=' + sectorFilter + '&year=' + dateInfo.year;
		navigate(path);
	};

	const handleNavigatePrev = () => {
		const yearMonthList = getPreviousMonths(2);
		let path = '';
		let programFilter = programName;
		let sectorFilter = sectorName;
		path = '/finops/details?programName=' + programFilter + '&sector=' + sectorFilter + '&year='+ yearMonthList.years + '&month=' + yearMonthList.months + '&lmcg='+ true;;
		navigate(path);
	};

	const handleNavigateByDate = (isMTD: any) => {
		let path = '';
		let programFilter = programName;
		let sectorFilter = sectorName;
		path = '/finops/details?programName=' + programFilter + '&sector=' + sectorFilter + '&year='+ dateInfo.year;
		if(isMTD)
			path = path + '&month=' + dateInfo.month;

		navigate(path);
	};

	function formatNumber(number: any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return '0.00';
		}
	}

	function formatAbsNumber(number: any) {
		if (number != null && !isNaN(number) && number != 0) {
			return Number(Math.abs(number)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return '0';
		}
	}

	const formatNumberByUnits = (number: number, isYtd : boolean = false) => {
		if (number >= 1000000) {
			if(isYtd) {
				setYtdUnit('MM')
			}
			else {
				setMtdUnit('MM')
			}
			return `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
				number / 1000000
			)}`;
		}
		if (number >= 1000) {
			if(isYtd) {
				setYtdUnit('K')
			}
			else {
				setMtdUnit('K')
			}
			return `${formatNumberWithCommas((number / 1000).toFixed(2), true)}`;
		}
		return new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number);
	};

	const getPreviousMonths = (numMonths: number) => {
		const currentDate = new Date();
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];
		const returnList = {
			months: [] as string[],
			years: [] as number[]
		};
		for (let i = 1; i <= numMonths; i++) {
			let date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
			if (numMonths == 1) date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
			const month = date.getMonth();
			const year = date.getFullYear();
			returnList.months.push(monthNames[month]);
			if (!returnList.years.includes(year)) 
				returnList.years.push(year);
		}
		return returnList;
	};

	async function getExecutiveDashboardDetails(prgName: any, sector: any) {
		SetCloudCostYTD('-');
		SetCloudCostMTD('-');
		SetCostChange('-');
		SetCostGrowth('-');
		setYtdUnit('');
		setMtdUnit('');
		setSearchInputLoading(true);
	
		const selectedProgram = {
			Program: [prgName],
			Sector: [sector]
		};
	
		try {
			//Fetch all required data concurrently
			const [programRes, ytdRes, mtdRes, momRes, lmcgRes, trendRes, programCostRes, serviceCostRes, teamCostRes, yoyTrendRes] =
				await Promise.all([
					fetchFinOpsExecutiveSummary('program', selectedProgram),
					fetchFinOpsExecutiveSummary('ytd', selectedProgram),
					fetchFinOpsExecutiveSummary('mtd', selectedProgram),
					fetchFinOpsExecutiveSummary('mom', selectedProgram),
					fetchFinOpsExecutiveSummary('lmcg', selectedProgram),
					fetchFinOpsExecutiveSummary('trend', selectedProgram),
					fetchFinOpsExecutiveSummary('programcost', selectedProgram),
					fetchFinOpsExecutiveSummary('servicecost', selectedProgram),
					prgName !== 'EDF' ? fetchFinOpsExecutiveSummary('teamcost', selectedProgram) : Promise.resolve(null),
					fetchFinOpsExecutiveSummary('yoyTrend', selectedProgram),
				]);
	
			// Handle 'program' data
			const programOptions = programRes.data.result.sort();
			setProgramOptionsList(programOptions);
			const programExists = programOptions.includes(prgName);
			if (!programExists) {
				selectedProgram.Program = ['all'];
				setProgramName('all');
			}
	
			// Handle 'ytd' data
			const ytdCount = ytdRes?.data?.result[0]?.KPI_VALUE ?? 0;
			SetCloudCostYTD(formatNumberByUnits(ytdCount, true));
	
			// Handle 'mtd' data
			const mtdCount = mtdRes?.data?.result[0]?.KPI_VALUE ?? 0;
			SetCloudCostMTD(formatNumberByUnits(mtdCount));
	
			// Handle 'mom' data
			const momCount = momRes?.data?.result[0]?.KPI_VALUE ?? 0;
			SetCostGrowth(momCount.toString());
	
			// Handle 'lmcg' data
			const lmcgCount = lmcgRes?.data?.result[0]?.KPI_VALUE ?? 0;
			SetCostChange(lmcgCount.toString());
	
			// Handle 'trend' data
			if (trendRes?.data?.result) {
				// const currentIndexValue = moment().format('YYYY-MM');
				// trendRes.data.result = Object.fromEntries(
				// 	Object.entries(trendRes.data.result).filter(([month, _]) => month <= currentIndexValue)
				// );

				let allDataAsArray = Object.entries(trendRes.data.result);
				const allMonths = Object.keys(trendRes.data.result);

				const firstMonthFromAllMonths = allMonths[0];
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				let currentMonth = new Date().getMonth();
				if (currentMonth == 0) currentMonth = 12;
				const currentYear = new Date().getFullYear();
				const lastMonthString = currentMonth.toString().padStart(2, '0');
				const filterMonth = `${currentYear - 1}-${lastMonthString}`;
				const filterDateEntry = Object.entries(trendRes.data.result).filter((x) => x[0] === filterMonth);
				if (filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(trendRes.data.result).filter((x) => x[0] === lastMonthDataIndex);
					if (lastMonthEntry.length) {
						setIsNoAnnualCloudCostTrend(false);
						const lastmonthvalue = lastMonthEntry[0][1] as number;
						SetCloudCostTrendCurrent(lastmonthvalue as number);
						SetCloudCostTrendLastDec(count);
						SetCloudCostTrendBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
					}
				}
				setGrafanaCloudCostTrendOptions(trendRes.data.result);
				setGrafanaCloudCostTrendLoading(false);
			}
	
			// Handle 'trend' data
			if (yoyTrendRes?.data?.result) {
				let allDataAsArray = Object.entries(yoyTrendRes.data.result);
				const allMonths = Object.keys(yoyTrendRes.data.result);

				const firstMonthFromAllMonths = allMonths[0];
				const lastMonth = moment().subtract(1, 'months').format('MM');
				const indexValue = moment().year() + '-' + lastMonth;
				const lastIndex = allMonths.indexOf(indexValue);
				const lastMonthFromAllMonths = allMonths[lastIndex];
				const lastMonthFromForecast = allMonths[allMonths.length - 1];

				let currentMonth = new Date().getMonth();
				if (currentMonth == 0) currentMonth = 12;
				const currentYear = new Date().getFullYear();
				const lastMonthString = currentMonth.toString().padStart(2, '0');
				const filterMonth = `${currentYear - 1}-${lastMonthString}`;
				const filterDateEntry = Object.entries(yoyTrendRes.data.result).filter((x) => x[0] === filterMonth);
				if (filterDateEntry.length) {
					let count: any = 0;
					count = filterDateEntry[0][1];
					const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
					const lastMonthEntry = Object.entries(yoyTrendRes.data.result).filter((x) => x[0] === lastMonthDataIndex);
					if (lastMonthEntry.length) {
						setIsNoAnnualYoYTrend(false);
					}
				}
				setYoYTrendOptions(yoyTrendRes.data.result);
				setYoYTrendLoading(false);
			}
	

			// Handle 'programcost' data
			const programCostData = programCostRes?.data?.result || [];
			// Extract unique environment names from the data
			const envNames = programCostData.length > 0
				? Object.keys(programCostData[0]).filter((key) => key !== 'programname' && key !== 'total_cost')
				: [];

			const maxTotalCost = Math.max(...programCostData.map((item: { total_cost: any; }) => item.total_cost));
			const localMaxTotalCost = maxTotalCost + (maxTotalCost * 0.15);

			// Create a series for the environment data
			const envSeriesData = envNames.map((env, index) => ({
				name: env.toUpperCase(),
				type: 'bar',
				stack: 'total',
				showBackground: true,
				backgroundStyle: {
					color: '#174377'
				},
				label: {
					show: index === envNames.length - 1,
					position: 'right',
					color: '#F5F5F5',
					clip: false,
					formatter: (params: any) => {
						const costIdx = programCostData.length - 1 - params.dataIndex;
						const totalCost = programCostData[costIdx].total_cost;
						let formattedValue;
						if (totalCost >= 1000000) {
							formattedValue = `${formatNumber(totalCost / 1000000)}MM`;
						} else {
							formattedValue = `${formatNumber(totalCost / 1000)}K`;
						}
						return `$${formattedValue}`;
					}
				},
				data: programCostData.map((item: any) => item[env]).reverse()
			}));

			const totalCostSeries = {
				name: 'TOTAL_COST', // Use a recognizable name
				type: 'bar',
				stack: 'total',
				label: {
					show: false,
					position: 'right',
					color: '#F5F5F5',
					formatter: (params: any) => {
						const costIdx = programCostData.length - 1 - params.dataIndex;
						const totalCost = programCostData[costIdx].total_cost;
						let formattedValue;
						if (totalCost >= 1000000) {
							formattedValue = `${formatNumber(totalCost / 1000000)}MM`;
						} else {
							formattedValue = `${formatNumber(totalCost / 1000)}K`;
						}
						return `$${formattedValue}`;
					}
				},
				// Make the bar invisible but clickable
				itemStyle: {
					color: 'transparent' // Invisible bar
				},
				emphasis: {
					itemStyle: {
						color: 'transparent'
					}
				},
				data: [
					...programCostData.map((item: any) => item.total_cost),
					localMaxTotalCost,
				  ],
			};
			
			setProgramByCostByEnvNames(envNames);
			setProgramByCostByEnvData(programCostData);
			setProgramByCostByEnvSeriesData(programCostData.length > 0 ? [... envSeriesData, totalCostSeries] : []);
			setProgramByCostByEnvironmentLoading(false);
	
			// Handle 'servicecost' data
			const serviceData = serviceCostRes?.data?.result || [];
			let seriesData = serviceData.map((item: any) => ({
				name: item.consumedservice,
				value: item.total_cost,
				formattedName: `${item.percentage || 0}%`,
			}));
			const others = seriesData.filter((item: { name: string }) => item.name === 'Others');
			seriesData = seriesData.filter((item: { name: string }) => item.name !== 'Others');
			seriesData = seriesData.concat(others);

			setServiceByCostSeriesData(seriesData);
			setGrafanaServicesByCostLoading(false);
	
			// Handle 'teamcost' data (if applicable)
			if (teamCostRes) {
				const teamData = teamCostRes?.data?.result || [];
				const formattedTeamData = teamData.map((item: any) => ({
					name: item.team,
					value: item.total_cost,
					formattedName: `${item.percentage || 0}%`,
				}))
				.sort((a: any, b: any) => a.name.localeCompare(b.name));
				setDATeamsSeriesData(formattedTeamData);
				setGrafanaDATeamsLoading(false);
			}
		} catch (error) {
			console.error('Error fetching executive dashboard details:', error);
		} finally {
			setSearchInputLoading(false);
		}
	}
	

	// async function getExecutiveDashboardDetails(prgName: any, sector: any) {
	// 	SetCloudCostYTD('-');
	// 	SetCloudCostMTD('-');
	// 	SetCostChange('-');
	// 	SetCostGrowth('-');
	// 	setYtdUnit('');
	// 	setMtdUnit('');

	// 	let selectedProgram = {
	// 		Program: [prgName],
	// 		Sector: [sector]
	// 	};

	// 	fetchFinOpsExecutiveSummary('program', selectedProgram)
	// 		.then((res) => {
	// 			setSearchInputLoading(false);
	// 			setProgramOptionsList(
	// 				res.data.result.sort().map((x: any) => {
	// 					return x;
	// 				})
	// 			);
	// 			const exists = res.data.result.filter((program: any) => program == prgName);
	// 			if (exists.length <= 0) {
	// 				selectedProgram = {
	// 					...selectedProgram,
	// 					Program: ['all']
	// 				};
	// 				setProgramName('all');
	// 			}

	// 		fetchFinOpsExecutiveSummary('ytd', selectedProgram)
	// 			.then((res) => {
	// 				let count = res?.data?.result[0]?.KPI_VALUE ?? 0;
	// 				SetCloudCostYTD(formatNumberByUnits(count, true));
	// 			})
	// 			.catch((error) => {
	// 				console.error(error);
	// 			});
	// 		fetchFinOpsExecutiveSummary('mtd', selectedProgram)
	// 			.then((res) => {
	// 				let count = res?.data?.result[0]?.KPI_VALUE ?? 0;
	// 				SetCloudCostMTD(formatNumberByUnits(count));
	// 			})
	// 			.catch((error) => {
	// 				console.error(error);
	// 			});
	// 		fetchFinOpsExecutiveSummary('mom', selectedProgram)
	// 			.then((res) => {
	// 				SetCostGrowth('');
	// 				if (res.data.result) {
	// 					let countCG = res?.data?.result[0]?.KPI_VALUE ?? 0;
	// 					SetCostGrowth(countCG.toString());
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				console.error(error);
	// 			});
	// 		fetchFinOpsExecutiveSummary('lmcg', selectedProgram)
	// 			.then((res) => {
	// 				SetCostChange('');
	// 				if (res.data.result) {
	// 					let count = res?.data?.result[0]?.KPI_VALUE ?? 0;
	// 					SetCostChange(count.toString());
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				console.error(error);
	// 			});

	// 		fetchFinOpsExecutiveSummary('trend', selectedProgram)
	// 			.then((res) => {
	// 				if (res.data) {
	// 					// const currentIndexValue = moment().format('YYYY-MM');
	// 					// res.data.result = Object.fromEntries(
	// 					// 	Object.entries(res.data.result).filter(([month, _]) => month <= currentIndexValue)
	// 					// );

	// 					let allDataAsArray = Object.entries(res.data.result);
	// 					const allMonths = Object.keys(res.data.result);

	// 					const firstMonthFromAllMonths = allMonths[0];
	// 					const lastMonth = moment().subtract(1, 'months').format('MM');
	// 					const indexValue = moment().year() + '-' + lastMonth;
	// 					const lastIndex = allMonths.indexOf(indexValue);
	// 					const lastMonthFromAllMonths = allMonths[lastIndex];
	// 					const lastMonthFromForecast = allMonths[allMonths.length - 1];

	// 					let currentMonth = new Date().getMonth();
	// 					if (currentMonth == 0) currentMonth = 12;
	// 					const currentYear = new Date().getFullYear();
	// 					const lastMonthString = currentMonth.toString().padStart(2, '0');
	// 					const filterMonth = `${currentYear - 1}-${lastMonthString}`;
	// 					const filterDateEntry = Object.entries(res.data.result).filter((x) => x[0] === filterMonth);
	// 					if (filterDateEntry.length) {
	// 						let count: any = 0;
	// 						count = filterDateEntry[0][1];
	// 						const lastMonthDataIndex = `${currentYear}-${lastMonthString}`;
	// 						const lastMonthEntry = Object.entries(res.data.result).filter((x) => x[0] === lastMonthDataIndex);
	// 						if (lastMonthEntry.length) {
	// 							setIsNoAnnualCloudCostTrend(false);
	// 							const lastmonthvalue = lastMonthEntry[0][1] as number;
	// 							SetCloudCostTrendCurrent(lastmonthvalue as number);
	// 							SetCloudCostTrendLastDec(count);
	// 							SetCloudCostTrendBubbleValue(getPercentageOfTwoNumbers(count, lastmonthvalue));
	// 						}
	// 					}
	// 					setGrafanaCloudCostTrendOptions(res.data.result);
	// 					setGrafanaCloudCostTrendLoading(false);
	// 				}
	// 			})
	// 			.catch((error) => {
	// 				console.error(error);
	// 			});

	// 		fetchFinOpsExecutiveSummary('programcost', selectedProgram)
	// 			.then((res) => {
	// 				let data = res.data.result;

	// 				// Extract unique environment names from the data
	// 				const envNames = data.length > 0 ? Object.keys(data[0])?.filter((key) => key !== 'programname' && key !== 'total_cost') : [];

	// 				// Create a series for the environment data
	// 				const envSeriesData = envNames.map((env, index) => ({
	// 					name: env.toUpperCase(),
	// 					type: 'bar',
	// 					stack: 'total',
	// 					showBackground: true,
	// 					backgroundStyle: {
	// 						color: '#174377'
	// 					},
	// 					label: {
	// 						show: index === envNames.length - 1,
	// 						position: 'right',
	// 						color: '#F5F5F5',
	// 						clip: false,
	// 						formatter: (params: any) => {
	// 							const costIdx = data.length - 1 - params.dataIndex;
	// 							const totalCost = data[costIdx].total_cost;
	// 							let formattedValue;
	// 							if (totalCost >= 1000000) {
	// 								formattedValue = `${formatNumber(totalCost / 1000000)}MM`;
	// 							} else {
	// 								formattedValue = `${formatNumber(totalCost / 1000)}K`;
	// 							}
	// 							return `$${formattedValue}`;
	// 						}
	// 					},
	// 					data: data.map((item: any) => item[env]).reverse()
	// 				}));

	// 				const totalCostSeries = {
	// 					name: 'TOTAL_COST', // Use a recognizable name
	// 					type: 'bar',
	// 					stack: 'total',
	// 					label: {
	// 						show: true,
	// 						position: 'right',
	// 						color: '#F5F5F5',
	// 						formatter: (params: any) => {
	// 							const costIdx = data.length - 1 - params.dataIndex;
	// 							const totalCost = data[costIdx].total_cost;
	// 							let formattedValue;
	// 							if (totalCost >= 1000000) {
	// 								formattedValue = `${formatNumber(totalCost / 1000000)}MM`;
	// 							} else {
	// 								formattedValue = `${formatNumber(totalCost / 1000)}K`;
	// 							}
	// 							return `$${formattedValue}`;
	// 						}
	// 					},
	// 					// Make the bar invisible but clickable
	// 					itemStyle: {
	// 						color: 'transparent' // Invisible bar
	// 					},
	// 					emphasis: {
	// 						itemStyle: {
	// 							color: 'transparent'
	// 						}
	// 					},
	// 					data: data.map((item: any) => 0) // No actual bar, just a label
	// 				};
					
	// 				setProgramByCostByEnvNames(envNames);
	// 				setProgramByCostByEnvData(data);
	// 				setProgramByCostByEnvSeriesData(data.length > 0 ? [... envSeriesData, totalCostSeries] : []);
	// 				setProgramByCostByEnvironmentLoading(false);
	// 			})
	// 			.catch((error) => {
	// 				console.error(error);
	// 			});

	// 		fetchFinOpsExecutiveSummary('servicecost', selectedProgram)
	// 			.then((res) => {
	// 				let data = res.data.result;
	// 				// Transform data into ECharts series format
	// 				let seriesData = data.map((item: any) => ({
	// 					name: item.consumedservice,
	// 					value: item.total_cost,
	// 					formattedName: `${item.percentage ? item.percentage : 0}%`
	// 				}));
	// 				const others = seriesData.filter((item: { name: string }) => item.name === 'Others');
	// 				seriesData = seriesData.filter((item: { name: string }) => item.name !== 'Others');
	// 				seriesData = seriesData.concat(others);

	// 				setServiceByCostSeriesData(seriesData);
	// 				setGrafanaServicesByCostLoading(false);
	// 			})
	// 			.catch((error) => {
	// 				console.error(error);
	// 			});

	// 		prgName !== 'EDF' && fetchFinOpsExecutiveSummary('teamcost', selectedProgram)
	// 			.then((res) => {
	// 				let data = res.data.result;
	// 				// Transform data into ECharts series format
	// 				let seriesData = data
	// 					.map((item: any) => ({
	// 						name: item.team,
	// 						value: item.total_cost,
	// 						formattedName: `${item.percentage ? item.percentage : 0}%`
	// 					}))
	// 					.sort((a: any, b: any) => a.name.localeCompare(b.name));
						
	// 				setDATeamsSeriesData(seriesData);
	// 				setGrafanaDATeamsLoading(false);
	// 			})
	// 			.catch((error) => {
	// 				console.error(error);
	// 			});
	// 	})
	// 	.catch((error) => {
	// 		console.error(error);
	// 	});
	// }

	useEffect(() => {
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/finops/executive-dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
				document.body.classList.add('program-arrow');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				// document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}

		fetchLastSyncData();
		fetchSectorOptionsData('Sectors');
		getExecutiveDashboardDetails(programName, 'all');
	}, []);

	const handleProgramChange = (event: any, autoValue: string) => {
		const { target: { value } } = event;
		const newValue = autoValue ? autoValue : (value == '0' ? '' : value);
		grafanaLoader(true);
		setProgramName(newValue);
		let programNameArr = programOptionsList.filter((program: any) => program == newValue)
		
		// Set the title based on the selected program(s)		
		if (newValue === 'all') {
			setChartTitle('Top 10 Programs by Cost (YTD) by Environment');
		} else {
			setChartTitle('Cost by Environment');
		}

		setPieTitle(newValue === 'EDF' 
			? 'Cost % Distribution Across Sectors (YTD)' 
			: 'Cost % Distribution Across Teams (YTD)'
		);

		if((programNameArr.length > 0 || newValue == 'all')) {
			getExecutiveDashboardDetails(newValue, sectorName);
		}
	}

	return (
		<Box mt="80px" bgcolor='blue.darker'>	
			<PageHeader title={[
					{
						text: 'Platform Cost Summary',
						link: '/D&AIXOps/platformCostSummary'
					},
					{
						text: 'Azure Cost'
					}
				]} icon={DashboardIcon} lastSync={lastSyncDate} isDataRefresh = {true}
			>
				<Stack direction="row" gap={1} alignItems="center">
					<ProgramFilter 
						handleChange={handleProgramChange} 
						programName={programName}
						setProgramName={setProgramName}
						programOptionsList={programOptionsList}
						searchInputLoading = {searchInputLoading} />
					<SectorFilter 
						sectorName={sectorName}
						setSectorName={setSectorName}
						sectorOptionsList={sectorOptionsList}
						handleChange={handleSectorChange} />
				</Stack>
			</PageHeader>
			<WidgetCardContainer title="Cost Summary">
				<Grid container spacing={1} m={0}>
					<Grid item container spacing={1} xs={12} md={5}>
						<Grid item xs={12} lg={6}>
							<NumberWidget
								title="Azure Cloud Cost (YTD)"
								number={'$' + CloudCostYTD}
								isLoading={CloudCostYTD === '-'}
								tooltipLabel={''}
								hasTooltip={false}
								units={ytdUnit}
								handleEvent={() => handleNavigateByDate(false)}
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<NumberWidget
								title="Azure Cloud Cost (MTD)"
								number={'$' + CloudCostMTD}
								isLoading={CloudCostMTD === '-'}
								tooltipLabel={''}
								hasTooltip={false}
								units={mtdUnit}
								handleEvent={() => handleNavigateByDate(true)}
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<NumberWidget
								title="Average Cost Growth (MOM)"
								number={formatAbsNumber(costGrowth)}
								isLoading={costGrowth === '-'}
								tooltipLabel={''}
								hasTooltip={false}
								units="%"
								unitIcon={
									costGrowth === null || costGrowth === undefined
										? null
										: parseFloat(costGrowth) > 0.0
										? ArrowIconRed
										: parseFloat(costGrowth) < 0.0
										? ArrowIconGreen
										: null
								}
								color={
									parseFloat(costGrowth) !== 0.0
										? parseFloat(costGrowth) < 0.0
										? 'success.main'
										: 'error.main'
										: 'white'
									}
								// unitIcon={
								// 	!costGrowth && parseFloat(costGrowth) != 0.0
								// 		? parseFloat(costGrowth) > 0.0
								// 			? ArrowIcon
								// 			: ArrowIconRed
								// 		: null
								// }
								// // unitIcon={costGrowth && parseFloat(costGrowth) != 0 ? (parseFloat(costGrowth) > 0 ? ArrowIcon : ArrowIconRed) : null}
								//color={parseFloat(costGrowth) != 0.0 ? (parseFloat(costGrowth) < 0.0 ? 'error.main' : 'success.main'): undefined}
								// handleEvent={() => handleSetComponent('MAJOR_INCIDENTS_LAST_MONTH', {
								handleEvent={() => handleNavigateByDate(false)}
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<NumberWidget
								title="Cost Change Compared to Last Month"
								number={formatAbsNumber(CostChange)}
								isLoading={CostChange === '-'}
								tooltipLabel={''}
								hasTooltip={false}
								units="%"
								unitIcon={
									CostChange === null || CostChange === undefined
										? null
										: parseFloat(CostChange) > 0.0
										? ArrowIconRed
										: parseFloat(CostChange) < 0.0
										? ArrowIconGreen
										: null
								}
								color={
									parseFloat(CostChange) !== 0.0
										? parseFloat(CostChange) < 0.0
										? 'success.main'
										: 'error.main'
										: 'white'
									}
								//unitIcon={CostChange ? (parseFloat(CostChange) > 0 ? ArrowIcon : ArrowIconRed) : null}
								//color={parseFloat(CostChange) < 0 ? 'error.main' : 'success.main'}
								handleEvent={handleNavigatePrev} // handleEvent={() => handleSetComponent('MAJOR_INCIDENTS_LAST_MONTH', {
							/>
						</Grid>
					</Grid>
					{/* right side */}
					<Grid item container spacing={1} xs={12} md={7}>
						<Grid item xs={12} lg={7}>
							<ChartWidget
								title="Azure Cloud Cost Trend (MOM)"
								minTitleHeight="40px"
								data={grafanaCloudCostTrendOptions}
								hasBadge={!grafanaCloudCostTrendLoading && !isNoAnnualCloudCostTrend ? true : false}
								isLoading={grafanaCloudCostTrendLoading}
								handleEvent={handleDefault}
								conversionUnitProp="cost"
								isFinops
							/>
						</Grid>
						<Grid item xs={12} lg={5}>
							<ChartWidget
								title="Azure Cloud Cost Trend (YOY)"
								minTitleHeight="40px"
								data={yoyTrendOptions}
								hasBadge={!yoyTrendLoading && !isNoAnnualYoYTrend ? true : false}
								isLoading={yoyTrendLoading}
								handleEvent={handleDefault}
								conversionUnitProp="cost"
								isYearly
								isFinops
							/>
						</Grid>
					</Grid>
				</Grid>
			</WidgetCardContainer>
			{/* second part */}
			<Grid item container mt={2} xs={12}>
				<WidgetCardContainer title="Cost Breakdown">
					<Grid container spacing={1}>
						<Grid item xs={12} md={4}>
							<ProgressChartWidget
								title={chartTitle}
								data={programByCostByEnvData}
								envNames={programByCostByEnvNames}
								seriesData={programByCostByEnvSeriesData}
								isLoading={grafanaProgramByCostByEnvironmentLoading}
								handleEvent={handleNavigateDetails}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<PieWidget
								title="Top Services by Cost (YTD)"
								isLoading={grafanaServicesByCostLoading}
								seriesData={serviceByCostSeriesData}
								handleEvent={handleNavigateServiceDetails}
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							{programName === 'EDF' ? 
							<UnderDevelopmentWidget title="Cost % Distribution Across Sectors (YTD)" />
							:<PieWidget
								title={pieTitle}
								isLoading={grafanaDATeamsLoading}
								seriesData={DATeamsSeriesData}
								handleEvent={handleNavigateTeamDetails}
							/>}
						</Grid>
					</Grid>
				</WidgetCardContainer>
			</Grid>			
		</Box>
	);
};

export default ExecutiveSummary;
