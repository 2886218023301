import {
	Grid,
	FormControl,
	TextField,
	Typography,
	Box,
	Button,
	Radio,
	RadioGroup,
	FormControlLabel,
	Autocomplete,
	IconButton,
	Switch
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the Delete icon
import AddIcon from '@mui/icons-material/Add'; // Import the Add icon

interface Field {
	id: number; // Unique identifier for each field
	field1: string; // Assuming two fields
	field2: string;
}

const GroupingAlertTab = ({groupingAlertsConfig = {}, setGroupingAlertsConfig}: any) => {
	const timeframeOptions = ['Minutes', 'Hours', 'Days', 'Weeks', 'Months']
	
	const handleChange = ({ name, value }: any) => {
		setGroupingAlertsConfig({...groupingAlertsConfig, [name]: value})
	};

	const handleFieldChange = (index: number, value: string) => {
		const identifiers = groupingAlertsConfig.identifiers.map((field: string, idx: number) => {
			if(idx === index){
				field = value
			}
			return field
		})
		setGroupingAlertsConfig({...groupingAlertsConfig, identifiers})
	};

	const addField = () => {
		const identifiers = groupingAlertsConfig.identifiers ? [...groupingAlertsConfig.identifiers, ''] : ['']
		setGroupingAlertsConfig({...groupingAlertsConfig, identifiers})
	};

	const removeField = (field: string) => {
		setGroupingAlertsConfig({...groupingAlertsConfig, identifiers: groupingAlertsConfig.identifiers.filter((f:string) => f !== field)})
	};

	return (
		<Box sx={{ mt: 3, padding: '20px', borderRadius: '8px' }}>
			<Typography variant="body2" sx={{ opacity: 0.7, mb: 2 }}>
				ⓘ This section is to configure grouped alerts.
			</Typography>
			
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 2 }}>
						<Typography variant="body1" >
						{!groupingAlertsConfig.isEnabled ? 'The grouping of alerts is currently disabled. Do you want to enable?' : 
							'The grouping of alerts is currently enabled. Do you want to disable?'}
						</Typography>
						<Switch sx={{ml: 1}} onChange={(e) => handleChange({ name: 'isEnabled', value: e.target.checked })} checked={groupingAlertsConfig.isEnabled} size="small"
						color={!groupingAlertsConfig.isEnabled ? 'warning' : 'info'} />
					</Box>
				</Grid>
				<Grid item xs={3}>
					<TextField
						label="Group Name*"
						variant="outlined"
						size="small"
						value={groupingAlertsConfig.name}
						onChange={(e) => handleChange({ name: 'name', value: e.target.value })}
						sx={{
							width: 300,
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#A9ADCB'
								},
								'&:hover fieldset': {
									borderColor: '#A9ADCB'
								},
								'&.Mui-focused fieldset': {
									borderColor: '#A9ADCB'
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						label="Duration*"
						variant="outlined"
						size="small"
						type={'number'}
						value={groupingAlertsConfig.timeframeValue}
						onChange={(e) => handleChange({ name: 'timeframeValue', value: e.target.value })}
						sx={{
							width: 300,
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#A9ADCB'
								},
								'&:hover fieldset': {
									borderColor: '#A9ADCB'
								},
								'&.Mui-focused fieldset': {
									borderColor: '#A9ADCB'
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<Autocomplete
						id="size-small-outlined"
						size="small"
						options={timeframeOptions}
						value={groupingAlertsConfig.timeframeUnit}
						onChange={(e, option) => handleChange({ name: 'timeframeUnit', value: option })}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Select unit*"
								variant="outlined"
								sx={{
									width: 300,
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: '#A9ADCB'
										},
										'&:hover fieldset': {
											borderColor: '#A9ADCB'
										},
										'&.Mui-focused fieldset': {
											borderColor: '#A9ADCB'
										}
									}
								}}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={3}></Grid>
			</Grid>

			<Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
				Dynamic Fields*
			</Typography>
			<Grid container spacing={2} alignItems="center" sx={{ mb: 1, flexWrap: 'wrap' }}>
				{groupingAlertsConfig.identifiers?.map((field: string, index: number) => (
				<>
					<Grid item xs={4} key={index}>
						<TextField
							label={`Select Field ${index + 1}`}
							variant="outlined"
							size="small"
							value={field}
							onChange={(e) => handleFieldChange(index, e.target.value)}
							sx={{
								width: '100%',
								'& .MuiOutlinedInput-root': {
									'& fieldset': { borderColor: '#A9ADCB' },
									'&:hover fieldset': { borderColor: '#A9ADCB' },
									'&.Mui-focused fieldset': { borderColor: '#A9ADCB' }
								}
							}} />
					</Grid>
					<Grid item xs={1}>
						<IconButton onClick={() => removeField(field)} color="error" aria-label="remove field">
							<DeleteIcon />
						</IconButton>
					</Grid>
				</>
				))}

				{/* Add Field Button in the Same Row */}
				<Grid item xs={12}>
					<Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={addField} sx={{ mt: 2 }}>
						Add Field
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};
export default GroupingAlertTab;
