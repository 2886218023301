import React, { useCallback } from 'react';
import {
	Box,
	Typography
} from '@mui/material';
import { CustomizedDataGrid } from '../customized_data_grid';
import moment from 'moment';
import { exportToXlsx } from 'components/exportUtils';

export const AlertDetailView = ({ alertList,
    handleSorting,
    sortingInfo,
    handleFilter,
    filterInfo, 
    handleSearch,
    searchDetails,
    handleEditCell,
	setPage,
	pageSize,
    handlepageSizeChange,
	totalCount,
	page,
	filterData,
	fetchAlertList,
	handlePageChange,
	loading }: any) => {

    const defaultColumns = [ 'Alert Type', 'Email', 'SNOW', 'Teams', 'Silenced Channels', 'Grouped', 'Generated At', 'ID'];
    
	const handleSortingFun = useCallback((sortingInfo: any) => {
		if(sortingInfo.sortBy === 'createdDt'){
			handleSorting({ sortBy: sortingInfo.sortBy, sortDir: sortingInfo.sortDir });
		}
	}, [fetchAlertList]);

    function getColumns() {
        return [
            { key: "alertType", name: "Alert Type", width: 200, minWidth: 150 },
            { key: "email", name: "Email", width: 200, minWidth: 150 },
            { key: "snow", name: "SNOW", minWidth: 95, formatter({row}:any){
				if(!row.serviceNowTime) return 'NO'
				const number = row.incidentNumber || row.alertNumber || null
				if(!number) return 'YES'
				const link = row.incidentLink || row.alertLink || null
				return (
					<Typography
						sx={{
							color: "blue",
							cursor: "pointer",
							textDecoration: "underline",
						}}						
					>
						{link ? <a href={link} target='_blank'>{number}</a> : number}
					</Typography>
				)
			} },
            { key: "teams", name: "Teams", minWidth: 95 },
            { key: "silenced", name: "Silenced Channels", minWidth: 120 },
            { key: "grouped", name: "Grouped", minWidth: 95 },
			{ key: "createdDt", name: "Generated At", minWidth: 120},
			{ key: "_id", name: "ID", width: 200, minWidth: 150 },
        ];
    }
    
    return (
		<Box sx={{ mt: 3, padding: '20px', borderRadius: '8px' }}>
			<Typography variant="body2" sx={{ opacity: 0.7 }}>
				All Alerts
			</Typography>
			<CustomizedDataGrid
				tableColumns={getColumns()}
				tableData={alertList || []}
				tableType="AlertDetail"
				defaultColumnNames={defaultColumns}
				filterDataOptions={filterData}
				page={page}
				pageSize={pageSize}
				onSorting={handleSortingFun}
				currSorting={sortingInfo}
				onFilterCheck={handleFilter}
				currFilter={filterInfo}
				showExport={true}
				fileName={`GeneratedAlerts-${moment().format()}.xlsx`}
				exportTableData={() => exportToXlsx(`AlertDetails-${moment().format()}.xlsx`, alertList, getColumns())}
				searchDataValues={searchDetails}
				onFilterSearch={handleSearch}
				onCellEdit={handleEditCell}
				handlepageSizeChange={handlepageSizeChange}
				handlePageChange={handlePageChange}
				isDateRangeFilter={true}
				isDisabled={false}
				listLength={totalCount || 0}
				paginationData={{totalElements: totalCount, totalPages: Math.ceil(totalCount / pageSize)}}
				isFetching={loading}
			/>
		</Box>
	);
};

