import React, { useEffect, useState } from "react";
import { Box, Typography, Chip, Grid, Tooltip, Button, TextField } from "@mui/material";
import { ReactComponent as ContactIcon } from '../../assets/contact-icon.svg';
import PhoneIcon from "@mui/icons-material/Phone";
import { getExecutiveStatusData, getProgramById } from "service/service";
import { ReactComponent as InfoIcon } from '../../assets/dp-info.svg';
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import moment from "moment";
import { ReactComponent as CommingSoonIcon } from '../../assets/coming-soon-dp.svg';
import Loader from "components/Loader/loader";

const ProgramDetailsCard = ({programId, contactDetails, statusCount} : any) => {
    const [statusData, setStatusData] = useState<any>([]);
    const [programName, setProgramName] = useState<any>('Loading...');
    
    const [loader, setLoader] = useState<any>(true)
    const [startDate, setStartDate] = useState(moment().tz("America/Chicago").startOf('day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().tz("America/Chicago").endOf('day').format('YYYY-MM-DD'));

    const getExeStatusData = async (programId: number, startDate: string) => {
        let response = await getExecutiveStatusData(programId, startDate);
        if (response.success) {
            const MdtData: any = [{
                Project_Name: "MDT",
                last7days: ['-', '-'],
                last30days: ['-', '-'],
                last90days: ['-', '-'],
                platform: "MDT",
                program_id: 143,
                project_id: 40,
                slamet: 0,
                slamissed: 0,
                slasuccess: "100 %",
                trend: "0 %"
            }]
            const transformData = [...response.data.allData, ...MdtData];
            const statusFormat = transformData.map((data: any) => {
                return {...data, status: data.platform == 'MDT' ? 'Coming Soon' : data.slamet == data.slamissed ? '--' : data.slamet > data.slamissed ? 'Completed on Time' : 'Completed Late', 
                    order: data.Project_Name.includes('BODS') ? 1 : data.Project_Name == 'MDT' ? 2 : data.Project_Name == 'GEDW' ? 3 : 4,
                    contact: contactDetails.find((d: any) => d.projectId == data.project_id) || {}
                }
            })
            statusFormat.map((s) => {
                statusCount.filter((sc: any) => {
                    if (sc.projectName == s.Project_Name) {
                        s.last7days = sc.last7days;
                        s.last30days = sc.last14days;
                        s.last90days = sc.last30days;
                    }
                })
                return s;
            })
            setLoader(false);
            setStatusData(statusFormat.sort((a: any, b: any) => a.order - b.order))
        }
    }
    
    const navigate = useNavigate();
    
    const getProgramDetails = async (programId: number) => {
        let response = await getProgramById(programId);
        if (response.success) {
            setProgramName(response.data.programData[0].program_Name)
        }
    };

    const navigateToExecutions = (navigateData: any) => {
        navigate(`/data-pipeline/programs/${navigateData.programId}/${encodeURIComponent(navigateData.programName)}`)
    }

    const handleDateSelect = (stDate: any) => {
        const sd = moment(stDate).startOf('day').format('YYYY-MM-DD');
        const ed = moment(stDate).startOf('day').format('YYYY-MM-DD');
        setStartDate(sd)
        setEndDate(ed)
        setLoader(true);
    }

    useEffect(() => {
        getProgramDetails(programId)
        getExeStatusData(programId, startDate);
    }, [startDate, programId, contactDetails]);

    return (
        <>
            <Grid container className="program-details-card-wrapper" style={{ backgroundColor: 'rgba(0, 55, 113, 0.2)' }}>
                { loader ? <Loader /> : 
                    <>
                <Grid container p={2} pb={0}>
                    <Grid item xs={6}>
                        
                       <Grid container>
                        {/* <Grid item xs={2.5}>
                            <Typography variant="h6" display={'inline-block'} style={{ lineHeight: 2.5, color: 'rgba(229, 229, 229, 1)', fontSize: '16px', fontWeight: '700'}}> Platform</Typography> 
                        </Grid> */}

                        <Grid item xs={5}>
                            {<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
                                <DatePicker
                                    showToolbar={false}
                                    components={{
                                        OpenPickerIcon: DatePickerIcon
                                    }}
                                    PopperProps={{ placement: 'auto' }}
                                    minDate={moment(new Date('2024-10-01T06:00:00Z'))}
                                    disableFuture={true}
                                    className="dateFilterDataPipeline"
                                    value={moment(startDate)}
                                    onChange={(dateTime) => {
                                        handleDateSelect(dateTime);
                                    }}
                                    renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
                                    shouldDisableDate={(date) => {
                                        const startDate = moment('2024-10-01');
                                        const endDate = moment('2024-11-16');
                                        return date.isBetween(startDate, endDate, 'day', '[]');
                                    }}
                                />
                            </LocalizationProvider>
                            }
                        </Grid>
                       </Grid>
                    
                    </Grid>
                </Grid>

                <Box className="platform-info" sx={{ width: '100%'}}>
                    <Grid container className="status-cards" p={2}>
                        <Grid item xs={1.6}>
                            <Typography color="light.diff" variant="subtitle1" style={{fontSize: '14px', fontWeight: '500', color: 'rgba(169, 173, 203, 1)', lineHeight: '1.5'}} mt={7} mb={1.5}>Total Jobs </Typography>
                            <Typography color="light.diff" variant="subtitle1" style={{fontSize: '14px', fontWeight: '500', color: 'rgba(169, 173, 203, 1)'}} pb={.7} mb={.6}>SLA Status</Typography>
                            <Typography color="light.diff" variant="subtitle1" style={{fontSize: '14px', fontWeight: '500', color: 'rgba(169, 173, 203, 1)'}}>Platform Health</Typography>
                        </Grid>
                        { statusData.map((status: any, index: number) => {
                            return (
                                <Grid item xs={2.6} key={index}>
                                    <Box className="card-box" sx={{ '& ': { opacity: status.platform == 'MDT' ? '.5': 1 }}}>
                                        <Box className="stats" mb={1} sx={{ '& ': { position: 'relative'}}}>
                                            <Typography variant="subtitle1" style={{ borderBottom: '1px solid #525252', marginLeft: '-10px', width: '92%', paddingLeft: '5px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                             className={status?.contact?.programId ? 'clickable': ''} onClick={(e) => status?.contact?.programId ? navigateToExecutions(status?.contact) : false} title={status.Project_Name}>{status.Project_Name}
                                            </Typography>
                                            
                                            {/* Contact Tooltip */}
                                            {status?.contact?.name && <Tooltip title={
                                                <><Typography variant="body2" color="light.diff" style={{fontSize: '13px', color: 'rgba(169, 173, 203, 1)', fontWeight: '500', fontStyle: 'italic'}}>Contact Person</Typography>
                                                <Typography variant="body2" color="inherit" style={{fontSize: '12px'}}>{status.contact.name} </Typography>
                                                <Typography variant="body2" color="light.diff" style={{fontSize: '12px', fontWeight: '500', color: 'rgba(245, 245, 245, 1)', fontStyle: 'italic'}}>{status?.contact?.email} </Typography>
                                                { status?.contact?.phone && <Typography variant="body2" color="light.diff" style={{fontSize: '12px'}}><PhoneIcon style={{ verticalAlign: "middle", width: '15px', height: '15px', color: 'blue' }} /> {status.contact.phone} </Typography>}
                                                </>
                                                } arrow>
                                                <ContactIcon style={{ verticalAlign: 'middle', position: 'absolute', right: '20px', top: '-2px'}} />
                                            </Tooltip>}
                                        </Box>

                                         
                                        <Box className={status?.contact?.programId ? 'clickable': ''} onClick={(e) => status?.contact?.programId ? navigateToExecutions(status?.contact) : false}>

                                            {/* SLA Status: & Actual Completion Time: */}
                                            { status.platform != 'MDT' && <>
                                                {/* Job Count  */}
                                                <Typography style={{ marginTop: '9px' }} className={status.platform == 'MDT' ? 'soon-color' : '' }>
                                                    {status.platform == 'MDT' ? 'NA' : status.jobCount ? status.jobCount : '--'}</Typography>
                                                <Box className="stats" mt={1}>
                                                    <Typography className={status.status == 'Completed on Time' ? "success-color" : status.status == 'Completed Late' ? "fail-color" : status.status == 'At Risk' ? "risk-color" : status.status == '--' ? 'risk-color' : "soon-color"}>{status.slamet > 0 ? 'SLA Met' : status.slamissed > 0 ? 'SLA Missed' : status.slamet == status.slamet ? status.platform == 'MDT' ? 'NA' : 'SLA To Be Determined' : 'NA'}                                                     
                                                    </Typography>
                                                </Box>
                                            </>
                                            }

                                            {/* Platform Health  */}

                                            { status.platform != 'MDT' &&
                                            <Typography style={{ textAlign: 'center', border: '1px solid rgba(113, 117, 143, 0.5)', borderRadius: '20px', margin: '10px 0', padding: '2px 5px', display: 'inline-block', fontSize: '12px' }} className="soon-bg-color">COMING SOON <CommingSoonIcon style={{ verticalAlign: 'text-bottom' }} /></Typography>
                                            }
                                            
                                            { status.platform == 'MDT' &&
                                            <Typography style={{ textAlign: 'center', margin: '37px auto 51px auto', padding: '2px 0', color: '#e5e5e5', fontSize: '12px', width: '130px' }} >COMING SOON <CommingSoonIcon style={{ verticalAlign: 'text-bottom' }} /></Typography>
                                            }                                        
                                            
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid container className="status-cards" p={2} pb={0}>
                        <Grid item xs={12}>
                            <Typography color="light.diff" variant="subtitle1" style={{borderTop: '1px solid rgb(82, 82, 82)', paddingTop: '15px'}}></Typography>
                        </Grid>
                    </Grid>
                    
                    <Grid container className="status-cards" p={2}>
                        
                        <Grid item xs={1.6}>
                            <Typography color="light.diff" variant="subtitle1" style={{fontSize: '16px', paddingTop: '8px', color: 'rgba(229, 229, 229, 1)', fontWeight: '700'}}>Historic Trend</Typography>
                            <Typography color="light.diff" variant="subtitle1" style={{fontSize: '14px', fontWeight: '500', color: 'rgba(169, 173, 203, 1)'}} mt={1.4} mb={.4}>SLA Type</Typography>
                            <Typography color="light.diff" variant="subtitle1" style={{fontSize: '14px', fontWeight: '500', color: 'rgba(169, 173, 203, 1)'}} mb={.1}>Last 7 days</Typography>
                            <Typography color="light.diff" variant="subtitle1" style={{fontSize: '14px', fontWeight: '500', color: 'rgba(169, 173, 203, 1)'}}>Last 14 days</Typography>
                            <Typography color="light.diff" variant="subtitle1" style={{fontSize: '14px', fontWeight: '500', color: 'rgba(169, 173, 203, 1)'}}>Last 30 days</Typography>
                        </Grid>
                        { statusData.map((status: any, index: number) => {
                            return (
                                <Grid item xs={2.6} key={index}>
                                    <Grid container className="card-box secondary" sx={{ '& ': { opacity: status.platform == 'MDT' ? '.5': 1 }}}>
                                        
                                    <Box className="stats" mb={1} sx={{ '& ': { position: 'relative'}}}>
                                        <Typography variant="subtitle1" style={{ borderBottom: '1px solid #525252', marginLeft: '10px', paddingLeft: '5px', width: '90%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{status.Project_Name}
                                        </Typography>
                                    </Box>
                                    
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Box className="stats">
                                                <Typography color="light.diff" variant="subtitle1" style={{ textAlign: 'center', color: 'rgba(169, 173, 203, 1)', fontSize: '14px', fontWeight: '500' }}>SLA Met</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box className="stats">
                                                <Typography color="light.diff" variant="subtitle1" style={{ textAlign: 'center', color: 'rgba(169, 173, 203, 1)', fontSize: '14px', fontWeight: '500' }}>SLA Missed</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    { status.platform != 'MDT' && <>
                                    <Grid container>
                                        {status.last7days.map((count: number, index: number) => {
                                            return (
                                                <Grid item xs={6} key={index}>
                                                    <Box className="stats">
                                                        <Typography style={{ textAlign: 'center' }}>{count}</Typography>
                                                    </Box>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    
                                    <Grid container>
                                        {status.last30days.map((count: number, index: number) => {
                                            return (
                                                <Grid item xs={6} key={index}>
                                                    <Box className="stats">
                                                        <Typography style={{ textAlign: 'center' }}>{count}</Typography>
                                                    </Box>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    
                                    <Grid container>
                                        {status.last90days.map((count: number, index: number) => {
                                            return (
                                                <Grid item xs={6} key={index}>
                                                    <Box className="stats">
                                                        <Typography style={{ textAlign: 'center' }}>{count}</Typography>
                                                    </Box>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>

                                    </>
                                    }
                                    { status.platform == 'MDT' &&
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography style={{ textAlign: 'center', margin: '26px auto 27px', padding: '2px 0', width: '130px', fontSize: '12px', color: '#e5e5e5' }}>COMING SOON <CommingSoonIcon style={{ verticalAlign: 'text-bottom' }} /></Typography>
                                            </Grid>
                                        </Grid>
                                    }

                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
                </>
                }
            </Grid>
        </>
    );
};

export default ProgramDetailsCard;
