import React, { useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactComponent as WarningImage } from '../../../assets/delete-img-modal.svg';

interface FormData {
	groupId: string;
	resource: string;
}
interface ClearAllocationsModalProps {
	handleSave: (
        formData: FormData,
      ) => void;
	onCancel: () => void;
    formData: FormData;
}

const ClearAllocationsModal: React.FC<ClearAllocationsModalProps> = ({
	onCancel,
	handleSave,
    formData,
}) => {
	// cancel button handler
	const handleCancel = () => {
		onCancel();
	};

	// Force clear button handler
	const handleForceClear = () => {
		handleSave(formData);
	};

	useEffect(() => {
	}, [])
	
	return (
		<Stack
			spacing={2}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
				px: 2,
				pb: 2,
				pt: 1
			}}
		>
			<Stack direction="row" alignItems="center" spacing={1}>
				<WarningImage className="warning-image" style={{ width: '4.5rem', height: '4.5rem' }} />

				<Stack
					sx={{
						alignItems: 'flex-start',
						justifyContent: 'center',
						pl: '1rem'
					}}
				>
					<Typography sx={{ textAlign: 'left', pb: '0.5rem', fontWeight:'500', fontSize: '1.25rem'}} >
						Are you sure to clear all allocations for{' '} 
						<Box component="span" sx={{fontWeight: '700 !important', fontSize: '1.25rem'}}>
							{formData.resource}
						</Box>?
					</Typography>
					<Typography sx={{ textAlign: 'left', color: 'light.diff' }}>
						Once confirmed, the action can't be undone.
					</Typography>
				</Stack>
			</Stack>
			<Stack direction="row" justifyContent="space-between" sx={{ alignSelf: 'flex-end' }}>
				<Stack direction="row">
					<Button variant="pepwiseOutline" sx={{ width: '10rem', color: 'info.main' }} onClick={handleCancel}>
						CANCEL
					</Button>
					<Button
						variant="pepwiseSecondary"
						sx={{ width: '10rem', backgroundColor: 'error.main' }}
						onClick={handleForceClear}
					>
						FORCE CLEAR
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default ClearAllocationsModal;
