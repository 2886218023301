import { Box, Divider, Grid, Typography } from '@mui/material';
import PageHeader from 'components/Header/Page';
import React, { useEffect, useRef, useState } from 'react';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import SectorFilter from 'components/Dropdown/SectorFilter';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import PieWidget from 'components/Widgets/PieWidget';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import LoaderComponent from 'components/Loader';
import { Column } from 'react-data-grid';
import IncidentFilter from 'components/Dropdown/IncidentFilter';
import Insights from './Insights';
import ExecutiveTabs from './ExecutiveTabs';
import PieInfoWidget from 'components/Widgets/PieInfoWidget';
import { addOrUpdateInsights, deleteInsights, getInsights } from 'service/service';
import Hierarchy1 from 'components/Dropdown/Hierarchy1';
import Hierarchy2 from 'components/Dropdown/Hierarchy2';
import Hierarchy3 from 'components/Dropdown/Hierarchy3';
import SegmentedBarChart from 'components/Widgets/SegmentedBarChart';
import RowTypography from 'components/Table/RowTypography';

const ExecutiveViewIncident = () => {
	const [sectorName, setSectorName] = useState('all');
	const [sectorList, setSectorList] = useState<any[]>([]);
	const [technology, setTechnology] = useState('ALL');
	const [technologyList, setTechnologyList] = useState<any[]>(["EDF", "SAP", "Teradata", "Informatica"]);
	const [customMonth, setCustomMonth] = useState('');
	const [filterMonth, setFilterMonth] = useState('1M');
	const [majorProblemLoading, setMajorProblemLoading] = useState(true);
	const [majorProblemSeriesData, setMajorProblemSeriesData] = useState<any>([]);
	const [mimsData, setMimsData] = useState<any>([])
	const [p3CriticalIncidentSeriesData, setP3CriticalIncidentSeriesData] = useState<
		{ name: string; value: number;formattedName: any}[]
	>([]);
	const [insights, setInsights] = useState<Insights[]>([]);
	const [loader, setLoader] = useState<boolean>(false);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [tableData, setTableData] = useState<Row[]>([]);
	const handlepageSizeChange = (event: any) => {
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		};

	const handleDateChange = () => {};
	const handleSectorChange = () => {};
	const handleTechnologyChange = () => {
		// if (technology === 'EDF') {
		// 	setTableData(getFormattedTableData(edfTableData));
		//   } else if (technology === 'SAP') {
		// 	setTableData(getFormattedTableData(sapRawData));
		//   } else {
		// 	setTableData(calculateTableData());
		//   }
	};


	const responseData = {
		// mim: [
		// 	{
		// 		totalCount: 7,
		// 		preventiveCount: 4,
		// 		mimsCount: 3,
		// 		lastMonthCount: 2,
		// 		currentMonthCount: 5
		// 	}
		// ],
		// p3Incidents: [
		// 	{
		// 		totalCount: 42,
		// 		openCount: 18,
		// 		resolvedCount: 24,
		// 		lastMonthCount: 4,
		// 		currentMonthCount: 2
		// 	}
		// ],
		incidents: [
			{
				totalCount:' 3,744',
				openCount: 453,
				resolvedCount: 794,
				lastMonthCount: 2,
				currentMonthCount: 5
			}
		],
		// changeRequests: [
		// 	{
		// 		totalCount: 384,
		// 		pendingCount: 170,
		// 		implementedCount: 214,
		// 		lastMonthCount: 6,
		// 		currentMonthCount: 2
		// 	}
		// ],
		// serviceRequests: [
		// 	{
		// 		totalCount: 284,
		// 		pendingCount: 120,
		// 		completedCount: 162,
		// 		lastMonthCount: 7,
		// 		currentMonthCount: 5
		// 	}
		// ],
		// availability: [
		// 	{
		// 		totalCount: 99.99,
		// 		lastMonthCount: 3,
		// 		currentMonthCount: 4
		// 	}
		// ]
	};

	// Function to transform response data into widget-friendly format
	const transformData = (data: any) => {
		return Object.keys(data).map((key) => {
			const item = data[key][0]; // Extract first object in the array
			// const change = ((item.currentMonthCount - item.lastMonthCount) / item.lastMonthCount) * 100;
			// const changeType = item.currentMonthCount > item.lastMonthCount ? 'increase' : 'decrease';

			let widgetTitle = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()); // Convert camelCase to Title

			let chartData: any[] = [];

			// Mapping specific categories to pie chart structure
			// if (key === 'mim') {
			// 	chartData = [
			// 		{ name: 'MIMs', value: item.mimsCount },
			// 		{ name: 'Preventive MIMs', value: item.preventiveCount }
			// 	];
			// }
			// else if (key === 'p3Incidents' || key === 'incidents') {
			// 	chartData = [
			// 		{ name: 'Open', value: item.openCount },
			// 		{ name: 'Resolved', value: item.resolvedCount }
			// 	];
			// }
			// else if (key === 'changeRequests') {
			// 	chartData = [
			// 		{ name: 'Pending', value: item.pendingCount },
			// 		{ name: 'Implemented', value: item.implementedCount }
			// 	];
			// } else if (key === 'serviceRequests') {
			// 	chartData = [
			// 		{ name: 'Pending', value: item.pendingCount },
			// 		{ name: 'Completed', value: item.completedCount }
			// 	];
			// } else if (key === 'availability') {
			// 	chartData = [{ name: 'Availability %', value: item.totalCount }];
			// }

			return {
				title: widgetTitle,
				value: item.totalCount,
				data: chartData,
				// change: Math.abs(Number(change.toFixed(2))), // Absolute value, rounded to 2 decimals
				// changeType: changeType
			};
		});
	};

	const widgetData = transformData(responseData);

	interface Row {
		technology: string;
		incident: string;
		mim: string;
		criticalp3: string;
		p4: string;
		p5: string;
		alerts: string;
		srs: string;
	}
	// Ensure the Insight interface is imported from Insights.tsx
	interface Insights {
		insightId: number;
		title: string;
		description: string;
		opsType: string;
		createdBy: string;
		updatedBy: string;
		createdDate: string;
		updatedDate: string;
	}
	
	const getColumns = (): Array<Column<any>> => {
		// const isSimple = ['EDF', 'SAP'].includes(technology);
	
		const columnMap: Array<{ key: string; label: string }> =
			// ? [
			// 		{ key: 'technology', label: 'Systems' },
			// 		{ key: 'p3', label: 'P3' },
			// 		{ key: 'p4', label: 'P4' },
			// 		{ key: 'p5', label: 'P5' },
			// 		{ key: 'Total', label: 'Total' },
			// 		{ key: 'TotalPercent', label: 'Total %' }
			//   ]
			// : 
			[
					{ key: 'technology', label: 'Technology' },
					{ key: 'mim', label: 'MIM' },
					{ key: 'criticalp3', label: 'Critical (P3)' },
					{ key: 'p4', label: 'P4' },
					{ key: 'p5', label: 'P5' },
					{ key: 'Total', label: 'TOTAL' },
			  ];
			  const totalCols = columnMap.length;
			  const baseWidth = window.innerWidth / (totalCols + 1);
		return columnMap.map((col, index) => {
			const isFirst = index === 0;
			
			if (col.key === 'technology') {
				return {
					key: col.key,
					name: col.label,
					resizable: true,
					width: isFirst ? baseWidth * 2 : baseWidth,
					formatter: ({ row }: any) => {
						if (row.id === 'total-row') {
							return (
								<RowTypography style={{ fontWeight: 'bold', width: '100%', color: '#fff' }}>
									TOTAL
								</RowTypography>
							);
						}
						return row.technology;
					}
				};
			} else if (col.key === 'Total') {
				return {
					key: col.key,
					name: col.label,
					resizable: true,
					width: isFirst ? baseWidth * 2 : baseWidth,
					formatter: ({ row }: any) => {
						// if (row.id === 'percent-row') {
						// 	// return (
						// 	// 	<RowTypography style={{ fontWeight: 'bold', width: '100%' }}>{row.Total}</RowTypography>
						// 	// );
						// }
						return <RowTypography style={{ fontWeight: 'bold', width: '100%' }}>{row.Total}</RowTypography>
					}
				};
			}else{
				return {
					key: col.key,
					name: col.label,
					resizable: true,
					width: baseWidth,
					formatter: ({ row }: any) => {
						return row.id === 'total-row' ? <RowTypography style={{ fontWeight: 'bold', width: '100%' }}>{row[col.key]}</RowTypography> : row[col.key]
					}
				};
			}
	
			// Default column
			return {
				key: col.key,
				name: col.label,
				resizable: true,
				width: isFirst ? baseWidth * 2 : baseWidth
			};
		});
	};

	const defaultColumnNames = 
	// ['EDF', 'SAP'].includes(technology)
	// ? ['Systems', 'P3', 'P4', 'P5', 'Total', 'Total %']
	// : 
	['Technology', 'MIM', 'Critical (P3)', 'P4', 'P5', 'TOTAL'];
	

	const mockData = [
		{ id: 1, technology: 'EDF', mim: '', incident: '2,558', criticalp3: '1', p4: '12', p5: '2,545', alerts: '7,727', srs: '30' },
		{
			id: 2,
			technology: 'Informatica DDH',
			mim: '',
			incident: '570',
			criticalp3: '--',
			p4: '566',
			p5: '4',
			alerts: '10,771',
			srs: '454'
		},
		{
			id: 3,
			technology: 'Informatica Cloud',
			mim: '',
			incident: '14',
			criticalp3: '--',
			p4: '13',
			p5: '1',
			alerts: '87',
			srs: '--'
		},
		{
			id: 4,
			technology: 'Informatica PBNA',
			mim: '',
			incident: '33',
			criticalp3: '--',
			p4: '33',
			p5: '--',
			alerts: '85',
			srs: '--'
		},
		{
			id: 5,
			technology: 'Informatica PFNA',
			mim: '',
			incident: '68',
			criticalp3: '1',
			p4: '60',
			p5: '7',
			alerts: '177',
			srs: '--'
		},
		{
			id: 6,
			technology: 'SAP BW Apps',
			mim: '',
			incident: '375',
			criticalp3: '9',
			p4: '28',
			p5: '338',
			alerts: '1,393',
			srs: '378'
		},
		{
			id: 7,
			technology: 'Teradata EDW',
			mim: '',
			incident: '70',
			criticalp3: '16',
			p4: '15',
			p5: '39',
			alerts: '4,440',
			srs: '52'
		}
	];

	  const sapRawData = [
		{
		  id: 1, technology: 'SAP BI NA', mim: '', criticalp3: '6', p4: '31', p5: '6399'
		},
		{
		  id: 2, technology: 'SAP BI LATAM', mim: '', criticalp3: '11', p4: '63', p5: '685'
		},
		{
		  id: 3, technology: 'SAP BI AMESA APAC', mim: '', criticalp3: '11', p4: '29', p5: '287'
		},
		{
		  id: 4, technology: 'SAP BI Europe & Sub Saharan Africa', mim: '', criticalp3: '2', p4: '10', p5: '83'
		},
		{
		  id: 5, technology: 'SAP BI PGCS', mim: '', criticalp3: '1', p4: '4', p5: '65'
		},
		{
		  id: 6, technology: '*Others', mim: '', criticalp3: '3', p4: '6', p5: '63'
		}
	  ];

	  const edfTableData = [
		{
			id: 1,
			technology: 'Data Fabric Operations',
			mim: '',
			criticalp3: '12',
			p4: '315',
			p5: '14,603',
		  }
	  ];

	// total and calculation
	const calculateTableData = (rawData: any[]) => {
		// Clean numerical strings and convert to numbers
		const cleanedData = rawData.map(row => {
			const getNum = (val: string) =>
				val === '--' || val === '' ? 0 : parseInt(val.replace(/,/g, '') || '0', 10);
	
			const mim = getNum(row.mim);
			const p3 = getNum(row.criticalp3);
			const p4 = getNum(row.p4);
			const p5 = getNum(row.p5);
			const total = mim + p3 + p4 + p5;
	
			return {
				...row,
				mim,
				criticalp3: p3,
				p4,
				p5,
				Total: total
			};
		});
	
		// Sum helper for numeric keys
		const sum = (key: keyof typeof cleanedData[0]) =>
			cleanedData.reduce((acc, row) => acc + (Number(row[key]) || 0), 0);
	
		// Create total row only
		const totalRow = {
			id: 'total-row',
			technology: 'TOTAL',
			mim: sum('mim').toString(),
			criticalp3: sum('criticalp3').toString(),
			p4: sum('p4').toString(),
			p5: sum('p5').toString(),
			Total: sum('Total')
		};
	
		return [...cleanedData, totalRow];
	};
	
	
	
	useEffect(() => {
	setTableData(calculateTableData(mockData));
	}, [])
	

	// const getFormattedTableData = (rawData: any[], totalLabel: string = 'TOTAL') => {
	// 	// Helper to safely extract numbers
	// 	const getNum = (val: any) =>
	// 		val === '--' || val === '' || val === undefined || val === null ? 0 : parseInt(String(val).replace(/,/g, '') || '0', 10);
	
	// 	// Normalize & compute row-wise total
	// 	const cleanedRows = rawData.map(row => {
	// 		const p3 = getNum(row.criticalp3); // handle either 'criticalp3' or 'p3'
	// 		const p4 = getNum(row.p4);
	// 		const p5 = getNum(row.p5);
	// 		const total = p3 + p4 + p5;
	
	// 		return {
	// 			...row,
	// 			criticalp3 : p3,
	// 			p4,
	// 			p5,
	// 			Total: total
	// 		};
	// 	});
	
	// 	// Grand total
	// 	const grandTotal = cleanedRows.reduce((acc, row) => acc + row.Total, 0);
	
	// 	// Add TotalPercent for each row
	// 	const withPercentages = cleanedRows.map(row => ({
	// 		...row,
	// 		TotalPercent: `${((row.Total / grandTotal) * 100).toFixed(1)}%`
	// 	}));
	
	// 	// Sum helper
	// 	const sum = (key: keyof typeof withPercentages[0]) =>
	// 		withPercentages.reduce((acc, row) => acc + (Number(row[key]) || 0), 0);
	
	// 	// Total row
	// 	const totalRow: any = {
	// 		id: 'total-row',
	// 		technology: totalLabel,
	// 		criticalp3: sum('criticalp3'),
	// 		p4: sum('p4'),
	// 		p5: sum('p5'),
	// 		Total: sum('Total'),
	// 		TotalPercent: '100%'
	// 	};
	
	// 	// Percent row
	// 	const percentRow: any = {
	// 		id: 'percent-row',
	// 		technology: '',
	// 		criticalp3: `${((Number(totalRow.criticalp3) / totalRow.Total) * 100).toFixed(1)}%`,
	// 		p4: `${((Number(totalRow.p4) / totalRow.Total) * 100).toFixed(1)}%`,
	// 		p5: `${((Number(totalRow.p5) / totalRow.Total) * 100).toFixed(1)}%`,
	// 		Total: totalRow.Total,
	// 		TotalPercent: ''
	// 	};
	
	// 	return [...withPercentages, totalRow, percentRow];
	// };
	
	  
	  
	  useEffect(() => {
		// if (technology === 'EDF') {
		//   setTableData(calculateTableData(edfTableData));
		// } else if (technology === 'SAP') {
		//   setTableData(calculateTableData(sapRawData));
		// } else {
		//   setTableData(calculateTableData(mockData));
		// }
		if (technology === 'ALL') {
			setTableData(calculateTableData(mockData));
		}else {
			setTableData(calculateTableData(mockData.filter(d => d.technology.indexOf(technology) > -1)));
		}
		fetchInsights();
	  }, [technology]);
	
	  
	// Mock data for PieWidget
	useEffect(() => {
		const fetchData = async () => {
			setMajorProblemLoading(true);
			// Simulating data fetch delay
			setTimeout(() => {
				setMajorProblemSeriesData([
					{ name: 'Open', value: 40 },
					// { name: 'Fix In Progress', value: 30 },
					{ name: 'Closed', value: 20 }
				]);
				setP3CriticalIncidentSeriesData([
					{ name: 'Teradata', value: 16, formattedName: 16 },
					{ name: 'EDF', value: 1, formattedName: 1 },
					{ name: 'Informatica', value: 1, formattedName: 1 },
					{ name: 'SAP BI', value: 9, formattedName: 9 }
				]);
				setMimsData([{name: 'MIMs', value: 0, formattedName: 0}])
				setMajorProblemLoading(false);
			}, 1000);
		};
		fetchData();
	}, []);

	const handleSorting = (sortingInfo: any) => {
		
	};

	//API to fetch insights
	const fetchInsights = async () => {
		setLoader(true);
		try {
			const response = await getInsights('Incidents', technology);
			if (response.success) {
				setInsights(response.data.data);
				setLoader(false);
				console.log('response', response);
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to get insights:', error);
		}
	};

	// Fetch insights
	useEffect(() => {
		fetchInsights();
	}, []);

	// Function to handle adding a new insight
	const handleAddInsight = async (newInsight: any) => {
		try {
			setLoader(true);
			const response = await addOrUpdateInsights({
				title: newInsight?.title,
				xopsType: 'Incidents',
				description: newInsight?.description,
				technology
			  });
			if (response.success) {
				console.log('response', response);
				fetchInsights();
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to add insight:', error);
		}
	};

	// Function to handle updating an insight
	const handleUpdateInsight = async (updatedInsight: any) => {
		try {
			setLoader(true);
			const response = await addOrUpdateInsights({
					title: updatedInsight?.title,
					xopsType: 'Incidents',
					description: updatedInsight?.description,
					insight_id: updatedInsight?.id,
					technology
				  });
			if (response.success) {
				console.log('response', response);
				fetchInsights();
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to update insight:', error);
		}
	};

	// Function to handle deleting an insight
	const handleDeleteInsight = async (insightId: number) => {
		try {
			setLoader(true);
			const response = await deleteInsights(insightId);
			if (response.success) {
				console.log('response', response);
				fetchInsights();
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to delete insight:', error);
		}
	};


	useEffect(() => {
		console.log("Technology", technology);
	}, [technology])
	
	const data = [
		{
			technology: 'EDF',
			values: [
				{ label: 'Average of 2024', value: 1323 },
				{ label: 'Jan 2025', value: 2817 },
				{ label: 'Feb 2025', value: 2616 }
			]
		},
		{
			technology: 'Teradata',
			values: [
				{ label: 'Average of 2024', value: 96 },
				{ label: 'Jan 2025', value: 79 },
				{ label: 'Feb 2025', value: 70 }
			]
		},
		{
			technology: 'SAP BW',
			values: [
				{ label: 'Average of 2024', value: 647 },
				{ label: 'Jan 2025', value: 609 },
				{ label: 'Feb 2025', value: 375 }
			]
		},
		{
			technology: 'Informatica',
			values: [
				{ label: 'Average of 2024', value: 1041 },
				{ label: 'Jan 2025', value: 800 },
				{ label: 'Feb 2025', value: 685 }
			]
		}
	];

	// Extract unique labels dynamically
	const uniqueLabels = Array.from(new Set(data.flatMap((item) => item.values.map((v) => v.label))));

	// Extract data per label for each technology
	const series = uniqueLabels.map((label, index) => ({
		name: label,
		type: 'bar',
		data: data.map((item) => item.values.find((v) => v.label === label)?.value || 0),
		itemStyle: { color: ['rgba(41, 155, 255, 1)', 'rgba(0, 208, 141, 1)', 'rgba(255, 194, 49, 1)'][index] }, // Dark Red, Blue, Dark Blue
		label: {
			show: true,
			position: 'top',
			fontSize: 12,
			color: '#FFF'
		},
		barWidth: '15%' //bar width
	}));

	// Find max value in dataset
	const maxValue = Math.max(...data.flatMap((item) => item.values.map((v) => v.value)));

	// Set Y-axis step size Either 100 or 500
	const stepSize = maxValue > 1000 ? 500 : 100;
	const maxYAxis = Math.ceil(maxValue / stepSize) * stepSize;

	const options = {
		textStyle: {
			color: '#FFFFFF' // Applies white text to axis labels, legends, etc.
		},
		backgroundColor: '#003771',
		tooltip: { trigger: 'axis' },
		legend: {
			data: uniqueLabels,
			bottom: 0,
			textStyle: {
				color: '#FFFFFF'
			}
		},
		grid: {
			left: '5%',
			right: '5%',
			bottom: '10%',
			containLabel: true
		},
		xAxis: [
			{
				type: 'category',
				data: data.map((item) => item.technology),
				axisTick: { show: false }, // Hides small ticks below x-axis labels
				axisLine: { show: true }, // Shows x-axis line
				splitLine: { // lines to separate technologies
					show: true,
					lineStyle: {
						color: '#ccc',
						type: 'solid'
					}
				}
			}
		],
		yAxis: [
			{
				type: 'value',
				min: 0,
				max: maxYAxis, // Dynamically calculated max
				interval: stepSize, // Step size is either 100 or 500
				axisLabel: { formatter: '{value}' },
				splitLine: { show: false } // Removes horizontal grid lines
			}
		],
		series
	};

	return (
		<Box bgcolor="blue.darker" mt="80px">
			<ExecutiveTabs />
			<Box sx={{ marginTop: '-10px', position: 'sticky' }}>
				<PageHeader
					title={[
						{
							text: 'Executive Summary',
							link: '/D&AIXOps/executiveView'
						},
						{
							text: decodeURIComponent(window.location.pathname.split('/')[3].replace(/%20/g, ' '))
						}
					]}
					icon={DashboardIcon}
					isDataRefresh={true}
					hideTitle={true}
					hideIcon={true}
				>
					<Hierarchy1
						sectorName={sectorName}
						setSectorName={setSectorName}
						sectorOptionsList={sectorList}
						handleChange={handleSectorChange}
						boxProps={{
							sx: { minWidth: '20px', position: 'static' }
						}}
					/>
					<Hierarchy2
						sectorName={sectorName}
						setSectorName={setSectorName}
						sectorOptionsList={sectorList}
						handleChange={handleSectorChange}
						boxProps={{
							sx: { minWidth: '20px', position: 'static' }
						}}
					/>
					<Hierarchy3
						sectorName={sectorName}
						setSectorName={setSectorName}
						sectorOptionsList={sectorList}
						handleChange={handleSectorChange}
						boxProps={{
							sx: { minWidth: '20px', position: 'static' }
						}}
					/>

					<DateFilterDropdown
						customMonth={customMonth}
						filterMonth={filterMonth}
						setCustomMonth={setCustomMonth}
						setFilterMonth={setFilterMonth}
						handleChange={handleDateChange}
						disableFilter={true}
					/>
					<SectorFilter
						sectorName={sectorName}
						setSectorName={setSectorName}
						sectorOptionsList={sectorList}
						handleChange={handleSectorChange}
						boxProps={{
							sx: { minWidth: '200px', position: 'static' },
							disabled: true
						}}
					/>
				</PageHeader>
			</Box>
			<Grid item container mt={1} xs={12} pl={2}>
				<Grid container spacing={1}>
					{/* {widgetData.map((widget, index) => (
						<Grid item key={index} sm={3} md={2}>
							<PieInfoWidget
								title={`${widget.title} (Feb 2025)`}
								value={widget.value}
								data={widget.data}
								// change={widget.change}
								// changeType={widget.changeType}
							/>
						</Grid>
					))} */}
					<Grid item xs={12} md={3} sx={{ display: 'flex' }}>
						<Box sx={{ width: '100%', height: '100%' }}>
							<PieWidget
								title="P3 Critical Incidents (Feb 2025)"
								isLoading={majorProblemLoading}
								seriesData={p3CriticalIncidentSeriesData}
								customSize="350px"
								showDollar = {false}
								shortDesc = {`Total: ${p3CriticalIncidentSeriesData.reduce((sum: number, item: any) => sum + item.value, 0)}`}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} md={2} sx={{ display: 'flex' }}>
						<Box sx={{ width: '100%', height: '100%' }}>
							<PieWidget
								title="MIMs (Feb 2025)"
								isLoading={majorProblemLoading}
								seriesData={mimsData}
								customSize="200px"
								showDollar = {false}
								shortDesc = {`Total: ${mimsData.reduce((sum: number, item: any) => sum + item.value, 0)}`}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} sm={9} md={7} sx={{ flex: 1, display: 'flex' }}>
						<Box sx={{ width: '100%', height: '100%', backgroundColor: '#003771', borderRadius: '5px' }}>
							<SegmentedBarChart title="Total Incidents Trend Analysis" data={data} options={options} />
						</Box>
					</Grid>

					

					{/* <Grid item xs={12} md={6} sx={{ flex: 1, display: 'flex' }}>
						<Box sx={{ width: '100%', height: '100%', backgroundColor: '#003771', borderRadius: '5px' }}>
							<SegmentedBarChart title="Total Incidents Trend Analysis" data={data} options={options} />
						</Box>
					</Grid> */}
				</Grid>

				<Grid item xs={12} mt={2}>
					<Box sx={{display: 'flex', justifyContent: 'end', marginRight: 8, marginBottom: -5}}>
						<IncidentFilter
							sectorName={technology}
							setSectorName={setTechnology}
							sectorOptionsList={technologyList}
							handleChange={handleTechnologyChange}
							boxProps={{
								sx: { minWidth: '20px', position: 'static' }
							}}
						/>
					</Box>
					<Box sx={{ width: '100%', overflow: 'auto'}}>
						{/* <CustomizedDataGrid
								title={'Assignment Group'}
								tableColumns={getColumns()}
								defaultColumnNames={defaultColumnNames}
								tableData={mockData}
								autoSetDraggedColumns={false}
								showExport={false}
								isDateRangeFilter={false}
								isDisabled={false}
								isRenderPagination={false}
								isShowMoreButton={false}
								listLength={mockData.length}
							/> */}
							<CustomizedDataGrid
								// title={'Assignment Group'}
								tableColumns={getColumns()}
								tableData={tableData}
								tableType="sector"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={tableData.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isDateRangeFilter={false}
								isDisabled={false}							  
							/>
						</Box>
					</Grid>
			</Grid>

			<Box marginTop={4} pl={3} pr={3}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Typography sx={{ color: '#009BDB', fontSize: '16px', fontWeight: 600 }}>Insights/Actions</Typography>
					<Divider sx={{ flexGrow: 1, backgroundColor: '#009BDB', marginLeft: 2 }} />
				</Box>
				<Typography
					variant="body2"
					sx={{ fontStyle: 'italic', color: '#71758F', fontSize: '14px', fontWeight: '500', marginBottom: 5 }}
				>
					You can display and update Insights/Actions if any.
				</Typography>
			</Box>
			{loader ? (
				<LoaderComponent />
			) : (
				<Box sx={{ mb: 3 }}>
					<Insights
						insights={insights}
						setInsights={setInsights}
						onAdd={handleAddInsight}
						onUpdate={handleUpdateInsight}
						onDelete={handleDeleteInsight}
					/>
				</Box>
			)}
		</Box>
	);
};
export default ExecutiveViewIncident;


