import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    Typography, Box, Button, Grid, IconButton, TextField, Menu, 
    MenuItem    
} from "@mui/material";
import PageHeader from "components/Header/Page";
import { AlertDetailView } from "./AlertDetailView";
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import moment from "moment";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationDialog from "./DeleteModal";
import EditIcon from '@mui/icons-material/Edit';
import '../jobExecutions/jobExecutions.css';
import '../jobs/jobs.css';
import './alert-kpi.scss';
import { getConfigDetails, getConfigAlerts, deleteAlertConfig } from 'service/service';
import { usePepwiseAuthController } from "Context/contexts/AuthContext";
import { checkRole } from "utils/roleUtils";
import DateTimeFilter from "./DateTimeFilter";

const AlertDetails = () => {
    const { id } = useParams();
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [metricsData, setMetricsData] = useState<any>({})
    const navigate = useNavigate()
    const initStartDate = moment().subtract(1, 'months')
    const initEndDate = moment()
    const [fromDateTime, setFromDateTime] = useState<any>(initStartDate);
    const [toDateTime, setToDateTime] = useState<any>(initEndDate)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false)
    const [alertList, setAlertList] = useState([])
    const [alertConfigDetails, setAlertConfigDetails] = useState<AlertConfig | undefined>({
        program: '',
        _id: '',
        alertType: '',
        ruleName: ''
    });

    const [sortingInfo, setSortingInfo] = useState({
        sortColumn: "",
        sortDir: "",
    });
    const [filterInfo, setFilterInfo] = useState([]);
    const [filterData, setFilterData] = useState<any[]>([]);
    
    const defaultBody = { page: 1, pageSize: 10, sortColumn: sortingInfo.sortColumn, sortDir: sortingInfo.sortDir, filters: filterInfo }

    const defaultFilterOptions = {
        email: ["Yes", "No"],
        teams: ["Yes", "No"],
        snow: ["Yes", "No"],
        grouped: ["Yes", "No"],
        silenced: ["Email", "Service Now", "Teams", "None"]
    };

    const [controller] = usePepwiseAuthController();
	const { role } = controller;
    const isAllowed = checkRole(role, ['Data Operations Admin', 'PepWise System User']);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        setOpenDialog(true);
        handleClose();
    };
    const handleDeleteConfirm = async () => {
        setOpenDialog(false);
        await deleteAlertConfig(alertConfigDetails?._id)
        window.location.href = '/alertFramework/alert';
    };

    interface AlertConfig {
        program?: string;
        _id?: any;
        alertType: string;
        ruleName: string;
    }

    const handleFilter = (newFilterInfo: any) => {
        setFilterInfo(newFilterInfo);
        setPage(1)
        fetchAlertList({
            page: 1,
            pageSize,
            sortColumn: sortingInfo.sortColumn,
            sortDir: sortingInfo.sortDir,
            filters: newFilterInfo
        });
    };

    const handleSorting = (newSortingInfo: any) => {
        setSortingInfo(newSortingInfo);
        fetchAlertList({
            page,
            pageSize,
            sortColumn: newSortingInfo.sortBy,
            sortDir: newSortingInfo.sortDir,
            filters: filterInfo
        });
    };

    const handlePageSizeChange = (event: any) => {
        const newPageSize = parseInt(event.target.value);
        setPageSize(newPageSize);
        fetchAlertList({
            page,
            pageSize: newPageSize,
            sortColumn: sortingInfo.sortColumn,
            sortDir: sortingInfo.sortDir,
            filters: filterInfo
        });
    };

	const handlePageChange = (data:any, newPage:any) => {
		setPage(newPage)
		fetchAlertList({
            page: newPage, 
            pageSize,
            sortColumn: sortingInfo.sortColumn,
            sortDir: sortingInfo.sortDir,
            filters: filterInfo
        })
	}

    const handleTimeFilter = () => {
        fetchAlertGridConfig()
        setPage(1)
        fetchAlertList({page: 1, pageSize, sortColumn: sortingInfo.sortColumn, sortDir: sortingInfo.sortDir, filters: filterInfo})
    }

    const clearTimeFilter = () => {
        setFromDateTime(initStartDate)
        setToDateTime(initEndDate)
        setPage(1)
        fetchAlertList({page: 1, pageSize, sortColumn: sortingInfo.sortColumn, sortDir: sortingInfo.sortDir, filters: filterInfo}, true)
    }

    const fetchAlertList = async ({ page, pageSize, sortColumn, sortDir, filters = {}}: any, clearTimeFilter = false) => {
        const filter = Object.keys(filters).reduce((filterObj: any, key) => {
            const fil = filters[key]
            switch(key){
                case 'email':
                    if(fil.length === 1){
                        filterObj['emailTime'] = fil[0] === 'Yes' ? {'$ne': null} : {'$eq': null}
                    }
                    break
                case 'snow':
                    if(fil.length === 1){
                        filterObj['serviceNowTime'] = fil[0] === 'Yes' ? {'$ne': null} : {'$eq': null}
                    }
                    break
                case 'teams':
                    if(fil.length === 1){
                        filterObj['msTeamsTime'] = fil[0] === 'Yes' ? {'$ne': null} : {'$eq': null}
                    }
                    break
                case 'silenced':
                    if(fil.length > 0){
                        filterObj['silencedChannels'] = {'$in': fil}
                    }
                    break
                case 'grouped':
                    if(fil.length === 1){
                        filterObj['isGrouped'] = fil[0] === 'Yes' ? true : null
                    }
                    break;
            }
            return filterObj
        }, {
            startDate: clearTimeFilter ? initStartDate : fromDateTime, 
            endDate: clearTimeFilter ? initEndDate : toDateTime
        })
        setLoading(true);

        try {
            const response: any = await getConfigAlerts(id, {
                page,
                pageSize,
                sortColumn : sortColumn === 'createdDt' ? 'createdAt' : sortColumn,
                sortDir,
                filter
            });

            if (response?.data) {
                const transformedData: any = response.data.data.map((alert: any) => ({
                    ...alert,
                    _id: typeof alert._id === 'object' && alert._id !== null && alert._id.$oid
                        ? alert._id.$oid
                        : alert._id,
                    email: alert.emailTime ? "Yes" : "No",
                    snow: alert.serviceNowTime ? "Yes" : "No",
                    teams: alert.msTeamsTime ? "Yes" : "No",
                    silenced: alert.silencedChannels && alert.silencedChannels.length > 0 ? alert.silencedChannels.join(', ') : "None",
                    grouped: alert.isGrouped ? "Yes" : "No",
                    createdDt: moment(alert.createdAt).format()
                }));

                setAlertList(transformedData);
                setTotalCount(response.data.totalCount);
                setFilterData(response.data.filterOptions ?? defaultFilterOptions);
            }
        } catch (error) {
            console.error("Error fetching alerts:", error);
        } finally {
            setLoading(false);
        }
    };


    const fetchAlertGridConfig = async () => {
        setLoading(true)
        const response = await getConfigDetails(id, {filter: {startDate: fromDateTime, endDate: toDateTime}})
        setLoading(false)
        if (response?.data) {
            setAlertConfigDetails(response.data.alertConfig)
            setMetricsData(response.data.counts)
        }
    }

    const CardItem = ({label, text}: any) => {
		return (
			<Grid item xs style={{ cursor: 'pointer', paddingLeft: 8, marginLeft: 8 }}>
				<div style={{ height: '100%'}}>
					<Typography variant="subtitle1" style={{ opacity: 0.7 }}>
						{label}
					</Typography>
					<Typography style={{ fontSize: '11px' }}>{text}</Typography>
				</div>
			</Grid>
		)
	}

    const Card = ({label, text}: any) => {
        return (
            <Grid item xs style={{ cursor: 'pointer', paddingLeft: 8, marginLeft: 8,  backgroundColor: '#003771'   }}>
                <div style={{ height: '100%'}}>
                    <Typography variant="subtitle1" style={{ opacity: 0.7 }}>
                        {label}
                    </Typography>
                    <Typography variant="h6">{text}</Typography>
                </div>
            </Grid>
        )
    }

    useEffect(() => {
        fetchAlertList(defaultBody)
        fetchAlertGridConfig()
    }, [])

    return (
        <>
            <Box bgcolor="blue.darker" mt="86px">
                <PageHeader title={[{text: "Alerts", link: '/alertFramework/alert'}, {text:`${alertConfigDetails?.ruleName}`}]} icon={DashboardIcon}>
                    <DateTimeFilter 
                        fromDateTime={fromDateTime} 
                        setFromDateTime={setFromDateTime} 
                        toDateTime={toDateTime} 
                        setToDateTime={setToDateTime} 
                        handleTimeFilter={handleTimeFilter}
                        clearTimeFilter={clearTimeFilter}/>
                </PageHeader>
            </Box>
            <Grid container spacing={1} style={{ marginLeft: 2, marginRight: 2, paddingRight: 2, paddingLeft: 2, marginTop: '0', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto'}}>
                <Grid xs={8} container spacing={1} style={{ marginTop: '0', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto',  backgroundColor: '#003771'  }}>
                    <CardItem label={'Name'} text={alertConfigDetails?.ruleName}/>
                    <CardItem label={'Program'} text={alertConfigDetails?.program}/>
                    <CardItem label={'Alert Type'} text={alertConfigDetails?.alertType}/>
                    <Grid item xs={1} style={{ cursor: 'pointer', textAlign: 'right' }}>
                        <div>
                            <IconButton aria-label="more" sx={{ color: '#F5F5F5', display: 'block', marginLeft: 'auto' }} onClick={(event) => handleClick(event)}>
                                <MoreVertIcon fontSize="small" />
                            </IconButton>

                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                sx={{ "& .MuiMenu-paper": { backgroundColor: "#003771 !important", width: "12%" } }}
                            >
                                <MenuItem disabled={!isAllowed} sx={{ marginLeft: '25px' }}>
                                    <IconButton
                                        aria-label="edit"
                                        size="small"
                                        onClick={() => {
                                            navigate(`/alertFramework/${id}`)
                                        }}
                                        sx={{ color: "#F5F5F5" }}
                                    >
                                        <EditIcon fontSize="small" />
                                        <span style={{ fontSize: "1.0rem", marginLeft: "6px" }}>Edit</span>
                                    </IconButton>
                                </MenuItem>
                                <MenuItem disabled={!isAllowed} onClick={handleDeleteClick} sx={{ marginLeft: "25px" }}>
                                    <DeleteIcon />
                                    <span style={{ fontSize: "1.0rem", marginLeft: "6px" }}>Delete</span>
                                </MenuItem>

                            </Menu>
                            <DeleteConfirmationDialog
                                open={openDialog}
                                onClose={() => setOpenDialog(false)}
                                onConfirm={handleDeleteConfirm}
                                identifierName={alertConfigDetails?.ruleName || ""}
                                type={''}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginLeft: 1, marginTop: '0', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
                    <Card label={'Alerts Generated'} text={metricsData.totalCount}/>
                    <Card label={'Email Alerts'} text={metricsData.emailsDelivered}/>
                    <Card label={'SNOW Alerts'} text={metricsData.snowDelivered}/>
                    <Card label={'Teams Alerts'} text={metricsData.teamsDelivered}/>
                    <Card label={'Grouped Alerts'} text={metricsData.grouped || 0}/>
                </Grid>
            </Grid>
            <AlertDetailView
                alertList={alertList}
                setAlertList={setAlertList}
                handleSorting={handleSorting}
                sortingInfo={sortingInfo}
                handleFilter={handleFilter}
                filterInfo={filterInfo}
                page={page}
                setPage={setPage}
                pageSize={pageSize}
                handlepageSizeChange={handlePageSizeChange}
                totalCount={totalCount}
                fetchAlertList={fetchAlertList}
                filterData={filterData}
                handlePageChange={handlePageChange}
                loading={loading}
            />
        </>
    );
};

export default AlertDetails;
