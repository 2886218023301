import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Chip,
	FormControlLabel,
	IconButton,
	Menu,
	MenuItem,
	Switch,
	Typography
} from '@mui/material';
import { CustomizedDataGrid } from '../customized_data_grid';
import DialogueModal from 'components/Modal/DialogModal';
import SilenceModal from './SilenceModal';
import { Column } from 'react-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteConfirmationDialog from './DeleteModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { SilenceRow } from './createAlert';

export const SilencesTab = ({ maintenanceWindows = [], setMaintenanceWindows }: any) => {
    const [showSilenceModal, setShowSilenceModal] = useState(false);
    const [selectedSilence, setSelectedSilence] = useState<SilenceRow | null>(null);
    const defaultColumnSilence = ['Silence Name', 'Start Time (UTC)', 'End Time (UTC)', 'Silenced Channels', 'Status', 'Action']

    const handleManageSilences = (silence: SilenceRow, state: string) => {
        setSelectedSilence(null)
		if(state === 'add'){
			setMaintenanceWindows([...maintenanceWindows, silence])
		}else if(state === 'update'){
			setMaintenanceWindows(maintenanceWindows.map((sl:SilenceRow) => {
				return sl.uuid === silence.uuid ? silence : sl
			}))
		}
		setShowSilenceModal(false)
	};

    function getColumnsSilence(): Array<Column<SilenceRow>> {
		return [
			{
				key: 'name',
				name: 'Silence Name',
				minWidth: 150,
				width: 308
			},
			{
				key: 'startTime',
				name: 'Start Time (UTC)',
				width: 200,
				minWidth: 150,
				formatter({ row }: any) {
					const date = row.startTime ? new Date(row.startTime) : new Date();
					return (
						<span>{!isNaN(date.getTime()) ? date.toISOString() : 'Invalid Date'}</span>
					);
				},
			},
			{
				key: 'endTime',
				name: 'End Time (UTC)',
				minWidth: 95,
				formatter({ row }: any) {
					const date = row.endTime ? new Date(row.endTime) : new Date();
					return (
						<span>{date && !isNaN(date.getTime()) ? date.toISOString() : 'Invalid Date'}</span>
					);
				},
			},
			{
				key: 'silencedChannels',
				name: 'Silenced Channels',
				width: 200,
				minWidth: 150,
				formatter({ row }: any) {
					return (
					  <span>{row.silencedChannels.join(", ")}</span>
					);
				},
			},
			{
				key: 'isEnabled',
				name: 'Status',
				minWidth: 95,
				formatter({ row }: any) {
					return (
					  <Chip
					  	style={{ minWidth: 64, backgroundColor: row.isEnabled ? "#01C00826" : "#D82B5133", marginTop: 2 }}
						label={row.isEnabled ? "ACTIVE" : "INACTIVE"}
					  />
					);
				},
			},
			{
				key: 'action',
				name: 'Action',
				formatter({ row }: any) {
					const [anchorEl, setAnchorEl] = useState(null);
					const [openDialog, setOpenDialog] = useState(false)

					const handleClick = (event: any) => {
						setAnchorEl(event.currentTarget);
					};

					const handleClose = () => {
						setAnchorEl(null);
					};

					const handleToggleStatus = () => {
						setMaintenanceWindows(maintenanceWindows.map((mw:SilenceRow) => {
							if(mw.uuid === row.uuid){
								mw.isEnabled = !mw.isEnabled
							}
							return mw
						}));
						handleClose();
					};

					const handleAction = (actionType: any) => {
						setSelectedSilence(row);
						if (actionType === 'edit') {
							setShowSilenceModal(true);
						} else if (actionType === 'delete') {
							setOpenDialog(true)
						}
						handleClose();
					};

					const handleDeleteConfirm = () => {
						setMaintenanceWindows((mws:SilenceRow[]) =>
							mws.filter((mw:SilenceRow) => mw.uuid !== row.uuid)
						);
						setOpenDialog(false);
					};

					return (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<IconButton aria-label="edit" size="small" onClick={() => handleAction('edit')} sx={{ color: '#71758F' }}>
								<EditIcon fontSize="small" />
							</IconButton>
							<IconButton aria-label="more" size="small" onClick={handleClick} sx={{ color: '#71758F' }}>
								<MoreVertIcon fontSize="small" />
							</IconButton>

							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleClose}
								sx={{
									'& .MuiMenu-paper': {
										backgroundColor: '#003771 !important',
										width: '12%'
									}
								}}
							>
								<MenuItem onClick={() => handleAction('delete')} sx={{ marginLeft: '25px' }}>
									<DeleteIcon />
									<span style={{ fontSize: '1.0rem', marginLeft: '6px' }}>Delete</span>
								</MenuItem>
								<MenuItem onClick={handleToggleStatus} sx={{ marginLeft: '25px' }}>
									<FormControlLabel
										control={<Switch checked={row.isEnabled} size="small" color="default" />}
										label={row.isEnabled ? 'Deactivate' : 'Activate'}
									/>
								</MenuItem>
							</Menu>
							{/* Delete Confirmation Dialog */}
							<DeleteConfirmationDialog
								open={openDialog}
								onClose={() => setOpenDialog(false)}
								onConfirm={handleDeleteConfirm}
								identifierName={row.name || ""}
								type={'Silence'}
							/>
						</Box>
					);
				},
				minWidth: 200
			}
		];
	}

    return (
        <Box sx={{ mt: 3, padding: '20px', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                    ⓘ This section is to create multiple silences for the alert.
                </Typography>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setShowSilenceModal(true)}
                >
                    CREATE SILENCE
                </Button>
            </Box>
            <CustomizedDataGrid
                tableColumns={getColumnsSilence()}
                tableData={maintenanceWindows}
                tableType="Silence"
                defaultColumnNames={defaultColumnSilence}
                listLength={maintenanceWindows?.length || 0}
                isDateRangeFilter={false}
                showExport={false}
                isShowMoreButton={false}
                page={1}
                pageSize={0}
                paginationData={0}
            />
            <DialogueModal
                title={selectedSilence ? 'Edit Silence' : 'Create Silence'}
                isOpen={showSilenceModal}
                handleClose={() => {
                    setShowSilenceModal(false);
                    setSelectedSilence(null);
                }}
            >
                <SilenceModal
                    selectedSilence={selectedSilence}
                    handleClose={() => {
                        setShowSilenceModal(false);
                        setSelectedSilence(null);
                    }}
                    onManageSilences={handleManageSilences}
                />
            </DialogueModal>
        </Box>
    )
}