import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Chip,
	IconButton,
	Menu,
	MenuItem,
	FormControlLabel,
	Switch,
	Typography
} from '@mui/material';
import { CustomizedDataGrid } from '../customized_data_grid';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmationDialog from './DeleteModal';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { activateAlertConfig, deactivateAlertConfig, deleteAlertConfig } from 'service/service';
import { exportToXlsx } from 'components/exportUtils';
export const AlertList = ({ isAllowed, alertConfigs, totalCount, updateAlerts, page, setPage, pageSize, setPageSize ,sortingInfo, setSortingInfo, handleSorting}: any) => {
    const [filterData, setFilterData] = useState<any[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any | null>(null);

    const defaultColumns = ['Rule ID', 'Alert Name', 'Alerts Generated', 'Program', 'Project', 'Sector', 'Alert Type', 'Status', 'Created By', 'Updated By', 'Created At', 'Updated At', 'Action'];

	const handleClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
		setAnchorEl(event.currentTarget);
		setSelectedRow(row);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
    const handleSortingFun = useCallback((sortingInfo: any) => {
        handleSorting({ sortBy: sortingInfo.sortBy, sortDir: sortingInfo.sortDir });
    }, [updateAlerts]);


    const handleToggleStatus = async() => {
		if (selectedRow) {
			if(selectedRow.isActive){
                await deactivateAlertConfig(selectedRow._id)
            }else{
                await activateAlertConfig(selectedRow._id)
            }
            updateAlerts({page, pageSize})
		}        
		handleClose();
	};

    const handleDeleteConfirm = async() => {
        if(selectedRow){
            await deleteAlertConfig(selectedRow._id)
            updateAlerts({page, pageSize})
        }
        setOpenDialog(false);
    };

    const handlePageChange = (data:any, page:any) => {
        setPage(page)
        updateAlerts({page, pageSize})
    }

    const handlePageSizeChange = (event:any) => {
        setPageSize(parseInt(event.target.value))
        updateAlerts({page, pageSize: event.target.value})
    }

    const navigate = useNavigate();

    function getColumns() {
        return [
            { key: "_id", name: "Rule ID", minWidth: 50, width: 180, sortable: false },
            { key: "ruleName", name: "Alert Name", width: 200, minWidth: 150, sortable: false },
            {
                key: "alertsCount",
                name: "Alerts Generated",
                minWidth: 95,
                sortable: false,
                formatter({ row }: any) {
                    return (
                        <Typography
                            sx={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline",
                            }}
                            onClick={() => navigate(`/alert-details/${row._id}`)}
                        >
                            {row.alertsCount}
                        </Typography>
                    );
                },
            },
            { key: "program", name: "Program", minWidth: 95, sortable: true, },
            { key: "project", name: "Project", minWidth: 95, sortable: true, },
            { key: "sector", name: "Sector", minWidth: 95, sortable: true, },
            { key: "alertType", name: "Alert Type", minWidth: 95,sortable: false },
            {
                key: "isActive",
                name: "Status",
                minWidth: 50,
                sortable: false,
                formatter({ row }: any) {
                    return (
                        <Chip 
                            style={{ minWidth: 64, backgroundColor: row.isActive ? "#01C00826" : "#D82B5133", marginTop: 2 }}
                            label={row.isActive ? "ACTIVE" : "INACTIVE"}
                        />
                    );
                },
            },
            { key: "createdByUser", name: "Created By", minWidth: 95,sortable: false },
            { key: "updatedByUser", name: "Updated By", minWidth: 95,sortable: false },
            { key: "createdDt", name: "Created At", minWidth: 95},
            { key: "updatedDt", name: "Updated At", minWidth: 95},
            {
                key: "action",
                name: "Action",
                sortable: false,
                formatter({ row }: any) {                    
                    const handleDeleteClick = () => { 
                        setOpenDialog(true); 
                        handleClose();
                    };
                    return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                                aria-label="edit"
                                size="small"
                                onClick={() => {
                                    navigate(`/alertFramework/${row._id}`)
                                }}
                                disabled={!isAllowed}
                                sx={{ color: "#71758F" }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
    
                            <IconButton
                                aria-label="more"
                                size="small"
                                onClick={(event) => handleClick(event, row)}
                                sx={{ color: "#71758F" }}
                            >
                                <MoreVertIcon fontSize="small" />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                sx={{ "& .MuiMenu-paper": { backgroundColor: "#003771 !important", width: "12%" } }}
                            >
                                <MenuItem disabled={!isAllowed} onClick={handleDeleteClick} sx={{ marginLeft: "25px" }}>
                                    <DeleteIcon />
                                    <span style={{ fontSize: "1.0rem", marginLeft: "6px" }}>Delete</span>
                                </MenuItem>
                                <MenuItem disabled={!isAllowed} onClick={handleToggleStatus} sx={{ marginLeft: '25px' }}>
                                    <FormControlLabel
                                        control={<Switch checked={selectedRow?.isActive} size="small" color="default" />}
                                        label={selectedRow?.isActive ? 'Deactivate' : 'Activate'}
                                    />
                                </MenuItem>
                            </Menu>
                        </Box>
                    );
                }
            },
        ];
    }
    
    return (
		<Box sx={{ mt: 3, padding: '20px', borderRadius: '8px' }}>
			<Typography variant="body2" sx={{ opacity: 0.7 }}>
				All Alerts
			</Typography>
			<CustomizedDataGrid
				tableColumns={getColumns()}
				tableData={alertConfigs || []}
				tableType="AlertList"
				defaultColumnNames={defaultColumns}
				filterDataOptions={filterData}
				listLength={totalCount || 0}
				page={page}
				pageSize={pageSize}
				paginationData={{totalElements: totalCount, totalPages: Math.ceil(totalCount / pageSize)}}
				onSorting={handleSortingFun}
				currSorting={sortingInfo}
				// onFilterCheck={handleFilter}
				// currFilter={filterInfo}
				showExport={true}
				fileName={`AlertConfigs-${moment().format()}.xlsx`}
                exportTableData={() => exportToXlsx(`AlertConfigs-${moment().format()}.xlsx`, alertConfigs, getColumns())}
				// searchDataValues={searchDetails}
				// onFilterSearch={handleSearch}
				// onCellEdit={handleEditCell}
				handlepageSizeChange={handlePageSizeChange}
				handlePageChange={handlePageChange}
				isDateRangeFilter={false}
				isDisabled={false}
			/>
			{/* Delete Confirmation Dialog */}
			<DeleteConfirmationDialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				onConfirm={handleDeleteConfirm}
				identifierName={selectedRow?.ruleName || ""}
				type={''}
			/>
		</Box>
	);
};
