import { Box, Grid, Stack, GridProps, Typography } from '@mui/material';

import NumberWidget from 'components/Widgets/NumberWidget';
import ChartWidget from 'components/Widgets/ChartWidget';

import '../DataEstate/data-estate-dashboard.scss';
import { useEffect, useRef, useState } from 'react';
import {
	fetchAnnualSpendDataSourceIncidents,
	fetchDataProcessingTrends,
	fetchDatabricksClusters,
	fetchDataprocessingSummaryKpi,
	fetchPerformanceData,
	getDecodedToken,
	getSector,
	getDBDatabricks,
	dataProcessingTable,
	fetchDatabricksClustersCount,
	fetchDataprocessingSynapse,
	getDataEstateSector
} from '../../service/service';
import moment from 'moment';
import useStyles from '../jobs/jobs_styles';

import { getPercentageOfTwoNumbers, formatNumberWithCommas } from '../../utils/common-util';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import '../DataEstate/data-estate.scss';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Row, type Column } from 'react-data-grid';
import { getSectorSql } from '../../service/service';
import { isEnvTypePROD } from '../../service/config';
import NoPrgramFound from '../../assets/no-program-found.svg';
import ToolTipIcon from '../../assets/info-icon.svg';
import PageHeader from 'components/Header/Page';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import DataProcIcon from '../../assets/data-processing.svg';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import GuageWidget from 'components/Widgets/GaugeWidget';
import UnderDevelopmentWidget from 'components/Widgets/UnderDevlopmentWidget';
import MultiLineWidget from 'components/Widgets/MultiLineChart';
import SectorFilter from 'components/Dropdown/SectorFilter';
import { sectorListDetails } from 'Context/contexts/cacheData/DataEstate/type';

export default function dataProcessingSummary() {
	const [controller] = useCacheDataController();
	const { teradataStorageLastSyncDate } = controller;

	const [ADFPipeline, SetADFPipeline] = useState(0);
	const [azureSqlUptime, SetAzureSqlUptime] = useState({});
	const [synapseUptime, SetSynapseUptime] = useState({});
	const [teradataJobs, SetTeradataJobs] = useState(0);
	const [teradataJobsLastExecution, SetTeradataJobsLastDec] = useState(0);
	const [teradataJobsCurrent, SetTeradataJobsCurrent] = useState(0);

	const [databricksClusters, SetDatabricksClusters] = useState('-');
	const [databricksClustersLastExecution, SetDatabricksClustersLastExecution] = useState(0);
	const [databricksClustersCurrent, SetDatabricksClustersCurrent] = useState(0);
	const [databricksJobExecutionTimeLoader, SetDatabricksJobExecutionTimeLoader] = useState(true);
	const [databricksJobExecutionTimeChartOptions, SetDatabricksJobExecutionTimeChartOptions] = useState({});
	const [teradataJobExecutionTimeLoader, SetTeradataJobExecutionTimeLoader] = useState(true);
	const [azureUptimeLoader, SetAzureUptimeLoader] = useState(true);
	const [synapseUptimeLoader, SetSynapseUptimeLoader] = useState(true);
	const [teradataJobExecutionTimeChartOptions, SetSetTeradataJobExecutionTimeChartOptions] = useState({});
	const [performanceDBJobExecutionTimeLoader, setPerformanceDBJobExecutionTimeLoader] = useState(true);
	const [performanceDBJobExecutionTimeChartOptions, setPerformanceDBJobExecutionTimeChartOptions] = useState({});

	const [pipelineGrowthLoading, setPipelineGrowthLoading] = useState(true);
	const [pipelineGrowthOptions, setPipelineGrowthOptions] = useState<any>();
	const [clusterGrowthLoading, setClusterGrowthLoading] = useState(true);
	const [clusterGrowthOptions, setClusterGrowthOptions] = useState<any>();
	const [teradataJobsLoading, setTeradataJobsLoading] = useState(true);
	const [teradataJobsOptions, setTeradataJobsOptions] = useState<any>();

	const [lastUpdatedDatePipeline, setLastUpdatedDatePipeline] = useState('');
	const [lastUpdatedDateCluster, setLastUpdatedDateCluster] = useState('');
	const [lastUpdatedDateTeradata, setLastUpdatedDateTeradata] = useState('');

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [filterMonth, setFilterMonth] = useState('0M');
	const [filterDefaultMonth, setFilterDefaultMonth] = useState('0M');
	const [customYear, setCustomYear] = useState(moment().format('YYYY'));
	const [isDateOpen, setIsDateOpen] = useState(false);
	const isCustomMonth = useRef(false);
	const dataFetchedRef = useRef(false);
	const [loading, setLoading] = useState(false);
	// server filter
	const [dateFilter, setDateFilter] = useState<any>('Apr 2024');

	const [adfpipelineBubbleValue, SetAdfpipelineBubbleValue] = useState(0);
	const [teradataBubbleValue, SetTeradataBubbleValue] = useState(0);
	const [databricksBubbleValue, SetDatabricksBubbleValue] = useState(0);

	const [adfpipelineLastDec, SetAdfpipelineLastDec] = useState(0);
	const [adfpipelineCurrent, SetAdfpipelineCurrent] = useState(0);
	const [adfpipelineStartMonth, setAdfpipelineStartMonth] = useState('');
	const [isNoAnnualAdfpipeline, setIsNoAnnualAdfpipeline] = useState(false);

	const [teradataJobStartMonth, setTeradataJobStartMonth] = useState('');
	const [isNoAnnualTeradataJob, setIsNoAnnualTeradataJob] = useState(false);

	let dimension: any = '';
	const [customMonth, setCustomMonth] = useState('');
	const [isDetailVisible, setIsDetailVisible] = useState(false);
	const [sectorName, setSectorName] = useState('all');

	const [allSectorOptionsList, setAllSectorOptionsList] = useState<sectorListDetails | null>(null);
	const [sectorTagList, setSectorTagList] = useState<any>({ datapipeline: true, uptime: true });

	function formatNumber(number: any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return '0.00';
		}
	}

	const applyDateFilter = (selectedDate: any) => {
		getDataProcessingSummary(selectedDate, checkedList);
		getDataProcessingSynapseTime(selectedDate, checkedList);
		loadDatabricksClustersCount(selectedDate);
		loadDatabricksClustersCountExecution(selectedDate);
		setDateFilter(selectedDate);
	};

	async function fetchSectorOptionsData() {
		let sectorKpi = [];
		sectorKpi.push("datapipeline", "uptime");
		const sectorRes = await getDataEstateSector(sectorKpi);
		if (sectorRes.success) {
			if(sectorRes.data) {
				setAllSectorOptionsList(sectorRes.data);
				const allSectors = [
					...sectorRes.data.datapipeline.map((item: any) => item.sector),
					...sectorRes.data.uptime.map((item: any) => item.sector)
				];
				const uniqueSectors = Array.from(new Set(allSectors));
				const sectorNameList = uniqueSectors.map(sector => {
					return sector === "" ? "Blank" : sector ;
				});
				setSectorOptionsList(sectorNameList);
			}
		}
	}

	async function getDataProcessingSynapseTime(selectedDate?: string, sectors?: any, from?: string) {
			setLoading(true);
			SetSynapseUptimeLoader(true);	
		try {
			const res = await fetchDataprocessingSynapse(selectedDate, sectors);
			console.log(res);

			if (res?.data) {
					let SynapseUptimeOption = {
						backgroundColor: '#003771',
						series: [
							// Outer Gauge
							{
								type: 'gauge',
								center: ['50%', '55%'],
								min: 0,
								max: 100,
								splitNumber: 5, // 5 increments for 0, 20, 40, 60, 80, 100
								itemStyle: {
									color: '#e5e5e5'
								},
								progress: {
									show: false
								},
								pointer: {
									show: false
								},
								axisLine: {
									show: true,
									lineStyle: {
										width: 20, // width of the gauge arc
										color: [
											[(res?.data?.synapseUptimeCount / 100).toFixed(2), '#FD7347'], // Orange color for the progress
											[1, '#e5e5e5'] // Light grey color for the remaining part
										]
									}
								},
								axisTick: {
									show: false
								},
								splitLine: {
									show: false
								},
								axisLabel: {
									distance: -50,
									color: '#999',
									fontSize: 12,
									formatter: (value: any) => {
										return `${value.toLocaleString()}%`;
									}
								},
								anchor: {
									show: false
								},
								title: {
									show: false
								},
								detail: {
									valueAnimation: true,
									width: '60%',
									lineHeight: 40,
									borderRadius: 8,
									offsetCenter: [0, 10],
									fontSize: 20,
									formatter: `${res?.data?.synapseUptimeCount.toFixed(2)}%`,
									color: '#e5e5e5'
								},
								data: [
									{
										value: res?.data?.synapseUptimeCount
									}
								]
							},
							// Inner Gauge for showing uptime
							{
								type: 'gauge',
								center: ['50%', '55%'],
								min: 0,
								max: 100,
								itemStyle: {
									color: '#FD7347'
								},
								progress: {
									show: true,
									width: 20
								},
								pointer: {
									show: false
								},
								axisLine: {
									show: false
								},
								axisTick: {
									show: false
								},
								splitLine: {
									show: false
								},
								axisLabel: {
									show: false
								},
								detail: {
									valueAnimation: true,
									width: '60%',
									lineHeight: 40,
									borderRadius: 8,
									offsetCenter: [0, '-20%'],
									fontSize: 14,
									fontWeight: 'normal',
									formatter: 'Uptime',
									color: '#e5e5e5'
								},
								data: [
									{
										value: res?.data?.synapseUptimeCount
									}
								]
							},
						]
					};						
				SetSynapseUptime(SynapseUptimeOption);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
			SetSynapseUptimeLoader(false);
		}
	}

	async function getDataProcessingSummary(selectedDate?: string, sectors?: any, from?: string) {
		// let sector = sectors.length == sectorOptionsList.length ? [] : sectors;
		setLoading(true);
		if(from == "") {
			SetDatabricksJobExecutionTimeLoader(true);
			SetTeradataJobExecutionTimeLoader(true);	
			SetAzureUptimeLoader(true);
		}
		const todaysDate = moment().utc().format('YYYY-MM-DD');
		fetchDataprocessingSummaryKpi(selectedDate, sectors)
			.then((res) => {
				SetADFPipeline(res?.data?.dataPipelineCount);
				setLoading(false);
				if(from == "") {
					if (res?.data?.azureSqlUptimeCount[0]?.VALUE) {
						let AzureSQLUptimeOption = {
							backgroundColor: '#003771',
							series: [
								// Outer Gauge
								{
									type: 'gauge',
									center: ['50%', '55%'],
									min: 0,
									max: 100,
									splitNumber: 5, // 5 increments for 0, 20, 40, 60, 80, 100 
									itemStyle: {
										color: '#e5e5e5'
									},
									progress: {
										show: false
									},
									pointer: {
										show: false
									},
									axisLine: {
										show: true,
										lineStyle: {
											width: 20, // width of the gauge arc
											color: [
												[(res?.data?.azureSqlUptimeCount[0]?.VALUE / 100).toFixed(2), '#FD7347'], // Orange color for the progress
												[1, '#e5e5e5'] // Light grey color for the remaining part
											]
										}
									},
									axisTick: {
										show: false
									},
									splitLine: {
										show: false
									},
									axisLabel: {
										distance: -50,
										color: '#999',
										fontSize: 12,
										formatter: (value: any) => {
											return `${value.toLocaleString()}%`;
										}
									},
									anchor: {
										show: false
									},
									title: {
										show: false
									},
									detail: {
										valueAnimation: true,
										width: '60%',
										lineHeight: 40,
										borderRadius: 8,
										offsetCenter: [0, 10],
										fontSize: 20,
										formatter: `${res?.data?.azureSqlUptimeCount[0]?.VALUE.toFixed(2)}%`,
										color: '#e5e5e5'
									},
									data: [
										{
											value: res?.data?.azureSqlUptimeCount[0]?.VALUE
										}
									]
								},
								// Inner Gauge for showing uptime
								{
									type: 'gauge',
									center: ['50%', '55%'],
									min: 0,
									max: 100,
									itemStyle: {
										color: '#FD7347'
									},
									progress: {
										show: true,
										width: 20
									},
									pointer: {
										show: false
									},
									axisLine: {
										show: false
									},
									axisTick: {
										show: false
									},
									splitLine: {
										show: false
									},
									axisLabel: {
										show: false
									},
									detail: {
										valueAnimation: true,
										width: '60%',
										lineHeight: 40,
										borderRadius: 8,
										offsetCenter: [0, '-20%'],
										fontSize: 14,
										fontWeight: 'normal',
										formatter: 'Uptime',
										color: '#e5e5e5'
									},
									data: [
										{
											value: res?.data?.azureSqlUptimeCount[0]?.VALUE
										}
									]
								},
							]
						};						
					SetAzureSqlUptime(AzureSQLUptimeOption);
					SetAzureUptimeLoader(false);
					}
			}})
			.catch((error) => {
				console.error(error);
			});
		if(from == "") {
		fetchAnnualSpendDataSourceIncidents('teradata-jobs', selectedDate, sectors)
			.then((res) => {
				SetTeradataJobs(res?.data?.result[0]?.count);
			})
			.catch((error) => {
				console.error(error);
			});
		fetchPerformanceData('databricks', selectedDate, sectors)
			.then((res) => {
				setPerformanceDBJobExecutionTimeLoader(false);

				if (res?.data?.interactiveCluster && res?.data?.jobCluster && res?.data?.warehouse && res?.data?.avgMedian) {
					const interactiveClusterData = res.data.interactiveCluster;
					const jobClusterData = res.data.jobCluster;
					const warehouseData = res.data.warehouse;
					const avgMedianData = res.data.avgMedian;

					const interactiveCluster = interactiveClusterData.map((item: { Median: any }) => item.Median / 3600);
					const DBWarehouseData = warehouseData.map((item: { Median: any }) => item.Median / 3600);
					const DBjobClusterData = jobClusterData.map((item: { Median: any }) => item.Median / 3600);
					const DBavgMedianData = avgMedianData.map((item: { avgMedian: any; Median: any }) => item.avgMedian / 3600);

					const dates = interactiveClusterData.map((item: { startDate: string | number | Date }) => {
						const date = new Date(item.startDate);
						return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
					});
					const dataBricksChartData = {
						backgroundColor: '#003771',
						tooltip: {
							trigger: 'axis',
							textStyle: {
								color: '#A7ADCB'
							},
							confine: true,
							backgroundColor: '#003771',
							formatter: function (params: any[]) {
								let tooltipContent = '';
								const date = params[0]?.axisValueLabel;
								params.forEach((item) => {
									tooltipContent += `
									<div><strong>${item.seriesName}</strong>: ${item.value.toFixed(2)}h</div>
									`;
								});
								return `<div><strong>Date:</strong> ${date}</div>` + tooltipContent;
							}
						},
						grid: {
							top: '7%',
							right: '2%'
						},
						xAxis: {
							type: 'category',
							boundaryGap: false,
							data: dates,
							axisLabel: {
								color: '#A7ADCB',
								interval: 0,
								rotate: 25
							},
							splitLine: {
								show: true,
								lineStyle: {
									type: 'dashed',
									color: 'rgba(113, 117, 143, 0.2)',
									width: 1
								}
							}
						},
						yAxis: {
							type: 'value',
							name: 'Exec. Time',
							nameLocation: 'middle',
							nameGap: 25,
							nameTextStyle: {
								color: '#A7ADCB',
								fontSize: 10,
								align: 'center'
							},
							min: 'dataMin',
							// max: "dataMax",
							axisLabel: {
								color: '#A7ADCB',
								formatter: function (value: number) {
									if (value < 1) {
										return `${(value * 60).toFixed(0)} m`;
									}
									return `${value.toFixed(0)}h`;
								}
							},
							splitLine: {
								show: true,
								lineStyle: {
									type: 'dashed',
									color: 'rgba(113, 117, 143, 0.2)',
									width: 1
								}
							}
						},
						series: [
							{
								name: 'Interactive Cluster',
								type: 'line',
								data: interactiveCluster,
								color: 'rgba(24, 165, 80, 1)'
							},
							{
								name: 'SQL Warehouse',
								type: 'line',
								data: DBWarehouseData,
								color: 'rgba(253, 120, 59, 1)'
							},
							{
								name: 'Job Cluster',
								type: 'line',
								data: DBjobClusterData,
								color: 'rgba(41, 155, 235, 1)'
							},
							{
								name: 'Databricks Avg Execution',
								type: 'line',
								data: DBavgMedianData,
								color: 'rgba(236, 48, 87, 1)'
							}
						],
						legend: {
							orient: 'horizontal',
							bottom: '1%',
							left: '2%',
							textStyle: {
								color: '#A7ADCB'
							},
							selectedMode: false,
							itemWidth: 10,
							itemHeight: 10,
							icon: 'roundRect'
						}
					};

					setPerformanceDBJobExecutionTimeChartOptions(dataBricksChartData);
				}
			})
			.catch((error) => {
				console.error(error);
			});

		fetchPerformanceData('teradata', selectedDate, sectors)
			.then((res) => {
				SetTeradataJobExecutionTimeLoader(false);
				// if (res?.data?.result[0]?.avg_execution) {
				// 	let teradataOption = {
				// 		backgroundColor: '#003771',
				// 		series: [
				// 			{
				// 				type: 'gauge',
				// 				center: ['50%', '55%'],
				// 				min: 0,
				// 				max: Math.floor(res?.data?.result[0]?.avg_execution * 1.1),
				// 				splitNumber: 5,
				// 				itemStyle: {
				// 					color: '#e5e5e5'
				// 				},
				// 				progress: {
				// 					show: false
				// 				},
				// 				pointer: {
				// 					show: false
				// 				},
				// 				axisTick: {
				// 					distance: -55,
				// 					splitNumber: 8,
				// 					lineStyle: {
				// 						width: 0,
				// 						color: '#999'
				// 					}
				// 				},
				// 				splitLine: {
				// 					distance: -30,
				// 					length: 14,
				// 					lineStyle: {
				// 						width: 0,
				// 						color: '#999'
				// 					}
				// 				},
				// 				axisLabel: {
				// 					distance: -35,
				// 					color: '#999',
				// 					fontSize: 14,
				// 					formatter: (value: any) => {
				// 						return `${Math.floor(value).toLocaleString()}`;
				// 					}
				// 				},
				// 				anchor: {
				// 					show: false
				// 				},
				// 				title: {
				// 					show: false
				// 				},
				// 				detail: {
				// 					valueAnimation: true,
				// 					width: '60%',
				// 					lineHeight: 40,
				// 					borderRadius: 8,
				// 					offsetCenter: [0, 30],
				// 					fontSize: 30,
				// 					formatter: `${Math.floor(res?.data?.result[0]?.avg_execution).toLocaleString()}`,
				// 					color: '#e5e5e5'
				// 				},
				// 				data: [
				// 					{
				// 						value: res?.data?.result[0]?.avg_execution
				// 					}
				// 				]
				// 			},
				// 			{
				// 				type: 'gauge',
				// 				center: ['50%', '55%'],
				// 				min: 0,
				// 				max: Math.floor(res?.data?.result[0]?.avg_execution * 1.1),
				// 				itemStyle: {
				// 					color: '#FD7347'
				// 				},
				// 				progress: {
				// 					show: true,
				// 					width: 10
				// 				},
				// 				pointer: {
				// 					show: false
				// 				},
				// 				axisLine: {
				// 					show: false
				// 				},
				// 				axisTick: {
				// 					show: false
				// 				},
				// 				splitLine: {
				// 					show: false
				// 				},
				// 				axisLabel: {
				// 					show: false
				// 				},
				// 				detail: {
				// 					valueAnimation: true,
				// 					width: '60%',
				// 					lineHeight: 40,
				// 					borderRadius: 8,
				// 					offsetCenter: [0, '-30%'],
				// 					fontSize: 14,
				// 					fontWeight: 'normal',
				// 					formatter: 'Avg Execution',
				// 					color: '#e5e5e5'
				// 				},
				// 				data: [
				// 					{
				// 						value: res?.data?.result[0]?.avg_execution
				// 					}
				// 				]
				// 			},
				// 			{
				// 				type: 'gauge',
				// 				center: ['50%', '55%'],
				// 				min: 0,
				// 				max: Math.floor(res?.data?.result[0]?.avg_execution * 1.1),
				// 				splitNumber: 5,
				// 				itemStyle: {
				// 					color: '#e5e5e5'
				// 				},
				// 				progress: {
				// 					show: false
				// 				},
				// 				pointer: {
				// 					show: false
				// 				},
				// 				axisTick: {
				// 					distance: -55,
				// 					splitNumber: 8,
				// 					lineStyle: {
				// 						width: 0,
				// 						color: '#999'
				// 					}
				// 				},
				// 				splitLine: {
				// 					distance: -30,
				// 					length: 14,
				// 					lineStyle: {
				// 						width: 0,
				// 						color: '#999'
				// 					}
				// 				},
				// 				axisLabel: {
				// 					distance: -35,
				// 					color: '#999',
				// 					fontSize: 14,
				// 					formatter: (value: any) => {
				// 						return `${Math.floor(value).toLocaleString()}`;
				// 					}
				// 				},
				// 				anchor: {
				// 					show: false
				// 				},
				// 				title: {
				// 					show: false
				// 				},
				// 				detail: {
				// 					valueAnimation: true,
				// 					width: '60%',
				// 					lineHeight: 40,
				// 					borderRadius: 8,
				// 					offsetCenter: [0, 65],
				// 					fontSize: 14,
				// 					fontWeight: 'normal',
				// 					formatter: 'seconds',
				// 					color: '#e5e5e5'
				// 				},
				// 				data: [
				// 					{
				// 						value: res?.data?.result[0]?.avg_execution
				// 					}
				// 				]
				// 			}
				// 		]
				// 	};
					// SetSetTeradataJobExecutionTimeChartOptions(teradataOption);
				// }
				const resp = {
					"data": {
					  "controlM": [
						{ "startDate": "2025-03-27T00:00:00.000Z", "Median": 26256.666666666668 },
						{ "startDate": "2025-03-28T00:00:00.000Z", "Median": 24062.5 },
						{ "startDate": "2025-03-29T00:00:00.000Z", "Median": 31844.25806451613 },
						{ "startDate": "2025-03-30T00:00:00.000Z", "Median": 25387.5 },
						{ "startDate": "2025-03-31T00:00:00.000Z", "Median": 29011.5 },
						{ "startDate": "2025-04-01T00:00:00.000Z", "Median": 24540 },
						{ "startDate": "2025-04-02T00:00:00.000Z", "Median": 16665.407608695652 }
					  ],
					  "autosys": [
						{ "startDate": "2025-03-27T00:00:00.000Z", "Median": 676.2780269058296 },
						{ "startDate": "2025-03-28T00:00:00.000Z", "Median": 677.3333333333334 },
						{ "startDate": "2025-03-29T00:00:00.000Z", "Median": 694.8675711467258 },
						{ "startDate": "2025-03-30T00:00:00.000Z", "Median": 680.5555555555555 },
						{ "startDate": "2025-03-31T00:00:00.000Z", "Median": 697.7660972404731 },
						{ "startDate": "2025-04-01T00:00:00.000Z", "Median": 666.3977398611667 },
						{ "startDate": "2025-04-02T00:00:00.000Z", "Median": 623.7350172954709 }
					  ],
					  "teradataavgexe": [
						{ "startDate": "2025-03-27T00:00:00.000Z", "Median": 49645.28 },
						{ "startDate": "2025-03-28T00:00:00.000Z", "Median": 52604.01 },
						{ "startDate": "2025-03-29T00:00:00.000Z", "Median": 611638.0608333333 },
						{ "startDate": "2025-03-30T00:00:00.000Z", "Median": 591161.03 },
						{ "startDate": "2025-03-31T00:00:00.000Z", "Median": 116854.81 },
						{ "startDate": "2025-04-01T00:00:00.000Z", "Median": 18361.18 },
						{ "startDate": "2025-04-02T00:00:00.000Z", "Median": 81084.05833333333 }
					  ]
					}
				  };
				if (resp?.data?.controlM && resp?.data?.autosys && resp?.data?.teradataavgexe) {
					const controlMData = resp.data.controlM;
					const autosysData = resp.data.autosys;
					const teradataavgexeData = resp.data.teradataavgexe;

					const ControlM = controlMData.map((item: { Median: any }) => item.Median / 3600);
					const Autosys = autosysData.map((item: { Median: any }) => item.Median / 3600);
					const TeraDataAvgExe = teradataavgexeData.map((item: { Median: any }) => item.Median / 3600);

					const dates = controlMData.map((item: { startDate: string | number | Date }) => {
						const date = new Date(item.startDate);
						return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
					});
					const teradataOption = {
						backgroundColor: '#003771',
						tooltip: {
							trigger: 'axis',
							textStyle: {
								color: '#A7ADCB'
							},
							confine: true,
							backgroundColor: '#003771',
							formatter: function (params: any[]) {
								let tooltipContent = '';
								const date = params[0]?.axisValueLabel;
								params.forEach((item) => {
									tooltipContent += `
									<div><strong>${item.seriesName}</strong>: ${item.value.toFixed(2)}h</div>
									`;
								});
								return `<div><strong>Date:</strong> ${date}</div>` + tooltipContent;
							}
						},
						grid: {
							top: '7%',
							right: '2%'
						},
						xAxis: {
							type: 'category',
							boundaryGap: false,
							data: dates,
							axisLabel: {
								color: '#A7ADCB',
								interval: 0,
								rotate: 25
							},
							splitLine: {
								show: true,
								lineStyle: {
									type: 'dashed',
									color: 'rgba(113, 117, 143, 0.2)',
									width: 1
								}
							}
						},
						yAxis: {
							type: 'value',
							name: 'Exec. Time',
							nameLocation: 'middle',
							nameGap: 40,
							nameTextStyle: {
								color: '#A7ADCB',
								fontSize: 10,
								align: 'center'
							},
							min: 'dataMin',
							// max: "dataMax",
							axisLabel: {
								color: '#A7ADCB',
								formatter: function (value: number) {
									if (value < 1) {
										return `${(value * 60).toFixed(0)} m`;
									}
									return `${value.toFixed(0)}h`;
								}
							},
							splitLine: {
								show: true,
								lineStyle: {
									type: 'dashed',
									color: 'rgba(113, 117, 143, 0.2)',
									width: 1
								}
							}
						},
						series: [
							{
								name: 'Control M',
								type: 'line',
								data: ControlM,
								color: 'rgba(24, 165, 80, 1)'
							},
							{
								name: 'Autosys',
								type: 'line',
								data: Autosys,
								color: 'rgba(41, 155, 235, 1)'
							},
							{
								name: 'Teradata Avg Exe.',
								type: 'line',
								data: TeraDataAvgExe,
								color: 'rgba(236, 48, 87, 1)'
							}
						],
						legend: {
							orient: 'horizontal',
							bottom: '1%',
							left: '2%',
							textStyle: {
								color: '#A7ADCB'
							},
							selectedMode: false,
							itemWidth: 10,
							itemHeight: 10,
							icon: 'roundRect'
						}
					};

					SetSetTeradataJobExecutionTimeChartOptions(teradataOption);
				}
			})
			.catch((error) => {
				console.error(error);
			});
		}
		fetchDataProcessingTrends('adfpipelinegrowth', selectedDate, sectors)
			.then((res) => {
				if (res.data) {
					setPipelineGrowthOptions(res.data.data);
					setPipelineGrowthLoading(false);
					setLastUpdatedDatePipeline(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				}
			})
			.catch((error) => {
				console.error(error);
			});
		if(from == "") {
		fetchDataProcessingTrends('teradataJobsgrowth', selectedDate, sectors)
			.then((res) => {
				if (res.data) {
					setTeradataJobsOptions(res.data.data);
					setTeradataJobsLoading(false);
					setLastUpdatedDateTeradata(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				}
			})
			.catch((error) => {
				console.error(error);
			});
		}
		// const monthList = ["2024-01-30", "2024-02-28", "2024-03-31", "2024-04-30", "2024-05-30", "2024-06-09"];

		// Databricks Cluster
		fetchDataProcessingTrends('clustergrowth', selectedDate, sectors)
			.then((res) => {
				if (res.data) {
					setClusterGrowthOptions(res.data.data);
					setClusterGrowthLoading(false);
					setLastUpdatedDateCluster(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	// fetchPerformanceData('databricks', selectedDate, sector).then(res => {
	async function getDatabricksExecutionData(exeCount: number) {
		SetDatabricksJobExecutionTimeLoader(false);
		let databricksOption = {
			backgroundColor: '#003771',
			series: [
				{
					type: 'gauge',
					center: ['50%', '55%'],
					min: 0,
					max: Math.floor(exeCount * 1.1),
					splitNumber: 5,
					itemStyle: {
						color: '#e5e5e5'
					},
					progress: {
						show: false
					},
					pointer: {
						show: false
					},
					axisTick: {
						distance: -55,
						splitNumber: 8,
						lineStyle: {
							width: 0,
							color: '#999'
						}
					},
					splitLine: {
						distance: -30,
						length: 14,
						lineStyle: {
							width: 0,
							color: '#999'
						}
					},
					axisLabel: {
						distance: -35,
						color: '#999',
						fontSize: 14,
						formatter: (value: any) => {
							return Math.floor(value).toLocaleString();
						}
					},
					anchor: {
						show: false
					},
					title: {
						show: false
					},
					detail: {
						valueAnimation: true,
						width: '60%',
						lineHeight: 40,
						borderRadius: 8,
						offsetCenter: [0, 30],
						fontSize: 30,
						formatter: Math.floor(exeCount).toLocaleString(),
						color: '#e5e5e5'
					},
					data: [
						{
							value: Math.floor(exeCount)
						}
					]
				},
				{
					type: 'gauge',
					center: ['50%', '55%'],
					min: 0,
					max: Math.floor(exeCount * 1.1),
					itemStyle: {
						color: '#FD7347'
					},
					progress: {
						show: true,
						width: 10
					},
					pointer: {
						show: false
					},
					axisLine: {
						show: false
					},
					axisTick: {
						show: false
					},
					splitLine: {
						show: false
					},
					axisLabel: {
						show: false
					},
					detail: {
						valueAnimation: true,
						width: '60%',
						lineHeight: 40,
						borderRadius: 8,
						offsetCenter: [0, '-30%'],
						fontSize: 14,
						fontWeight: 'normal',
						formatter: 'Avg Execution',
						color: '#e5e5e5'
					},
					data: [
						{
							value: exeCount
						}
					]
				}
			]
		};
		SetDatabricksJobExecutionTimeChartOptions(databricksOption);
		// }).catch(error => {
		// 	console.error(error);
		// });
	}

	async function loadDatabricksAnnualIndicator() {
		let startDate = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
		let endDate = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD');

		try {
			const res = await fetchDatabricksClustersCount(startDate, endDate, true);
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let currentYearCount = res.data.databricksClustersCount.reduce(
						(count: any, item: any) => count + item.count,
						0
					);
					loadDatabricksLastYearData(currentYearCount);
					SetDatabricksClustersCurrent(currentYearCount);
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function loadDatabricksLastYearData(currentYearCount: number) {
		let lastyearStartDate = moment().subtract(1, 'year').subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
		let lastYearEndDate = moment().subtract(1, 'year').subtract(1, 'months').format('yyyy-MM-DD');

		// let lastyearStartDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
		// let lastYearEndDate = moment().subtract(1, 'year').endOf('year').format('yyyy-MM-DD');

		try {
			const res = await fetchDatabricksClustersCount(lastyearStartDate, lastYearEndDate, true);
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let lastYearCount = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.count, 0);
					SetDatabricksClustersLastExecution(lastYearCount);
					SetDatabricksBubbleValue(getPercentageOfTwoNumbers(lastYearCount, currentYearCount));
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function loadDatabricksClustersCount(selectedDateFilter?: string) {
		let startDate: any;
		let endDate: any;
		if (selectedDateFilter === '0M') {
			startDate = moment().startOf('month').format('yyyy-MM-DD');
			endDate = moment().format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1M') {
			startDate = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '3M') {
			startDate = moment().subtract(2, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '6M') {
			startDate = moment().subtract(5, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '9M') {
			startDate = moment().subtract(8, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '0Y') {
			startDate = moment().subtract(0, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'year').endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '2Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		setLoading(true);
		try {
			const res = await fetchDatabricksClustersCount(startDate, endDate, true, 'databricksCluster');
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let count = res.data.databricksClustersCount.reduce((count: any, item: any) => count + item.count, 0);
					count = count / 1000000;
					SetDatabricksClusters(count.toFixed(2));
				} else {
					SetDatabricksClusters('-');
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	async function loadDatabricksClustersCountExecution(selectedDateFilter?: string) {
		let startDate: any;
		let endDate: any;
		if (selectedDateFilter === '0M') {
			startDate = moment().startOf('month').format('yyyy-MM-DD');
			endDate = moment().format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1M') {
			startDate = moment().subtract(1, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'months').endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '3M') {
			startDate = moment().subtract(2, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '6M') {
			startDate = moment().subtract(5, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '9M') {
			startDate = moment().subtract(8, 'months').startOf('month').format('yyyy-MM-DD');
			endDate = moment().endOf('month').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '0Y') {
			startDate = moment().subtract(0, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '1Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().subtract(1, 'year').endOf('year').format('yyyy-MM-DD');
		}
		if (selectedDateFilter === '2Y') {
			startDate = moment().subtract(1, 'year').startOf('year').format('yyyy-MM-DD');
			endDate = moment().endOf('year').format('yyyy-MM-DD');
		}
		setLoading(true);
		try {
			const res = await fetchDatabricksClustersCount(startDate, endDate);
			if (res.success) {
				if (res.data.databricksClustersCount.length) {
					let sumOfExeCount = res.data.databricksClustersCount.reduce(
						(count: any, item: any) =>
							count + item.jobSumOfRunTimeSec + item.sqlSumOfRunTimeSec + item.interactiveSumOfRunTimeSec,
						0
					);
					let countOfExeCount = res.data.databricksClustersCount.reduce(
						(count: any, item: any) =>
							count + item.jobCountOfRunTimeSec + item.sqlCountOfRunTimeSec + item.interactiveCountOfRunTimeSec,
						0
					);
					let exeCount = sumOfExeCount / countOfExeCount;
					getDatabricksExecutionData(exeCount);
				} else {
					getDatabricksExecutionData(0);
				}
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/data-processing') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		// if (userSector === 'all') 
		fetchSectorOptionsData();
		getDataProcessingSummary(filterMonth, sectorName, '');
		loadDatabricksClustersCount();
		loadDatabricksClustersCountExecution();
		loadDatabricksAnnualIndicator();
		getDataProcessingSynapseTime(filterMonth, sectorName, '');
	}, []);
	const [tableName, setTableName] = useState('');
	interface ADF {
		azureSubscription: string;
		resourceGroup: string;
		pipelineName: string;
		pipelineStatus: string;
		runStart: string;
		runEnd: string;
		durationInMs: string;
		lastUpdated: string;
	}
	interface teraDataJobs {
		jobName: string;
		boxName: string;
		sector: string;
		system: string;
		process: string;
		critical: string;
		lastSuccessfulRun: string;
		normalCompletionTime: string;
		observedSince: string;
	}
	interface dataBricks {
		workspace_name: string;
		cluster_name: string;
		cluster_category: string;
		total_dbus: string;
		project_name: string;
		job_name: string;
		timestamp_state_start: string;
		timestamp_state_end: string;
		sector_name: string;
	}
	interface Row {}
	function getColumns(): Array<Column<Row>> {
		if (typeName == 'ADF') {
			return [
				{
					key: 'azureSubscription',
					name: 'Azure Subscription',
					minWidth: 250
				},
				{
					key: 'resourceGroup',
					name: 'Resource Group',
					minWidth: 200
				},
				{
					key: 'pipelineName',
					name: 'Pipeline Name',
					minWidth: 200
				},
				{
					key: 'pipelineStatus',
					name: 'Pipeline Status',
					minWidth: 250
				},
				{
					key: 'runStart',
					name: 'Run Start',
					minWidth: 200
				},
				{
					key: 'runEnd',
					name: 'Run End',
					minWidth: 200
				},
				{
					key: 'durationInMs',
					name: 'Duration In MS',
					minWidth: 250
				},
				{
					key: 'lastUpdated',
					name: 'Last Updated',
					minWidth: 250
				}
			];
		} else if (typeName == 'TeraData') {
			return [
				{
					key: 'jobName',
					name: 'Job Name',
					minWidth: 180
				},
				{
					key: 'boxName',
					name: 'Box Name',
					minWidth: 180
				},
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 170
				},
				{
					key: 'system',
					name: 'System',
					minWidth: 180
				},
				{
					key: 'process',
					name: 'Process',
					minWidth: 180
				},
				{
					key: 'critical',
					name: 'Critical',
					minWidth: 180
				},
				{
					key: 'lastSuccessfulRun',
					name: 'Last successful run',
					minWidth: 250
				},
				{
					key: 'normalCompletionTime',
					name: 'Normal completion Time',
					minWidth: 250
				},
				{
					key: 'observedSince',
					name: 'Observed since',
					minWidth: 250
				}
			];
		} else if (typeName == 'dataBricks') {
			return [
				{
					key: 'workspace_name',
					name: 'Project name',
					minWidth: 250
				},
				{
					key: 'cluster_name',
					name: 'Workspace',
					minWidth: 250
				},
				{
					key: 'cluster_category',
					name: 'Cluster name',
					minWidth: 250
				},
				{
					key: 'total_dbus',
					name: 'Job Name',
					minWidth: 250
				},
				{
					key: 'project_name',
					name: 'Job Run Time',
					minWidth: 250
				},
				{
					key: 'job_name',
					name: 'Job Status',
					minWidth: 250
				},
				{
					key: 'timestamp_state_start',
					name: 'Timestamp State Start',
					minWidth: 170
				},
				{
					key: 'timestamp_state_end',
					name: 'Timestamp State End',
					minWidth: 170
				},
				{
					key: 'sector_name',
					name: 'Start Time',
					minWidth: 170
				}
			];
		} else {
			return [];
		}
	}
	const ADFPipelinesColumns = [
		'Azure Subscription',
		'Resource Group',
		'Pipeline Name',
		'Pipeline Status',
		'Run Start',
		'Run End',
		'Duration In MS',
		'Last Updated'
	];
	const teraDataJobsColumns = [
		'Job Name',
		'Box Name',
		'Sector',
		'System',
		'Process',
		'Critical',
		'Last successful run',
		'Normal completion Time',
		'Observed since'
	];
	const dataBricksColumns = [
		'Project name',
		'Workspace',
		'Cluster name',
		'Job Name',
		'Job Run Time',
		'Job Status',
		'Timestamp State End',
		'Timestamp State End',
		'Start Time'
	];
	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isTableVisible, setIsTableVisible] = useState(false);

	const [tableData, setTableData] = useState<Row[]>([]);
	const [tableHeading, setTableHeading] = useState('');
	const [typeName, setTypeName] = useState('');

	async function processingListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		let month = customMonth ? customMonth : filterMonth;
		const res = await dataProcessingTable('dataProcessing', dimension, sector, month);
		if (res.success) {
			setTableData(res.data.summary);
			if (res.data.page) {
				setPaginationData(res.data.page);
			}
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}

	async function getDBDatabricksData(pageNumber: any, size: any, sortingInfo: any) {
		let startDate: any;
		let endDate: any;
		if (filterMonth === '0M') {
			startDate = moment();
			endDate = moment();
		}
		if (filterMonth === '1M') {
			startDate = moment().subtract(1, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '3M') {
			startDate = moment().subtract(3, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '6M') {
			startDate = moment().subtract(6, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '9M') {
			startDate = moment().subtract(9, 'months').startOf('month');
			endDate = moment().endOf('month');
		}
		if (filterMonth === '0Y') {
			startDate = moment().subtract(0, 'year').startOf('year');
			endDate = moment().endOf('year');
		}
		if (filterMonth === '1Y') {
			startDate = moment().subtract(1, 'year').startOf('year');
			endDate = moment().endOf('year');
		}
		if (filterMonth === '2Y') {
			startDate = moment().subtract(2, 'year').startOf('year');
			endDate = moment().endOf('year');
		}
		let sector: any = [];
		let filterInfo: any = [];

		const res = await getDBDatabricks(pageNumber, size, sortingInfo, filterInfo, startDate, endDate, sector);

		if (res.success) {
			setTableData(res.data.result);
			if (res.data.page) {
				setPaginationData(res.data.page);
			}

			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}

	const handleSorting = (sortingInfo: any) => {
		processingListingFunc(page, pageSize, sortingInfo);
	};
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		processingListingFunc(value, pageSize, sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		processingListingFunc(page, event.target.value, sortingInfo);
	};
	const handleClick = (typeName: any) => {
		setDynamicValue([]);
		setTableData([]);
		setIsTableVisible(true);
		if (typeName == 'ADF') {
			console.log('default before', defaultColumnNames);
			setDynamicValue(ADFPipelinesColumns);
			setTableHeading('ADF Pipelines');
			type Row = keyof ADF;
			setTypeName('ADF');
			dimension = 'adfpipeline';
			console.log('default', defaultColumnNames);
			processingListingFunc(page, pageSize, sortingInfo);
		}
		if (typeName === 'Teradata') {
			console.log('default before', defaultColumnNames);
			setDynamicValue(teraDataJobsColumns);
			setTableHeading('TeraData Jobs');
			type Row = keyof teraDataJobs;
			setTypeName('TeraData');
			dimension = 'teradataJobs';
			console.log('default', defaultColumnNames);
			processingListingFunc(page, pageSize, sortingInfo);
		}
		if (typeName === 'dataBricks') {
			console.log('default before', defaultColumnNames);
			setDynamicValue(dataBricksColumns);
			setTableHeading('Databricks clusters');
			type Row = keyof teraDataJobs;
			setTypeName('dataBricks');
			console.log('default', defaultColumnNames);
			getDBDatabricksData(page, pageSize, sortingInfo);
		}
	};

	const clearMetricsSummary = () => {
		// setDynamicValue(['']);
		setTableData([]);
		setIsDetailVisible(false);
	};

	const handleSectorChange = (value: string) => {
		setLoading(true);
		setSectorName(value); 
		sectorTagUpdatefun(value);
	};

	const sectorTagUpdatefun = (sector: string) => {
		if(sector != 'all') {
			if(sector == 'Blank')
				sector = '';

			const isInDatapipeline = allSectorOptionsList?.datapipeline.some(item => item.sector === sector);
			const isInUptime = allSectorOptionsList?.uptime.some(item => item.sector === sector);
			const filteredData = {
				datapipeline: isInDatapipeline || false,
				uptime: isInUptime || false
			};
			setSectorTagList(filteredData);
			getDataProcessingSummary(filterMonth, sector, 'sector');
			if(isInUptime)
				getDataProcessingSynapseTime(filterMonth, sector, 'sector');
		} else {
			const filteredData = {
				datapipeline: true,
				uptime: true
			};
			setSectorTagList(filteredData);
			getDataProcessingSummary(filterMonth, sector, 'sector');
			getDataProcessingSynapseTime(filterMonth, sector, 'sector');
		}
	}

	return (
		<Box mt="45px">
			<PageHeader title="Data Processing Summary" icon={DataProcIcon} lastSync={teradataStorageLastSyncDate}>
				{/* <DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={applyDateFilter}
				/> */}
				<SectorFilter  
					sectorName={sectorName}
					setSectorName={setSectorName}
					sectorOptionsList={sectorOptionsList}
					handleChange={handleSectorChange} 
				/>
			</PageHeader>
			<Grid container spacing={1} p={2} mt={2}>
				<Grid item xs={12} lg={8}>
					<WidgetCardContainer title="Data Processing">
						<Grid item xs={12} md={6}>
							<NumberWidget
								title="ADF Pipelines"
								tooltipLabel={''}
								hasTooltip={false}
								number={ADFPipeline ? formatNumber((ADFPipeline / 1000).toFixed(2).toString()) : '-'}
								units={ADFPipeline ? 'k' : ''}
								isLoading={ADFPipeline ? ADFPipeline.toString() === '-' : false}
								handleEvent={!isEnvTypePROD ? () => handleClick('ADF') : undefined}
								sectorLabel={sectorTagList?.datapipeline == true ? sectorName == 'all' ? '' : sectorName : ''}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<NumberWidget
								title="Teradata Jobs"
								tooltipLabel={''}
								hasTooltip={false}
								number={teradataJobs ? formatNumber((teradataJobs / 1000).toFixed(2).toString()) : ''}
								units={teradataJobs ? 'k' : ''}
								isLoading={teradataJobs?.toString() === '-'}
								handleEvent={!isEnvTypePROD ? () => handleClick('Teradata') : undefined}
							/>
						</Grid>
						{/* </Grid> */}

						{/* <Grid container spacing={2}> */}
						<Grid item xs={12} md={6}>
							<ChartWidget
								title="Azure Pipeline Trend"
								data={pipelineGrowthOptions}
								isLoading={pipelineGrowthLoading}
								lastUpdatedDate={lastUpdatedDatePipeline}
								sectorLabel={sectorTagList?.datapipeline == true ? sectorName == 'all' ? '' : sectorName : ''}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<ChartWidget
								title="Teradata Job Trend"
								data={teradataJobsOptions}
								isLoading={teradataJobsLoading}
								lastUpdatedDate={lastUpdatedDateTeradata}
							/>
						</Grid>
						{/* </Grid> */}
					</WidgetCardContainer>
				</Grid>
				<Grid item xs={12} lg={4}>
					<WidgetCardContainer title="Compute Clusters">
						<Grid item xs={12}>
							<NumberWidget
								title="Databricks"
								tooltipLabel={''}
								hasTooltip={false}
								number={databricksClusters ? formatNumber(databricksClusters) : 0}
								units={databricksClusters ? 'MM' : ''}
								isLoading={databricksClusters?.toString() === '-'}
								handleEvent={!isEnvTypePROD ? () => handleClick('dataBricks') : undefined}
							/>
						</Grid>

						<Grid item xs={12} className="">
							<ChartWidget
								title="Databricks Trend"
								data={clusterGrowthOptions}
								isLoading={clusterGrowthLoading}
								lastUpdatedDate={lastUpdatedDateCluster}
								restrictToMillions={true}
								isDatabrick={true}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid item xs={12} lg={7}>
					<WidgetCardContainer title="Performance">
						<Grid item xs={12} md={6}>
							<MultiLineWidget
								title="Databricks Job Execution Time"
								isLoading={performanceDBJobExecutionTimeLoader}
								data={performanceDBJobExecutionTimeChartOptions}
							/>
							{/* <UnderDevelopmentWidget title="Databricks Job Execution Time" /> */}
						</Grid>
						<Grid item xs={12} md={6}>
							{/* <GuageWidget
								title="Teradata Job Execution Time"
								isLoading={teradataJobExecutionTimeLoader}
								data={teradataJobExecutionTimeChartOptions}
							/> */}
							<MultiLineWidget
								title="Teradata Job Execution Time"
								isLoading={teradataJobExecutionTimeLoader}
								data={teradataJobExecutionTimeChartOptions}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid item xs={12} lg={5}>
					<WidgetCardContainer title="Reliability">
						<Grid item xs={12} md={6}>
							{/* <NumberWidget
								title="Azure SQL Uptime"
								tooltipLabel={''}
								hasTooltip={false}
								number={azureSqlUptime ? azureSqlUptime : '0'}
								units={'%'}
								isLoading={azureUptimeLoader}
							/> */}
							<GuageWidget
								title="Azure SQL Uptime"
								isLoading={azureUptimeLoader}
								data={azureSqlUptime}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							{/* <NumberWidget
								title="Synapse Uptime"
								tooltipLabel={''}
								hasTooltip={false}
								number={synapseUptime ? `${formatNumber(synapseUptime)}` : '0'}
								units={'%'}
								isLoading={synapseUptimeLoader}
								sectorLabel={sectorTagList?.uptime == true ? sectorName == 'all' ? '' : sectorName : ''}
							/> */}
							<GuageWidget
								title="Synapse Uptime"
								isLoading={synapseUptimeLoader}
								data={synapseUptime}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				{isTableVisible && (
					<Grid item xs={12}>
						<WidgetCardContainer title={tableHeading}>
							<Grid>
								{tableData && tableData.length > 0 ? (
									<CustomizedDataGrid
										tableColumns={getColumns()}
										tableData={tableData}
										tableType="sector"
										defaultColumnNames={defaultColumnNames}
										onSorting={handleSorting}
										currSorting={sortingInfo}
										page={page}
										pageSize={pageSize}
										paginationData={paginationData}
										listLength={tableData.length}
										handlepageSizeChange={handlepageSizeChange}
										handlePageChange={handlePageChange}
										isDateRangeFilter={false}
										isDisabled={false}
										isFetching={true}
									/>
								) : (
									<span>No data found </span>
								)}
							</Grid>
						</WidgetCardContainer>
					</Grid>
				)}
			</Grid>
		</Box>
	);
}
