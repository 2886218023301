import React, { useState, useRef, useEffect } from 'react';
import { CardMedia, Box, Container, Grid, Typography, Button, TextField } from '@mui/material';
import { Add, AttachFile } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Insight {
	id: number;
	title: string;
	description: string;
	// createdAt: string;
}
interface Insights {
	insightId: number;
	title: string;
	description: string;
	opsType: string;
	createdBy: string;
	updatedBy: string;
	createdDate: string;
	updatedDate: string;
}
const formatCSTTime = (isoDate: string) => {
	const date = new Date(isoDate);
	const options: Intl.DateTimeFormatOptions = {
		month: 'short',
		day: 'numeric',
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
		timeZone: 'America/Chicago'
	};
	return `Created: ${date.toLocaleString('en-US', options)} CST`;
};

const modules = {
	toolbar: [
		['bold', 'italic', 'underline'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['image']
	]
};

const formats = ['bold', 'italic', 'underline', 'list', 'bullet', 'indent', 'image'];

const Insights: React.FC<{
	insights: Insights[];
	setInsights: React.Dispatch<React.SetStateAction<Insights[]>>;
	onAdd: (insight: Insight) => void;
	onUpdate: (insight: Insight) => void;
	onDelete: (id: number) => void;
}> = ({ insights, setInsights, onAdd, onUpdate, onDelete }) => {
	const [currentInsight, setCurrentInsight] = useState<Insights | null>(null);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [showForm, setShowForm] = useState(false);
	const formRef = useRef<HTMLDivElement>(null);
	const quillRef = useRef<ReactQuill>(null);

	const handleOpen = (insight?: Insights) => {
		if (insight) {
			setCurrentInsight(insight);
			setTitle(insight.title);
			setDescription(insight.description);
		} else {
			setCurrentInsight(null);
			setTitle('');
			setDescription('');
		}
		setShowForm(true);
		setTimeout(() => {
			formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 100);
	};

	const handleSave = () => {
		if (currentInsight) {
			const newInsight: Insight = {
				id: currentInsight.insightId,
				title,
				description
			};
			
			onUpdate(newInsight);
		} else {
			const newInsight: any = {
				title,
				description
			};
			onAdd(newInsight);
		}

		setShowForm(false);
	};


	const handleDelete = (InsightId: number) => {
		onDelete(InsightId);
	};

    const formatDescription = (description: string) => {
        return description.replace(
            /<img /g,
            '<img style="width: 100%; max-width: 100%; height: auto; display: block; margin-top: 8px;" '
        );
    };

	return (
		<Container>
			<style>
				{`
				/* Remove border under toolbar */
				.ql-toolbar {
					border: none !important;
					background-color: transparent !important;
					color: white !important;
					display: flex;
					justify-content: flex-start;
				}

				/* Remove border around text editor */
				.ql-container {
					border: none !important;
				}

                /* Change toolbar icons to white */
                .ql-toolbar button .ql-stroke {
                    stroke: white !important;
                }

                .ql-toolbar button .ql-fill {
                    fill: white !important; /* Changes filled icons */
                }

				/* Ensures text inside editor is visible */
				.ql-editor {
					min-height: 150px !important;
					color: white !important;
				}

				/* Aligns text styling icons to the left */
				.ql-toolbar .ql-formats {
					display: flex;
					justify-content: flex-start;
				}

				/* Moves the image icon to the right */
				.ql-toolbar .ql-formats:last-child {
					margin-left: 0;
				}

				/* Custom scrollbar styling */
				.ql-container::-webkit-scrollbar {
					width: 6px;
				}

				.ql-container::-webkit-scrollbar-thumb {
					background-color: #71758F;
					border-radius: 10px;
				}

				.ql-container::-webkit-scrollbar-track {
					background: transparent;
				}
				
				.ql-editor.ql-blank::before {
    				color: rgba(255, 255, 255, 0.4) !important; /* Change placeholder text color */
					font-style: normal !important;
					font-size: 16px;
				}
				`}
			</style>
			<Box>
				{insights.map((insight: any) => {
					return (
						<Grid
							container
							key={insight.insightId}
							sx={{
								pb: 1,
								marginBottom: 2,
								borderBottom: `2px solid #A9ADCB`,
								display: 'flex',
								flexDirection: 'column'
							}}
						>
							<Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 1 }}>
								<Typography fontSize="20px" fontWeight={700}>
									{insight.title}
								</Typography>
								<Typography variant="body2" fontStyle="italic" fontSize={12} color="#71758F">
									{formatCSTTime(insight.updatedDate)}
								</Typography>
							</Grid>
							<Box
								sx={{
									width: '100%',
									// maxWidth: '800px',
									// height: '300px',
									// overflowY: 'auto',
									marginBottom: 2,
									'&::-webkit-scrollbar': {
										display: 'none'
									},
									scrollbarWidth: 'none',
									msOverflowStyle: 'none'
								}}
							>
								<div
									dangerouslySetInnerHTML={{ __html: formatDescription(insight.description) }}
									style={{ width: '100%' }}
								/>
							</Box>
							<Box sx={{ marginTop: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
								<Button sx={{ color: '#D82B51', fontWeight: '700' }} onClick={() => handleDelete(insight.insightId)}>
									DELETE
								</Button>
								<Button sx={{ color: '#009BDB', fontWeight: '700' }} onClick={() => handleOpen(insight)}>
									UPDATE
								</Button>
							</Box>
						</Grid>
					);
				})}
				{!showForm && (
					<Button
						variant="text"
						startIcon={<Add sx={{ color: '#009BDB' }} />}
						onClick={() => handleOpen()}
						sx={{ color: '#009BDB', fontSize: '16px', fontWeight: '600' }}
					>
						Add Insights/Actions
					</Button>
				)}
			</Box>

			{showForm && (
				<Box ref={formRef} sx={{ marginTop: 2, borderBottom: 1 }}>
					<TextField
						fullWidth
						margin="dense"
						placeholder="Title"
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						sx={{
							marginBottom: '1rem',
							borderRadius: '4px',
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#71758F'
								},
								'&:hover fieldset': {
									borderColor: '#71758F'
								},
								'&.Mui-focused fieldset': {
									borderColor: '#71758F'
								}
							}
						}}
					/>
					<ReactQuill
						ref={quillRef}
						value={description}
						onChange={setDescription}
						modules={modules}
						formats={formats}
						placeholder="Description"
						style={{
							minHeight: '180px',
							color: 'white',
							border: '1px solid #71758F',
							borderRadius: '4px',
							display: 'flex',
							flexDirection: 'column-reverse'
						}}
					/>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2, gap: '2rem' }}>
						<Button
							variant="text"
							onClick={() => setShowForm(false)}
							sx={{ color: '#009BDB', fontSize: '16px', fontWeight: '700' }}
						>
							CANCEL
						</Button>
						<Button
							onClick={handleSave}
							variant="contained"
							sx={{ backgroundColor: '#009BDB', padding: '13px 50px' }}
							disabled={!title.trim() || !description.trim()}
						>
							{currentInsight ? 'UPDATE' : 'SAVE'}
						</Button>
					</Box>
				</Box>
			)}
		</Container>
	);
};

export default Insights;
