import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Chip,
	FormControlLabel,
	IconButton,
	Menu,
	MenuItem,
	Switch,
	Typography
} from '@mui/material';
import { CustomizedDataGrid } from '../customized_data_grid';
import DialogueModal from 'components/Modal/DialogModal';
import { Column } from 'react-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteConfirmationDialog from './DeleteModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { VariableRow } from './createAlert';
import VariableModal from './VariableModal';

export const VariablesTab = ({ variables = [], setVariables }: any) => {
    const [showVariableModal, setShowVariableModal] = useState(false);
    const defaultColumnVariables = ['Name', 'Type', 'Value', 'Status', 'Action'];
    const [selectedVariable, setSelectedVariable] = useState<VariableRow | null>(null);

    const handleManageVariables = (variable: VariableRow, state: string) => {
        setSelectedVariable(null)
		if(state === 'add'){
			setVariables([...variables, variable])
		}else if(state === 'update'){
			setVariables(variables.map((vr: VariableRow) => {
				return vr.uuid === variable.uuid ? variable : vr
			}))
		}
		setShowVariableModal(false);
	};

    function getColumnsVariable(): Array<Column<VariableRow>> {
		return [
			{
				key: "name",
				name: "Name",
				minWidth: 150,
				width: 308,
			},
			{
				key: "type",
				name: "Type",
				width: 200,
				minWidth: 150,
			},
			{
				key: "value",
				name: "Value",
				minWidth: 95,
			},
			{
				key: 'isEnabled',
				name: 'Status',
				minWidth: 95,
				formatter({ row }: any) {
					return (
					  <Chip
					  	style={{ minWidth: 64, backgroundColor: row.isEnabled ? "#01C00826" : "#D82B5133", marginTop: 2 }}
						label={row.isEnabled ? "ACTIVE" : "INACTIVE"}
					  />
					);
				},
			},
			{
				key: "action",
				name: "Action",
				formatter({ row, rowIdx }: any) {
					const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
					const [openDialog, setOpenDialog] = useState(false);
	
					const handleClick = (event: React.MouseEvent<HTMLElement>) => {
						setAnchorEl(event.currentTarget);
					};
	
					const handleClose = () => {
						setAnchorEl(null);
					};
	
					const handleDeleteClick = () => {
						setOpenDialog(true);
						handleClose();
					};
	
					const handleToggleStatus = () => {
						setVariables(variables.map((vr:any) => {
							if(vr.uuid === row.uuid){
								vr.isEnabled = !vr.isEnabled
							}
							return vr
						}));
						handleClose();
					};

	                const handleDeleteConfirm = () => {
						setVariables((prevVariables: VariableRow[]) =>
							prevVariables.filter((v: VariableRow) => v.uuid !== row.uuid)
						);
						setOpenDialog(false);
					};

					return (
						<>
							{/* Action Buttons */}
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<IconButton
									aria-label="edit"
									size="small"
									onClick={() => {
										setSelectedVariable(row);
										setShowVariableModal(true);
									}}
									sx={{ color: "#71758F" }}
								>
									<EditIcon fontSize="small" />
								</IconButton>
	
								<IconButton
									aria-label="more"
									size="small"
									onClick={handleClick}
									sx={{ color: "#71758F" }}
								>
									<MoreVertIcon fontSize="small" />
								</IconButton>
	
								<Menu
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={handleClose}
									sx={{
										"& .MuiMenu-paper": {
											backgroundColor: "#003771 !important",
											width: "12%",
										},
									}}
								>
									<MenuItem onClick={handleDeleteClick} sx={{ marginLeft: "25px" }}>
										<DeleteIcon />
										<span style={{ fontSize: "1.0rem", marginLeft: "6px" }}>Delete</span>
									</MenuItem>
									<MenuItem onClick={handleToggleStatus} sx={{ marginLeft: '25px' }}>
										<FormControlLabel
											control={<Switch checked={row.isEnabled} size="small" color="default" />}
											label={row.isEnabled ? 'Deactivate' : 'Activate'}
										/>
									</MenuItem>
								</Menu>
							</Box>
	
							{/* Delete Confirmation Dialog */}
							<DeleteConfirmationDialog
								open={openDialog}
								onClose={() => setOpenDialog(false)}
								onConfirm={handleDeleteConfirm}
								identifierName={row.name || ""}
								type={'Variable'}
							/>
						</>
					);
				},
				minWidth: 200,
			},
		];
	}

    return (
        <Box sx={{ mt: 3, padding: '20px', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                    ⓘ This section is to map multiple variables/labels for the alert.
                </Typography>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setShowVariableModal(true)}
                >
                    CREATE VARIABLE
                </Button>
            </Box>
            <CustomizedDataGrid
                tableColumns={getColumnsVariable()}
                tableData={variables}
                tableType="Variable"
                defaultColumnNames={defaultColumnVariables}
                listLength={variables?.length || 0}
                isDateRangeFilter={false}
                showExport={false}
                isShowMoreButton={false}
                page={1}
                pageSize={0}
                paginationData={0}
            />
            <DialogueModal
                title={selectedVariable ? 'Edit variable' : 'CREATE VARIABLE'}
                isOpen={showVariableModal}
                handleClose={() => {
                    setShowVariableModal(false);
                    setSelectedVariable(null);
                }}
            >
                <VariableModal
                    selectedVariable={selectedVariable}
                    handleClose={() => {
                        setShowVariableModal(false);
                        setSelectedVariable(null);
                    }}
                    onManageVariables={handleManageVariables}
                />
            </DialogueModal>
        </Box>
    )
}