import { IconButton, Stack, Typography } from '@mui/material';
import colors from 'assets/theme/base/colors';
import RowTypography from 'components/Table/RowTypography';
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { ReactComponent as ClockIcon } from '../../assets/time-history.svg';
import CloseIcon from '@mui/icons-material/Close';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import LoaderComponent from 'components/Loader';
import { getSLAHistory } from 'service/service';
import moment from 'moment';



interface SLAHistoryProps {
	setSlaHistory: any;
	proInfo: any;
}

interface slaHistoryData {
	SLA_Type: number;
	SLA_Week_Days: any;
	SLA_Time: any;
	SLA_Date: any;
	Updated_At: any;
	Updated_By: any;

}

const SLAHistory: React.FC<SLAHistoryProps> = ({ setSlaHistory, proInfo }: any) => {
	const defaultColumnNames = [
		'SLA Type',
		'Days',
		'SLA Time',
		'SLA Date (Sch.Date+)',
		'Update Date',
		'Created By'
	];

	const columnNames = [
		{ name: 'SLA_Type', label: 'SLA Type' },
		{ name: 'SLA_Week_Days', label: 'Days' },
		{ name: 'SLA_Time', label: 'SLA Time' },
		{ name: 'SLA_Date', label: 'SLA Date (Sch.Date+)' },
		{ name: 'Updated_At', label: 'Update Date' },
		{ name: 'Updated_By', label: 'Created By' }


	];

	const [isSlaHistoryData, setSlaHistoryData] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);


	function formatTime(number: number) {
		let str = number?.toString().padStart(4, "0"); // Ensure it's 4 digits (e.g., "0115")
		let hours = str?.slice(0, 2); // Get the first two digits as hours
		let minutes = str?.slice(2, 4); // Get the last two digits as minutes
		return hours || minutes ? `${hours}:${minutes}` : 'NA';
	}



	async function SlaHistoryFunc() {
		setLoading(true);
		const res = await getSLAHistory(proInfo.hisProId);
		if (res.success) {
			setLoading(false);
			setSlaHistoryData(res.data);

		}
	}

	//fetching data
	useEffect(() => {
		SlaHistoryFunc();
		// setLoading(true);
		// if (curRow) {

		// 	setTimeout(() => {
		// 		cyclicExecFunc();
		// 	}, 2000);
		// }
		// setLoading(false)

	}, [proInfo]);

	function getColumns(): Array<any> {
		return columnNames.map((col: any, idx: number) => ({
			key: col.name,
			name: col.label,
			width: 'auto',
			flexGrow: 1,
			formatter({ row, column }: any) {
				return (
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<RowTypography>
							{column.key === 'SLA_Type' ? (
								row[column.key] === 0 ? 'Daily' : row[column.key] === 1 ? 'Weekly' : '--'
							) : column.key === 'SLA_Time' ? (
								row[column.key] ? `${String(row[column.key]).padStart(4, '0').slice(0, 2)}:${String(row[column.key]).padStart(4, '0').slice(2)}` : '--'
							) : column.key === 'Updated_At' ? (row['Updated_At']
								? row[column.key].split('T')[0] : row['Created_At'] ? row['Created_At'].split('T')[0] : '--'
							) : (
								row[column.key] || '--'
							)}
						</RowTypography>
					</Stack>
				);
			},
			//width: 150
				   
		}));
	}
	

	return (
		<Stack direction="column" p={4} position='relative'>
			<IconButton
				onClick={() => setSlaHistory(false)}
				sx={{
					position: 'absolute',
					top: 8,
					right: 8,
					zIndex: 1
				}}
			>
				<CloseIcon />
			</IconButton>
			<Stack
				direction="row"
				spacing={12}
				pt={1}
				pb={4}
				marginBottom={1}
				borderBottom={`0.5px solid ${colors.dark.dark}`}
			>
				<Stack>
					<Typography sx={{ color: 'text.subtle' }}>
						Project Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Typography component="span" sx={{ fontWeight: 'bold' }}>
							{proInfo.hisProName}
						</Typography>
					</Typography>
				</Stack>

				<Stack>
					<Typography sx={{ color: 'text.subtle' }}>
						Current SLA Time &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Typography component="span" sx={{ fontWeight: 'bold' }}>
							{formatTime(proInfo.hisSlaTime)}
						</Typography>
					</Typography>
				</Stack>
				<Stack>
					<Typography sx={{ color: 'text.subtle' }}>
						Current SLA Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<Typography component="span" sx={{ fontWeight: 'bold' }}>
							{'Schedule Date ' + (proInfo.hisSlaDate ? proInfo.hisSlaDate == 1 ? '+ 1' : '' : '')}
						</Typography>
					</Typography>
				</Stack>
			</Stack>
			{loading ? (
				<LoaderComponent />
			) : (
				<CustomizedDataGrid
					title={
						<Stack direction="row" alignItems="center" spacing={1} mt="1rem">
							<ClockIcon style={{ color: 'red' }} />
							<Typography fontSize="16px">Past SLA Configurations</Typography>
						</Stack>
					}
					tableColumns={getColumns()}
					tableData={isSlaHistoryData}
					tableType="history-list"
					defaultColumnNames={defaultColumnNames}
					autoSetDraggedColumns={false}
					listLength={isSlaHistoryData.length}
					showExport={false}
					isDateRangeFilter={false}
					isDisabled={false}
					isRenderPagination={false}
					isShowMoreButton={false}
					style={{ maxHeight: '250px' }}
					rowHeight={40}
				/>
			)}
		</Stack>
	);
};

export default SLAHistory;
