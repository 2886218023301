import { useEffect, useState, useRef, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import { useProSidebar } from 'react-pro-sidebar';
import { deleteCookie, getDecodedToken, getAlerts } from './service/service';
import moment from 'moment-timezone';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as FullScreenIcon } from './assets/fullscreen.svg';
import { ReactComponent as ExitFullScreenIcon } from './assets/exit_fullscreen.svg';
import { OverflowTooltip } from './components/overflow_tooltip';
import { HeaderContext } from './Context/HeaderContext';
import { Alert, Badge, Box, Snackbar, Stack, Tooltip, Typography, Zoom } from '@mui/material';
import { ReactComponent as NotificationBellIcon } from './assets/notification.svg';
import RulesEngine from './components/RulesEngine/RulesEngine';
import { useRedirectLogin } from 'hooks/useRedirectHooks';
import AppRoutes from 'routes/routes';
import { useLocation } from "react-router-dom";

import Sidebar from './components/landingScreen/sidebar';
import Header from './components/landingScreen/header';
import Login from './components/loginScreen/loginScreen';
import FormLogin from './components/loginScreen/formLoginScreen';
import SideDrawer from 'components/SideDrawer';
import {
	setAuthenticated,
	setAuthToken,
	setAuthType,
	setCapabilities,
	setRole,
	usePepwiseAuthController
} from 'Context/contexts/AuthContext';
import eventEmitter, { Severity } from 'SnackBarEventEmitter';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SessionTimeoutDialog from 'utils/SessionTimeoutDialog';
// Lazy load components
// const Sidebar = lazy(() => import('./components/landingScreen/sidebar'));
// const Header = lazy(() => import('./components/landingScreen/header'));
// const Login = lazy(() => import('./components/loginScreen/loginScreen'));
// const FormLogin = lazy(() => import('./components/loginScreen/formLoginScreen'));
import { ReactComponent as ComingSoonIcon } from './assets/coming-soon-dp.svg'

const synced = moment();

function App() {
	const dataFetchedRef = useRef(false);
	const [fullscreen, setFullScreen] = useState(false);
	const [originalRuleEngineData, setOriginalRuleEngineData] = useState<any>([]);
	const [isPanelOpen, setPanelOpen] = useState<boolean>(false);
	const [controller, dispatch] = usePepwiseAuthController();
	const { redirectToLogin, redirectAfterLogin } = useRedirectLogin();
	const getToken = () => document.cookie.split("; ").find(row => row.startsWith("id_token="));

	const [dateString, setDateString] = useState('');
	
	const { isAuthenticated, accessToken } = controller;

	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState('');
	const [snackbarSeverity, setSnackbarSeverity] = useState<Severity>('error');


	document.addEventListener('fullscreenchange', exitHandler);
	document.addEventListener('webkitfullscreenchange', exitHandler);
	document.addEventListener('mozfullscreenchange', exitHandler);
	document.addEventListener('MSFullscreenChange', exitHandler);

	function exitHandler() {
		if (!document.fullscreenElement) {
			setFullScreen(false);
		}
	}

	moment.tz.setDefault('America/New_York');
	moment.defaultFormat = 'MM-DD-YYYY HH:mm:ss';
	const [showDialog, setShowDialog] = useState(false);
    const [countdown, setCountdown] = useState(30);
    const lastActivity = useRef(Date.now());
    const countdownInterval = useRef<number | null>(null);
    const idleInterval = useRef<number | null>(null);
	const location = useLocation(); // Get the current route
   
	const idleout = () => {
		deleteCookie('id_token');
		window.location.href = "/";
	};
	const handleCloseLogout= () =>{
		setShowDialog(false);
	}

	const stayLoggedIn = () => {
		setShowDialog(false);
		setCountdown(30);
		lastActivity.current = Date.now();
		localStorage.setItem("lastActivity", Date.now().toString());

		if (countdownInterval.current !== null) {
			clearInterval(countdownInterval.current);
			countdownInterval.current = null;
		}
	};


	  useEffect(() => {
		if (location.pathname === "/dashboard/sessionTimeOut") {
		  return; 
		}
	
		const checkIdle = () => {
		  if (!getToken()) return;
	
		  const storedTime = localStorage.getItem("lastActivity");
		  if (storedTime) {
			lastActivity.current = parseInt(storedTime, 10);
		  }
	
		  const idleTime = (Date.now() - lastActivity.current) / 1000;
		  if (idleTime >= 897 && !showDialog) {
			setShowDialog(true);
			setCountdown(30);
	
			if (countdownInterval.current !== null) {
			  clearInterval(countdownInterval.current);
			}
	
			countdownInterval.current = window.setInterval(() => {
			  setCountdown((prev) => {
				if (prev === 1) {
				  clearInterval(countdownInterval.current!);
				  setShowDialog(false);
				  window.location.href = "/dashboard/sessionTimeOut";
				  return 0;
				}
				return prev - 1;
			  });
			}, 1000);
		  }
		};
	
		const activityListener = () => {
		  lastActivity.current = Date.now();
		  localStorage.setItem("lastActivity", Date.now().toString());
		};
	
		idleInterval.current = window.setInterval(checkIdle, 1000);
		window.addEventListener("mousemove", activityListener);
		window.addEventListener("keydown", activityListener);
	
		return () => {
		  clearInterval(idleInterval.current!);
		  clearInterval(countdownInterval.current!);
		  window.removeEventListener("mousemove", activityListener);
		  window.removeEventListener("keydown", activityListener);
		};
	  }, [showDialog, location.pathname]);
	  

	  useEffect(() => {
		if (showDialog && countdown > 0) {
		  countdownInterval.current = window.setInterval(() => {
			setCountdown((prev) => {
			  if (prev === 1) {
				clearInterval(countdownInterval.current!);
				window.location.href = "/dashboard/sessionTimeOut";
				return 0;
			  }
			  return prev - 1;
			});
		  }, 1000);
		}
	
		return () => {
		  if (countdownInterval.current) {
			clearInterval(countdownInterval.current);
		  }
		};
	  }, [showDialog, countdown]);




	const handleFullScreen = () => {
		if (fullscreen) document.exitFullscreen();
		else document.body.requestFullscreen();
		setFullScreen(!fullscreen);
	};

	// [Context Api]
	const [isNull, setIsNull] = useState(true);
	const [lastSync, setLastSync] = useState(synced.fromNow());
	const value = { isNull, setIsNull, lastSync, setLastSync };

	useEffect(() => {
		const date = new Date();

		// const options: any = {
		// 	weekday: 'short',
		// 	year: 'numeric',
		// 	month: 'short',
		// 	day: 'numeric',
		// 	hour: '2-digit',
		// 	minute: '2-digit',
		// 	hour12: true,
		// 	timeZone: 'America/Chicago'
		// };

        const options: Intl.DateTimeFormatOptions = {
            weekday: 'short', // "Fri"
            day: '2-digit', // "01"
            month: 'short', // "Nov"
            year: 'numeric', // "2024"
            hour: '2-digit', // "10"
            minute: '2-digit', // "00"
            hour12: true, // 12-hour format
			timeZone: 'America/Chicago'
          };

        const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);
        const [dayOfWeek, day, month, year, time] = formattedDate.split(' ');
        // Format the time to append AM/PM
        const [hours, minutes] = time.split(':');
        const period = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';

        const exelastsync = `${dayOfWeek} ${day} ${month} ${year} ${hours}:${minutes} ${period}`;
		setDateString(exelastsync);
	  
	  }, []);

	// check if session is still valid
	useEffect(() => {
		// Initial Authentication Check
		let isRedirectToLogin = false;

		if (location.pathname === '/userLogin') {
			localStorage.setItem('sso-login', 'false');
			setAuthType(dispatch, 'local');
		}
		if (location.pathname === '/') {
			localStorage.setItem('sso-login', 'true');
			setAuthType(dispatch, 'sso');
		}

		let authenticated = false;

		if (isAuthenticated) {
			if (accessToken?.exp) {
				const currentTime = Math.floor(Date.now() / 1000);
				// Check if access token is expired
				if (accessToken.exp >= currentTime) {
					authenticated = true;
				} else {
					setAuthenticated(dispatch, false);
					setAuthToken(dispatch, null);
				}
			}
		}

		if (!authenticated) {
			const auth = getDecodedToken();
			if (auth) {
				const currentTime = Math.floor(Date.now() / 1000);
				if (auth.exp >= currentTime) {
					setAuthType(dispatch, 'local');
					setAuthToken(dispatch, auth);
					setAuthenticated(dispatch, true);
				} else {
					isRedirectToLogin = true;
				}
			} else {
				isRedirectToLogin = true;
			}

			if (isRedirectToLogin) {
				redirectToLogin();
			}
		}

		eventEmitter.on('showSnackbar', (message: string,severity:Severity) => {
			setSnackbarMessage(message);
			setSnackbarSeverity(severity);
			setSnackbarOpen(true);
		});
	}, []);

	// const checkSession = async (): Promise<void> => {
	// 	axios
	// 		.get(`${url}/user/me`)
	// 		.then((response) => {
	// 			if (response.status === 200) {
	// 				const { success } = response.data;
	// 				if (success !== true) {
	// 					console.log("redirecting to sso login from App.tsx")
	// 					window.location.href = '/sso/login';
	// 				}
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.error('Login error:', error);
	// 		});
	// };

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	useEffect(() => {
		if (isAuthenticated) {
			const auth = getDecodedToken();

			if (auth) {
				const capabilities: string[] =
					auth.role?.reduce((acc: string[], role: any) => {
						const roleCapabilities = role.capabilities || [];
						roleCapabilities.forEach((capability: string) => {
							if (!acc.includes(capability)) {
								acc.push(capability);
							}
						});
						return acc;
					}, [] as string[]) || [];

				const roleNames = Array.isArray(auth.role)
					? auth.role.map((role: any) => role.name)
					: auth.role
					? [auth.role.name]
					: [];

				// Set the updated auth details
				setRole(dispatch, roleNames);
				setCapabilities(dispatch, capabilities);
			}
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (dataFetchedRef.current || !isAuthenticated) return;
		dataFetchedRef.current = true;
		// listenToEventStream(); // Start listening to event stream
		redirectAfterLogin();
		fetchAlerts();
		const apiCallInterval = 10 * 60 * 1000; // 10 minutes in milliseconds
		const intervalId = setInterval(fetchAlerts, apiCallInterval);
		return () => clearInterval(intervalId);
	}, [isAuthenticated]);

	const [totalObservationCount, setTotalObservationCount] = useState(-1);

	const calculateTotalObservation = (data: any) => {
		const totalCount = data.observations.reduce((count: any, item: any) => count + item.totalObservation, 0);
		setTotalObservationCount(totalCount);
	};

	async function fetchAlerts() {
		const res = await getAlerts();
		if (res.success) {
			const newData = res.data;
			setOriginalRuleEngineData((prevData: any) => [...prevData, newData]);
			calculateTotalObservation(newData);
		}
	}

	// const listenToEventStream = () => {
	// 	const eventSource = new EventSource(`${url}${endpoints.notificationAlerts}`);

	// 	eventSource.onmessage = (event) => {
	// 		const newData = JSON.parse(event.data);
	// 		setOriginalRuleEngineData((prevData: any) => [...prevData, newData]);
	// 		calculateTotalObservation(newData);
	// 	};

	// 	eventSource.onerror = (error) => {
	// 		console.error('EventSource failed:', error);
	// 		eventSource.close();
	// 		listenToEventStream();
	// 	};
	// };

	const togglePanel = () => {
		setPanelOpen(!isPanelOpen);
	};

	const alertIconClass = `${
		location.pathname === '/LandingPage' ? 'landing-common-notification' : 'common-notification'
	} badge_box`;

	const showFullScreen = () => {
		return (
			location.pathname !== '/LandingPage' &&
			location.pathname !== '/dashboard/dataOpsLanding' &&
			location.pathname !== '/dashboard/financeOpsLanding' &&
			location.pathname !== '/dashboard/D&AIOpsLanding' &&
			location.pathname !== '/dashboard/programExecutiveLanding' && 
			location.pathname !== '/dashboard/platformCostLanding' && 
			location.pathname !== '/dashboard/alertKpiLanding' &&
			location.pathname !== '/dashboard/sessionTimeOut' && 
			location.pathname !== '/dashboard/monthlyXOpsLanding' && (
				<OverflowTooltip customTooltip={fullscreen ? 'Exit Full Screen' : 'Full Screen'}>
					<IconButton
						style={{ paddingLeft: '1px', paddingTop: '14px' }}
						onClick={() => {
							handleFullScreen();
						}}
						className={fullscreen ? 'exitFullScrrenBtn' : 'fullScrrenBtn'}
					>
						{fullscreen ? <ExitFullScreenIcon /> : <FullScreenIcon />}
					</IconButton>
				</OverflowTooltip>
			)
		);
	};

	const showSchedulerHealthlabel = () => {
		return (
			location.pathname == '/data-pipeline/executiveOps' && (
				<Typography variant="subtitle1" className='sch-health-placeholder' color="light.diff" style={{ color: 'rgba(169, 173, 203, 1)', fontWeight: '600', fontSize: '12px'}}>SCHEDULER HEALTH: <span style={{ marginLeft: '5px'}}>COMING SOON <ComingSoonIcon style={{ verticalAlign: 'text-bottom' }} /></span></Typography>
			)
		)
	}

	const showCSTtimelabel = () => {
		return (
			location.pathname == '/data-pipeline/executiveOps' && (<Typography style={{ color: 'rgba(113, 117, 143, 1)', fontWeight: '500', fontSize: '14px'}} variant="subtitle1" className='time-placeholder' color="light.diff">{ dateString }</Typography>)
		);
	}

	const showLandingPageNotification = () => {
		return (
			location.pathname !== '/evidently-mlobs-poc' &&
			location.pathname !== '/' &&
			location.pathname !== '/userLogin' &&
			location.pathname === '/LandingPage' && (
				<div className={location.pathname === '/LandingPage' ? 'bell-wrapper' : ''}>
					<Box
						component="span"
						sx={{ p: 2 }}
						onClick={() => {
							totalObservationCount > 0 && togglePanel();
						}}
						className={alertIconClass}
					>
						<Tooltip arrow title="Rule Observations" TransitionComponent={Zoom}>
							<Badge
								badgeContent={totalObservationCount >= 0 ? totalObservationCount : '...'}
								max={9999}
								showZero
								className={Number(totalObservationCount) > 0 ? 'alert_badge' : 'zero_badge'}
							>
								<NotificationBellIcon className="badge_icon" />
								{/* <span className="icon-notification icon-style"></span> */}
							</Badge>
						</Tooltip>
					</Box>
				</div>
			)
		);
	};

	// useEffect(() => {}, [isAuthenticated]);

	const showHeaderAndNav = () => {
		return (
			isAuthenticated &&
			location.pathname !== '/' &&
			location.pathname !== '/userLogin' &&
			location.pathname !== '/LandingPage' && 
			location.pathname !== '/dashboard/dataOpsLanding' &&
			location.pathname !== '/dashboard/financeOpsLanding' && 
			location.pathname !== '/dashboard/D&AIOpsLanding' &&
			location.pathname !== '/dashboard/programExecutiveLanding' && 
			location.pathname !== '/dashboard/platformCostLanding' && 
			location.pathname !== '/dashboard/alertKpiLanding' &&
			location.pathname !== '/dashboard/sessionTimeOut' && 
			location.pathname !== '/dashboard/monthlyXOpsLanding' && (
				<>
					<Suspense fallback={<div>Loading...</div>}>
						<Sidebar />
					</Suspense>
					<Suspense fallback={<div>Loading...</div>}>
						<Header notificationData={originalRuleEngineData} />
					</Suspense>
				</>
			)
		);
	};

	return (
		<DndProvider backend={HTML5Backend}>
			<HeaderContext.Provider value={value}>

				{showSchedulerHealthlabel()}

				{showCSTtimelabel()}

				{showFullScreen()}

				{isPanelOpen && <RulesEngine close={togglePanel} ruledata={originalRuleEngineData} />}

				{showHeaderAndNav()}

				<SideDrawer />
				{(location.pathname.includes('finops/resourcemapping/search') 
				|| location.pathname.includes('finops/resourcemapping/projects')
				|| (location.pathname.includes('finops/resourcemapping/programs') 
					&& snackbarMessage.includes('Service temporarily unavailable')))
				 ?  ( 
					<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
					open={snackbarOpen}
					onClose={handleCloseSnackbar} className = 'bg-resource'
					>
						<Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} className = 'alert-resource'>
							<Typography style={{ fontSize: '16px', fontWeight: '700', marginBottom: '4px', color: '#fd9532' }}>
								{snackbarSeverity === 'info' && 'Alert Message'}
							</Typography>
							<Stack sx={{backgroundColor:'#ffffff !important' }}>
								{snackbarMessage
									.split('.')
									.map((sentence, index) =>
										sentence.trim() ? (
											<Typography key={index} style={{ fontSize: '14px', fontWeight: '400', color: '#000000' }}>
												{sentence.trim()}
											</Typography>
										) : null
									)}
							</Stack>
						</Alert>
					</Snackbar>
				) : (
					<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={snackbarOpen}
					autoHideDuration={6000}
					onClose={handleCloseSnackbar}
					>
						<Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
							<Typography style={{ fontSize: '16px', fontWeight: '700', marginBottom: '4px' }}>
								{snackbarSeverity === 'info' && 'Alert Message'}
								{snackbarSeverity === 'success' && 'Success Message'}
								{snackbarSeverity === 'warning' && 'Warning Message'}
								{snackbarSeverity === 'error' && 'Error Message'}
							</Typography>
							<Stack>
								{snackbarMessage
									.split('.')
									.map((sentence, index) =>
										sentence.trim() ? (
											<Typography key={index} style={{ fontSize: '14px', fontWeight: '400' }}>
												{sentence.trim()}
											</Typography>
										) : null
									)}
							</Stack>
						</Alert>
					</Snackbar>
				)}
				{showDialog && getToken() && (
					<SessionTimeoutDialog
					open={showDialog}
					onClose={handleCloseLogout}
					idleout = {idleout}
					onStayLoggedIn={stayLoggedIn}
					countdown={countdown}
				  />
				)}

				{!isAuthenticated && (
					<Routes>
						<Route path="/" element={<Login />} />

						<Route path="/userLogin" element={<FormLogin />} />
					</Routes>
				)}

				{isAuthenticated && (
					<aside className={isAuthenticated ? 'main-content' : ''}>
						{showLandingPageNotification()}
						<div className="parent-container">
							<Suspense fallback={<div>Loading...</div>}>
								<AppRoutes />
							</Suspense>
						</div>
					</aside>
				)}
				{/* <Footer /> */}
			</HeaderContext.Provider>
		</DndProvider>
	);
}

export default App;
