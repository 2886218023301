import { lazy } from 'react';
import PrivateRoute from 'utils/privateRoute';
import ExecutiveView from 'components/DataEstate/ExecutiveView';
import ExecutiveViewAvailabity from 'components/DataEstate/ExecutiveViewAvailabity';
import ExecutiveViewMims from 'components/DataEstate/ExecutiveViewMims';
import ExecutiveViewIncident from 'components/DataEstate/ExecutiveViewIncident';
import PlatformCostSummary from 'components/FineOps/PlatformCostSummary';
import ExecutiveViewChangeRequest from 'components/DataEstate/ExecutiveViewChangeRequest';
import ExecutiveViewServiceRequest from 'components/DataEstate/ExecutiveViewServiceRequest';
import PlatformInsightsView from 'components/FineOps/PlatformInsightsView';
import ExecutiveView2024OperationalMetrics from 'components/DataEstate/ExecutiveView2024OperationalMetrics';
// const OverallSummary = lazy(() => import('components/DataEstate/overall-summary'));

const DandAIOpsRoutes = [
	{
		key: 'D_AI_execview',
		route: '/D&AIXOps/executiveView',
		component: (
			<PrivateRoute
				Component={ExecutiveView}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'D_AI_execviewMims',
		route: '/D&AIXOps/executiveView/Summary',
		component: (
			<PrivateRoute
				Component={ExecutiveViewMims}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'D_AI_execview2024OperationalMetrics',
		route: '/D&AIXOps/executiveView/2024OperationalMetrics',
		component: (
			<PrivateRoute
				Component={ExecutiveView2024OperationalMetrics}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'D_AI_execviewIns',
		route: '/D&AIXOps/executiveView/Incidents',
		component: (
			<PrivateRoute
				Component={ExecutiveViewIncident}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'D_AI_execviewCRs',
		route: '/D&AIXOps/executiveView/ChangeRequests',
		component: (
			<PrivateRoute
				Component={ExecutiveViewChangeRequest}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
    {
		key: 'D_AI_execviewSRs',
		route: '/D&AIXOps/executiveView/ServiceRequests',
		component: (
			<PrivateRoute
				Component={ExecutiveViewServiceRequest}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
    {
		key: 'D_AI_execviewAvailability',
		route: '/D&AIXOps/executiveView/Availability',
		component: (
			<PrivateRoute
				Component={ExecutiveViewAvailabity}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'D_AI_platformcostsummary',
		route: '/D&AIXOps/platformCostSummary',
		component: (
			<PrivateRoute
				Component={PlatformCostSummary}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},
	{
		key: 'D_AI_platformviewInsights',
		route: '/D&AIXOps/platformCostSummary/insightView',
		component: (
			<PrivateRoute
				Component={PlatformInsightsView}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User',
					'Program Executive'
				]}
			/>
		)
	},

];

export default DandAIOpsRoutes;
