import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import { Box, Button, PaginationItem, TextField, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

export function PaginationDetails({
	page,
	pageSize,
	paginationData,
	listLength,
	handlepageSizeChange,
	handlePageChange,
	showGoToPage = false,
}: any) {
	const [inputPage, setInputPage] = useState('');

	const handleGoToPage = () => {
        const pageNumber = Number(inputPage)
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= paginationData.totalPages) {
            handlePageChange(null, pageNumber);
        } else {
            alert(`Please enter a valid page number between 1 and ${paginationData.totalPages}`);
        }
    };

	const validate = useMemo(() => {
		const pageNumber = Number(inputPage?.trim());
		return (
			Number.isInteger(pageNumber) &&
			pageNumber >= 1 &&
			pageNumber <= paginationData.totalPages &&
			pageNumber !== page
		);
	},[inputPage, paginationData.totalPages, page]);

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				px: 1,
				backgroundColor: 'blue.dark',
			}}
		>
			<Typography variant="body2" color="light.diff">
				Showing {(page * parseInt(pageSize) - parseInt(pageSize) + 1)?.toLocaleString()} -{' '}
				{page * parseInt(pageSize) > paginationData.totalElements
					? paginationData.totalElements?.toLocaleString()
					: (page * parseInt(pageSize))?.toLocaleString()}{' '}
				of {listLength ? paginationData.totalElements?.toLocaleString() : 0} results
			</Typography>
			<Stack direction="row" gap={2} alignItems="center">
				<Stack direction="row" alignItems='center'>
					<Typography variant="body2" color="light.diff">
						Rows per Page:
					</Typography>
					<Select
						sx={{
							color: 'white',
							'& .MuiOutlinedInput-notchedOutline': {
								border: 'none'
							},
							'& .MuiSvgIcon-root': {
								color: 'white.main'
							}
						}}
						labelId="pageSize"
						id="pageSize"
						value={pageSize}
						onChange={handlepageSizeChange}
					>
						{pageSize < 100 && <MenuItem value={pageSize}>{pageSize}</MenuItem>}
						<MenuItem value={100}>100</MenuItem>
						<MenuItem value={200}>200</MenuItem>
						<MenuItem value={500}>500</MenuItem>
						<MenuItem value={1000}>1000</MenuItem>
					</Select>
				</Stack>
				<Pagination
					count={paginationData.totalPages}
					page={Number(page)}
					onChange={handlePageChange}
					shape="rounded"
					showFirstButton
					showLastButton
					renderItem={(item) => (
						<PaginationItem
							{...item}
							// Format displayed page number with commas
							page={item.page?.toLocaleString()}
						/>
					)}
					sx={{
						'& .MuiPaginationItem-root': {
							color: 'rgba(229, 229, 229, 1)'
						},
						'& .MuiPaginationItem-root:hover': {
							backgroundColor: 'rgba(90, 84, 152, 0.2)'
						},
						'& .Mui-selected': {
							backgroundColor: 'rgba(90, 84, 152, 0.2) !important'
						}
					}}
				/>
				{showGoToPage && (
					<Stack direction="row" alignItems="center" gap={2}>
						<Typography variant="body2" color="light.diff">
							Enter Page Number :{' '}
						</Typography>
						<TextField
							size="small"
							variant="filled"
							value={inputPage}
							onChange={(e) => setInputPage(e.target.value)}
							onKeyDown={(event) => {
								if (event.key === 'Enter') {
									handleGoToPage();
								}
							}}
							sx={{
								width: 40,
								height: 25,
								input: { color: 'white', textAlign: 'center', padding: '0px !important' },
								'& .MuiOutlinedInput-root': {
									height: 30,
									fontSize: '0.875rem'
								},
								'& .MuiFilledInput-root': {
									borderBottom: '1px solid white',
									'&:hover': {
										borderBottom: '2px solid rgb(0, 155, 216) !important'
									},
									'&.Mui-focused': {
										borderBottom: '2px solid rgb(0, 155, 216) !important'
									}
								},
								display: 'flex',
								direction: 'column',
								alignSelf: 'center'
							}}
						/>
						<Button
							variant="contained"
							onClick={handleGoToPage}
							disabled={!validate}
							sx={{
								color: 'white',
								backgroundColor: 'rgb(0, 155, 216)',
								width: 30,
								height: 30,
								minWidth: 30,
								borderRadius: '50%',
								padding: 0,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							Go
						</Button>
					</Stack>
				)}
			</Stack>
		</Box>
	);
}
