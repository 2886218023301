import { Box, debounce } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import LoaderComponent from 'components/Loader';
import { defaultOptions, getLineChartOptions } from './chartWidgetUtility';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import { useEffect, useRef, useState } from 'react';
import { AreaLineChartWidgetProps } from './type';
import colors from 'assets/theme/base/colors';

const AreaLineChartWidget = ({
	isLoading = false,
	data,
	colorMapping,
	gradientMapping,
	hasLegend,
	handleEvent
}: Readonly<AreaLineChartWidgetProps>) => {
	const [controller] = useCacheDataController();
	const [windowSize, setWindowSize] = useState(window.innerWidth);
	const [gridLeft, setGridLeft] = useState('9%');
	const chartContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// re-render chart to fix alignment when resizing the browser
		const handleResize = debounce(() => {
			if (chartContainerRef.current) {
				const containerWidth = chartContainerRef.current.offsetWidth;
				const calculatedLeft =
					containerWidth < 420
						? '22%'
						: containerWidth < 600
						? '19%'
						: containerWidth < 820
						? '16%'
						: containerWidth < 920
						? '13%'
						: containerWidth < 1260
						? '11%'
						: '9%';
				// setGridLeft(calculatedLeft);
				setWindowSize(window.innerWidth);
			}
		}, 200);

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const result = getLineChartOptions(data ?? {}, colorMapping ?? [], gradientMapping ?? [], hasLegend);
	const { chartOption } = result;

	const adjustedOption: any =
		!chartOption || Object.keys(chartOption).length === 0 
			? defaultOptions
			: {
				...chartOption,
				grid: {
					...chartOption.grid,
					left: gridLeft
				} 
			}

	return (
		<Box
			sx={{
				flex: '55%',
				textAlign: 'center',
				justifyContent: 'center',
				borderLeft: `2px solid ${colors.dark.dark}`,
				paddingLeft: '16px', // Adjusts spacing between border and content
				paddingRight: '8px', // Adjusts spacing on the right side
				cursor: !hasLegend ? 'pointer' : 'default',
			}}
			{...(handleEvent && { onClick: !hasLegend ? handleEvent : undefined })}
		>
			{!isLoading ? (
				<ReactECharts
					option={adjustedOption}
					style={{ width: '100%', height: '100%' }}
					opts={{ renderer: 'canvas', width: 'auto' }}
					notMerge={true}
					lazyUpdate={true}
				/>
			) : (
				<LoaderComponent />
			)}
		</Box>
	);
};

export default AreaLineChartWidget;
