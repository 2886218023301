import '../LandingPage/LandingPage.scss';
import {  Grid } from '@mui/material';
import dataPipelineLogo from '../../assets/data-pipeline-logo.svg';
import { useEffect, useState, lazy, Suspense } from 'react';
import FinOpsLogo from '../../assets/FinOps_small.svg';
import landingBackArrow from '../../assets/landingBackArrow.svg';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import PlatformCostLogo from '../../assets/ri_money-dollar-circle-fill.svg';
import AIfaddedIcon from '../../assets/simple-icons_aiops_faded.svg';
import MLfaddedIcon from '../../assets/simple-icons_ml_faded.svg';
import NPIfaddedIcon from '../../assets/simple-icons_npi_faded.svg';
import ArchitechfaddedIcon from '../../assets/simple-icons_architecture_faded.svg';
import clockIcon from '../../assets/ic_sharp-access-time.svg';

// Lazy load components
const Header = lazy(() => import('./Header'));
const Footer = lazy(() => import('./ModuleLandingFooter'));
const capabilitiesNew : any = localStorage.getItem('capabilities');


export default function MonthlyXOpsLanding() {
	const [memberData, setMemberData] = useState<any>(null);
	const [controller, _] = usePepwiseAuthController();
	const { accessToken, capabilities } = controller;
	const capabilitiesArray = capabilitiesNew ? capabilitiesNew.split(',') : [];
	

	// to display data
	useEffect(() => {
		setMemberData(accessToken);
	}, []);

	//navigate to data-pipeline observability
	const handleMonthlyXOpsClick = (event: any, type: string, url: string) => {
		event.preventDefault(); // Prevent the default context menu from showing up
		if (type === 'blank') {
			// Open the URL in a new tab if type is 'blank'
			window.open(url, '_blank');
		} else {
			// Navigate to the provided URL in the current tab
			window.location.href = url;
		}
	};

	const handleBackClick = () => {
		window.location.href = '/LandingPage';
	};
	

	return (
		<div className="outerLandingPageContainer">
			<Header memberData={memberData} />
			<div style={{ maxWidth: '86%', margin: '0 auto' }}>
                <div className="landingMessage-container">
					<img src={landingBackArrow} alt="CCLogo" className="landingMessageImage" onClick={handleBackClick} />
					<p className="landingMessage">{`D&AI Monthly XOps Dashboards`}</p>
				</div>


				<div className="welcome_text">
					<p>Click on the module below to monitor!</p>
				</div>
				<Grid container spacing={1} style={{ marginTop: '0px' }}>
                {capabilitiesArray.includes('Data Ops') ? (
					<Grid
						item
						xs={4}
						style={{ cursor: 'pointer'}}
						onClick={(e) => handleMonthlyXOpsClick(e, 'no-blank', '/D&AIXOps/executiveView/Summary')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleMonthlyXOpsClick(e, 'blank', '/D&AIXOps/executiveView/Summary');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
                                <img src={dataPipelineLogo} alt="tableauLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }} />
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
									Monthly Data Pipelines Dashboard
								</h3>
							</div>
							<p
								className="message1"
								title="Monitor jobs or pipelines for a specific project."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Monitor jobs or pipelines for a specific project.
							</p>
						</div>
					</Grid>
                    ) : (
					''
					)}
				{capabilitiesArray.includes('Fin Ops') ? (
                    <Grid
						item
						xs={4}
						
						onClick={(e) => handleMonthlyXOpsClick(e, 'no-blank', '/D&AIXOps/platformCostSummary')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleMonthlyXOpsClick(e, 'blank', '/D&AIXOps/platformCostSummary');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
							<img src={FinOpsLogo} alt="finOpsLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }} />
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
									FinOps Dashboard
								</h3>
							</div>
							<p
								className="message1"
								title="Monitor platform cost."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Monitor platform cost. 
							</p>
						</div>
					</Grid>
                    ) : (
						''
				)}
				{capabilitiesArray.includes('Platform Cost') ? (
                    <Grid
						item
						xs={4}
						
						onClick={(e) => handleMonthlyXOpsClick(e, 'no-blank', '/platformCostObs/executiveView')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleMonthlyXOpsClick(e, 'blank', '/platformCostObs/executiveView');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
                                <img src={PlatformCostLogo} alt="platformLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }}/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
									Monthly Platform Ops Dashboard
								</h3>
							</div>
							<p
								className="message1"
								title="Monitor platform operations metrics."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Monitor platform operations metrics. 
							</p>
						</div>
					</Grid>
                    ) : (
						''
				)} 
				{capabilitiesArray.includes('Platform Cost') ? (
                    <Grid
						item
						xs={4}
						
						// onClick={(e) => handleMonthlyXOpsClick(e, 'no-blank', '/platformCostObs/executiveView')}
						// onContextMenu={(e) => {
						// 	e.preventDefault();
						// 	handleMonthlyXOpsClick(e, 'blank', '/platformCostObs/executiveView');
						// }}
					>
						<div className="observalbility_screens1" style={{ textAlign: 'center' }}>
							<div className="title">
                                <img src={AIfaddedIcon} alt="platformLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }}/>
							</div>
							<div>
								<h3 className="obsTitle1" style={{ margin: '10px 0' }}>
									Monthly AI Ops Dashboard
								</h3>
							</div>
							<p
								className="message2"
								title="Monitor cost of cloud resources."
								style={{ margin: '0', textAlign: 'center' }}
							>
								COMING SOON <img src ={clockIcon} style={{ position: 'relative', top: '4px' }}/>
							</p>
						</div>
					</Grid>
                    ) : (
						''
				)} 
				{capabilitiesArray.includes('Platform Cost') ? (
                    <Grid
						item
						xs={4}
						
						// onClick={(e) => handleMonthlyXOpsClick(e, 'no-blank', '/platformCostObs/executiveView')}
						// onContextMenu={(e) => {
						// 	e.preventDefault();
						// 	handleMonthlyXOpsClick(e, 'blank', '/platformCostObs/executiveView');
						// }}
					>
						<div className="observalbility_screens1" style={{ textAlign: 'center' }}>
							<div className="title">
                                <img src={MLfaddedIcon} alt="platformLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }}/>
							</div>
							<div>
								<h3 className="obsTitle1" style={{ margin: '10px 0' }}>
									Monthly ML Ops Dashboard
								</h3>
							</div>
							<p
								className="message2"
								title="Monitor cost of cloud resources."
								style={{ margin: '0', textAlign: 'center' }}
							>
								COMING SOON <img src ={clockIcon} style={{ position: 'relative', top: '4px' }}/>
							</p>
						</div>
					</Grid>
                    ) : (
						''
				)} 
				{capabilitiesArray.includes('Platform Cost') ? (
                    <Grid
						item
						xs={4}
						
						// onClick={(e) => handleMonthlyXOpsClick(e, 'no-blank', '/platformCostObs/executiveView')}
						// onContextMenu={(e) => {
						// 	e.preventDefault();
						// 	handleMonthlyXOpsClick(e, 'blank', '/platformCostObs/executiveView');
						// }}
					>
						<div className="observalbility_screens1" style={{ textAlign: 'center' }}>
							<div className="title">
                                <img src={NPIfaddedIcon} alt="platformLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }}/>
							</div>
							<div>
								<h3 className="obsTitle1" style={{ margin: '10px 0' }}>
									Monthly NPI Dashboard
								</h3>
							</div>
							<p
								className="message2"
								title="Monitor cost of cloud resources."
								style={{ margin: '0', textAlign: 'center' }}
							>
								COMING SOON <img src ={clockIcon} style={{ position: 'relative', top: '4px' }}/>
							</p>
						</div>
					</Grid>
                    ) : (
						''
				)} 
				{capabilitiesArray.includes('Platform Cost') ? (
                    <Grid
						item
						xs={4}
						
						// onClick={(e) => handleMonthlyXOpsClick(e, 'no-blank', '/platformCostObs/executiveView')}
						// onContextMenu={(e) => {
						// 	e.preventDefault();
						// 	handleMonthlyXOpsClick(e, 'blank', '/platformCostObs/executiveView');
						// }}
					>
						<div className="observalbility_screens1" style={{ textAlign: 'center' }}>
							<div className="title">
                                <img src={ArchitechfaddedIcon} alt="platformLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }}/>
							</div>
							<div>
								<h3 className="obsTitle1" style={{ margin: '10px 0' }}>
									Monthly Architecture Dashboard
								</h3>
							</div>
							<p
								className="message2"
								title="Monitor cost of cloud resources."
								style={{ margin: '0', textAlign: 'center' }}
							>
								COMING SOON <img src ={clockIcon} style={{ position: 'relative', top: '4px' }}/>
							</p>
						</div>
					</Grid>
                    ) : (
						''
				)} 
				</Grid>
			</div>
			<Footer />
		</div>
	);
}
