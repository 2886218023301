import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const SessionTimeoutDialog = ({ open, onClose, idleout, onStayLoggedIn, countdown }: any) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "#022b5f",
          color: "white",
          width: "400px",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        sx={{
          bgcolor: "primary.main",
          color: "white",
          p: 1.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">Session Timeout due to Inactivity</Typography>
        <IconButton onClick={onClose} sx={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: "center", padding: "20px" }}>
        <Box display="flex" alignItems="center" gap={2}>
          <WarningAmberIcon sx={{ fontSize: 40, color: "yellow" }} />
          <Box textAlign="left">
            <Typography variant="h6">
              Your session will expire in <strong>{countdown} Sec</strong>
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.8 }}>
              Do you want to stay logged in?
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between", padding: "20px" }}>
        <Button onClick={idleout} sx={{ color: "#00A3E0", fontWeight: "bold", textTransform: "uppercase" }}>
          NO, LOGOUT
        </Button>
        <Button onClick={onStayLoggedIn} variant="contained" color="primary" sx={{ textTransform: "uppercase" }}>
          YES, KEEP ME LOGGED IN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
