import '../../src/components/LandingPage/LandingPage.scss';
import { Grid, Typography, Button } from '@mui/material';
import { useEffect, useState, lazy, Suspense } from 'react';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import { deleteCookie } from '../service/service';

const Header = lazy(() => import('../../src/components/LandingPage/Header'));
const Footer = lazy(() => import('../../src/components/LandingPage/ModuleLandingFooter'));

export default function SessionLogout() {
    const [memberData, setMemberData] = useState<any>(null);
    const [controller, _] = usePepwiseAuthController();
    const { accessToken } = controller;

    useEffect(() => {
        setMemberData(accessToken);
    }, []);
    const idleout = () => {
            deleteCookie('id_token');
            window.location.href = "/";
        };

    return (
        <div className="outerLandingPageContainer">
            <Header memberData={memberData} />
            
            <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh' }}>
                <Grid item xs={10} sm={8} md={6} lg={4} textAlign="center">
                    <Typography variant="h5" color="white" gutterBottom>
                        Your session is expired.
                    </Typography>
                    <Typography variant="body1" color="white" gutterBottom>
                        For security reasons, you have been automatically logged out due to 15 mins of inactivity.
                    </Typography>
                    <Button variant="contained" color="primary" onClick={idleout} sx={{ mt: 2 }}>
                        Go Back to Login
                    </Button>
                </Grid>
            </Grid>

            <Footer />
        </div>
    );
}
