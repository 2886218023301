import {  Accordion, AccordionDetails, AccordionProps, AccordionSummary, Box, styled, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AreaLineChartWidget from './AreaLineChartWidget';
import { ExpandMore } from '@mui/icons-material';
import Insights from 'components/DataEstate/Insights';
import { useEffect } from 'react';
import LoaderComponent from 'components/Loader';

interface Insight {
	id: number;
	title: string;
	description: string;
}

// Define the component props
interface WidgetProps {
	isLoading: boolean;
	title: string;
	value: string | number;
	data?: any[];
	change: string | number;
	changeType: 'increase' | 'decrease' | string;
	colorMapping?: any;
	gradientMapping?: any;
	hasLegend?: any;
	handleEvent?: () => void;
	loader?: boolean;
	fetchInsights: () => void;
	insights: Insights[];
	setInsights: React.Dispatch<React.SetStateAction<Insights[]>>;
	onAdd: (insight: Insight) => void;
	onUpdate: (insight: Insight) => void;
	onDelete: (id: number) => void;
}

const AreaInfoWidget = ({
	isLoading = false,
	title = '',
	data,
	value,
	change,
	changeType,
	colorMapping,
	gradientMapping,
	hasLegend,
	handleEvent,
	loader,
	fetchInsights,
	insights,
	setInsights,
	onAdd,
	onUpdate,
	onDelete
}: Readonly<WidgetProps>) => {
	const handleAccordionChange = async (_event: React.SyntheticEvent, isExpanded: boolean) => {
		if (isExpanded) 
			fetchInsights(); 
	};
	useEffect(() => {
		document.body.classList.add('accord');
	}, [])
	
	return (
		<Box
			sx={{
				backgroundColor: '#003771',
				display: 'flex',
				flexDirection: 'column',
				color: 'white',
				padding: '16px',
			}}
		>
			<Box
				sx={{
					backgroundColor: '#003771',
					display: 'flex',
					flexDirection: 'row',
					color: 'white',
					height: '170px'
				}}
			>
				{/* Left Section (45%) */}
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: '45%', 
					cursor: 'pointer' }} onClick = {handleEvent}>
					<Typography variant="body1" fontWeight={600}>
						{title}
					</Typography>
					<Typography variant="h3" fontWeight={500} mt={1}>
						{value}
					</Typography>

					{/* Change Indicator */}
					{!hasLegend ? <Box display="flex" alignItems="center" mt={1} color={changeType === 'increase' ? 'red' : 'green'}>
						{changeType === 'increase' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
						<Typography variant="body2" fontWeight={600} ml={0.5}>
							{change}% vs previous month
						</Typography>
					</Box> : 
					<Box display="flex" alignItems="center" mt={1}></Box>}
				</Box>
				{/* Right Section (55%) */}
				<AreaLineChartWidget
					data={data}
					isLoading={isLoading}
					colorMapping={colorMapping}
					gradientMapping={gradientMapping}
					hasLegend = {hasLegend}
					handleEvent = {handleEvent}
				/>
			</Box>
			<Box sx={{paddingTop: '10px', backgroundColor: '#003771 !important'}}>
				<Accordion className='accordion' onChange={handleAccordionChange} sx={{ boxShadow: 'none' }}>
					<AccordionSummary sx={{ backgroundColor: '#003771 !important', color: 'white' }} expandIcon={<ExpandMore sx={{color:'white !important'}}/>}>
						<Typography>Insights/Actions</Typography>
					</AccordionSummary>
					{loader ? (
					<LoaderComponent bgColor = '#003771'/>
					) : (
						<AccordionDetails className='accord'>
							<Insights
								insights={insights}
								setInsights={setInsights}
								onAdd={onAdd}
								onUpdate={onUpdate}
								onDelete={onDelete}
							/>
						</AccordionDetails>
					)}
				</Accordion>
			</Box>
		</Box>
	);
};

export default AreaInfoWidget;
