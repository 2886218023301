import Axios from 'axios';
import { deleteCookie, getCookie } from './service/service';
import { isLocalEnv } from '../src/service/config';
import eventEmitter from 'SnackBarEventEmitter';

let hasShownMessage = false;

const ssoLoginLocalStore = localStorage.getItem('sso-login');
const ssoLogin = ssoLoginLocalStore ? ssoLoginLocalStore === 'true' : process.env.REACT_APP_SSO_LOGIN === 'true';
const sessionCookie = 'id_token';

Axios.interceptors.request.use(function (config: any) {
	if (!config.url.includes('/job/schedules')) document.body.classList.add('loading-indicator');
	if (isLocalEnv) {
		config.headers['id_token'] = getCookie('id_token')
	}
	return config;
});

Axios.interceptors.response.use(
	function (response: any) {
		if (!response.config.url.includes('/job/schedules')) document.body.classList.remove('loading-indicator');

		const endpointPattern = /(finOps\/(editProgram|createProgram|deleteProgram|createProject|editProject|deleteProject))/;
		if (endpointPattern.test(response.config.url) && response.data?.success) {
			const successMessage = response.data?.data?.data;
			eventEmitter.emit('showSnackbar', successMessage, 'success');
		}
		else if (response.config.url.includes("/finops/mappinglist") && response.data?.success) {
			const Alerts = response.data?.data?.dataconcerns;
			if (Alerts && Array.isArray(Alerts) && !hasShownMessage) {
				const alertMessages = Alerts.map((alert: any) => {
					const { Field, "Data Available From" : dataAvailableFrom, "Impacts on": impactsOn } = alert;
					return `${Field} Field: Data available only from ${dataAvailableFrom}. This impacts ${impactsOn}.`;
				  }).join('\n');
					eventEmitter.emit('showSnackbar', alertMessages.trim(), 'info');
					hasShownMessage = true;
			}
		}else if (response.config.url.includes("/finOps/createAllocation") && response.data?.success) {
			const successMessage = response.data?.data?.result;
			eventEmitter.emit('showSnackbar', successMessage, 'success');
		}
		return response;
	},
	async function (error) {
		if (!error.config.url.includes('/user/login') && error.response.status === 401) {
			if (!isLocalEnv) deleteCookie(sessionCookie);
		}
		document.body.classList.remove('loading-indicator');
		if (ssoLogin && error.response.status === 401) {
			if (!isLocalEnv) location.replace('/sso/login');
		}
		if (error.config.url.includes('dataPipeline')) {
			if (error.response.status >= 500) {
				// Emit an event to show the Snackbar
				eventEmitter.emit('showSnackbar', 'An error occurred on the server. Please try again later.');
			}
			if (error.response.status >= 400 && error.response.status < 500) {
				// Emit an event to show the Snackbar
				if (error.response?.data?.data?.message.includes('already exists')) {
					eventEmitter.emit('showSnackbar', error.response?.data?.data?.message);
				} else {
					eventEmitter.emit('showSnackbar', 'Something went wrong. Please try again later.');
				}
			}
		}
		if (error.config.url.includes('finOps')) {

			const isExcludedPath = ['finOps/createProgram', 'finOps/createProject', 'finOps/editProject','finOps/editProgram'].some((endpoint) => error.config.url.includes(endpoint));
			if (!isExcludedPath) {
				if (error.response.data?.data?.message) {
					eventEmitter.emit('showSnackbar', error.response.data?.data?.message);
				}
			}
			else {
				if (!isExcludedPath) {
					if (error.response.status >= 500) {
						// Emit an event to show the Snackbar
						eventEmitter.emit('showSnackbar', 'An error occurred on the server. Please try again later.');
					}
					if (error.response.status >= 400 && error.response.status < 500) {
						// Emit an event to show the Snackbar
						eventEmitter.emit('showSnackbar', 'Something went wrong. Please try again later.');
					}
				}
			}
		}

		return await Promise.reject(error);
	}
);
