import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, debounce, Dialog, Grid, InputAdornment, Snackbar, Stack, Typography } from "@mui/material";

import PageHeader from "components/Header/Page";
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import { usePepwiseAuthController } from "Context/contexts/AuthContext";
import { getProgramList, getProgramSectorList, getProjectListByProgram } from "service/service";
import TitleStatus from "components/Widgets/TitleStatus";
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg';
import Loader from "components/Loader/loader";
import { DataPipelineModal } from "./modal-component";
import { CustomizedDataGrid } from "components/customized_data_grid";
import RowTypography from "components/Table/RowTypography";
import { Link } from "react-router-dom";
import StatusChip from "components/Table/StatusChip";
import moment from "moment";
import SectorFilter from "components/Dropdown/SectorFilter";
import { checkRole } from "utils/roleUtils";
import CustomTextField from "components/Inputs/CustomTextField";
import { Search } from "@mui/icons-material";

const ProgramsDashboard = () => {
	const [openModal, setOpenModal] = useState<any>({ isOpen: false });
	const [programList, setProgramList] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [currentProgram, setCurrentProgram] = useState<any>({});
	const [currentProjectSLAFilter, setCurrentProjectSLAFilter] = useState<string>('');
	const [currentFilterType, setCurrentFilterType] = useState<string>('');
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [projectList, setProjectList] = useState<any>([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [controller] = usePepwiseAuthController();
	const { role } = controller;
	const [anchorElDetails, setAnchorElDetails] = React.useState<null | HTMLElement>(null);
	const defaultColumnNames = ['Sectors', 'Project', 'Delayed Start', 'Long Running', 'Failed', 'Success', 'SLA Status', 'SLA Time (CST)'];
	const [filterData, setFilterData] = useState<any[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [filterInfo, setFilterInfo] = useState([]);	
	const [sectorMasterList, setSectorMasterList] = useState<any[]>([]);
	const [sectorList, setSectorList] = useState<any[]>([]);
	const [sectorName, setSectorName] = useState('all');
	const [searchProgram, setSearchProgram] = useState('');
	const [toast, setToast] = useState<any>({
		open: false,
		message: ''
	});
	const hiddenSector = "SODASTREAM";
	// const updatedSectorOptionsList = sectorList
    // .filter(sector => sector !== hiddenSector) 
    // .map(sector => {
    //     if (sector === "Europe") {
    //         return sector.toUpperCase(); 
    //     }
    //     return sector;
    // });
	const filteredSectorList = sectorList.filter(sector => sector !== hiddenSector);
	const messagesEndRef = useRef<null | HTMLDivElement>(null);
	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};
	const isAllowed = checkRole(role, ['Data Operations Admin', 'PepWise System User']);
	const getColumns = () => {
		return [
			{
				key: 'Sectors',
				name: 'Sectors',
				formatter(props: any) {
					const sectorList = props.row.Sectors ? props.row.Sectors.split(', ') : [];
					if (sectorList && sectorList.length) {
						return sectorList.map((sector: string, idx: number) => <RowTypography sx={{ backgroundColor: 'rgba(41, 155, 255, 0.15)', color: '#fff', padding: '4px 10px', marginRight: '5px', borderRadius: '20px' }} key={idx} title={sector}>{sector}</RowTypography>);
					} else {
						return '--'
					}
				},
				minWidth: 150,
				maxWidth: 250
			},
			{
				key: 'Project_Name',
				name: 'Project',
				formatter(props: any) {
					return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(currentProgram.Program_Name)}/${encodeURIComponent(props.row.Project_Name)}/${moment().tz("America/Chicago").format("YYYY-MM-DD")}`} state={props.row} title={props.row.Friendly_Name}>{props.row.Project_Name}</Link>
				},
				minWidth: 200,
				maxWidth: 500
			},
			{
				key: 'IP_Delayed_Start',
				name: 'Delayed Start',
				formatter(props: any) {
					if (props.row.IP_Delayed_Start && props.row.IP_Delayed_Start != 'NA') {
						return <StatusChip displayNumber={true} status='IP_Delayed_Start' value={props.row.IP_Delayed_Start ? props.row.IP_Delayed_Start : '--'} />
						// return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(currentProgram.Program_Name)}/${encodeURIComponent(props.row.Project_Name)}?jobStatus=Delay Start`} state={props.row}>
						// 	<StatusChip displayNumber={true} status='IP_Delayed_Start' value={props.row.IP_Delayed_Start ? props.row.IP_Delayed_Start : '--'} />
						// </Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'IP_Long_Running',
				name: 'Long Running',
				formatter(props: any) {
					if (props.row.IP_Long_Running && props.row.IP_Long_Running != 'NA') {
						return <StatusChip displayNumber={true} status='IP_Long_Running' value={props.row.IP_Long_Running ? props.row.IP_Long_Running : '--'} />
						
						// return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(currentProgram.Program_Name)}/${encodeURIComponent(props.row.Project_Name)}?jobStatus=Long Running`} state={props.row}>
						// 	<StatusChip displayNumber={true} status='IP_Long_Running' value={props.row.IP_Long_Running ? props.row.IP_Long_Running : '--'} />
						// </Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'Failed',
				name: 'Failed',
				formatter(props: any) {
					if (props.row.Failed && props.row.Failed != 'NA') {
						return <StatusChip displayNumber={true} status='JobsFailed' value={props.row.Failed ? props.row.Failed : '--'} />
						// return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(currentProgram.Program_Name)}/${encodeURIComponent(props.row.Project_Name)}?jobStatus=Failure`} state={props.row}>
						// 	<StatusChip displayNumber={true} status='JobsFailed' value={props.row.Failed ? props.row.Failed : '--'} />
						// </Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'Success',
				name: 'Success',
				formatter(props: any) {
					if (props.row.Success && props.row.Success != 'NA') {
						return <StatusChip displayNumber={true} status='JobsSuccess' value={props.row.Success ? props.row.Success : '--'} />
						// return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${encodeURIComponent(currentProgram.Program_Name)}/${encodeURIComponent(props.row.Project_Name)}?jobStatus=Successful`} state={props.row}>
						// 	<StatusChip displayNumber={true} status='JobsSuccess' value={props.row.Success ? props.row.Success : '--'} />
						// </Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'SLA_Status',
				name: 'SLA Status',
				formatter(props: any) {
					if (props.row.SLA_Status == 'NA' || props.row.SLA_Status == '--') {
						return <div style={{textAlign: 'center'}}>{props.row.SLA_Status}</div>
					} else if (props.row.SLA_Status == '' || props.row.SLA_Status == null) {
						return ''
					}
					return <StatusChip displayNumber={true} status='ProjectSLAStatus' value={props.row.SLA_Status} />
				},
				minWidth: 150
			},
			{
				key: 'SLA_Time',
				name: 'SLA Time (CST)',
				formatter(props: any) {
					return (
						<RowTypography textTransform="capitalize">
							{
								props.row.SLA_Date != null && props.row.SLA_Date > 0 ? moment(props.row.Schedule_Date ? props.row.Schedule_Date.split('T')[0] : '').add(props.row.SLA_Date, 'days').format('MM-DD-YY')
								: moment(props.row.Schedule_Date ? props.row.Schedule_Date.split('T')[0] : '').format('MM-DD-YY')
							} {' '}
							{props.row.SLA_Time
								? props.row.SLA_Time < 10
									? `000${props.row.SLA_Time}`.replace(/(.{2})$/, ':$1')
									: props.row.SLA_Time < 100
										? `00${props.row.SLA_Time}`.replace(/(.{2})$/, ':$1')
										: props.row.SLA_Time.toString().replace(/(.{2})$/, ':$1')
								: '--'}
						</RowTypography>
					);
				},
				minWidth: 150
			}
		];
	};

	const handleFilter = (e: any) => {
		console.log('FILTER', e);
	};

	const handleSearch = (e: any) => {
		console.log('FILTER', e);
	};

	const handleSorting = (sortingInfo: any) => {
		setSortingInfo(sortingInfo);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		getProjectsByProgram(currentProgram, value, pageSize);
	};

	const getToastData = (data: any) => {
		setToast({
			...toast,
			message: data.data.message
		});
	};

	const handleClose = () => {
		setToast({
			...toast,
			open: false
		});
	};

	const handlepageSizeChange = (e: any) => {
		getProjectsByProgram(currentProgram, page, e.target.value);
	};
	const onCreateProgramClick = useCallback(() => {
		setOpenModal({
			isOpen: true,
			type: 'Program',
			action: 'CREATE',
			data: ''
		});
	}, []);

	const handleDetailsThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(event.currentTarget);
	};
	const handleDetailsThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(null);
	};

	const handleProjectSLA = (programData: any, filterType: string) => {
		setCurrentFilterType(filterType);
		setCurrentProjectSLAFilter(filterType);
		getProjectsByProgram(programData, 1, 100, filterType, getSectorId())
	}

	const onClickAddProject = (event: any, prgId: string) => {
		setOpenModal({
			...openModal,
			isOpen: true,
			type: 'Project',
			action: 'CREATE',
			data: { ProgramID: prgId }
		});
	}

	const handleEditClose = useCallback((closeType: any) => {
		setOpenModal({
			isOpen: false,
			closeType: closeType
		});
	}, []);

	const handleAction = (module: string, type: string, data: any, e: any) => {
		e.preventDefault();
		e.stopPropagation();
		handleDetailsThreeDotsClose(e);
		data = {
			...data,
			ProgramName: data.Program_Name,
			ProgramID: data.Program_id
		};
		switch (type) {
			case 'edit':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'EDIT',
					data: data
				});
				break;
			case 'delete':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'DELETE',
					data: data
				});
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}		
		if (localStorage.getItem('filterSectors') && localStorage.getItem('sectorsMasterData')) {
			setSectorList(JSON.parse(localStorage.getItem('filterSectors') ?? ''));
			setSectorMasterList(JSON.parse(localStorage.getItem('sectorsMasterData') ?? ''));
		} else {
			getSectorData();
		}
		const selSector = localStorage.getItem('selectedSector');
		if (selSector && selSector != 'all') {
			setSectorName(selSector)
		}
		getProgramData(getSectorId());
	}, []);

	useEffect(() => {
		if (openModal && !openModal.isOpen && openModal.closeType && openModal.closeType == 'updated') {
			getProgramData();
			setSearchProgram('');
		}
	}, [openModal]);	

	const handleSearchData = (searchText: string) => {
		setSearchProgram(searchText);
		debouncedFetchOptions(searchText);
	};
	const debouncedFetchOptions = useMemo(
		() =>
			debounce(async (searchText: string) => {
				try {
					getProgramData(getSectorId(), searchText);
					setProjectList([]);
				} catch (error) {
					console.error(error);
				}
			}, 500),
		[]
	);

	const handleSectorChange = (value: string) => {
		setSectorName(value);
		localStorage.setItem('selectedSector', value);		
		getProgramData(getSectorId());
		setProjectList([]);
	};

	const getSectorId = () => {
		let sectorId;
		let selectedSctr = localStorage.getItem('selectedSector')
		if (selectedSctr && selectedSctr != 'all') {
			const sectorList = JSON.parse(localStorage.getItem('sectorsMasterData') ?? '')
			if (sectorList) {
				sectorId = sectorList.filter((s: any) => s.Sector_Name == selectedSctr)[0].Sector_Id
			}
		}
		return sectorId;
	}
	
	const getSectorData = async () => {
		let sectors: any = await getProgramSectorList();
		const filterSectors = sectors.data.sectors.map((sec: any) => sec.Sector_Name);
		localStorage.setItem('filterSectors', JSON.stringify(filterSectors));
		localStorage.setItem('sectorsMasterData', JSON.stringify(sectors.data.sectors));
		setSectorList(filterSectors);
		setSectorMasterList(sectors.data.sectors);
	};

	const getProgramData = async (sectorId?: number, searchKeyword?: string) => {
		setLoading(true);
		let data: any = await getProgramList(sectorId, searchKeyword);
		setLoading(false);
		setProgramList(data.data.programData);
	};

	const getProjectsByProgram = async (data: any, page: number = 1, pageSize: number, filterType?: string, sectorId?: number) => {
		setTableLoading(true);
		setCurrentProgram(data);
		let result = await getProjectListByProgram(data.Program_id, page, pageSize, filterType, {}, '', '', sortingInfo, sectorId);
		setTimeout(() => {
			let table = document.getElementById(`project_list${data.Program_id}`);
			if (table) {
				const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
				if (rdgElement) {
					let jobsLength = result.data.projectList.length;
					let height = 80;
					if (jobsLength === 1) height = jobsLength * 80;
					else if (jobsLength > 1 && jobsLength <= 3) height = jobsLength * 65;
					else if (jobsLength > 3 && jobsLength <= 5) height = jobsLength * 55;
					else if (jobsLength > 5 && jobsLength <= 10) height = jobsLength * 50;
					else if (jobsLength > 10 && jobsLength <= 15) height = jobsLength * 40;
					else height = jobsLength * 30;
					rdgElement.style.height = `${height}px`;
				}
			}
		}, 100);
		setPaginationData(result.data.page);
		setTableLoading(false);
		const projData = result.data.projectList.map((list: any) => {
			list['activeRow'] = 0;
			const selectedSectors = list.Sectors && list.Sectors.split(', ');
			list['Sectors'] = selectedSectors && selectedSectors.length >= 10 ? ['All 10 Sectors'] : list.Sectors;
			return list;
		});
		setProjectList(projData);
		setTimeout(() => {
			scrollToBottom();
		}, 500);
	};

	return (
		<Box bgcolor="blue.darker" mt="60px">
			<PageHeader title="Programs Dashboard" icon={DashboardIcon} lastSync={'Today'} isDataRefresh={true}>
				<CustomTextField
					className="search-prgm"
					placeholder="Search Programs"
					value={searchProgram}
					onChange={(e) => handleSearchData(e.target.value)}
					style={{ marginTop: '3px', backgroundColor: 'rgba(0, 35, 73, 1)', borderRadius: '5px', zIndex: '99'}}
					sx={{
						backgroundColor: 'rgba(0, 35, 73, 1)'
					}}
					InputLabelProps={{
						sx: {
							backgroundColor: 'rgba(0, 35, 73, 1)'
						}
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search sx={{ color: 'white.main' }} />
							</InputAdornment>
						),
						sx: {
							'& .MuiInputBase-input': {
								padding: '10px',
								paddingLeft: 0
							},
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: 'dark.dark',
								borderWidth: '0px',
								borderStyle: 'solid'
							},
							'&:hover .MuiOutlinedInput-notchedOutline': {
								borderColor: 'dark.dark',
								borderWidth: '0px'
							},
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: 'dark.dark',
								borderWidth: '0px'
							}
						}
					}}
				/>
				<SectorFilter 
					sectorName={sectorName}
					setSectorName={setSectorName}
					sectorOptionsList={filteredSectorList}
					handleChange={handleSectorChange} 
					boxProps={{
						sx: {minWidth: '200px', position: 'static'},
					}}/>

				{isAllowed && (
					<Button variant="pepwiseSecondary" onClick={onCreateProgramClick} sx={{ height: '43px'}}>
						CREATE PROGRAM
					</Button>
				)}
			</PageHeader>

			<Box p={3} mt={2}>
				{!loading ? (
					<Grid container spacing={2}>

						{programList.map((program: any, idx: number) => (
							<Grid key={idx} container item xs={12} sm={6} md={4}>
								<TitleStatus
									title={program.Program_Name}
									programId={program.Program_id}
									isEditEnabled={isAllowed ? true : false}
									projectCount={program.ProjectCount}
									projectSLAs={[
										{ label: 'SLA Missed', count: program.SLA_Missed_Count, onBtnClick: (e) => handleProjectSLA(program, 'SLA Missed') },
										{ label: 'SLA Met', count: program.SLA_Met_Count, onBtnClick: (e) => handleProjectSLA(program, 'SLA Met') },
										{ label: 'In Progress', count: program.In_Progress_Count || 0, onBtnClick: (e) => handleProjectSLA(program, 'In Progress') },
										{ label: 'Scheduled', count: program.Scheduled_Count || 0, onBtnClick: (e) => handleProjectSLA(program, 'Scheduled') }
									]}
									statusCount={program.SLA_Missed_Count + program.SLA_Met_Count + (program?.Scheduled_Count ? program.Scheduled_Count : 0) + (program?.In_Progress_Count ? program.In_Progress_Count : 0)}
									infoTooltip={{
										dateValues: [
											{ label: 'Date Created', value: program?.Created_dt ?? '' },
											{ label: 'Last Updated', value: program?.Updated_dt ?? '' }
										]
									}}
									infoOptionMenu={{
										onClick: handleDetailsThreeDotsClick,
										onClose: handleDetailsThreeDotsClose,
										menuItems: [
											{
												key: 'edit',
												label: 'Edit',
												icon: <EditIcon />,
												onMenuClick: (e) => handleAction('Program', 'edit', program, e)
											},
											{
												key: 'delete',
												label: 'Delete',
												icon: <DeleteIcon />,
												onMenuClick: (e) => handleAction('Program', 'delete', program, e)
											}
										],
										sx: { width: '20px', height: '20px' }
									}}
									onClickAddProject={(e) => onClickAddProject(e, program.Program_id)}
								/>
							</Grid>
						))
						}

						{
							!programList.length && <Grid container item xs={12}>
									<Stack justifyContent="center" alignItems="center" height="60vh" width="100%">
									<Typography variant="pageTitle" color="dark.main">
										No data for the selected filters
									</Typography>
								</Stack>
							</Grid>
						}

					</Grid>
				) : (
					<Loader />
				)
				}
			</Box>

			{projectList.length > 0 && (
				<Box p={3} sx={{position: 'relative'}}>
					<Typography variant="overlayWidgetTitle" style={{ color: '#E5E5E5', position: 'absolute', top: 0 }} ref={messagesEndRef}>{currentProgram.Program_Name}</Typography>
						 {/* <Typography variant="subtitle2" component="span" style={{ marginLeft: '12px', color: '#F5F5F5', fontWeight: 400 }}>{currentProjectSLAFilter} Projects</Typography> */}
						 

					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={projectList}
						tableType="programs-dashboard"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						searchDataValues={searchDetails}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={projectList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isFetching={tableLoading}
						showExport={false}
						isDateRangeFilter={false}
						isDisabled={false}
						isShowMoreButton={false}
					/>
				</Box>
			)}
			{tableLoading && <Loader />}

			<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
				<DataPipelineModal handleEditClose={handleEditClose} programData={openModal} 
					getToastData={getToastData}
				/>
			</Dialog>
			<Snackbar
				open={toast && openModal.message}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				autoHideDuration={6000}
				onClick={handleClose}
				message={openModal.message}
			/>
		</Box>
	);
}

export default ProgramsDashboard;