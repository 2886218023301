import { Box, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import LoaderComponent from 'components/Loader';
import { addOrUpdateInsights, deleteInsights, getInsights } from 'service/service';
import Insights from 'components/DataEstate/Insights';
import { useSearchParams } from 'react-router-dom';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';

const PlatformInsightsView = () => {
	const [insights, setInsights] = useState<Insights[]>([]);
	const [loader, setLoader] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [title, setTitle] = useState<string>('');
	const [paramName, setParamName] = useState<any>('');

	// Ensure the Insight interface is imported from Insights.tsx
	interface Insights {
		insightId: number;
		title: string;
		description: string;
		opsType: string;
		createdBy: string;
		updatedBy: string;
		createdDate: string;
		updatedDate: string;
	}

	//Function to fetch insights
	const fetchInsights = async (typeName?: any) => {
		setLoader(true);
		try {
			const response = await getInsights(typeName ? typeName : paramName);
			if (response.success) {
				setInsights(response.data.data);
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to get insights:', error);
		}
	};

	// Function to handle adding a new insight
	const handleAddInsight = async (newInsight: any) => {
		try {
			setLoader(true);
			const response = await addOrUpdateInsights({
				title: newInsight?.title,
				xopsType: paramName,
				description: newInsight?.description
			});
			if (response.success) {
				fetchInsights();
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to add insight:', error);
		}
	};

	// Function to handle updating an insight
	const handleUpdateInsight = async (updatedInsight: any) => {
		try {
			setLoader(true);
			const response = await addOrUpdateInsights({
					title: updatedInsight?.title,
					xopsType: paramName,
					description: updatedInsight?.description,
					insight_id: updatedInsight?.id
				});
			if (response.success) {
				fetchInsights();
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to update insight:', error);
		}
	};

	// Function to handle deleting an insight
	const handleDeleteInsight = async (insightId: number) => {
		try {
			setLoader(true);
			const response = await deleteInsights(insightId);
			if (response.success) {
				fetchInsights();
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to delete insight:', error);
		}
	};

	// Fetch insights
	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/D&AIXOps/platformCostSummary/insightView') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.remove('root');
			}
		} else {
			if (activeId) {
				activeId.classList.add('root');
			}
		}
		let typeName = searchParams.get('type');
		setParamName(typeName);
		if(typeName === 'Integration Platform')
			setTitle('Integration (Informatica) Platform Cost (YTD)');
		else if(typeName === 'Visualization Platform')
			setTitle('Data Visualization Platform (YTD)');
		else if(typeName === 'Virtualization Platform')
			setTitle('Data Virtualization Platform (YTD)');
		else if(typeName === 'OnPerm')
			setTitle('OnPrem Vs Cloud Cost (YTD)');
		else if(typeName === 'Other Platforms')
			setTitle('Other Platforms Cost (YTD)');
		fetchInsights(typeName);
	}, []);

	return (
		<Box bgcolor="blue.darker" mt="80px">
			<PageHeader title={[
				{
					text: 'Platform Cost Summary',
					link: '/D&AIXOps/platformCostSummary'
				},
				{
					text: title
				}
			]} icon={DashboardIcon}>
			</PageHeader>
			<Box marginTop={4} pl={3} pr={3}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Typography sx={{ color: '#009BDB', fontSize: '16px', fontWeight: 600 }}>Insights/Actions</Typography>
					<Divider sx={{ flexGrow: 1, backgroundColor: '#009BDB', marginLeft: 2 }} />
				</Box>
				<Typography
					variant="body2"
					sx={{ fontStyle: 'italic', color: '#71758F', fontSize: '14px', fontWeight: '500', marginBottom: 5 }}
				>
					You can display and update Insights/Actions if any.
				</Typography>
			</Box>
			{loader ? (
				<LoaderComponent />
			) : (
				<Box sx={{ mb: 3 }}>
					<Insights
						insights={insights}
						setInsights={setInsights}
						onAdd={handleAddInsight}
						onUpdate={handleUpdateInsight}
						onDelete={handleDeleteInsight}
					/>
				</Box>
			)}
		</Box>
	);
};
export default PlatformInsightsView;
