import {
	Button,
	Stack,
	Typography,
	Box,
	Link,
    Chip
} from '@mui/material';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import RowTypography from 'components/Table/RowTypography';
import React, { useEffect, useState} from 'react';

import { ReactComponent as ResourceMapping } from '../../../../src/assets/resourceMappingNew.svg';
import styled from '@emotion/styled';
import {ReactComponent as WarningImage} from '../../../assets/warning.svg';
import dayjs, { Dayjs } from 'dayjs';
import { TableRow } from './ResourceAllocationModal';
import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import moment from 'moment';

const StyledConfirmationIcon = styled(ResourceMapping)({
	width: '5rem',
	height: '5rem'
});

// interface TableRow {
// 	id: number;
// 	isNew: boolean;
// 	isEditing: boolean;
// 	PepwiseEnvironment: string;
// 	PepwiseProject: string;
// 	PepwiseProgram: string;
// 	PepwiseSector: string;
// 	AllocPercent: string;
// 	startDate: Dayjs;
// 	endDate: Dayjs | 'current';
// }

type ModalWidth = "sm" | "md" | "lg" | "xl";

interface ResourceAllocationSuccessModalProps {
    onTitleChange: (newtitle: string) => void;
    onWidthChange: (newWidth: ModalWidth) => void;
    onCancel: () => void;
    modalTitle: string;
    setIsSuccessModalOpen: any;
    allocationResponse: any;
		allocationType: {
			key: string;
			label: string;
		};
}

const commonChipStyles = {
	color: 'white',
	whiteSpace: 'normal',
	overflow: 'visible',
	maxWidth: 'none'
};

const ResourceAllocationSuccessModal: React.FC<ResourceAllocationSuccessModalProps> = ({ onTitleChange, onWidthChange, modalTitle, onCancel, setIsSuccessModalOpen, allocationResponse, allocationType }) => {
	const defaultColumnNames = ['Resource', 'Sector', 'Environment', 'Program', 'Project', 'Allocation%', 'Start Date', 'End Date'];

	const columnNames = [
		{ name: 'sector', label: 'Sector' },
		{ name: 'environment', label: 'Environment' },
		{ name: 'program', label: 'Program' },
		{ name: 'project', label: 'Project' },
		{ name: 'allocation', label: 'Allocation%' },
		{ name: 'startDate', label: 'Start Date' },
		{ name: 'endDate', label: 'End Date' }
	];

	const [rows, setRows] = useState<any>([]);
	
	useEffect(() => {
		if (allocationResponse) {
			const responseData = allocationResponse ?? [];
			const data = {
				sectors: new Set(),
				projects: new Set(),
				programs: new Set(),
				allocations: [] as number[],
				startDate: String(),
				endDate: String()
			};

			if (Array.isArray(allocationResponse) && allocationResponse.length > 0) {
				responseData?.forEach((item: any) => {
					data.sectors.add(item.sector);
					data.projects.add(item.project);
					data.programs.add(item.program);
					data.allocations.push(item.allocation);
					data.startDate = item.startDate;
					data.endDate = item.endDate;
				});

				setRows([
					{
						id: crypto.randomUUID(),
						// [allocationType.key]: responseData[0]?.resource,
						resource: responseData[0]?.resource,
						sector: Array.from(data.sectors),
						program: Array.from(data.programs),
						project: Array.from(data.projects),
						environment: responseData[0].environment,
						allocation: `(${data.allocations.map((perc) => `${perc}%`).join(', ')})`,
						values: responseData?.map((res: any) => ({
							...res,
							id: crypto.randomUUID(),
							startDate: moment(res.startDate).format('YYYY-MM-DD'),
							endDate:
								res.endDate === '9999-12-31' || res.endDate === ''
									? moment().format('YYYY-MM-DD')
									: moment(res.endDate).format('YYYY-MM-DD')
						}))
					}
				]);
			}
		}
	}, [allocationResponse]);

	const handleCancel = () => {
		setIsSuccessModalOpen(false);
		onCancel();
	};

	function toggleSubRow(id: string, resourceTypes: any) {
		setRows((prev: any) => {
			const rows = [...prev];
			const rowIndex = rows.findIndex((r: any) => r.id === id);
			const row = rows[rowIndex];
			const newRows = [...rows];
			let children: any[] = [];
			let childRows: any[] = [];
			if (!row.isExpanded) {
				if (resourceTypes?.length > 0) {
					childRows = resourceTypes;
				}
				children = childRows.map((x: any) => ({
					...x,
					isChildren: true
				}));
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: true, children };

				row.isExpanded = true;
			} else {
				children = row.children ?? [];
				newRows.splice(Number(rowIndex) + 1, children.length);
				newRows[rowIndex] = { ...row, isExpanded: false, children: [] };

				row.isExpanded = false;
			}
			return newRows;
		});
	}
	
	function getColumns(): Array<any> {
		return [
			{
				// name: allocationType.key,
				// label: allocationType.label
				name: 'resource',
				label: 'Resource'
			},
			...columnNames
		].map((col: any, idx: number) => ({
			key: col.name,
			name: col.label,
			editable: () => col.isEditable,
			formatter({ row, column, isCellSelected }: any) {
				return (
					<Stack direction="row" alignItems="center" spacing={1}>
						{/* {column.key === allocationType.key && !!row.values && row.values.length > 0 && ( */}
						{column.key === 'resource' && !!row.values && row.values.length > 0 && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.id, row.values);
									}}
								/>
							)}
						<RowTypography isSuccessor={!row[column.key]} isEditing>
							{column.key === 'sector' || column.key === 'project' || column.key === 'program' ? (
								<Stack direction="row" spacing={1}>
								{
									<Box bgcolor="primary.main" px={1} borderRadius={3}>
										{Array.isArray(row[column.key]) && row[column.key].length > 1
											? `${row[column.key].length} ${column.name}s`
											: row[column.key]}
									</Box>
								}
							</Stack>
							) : column.key === 'allocation' ? (
								`${row[column.key] ?? 0}`
							) : (
								row[column.key]
							)}
						</RowTypography>
					</Stack>
				);
			},
			width: [allocationType.key].includes(col.name) ? 230 : ['allocation'].includes(col.name) ? 200 : 145
		}));
	}

	return (
		<>
			<Stack sx={{ padding: '15px' }}>
				<Stack sx={{ flexDirection: 'row', gap: 2 }}>
					<Box sx={{ alignItems: 'center', justifyContent: 'flex-start', width: '5rem' }}>
						<StyledConfirmationIcon />
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<Typography variant="h6" sx={{ fontWeight: 500 }}>
						{(typeof allocationResponse !== 'string') ? `Resource Allocation Successful.` : allocationResponse}
						</Typography>
                        <Typography sx={{ alignSelf: 'flex-start', color: 'light.diff' }}>
						{(typeof allocationResponse !== 'string') ? `1 Resource is allocated successfully` : ''}
							</Typography>
					</Box>
				</Stack>
				<Stack sx={{ flexDirection: 'column', gap: 3, textAlign: 'left' }}>
				{(Array.isArray(allocationResponse) && allocationResponse .length > 0) || (typeof allocationResponse !== 'string') ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={rows}
						defaultColumnNames={defaultColumnNames}
						autoSetDraggedColumns={false}
						isRenderPagination={false}
						isShowMoreButton={false}
						style={{ maxHeight: '250px' }}
					/>
				) : null}
					<Stack direction="row" gap={2} sx={{ alignSelf: 'flex-end' }}>
						<Button variant="pepwiseSecondary" onClick={handleCancel}>CLOSE</Button>
					</Stack>
				</Stack>
			</Stack>
		</>
	);
};

export default ResourceAllocationSuccessModal;
