import {
	Autocomplete,
	Box,
	FormLabel,
	Grid,
	TextField,
	Typography,
	Button,
	IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the Delete icon
import AddIcon from '@mui/icons-material/Add'; // Import the Add icon

export const SourceTab = ({ sourceData, setSourceData, programOptions, sectorOptions }: any) => {
    
	const sourceOptions = [
		{ title: 'SQL', value: 'sql' },
		{ title: 'Log analytics', value: 'loganalytics' },
		{ title: 'Cosmos', value: 'cosmos' },
		{ title: 'Elastic search', value: 'elasticsearch' }
	];

	const handleChange = ({ name, value }: any) => {
		setSourceData({ ...sourceData, [name]: value });
	};

	const handleSourceConfigChange = ({ name, value }: any) => {
		setSourceData({ ...sourceData, sourceConfig: { ...sourceData.sourceConfig, [name]: value } });
	};

	const handleFieldChange = (index: number, value: string) => {
		const duplicateCheckFields = sourceData.sourceConfig.duplicateCheckFields.map((field: string, idx: number) => {
			if(idx === index){
				field = value
			}
			return field
		})
		setSourceData({...sourceData, sourceConfig: { ...sourceData.sourceConfig, duplicateCheckFields } })
	};

	const addField = () => {
		const duplicateCheckFields = sourceData.sourceConfig.duplicateCheckFields ? [...sourceData.sourceConfig.duplicateCheckFields, ''] : ['']
		setSourceData({...sourceData, sourceConfig: { ...sourceData.sourceConfig, duplicateCheckFields } })
	};

	const removeField = (field: string) => {
		setSourceData({...sourceData, sourceConfig: { ...sourceData.sourceConfig, duplicateCheckFields: sourceData.sourceConfig.duplicateCheckFields.filter((f:string) => f !== field)} })
	};
	
	return (
        <Box sx={{ mt: 3, padding: '20px', borderRadius: '8px' }}>
			<Typography variant="body2" sx={{ opacity: 0.7, mb: 2 }}>
				ⓘ This section is to configure alert type.
			</Typography>

			<Grid container spacing={2}>
				<Grid item xs={3}>
					<Autocomplete
						id="size-small-outlined"
						size="small"
						options={programOptions}
						value={sourceData.program}
						onChange={(e, option) => handleChange({ name: 'program', value: option })}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Select Program*"
								variant="outlined"
								sx={{
									width: 300,
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: '#A9ADCB'
										},
										'&:hover fieldset': {
											borderColor: '#A9ADCB'
										},
										'&.Mui-focused fieldset': {
											borderColor: '#A9ADCB'
										}
									}
								}}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						label="Project name*"
						variant="outlined"
						size="small"
						value={sourceData.project}
						onChange={(e) => handleChange({ name: 'project', value: e.target.value })}
						sx={{
							width: 300,
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#A9ADCB'
								},
								'&:hover fieldset': {
									borderColor: '#A9ADCB'
								},
								'&.Mui-focused fieldset': {
									borderColor: '#A9ADCB'
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<Autocomplete
						id="size-small-outlined"
						size="small"
						options={sectorOptions}
						value={sourceData.sector}
						onChange={(e, option) => handleChange({ name: 'sector', value: option })}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Select Sector*"
								variant="outlined"
								size="small"
								sx={{
									width: 300,
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: '#A9ADCB'
										},
										'&:hover fieldset': {
											borderColor: '#A9ADCB'
										},
										'&.Mui-focused fieldset': {
											borderColor: '#A9ADCB'
										}
									}
								}}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={3}></Grid>
			</Grid>
			<Grid item xs={12} style={{ margin: '30px 0' }} />
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<TextField
						label="Alert name*"
						variant="outlined"
						size="small"
						value={sourceData.ruleName}
						onChange={(e) => handleChange({ name: 'ruleName', value: e.target.value })}
						sx={{
							width: 300,
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#A9ADCB'
								},
								'&:hover fieldset': {
									borderColor: '#A9ADCB'
								},
								'&.Mui-focused fieldset': {
									borderColor: '#A9ADCB'
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						label="Alert type*"
						variant="outlined"
						size="small"
						value={sourceData.alertType}
						onChange={(e) => handleChange({ name: 'alertType', value: e.target.value })}
						sx={{
							width: 300,
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#A9ADCB'
								},
								'&:hover fieldset': {
									borderColor: '#A9ADCB'
								},
								'&.Mui-focused fieldset': {
									borderColor: '#A9ADCB'
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<Autocomplete
						id="size-small-outlined"
						size="small"
						options={sourceOptions}
						getOptionLabel={(option) => option.title}
						value={sourceOptions.find((option) => option.value === sourceData.sourceConfig?.sourceType) || null}
						onChange={(e, option) => handleSourceConfigChange({ name: 'sourceType', value: option?.value })}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Source*"
								variant="outlined"
								size="small"
								sx={{
									width: 300,
									'& .MuiOutlinedInput-root': {
										'& fieldset': {
											borderColor: '#A9ADCB'
										},
										'&:hover fieldset': {
											borderColor: '#A9ADCB'
										},
										'&.Mui-focused fieldset': {
											borderColor: '#A9ADCB'
										}
									}
								}}
							/>
						)}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} style={{ margin: '30px 0' }} />
			<Grid item xs={12}>
				<FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' }, textAlign: 'left' }}>
					Query
				</FormLabel>
				<TextField
					fullWidth
					multiline
					rows={5}
					variant="outlined"
					placeholder={`${sourceData.sourceConfig?.sourceType} Query*`}
					value={sourceData.sourceConfig?.query}
					onChange={(e) => handleSourceConfigChange({ name: 'query', value: e.target.value })}
					InputProps={{
						sx: {
							width: 1100,
							color: 'white',
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: '#A9ADCB'
							},
							'&:hover .MuiOutlinedInput-notchedOutline': {
								borderColor: '#A9ADCB'
							},
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: '#A9ADCB'
							}
						}
					}}
				/>
			</Grid>
			<Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
				Duplicate Check Fields:
			</Typography>
			<Grid container spacing={2} alignItems="center" sx={{ mb: 1, flexWrap: 'wrap' }}>
				{sourceData.sourceConfig?.duplicateCheckFields?.map((field: string, index: number) => (
				<>
					<Grid item xs={4} key={index}>
						<TextField
							label={`Select Field ${index + 1}`}
							variant="outlined"
							size="small"
							value={field}
							onChange={(e) => handleFieldChange(index, e.target.value)}
							sx={{
								width: '100%',
								'& .MuiOutlinedInput-root': {
									'& fieldset': { borderColor: '#A9ADCB' },
									'&:hover fieldset': { borderColor: '#A9ADCB' },
									'&.Mui-focused fieldset': { borderColor: '#A9ADCB' }
								}
							}} />
					</Grid>
					<Grid item xs={1}>
						<IconButton onClick={() => removeField(field)} color="error" aria-label="remove field">
							<DeleteIcon />
						</IconButton>
					</Grid>
				</>
				))}

				{/* Add Field Button in the Same Row */}
				<Grid item xs={12}>
					<Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={addField} sx={{ mt: 2 }}>
						Add Field
					</Button>
				</Grid>
			</Grid>
		</Box>
    )
}