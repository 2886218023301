import { useState } from 'react';
import { Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MoreHoriz } from '@mui/icons-material';

export type MenuItemType =
	| {
			key: string;
			label: string;
			icon: JSX.Element;
			onClick: (event?: any) => void;
			disabled?: boolean;
			isExportButton?: undefined;
			isShowExportButton?: undefined;
	  }
	| {
			key: string;
			label: string;
			icon: JSX.Element;
			onClick: () => Promise<unknown>;
			disabled?: boolean;
			isExportButton: true;
			isShowExportButton: boolean;
	  };

type MoreOptionDropdownProps = {
	menuItems: MenuItemType[];
	isDisabled?: boolean;
	isShowDisabledButton?: boolean;
};

function ExportButton({
	onExport,
	disabled,
	children
}: {
	onExport: () => Promise<unknown>;
	disabled: boolean;
	children: string;
}) {
	const [exporting, setExporting] = useState(false);
	return (
		<Box
			component="span"
			color="white.main"
			sx={{
				pointerEvents: disabled || exporting ? 'none' : 'auto',
				cursor: disabled || exporting ? 'default' : 'pointer',
			}}
			onClick={async () => {
				setExporting(true);
				await onExport();
				setExporting(false);
			}}
		>
			{!exporting ? children : 'Exporting...'}
		</Box>
	);
}

const MoreOptionDropdown: React.FC<MoreOptionDropdownProps> = ({ menuItems, isDisabled = false, isShowDisabledButton=true, }) => {
	const [moreEl, setMoreEl] = useState<null | HTMLElement>(null);

	const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreEl(event.currentTarget);
	};
	const handleMoreClose = () => {
		setMoreEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				id="more-button"
				aria-controls={!!moreEl ? 'more-menu' : undefined}
				aria-expanded={!!moreEl ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleMoreClick}
				sx={{
					padding: '4px 2px',
					borderRadius: 0,
					width: '42px',
					height: '45px',
					color: 'white.main'
					// display:
					//   tableType === "ML_ModelFeatureDrifts" ||
					//   tableType == "incident-details-ver" ||
					//   tableType == "add-schedules" ||
					//   tableType == "job-schedules-1"
					//     ? "none"
					//     : "block",
				}}
				disabled={isDisabled}
			>
				<MoreHoriz />
			</IconButton>
			<Menu
				id="more-menu"
				MenuListProps={{
					'aria-labelledby': 'more-button'
				}}
				anchorEl={moreEl}
				open={!!moreEl}
				onClose={handleMoreClose}
				PaperProps={{
					style: {
						width: '13ch'
					}
				}}
			>
				{menuItems.map(({ key, label, icon, onClick, disabled, isExportButton, isShowExportButton }) => {

					// If isShowDisabledButton is false, only show the Export button
					if (!isShowDisabledButton) {
						if (isExportButton && isShowExportButton) {
						  return (
							<MenuItem key={key} onClick={onClick}>
							  <ListItemIcon>{icon}</ListItemIcon>
							  <ExportButton onExport={onClick} disabled={!!disabled}>
								{label}
							  </ExportButton>
							</MenuItem>
						  );
						}
						// Skip rendering other buttons
						return null;
					  }

					// If isShowDisabledButton is true, show all buttons (enabled/disabled)
					return (
						((isExportButton && isShowExportButton) || !isExportButton) && (
							<MenuItem key={key} disabled={!!disabled} {...(!isExportButton && { onClick })}>
								<ListItemIcon>{icon}</ListItemIcon>
								{isExportButton ? (
									<ExportButton onExport={onClick} disabled={!!disabled}>
										{label}
									</ExportButton>
								) : (
									<Box component="span" color="white.main">
										{label}
									</Box>
								)}
							</MenuItem>
						)
					)})}
			</Menu>
		</>
	);
};

export default MoreOptionDropdown;
