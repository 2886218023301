import { useState } from "react";
import {
    Button, TextField, Menu
} from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import moment from "moment";
import '../jobExecutions/jobExecutions.css';
import '../jobs/jobs.css';
import './alert-kpi.scss';

export default function DateTimeFilter({fromDateTime, setFromDateTime, toDateTime, setToDateTime, handleTimeFilter, clearTimeFilter}: any) {
    const [dateRangeMenuOpen, setDateRangeMenuOpen] = useState<null | HTMLElement>(null);
    const open = Boolean(dateRangeMenuOpen);
    const minDate = '2023-03-01';

    const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
        setDateRangeMenuOpen(event.currentTarget);
    };

    const handleDateRangeMenu = () => {
        setDateRangeMenuOpen(null);
    };

    const handleDateTimeChange = (field: "fromDateTime" | "toDateTime", dateTime: moment.Moment | null) => {
        if (field === "fromDateTime") {
            setFromDateTime(dateTime);
        } else {
            setToDateTime(dateTime);
        }
    };

    const applyDateTimeRange = () => {
        setDateRangeMenuOpen(null);
        handleTimeFilter()
    };

    return(
        <div className="job-exexutions-dateFilter-div">
            {/* [Date Range Menu] */}
            <div style={{ display: 'flex', alignItems: 'center', padding: '5px 0px 5px 0px', marginTop: '5px', marginBottom: '1px' }}>
                {/* condition for default Today  */}
                {moment(fromDateTime).format('DD MM YYYY') ===
                    moment().subtract(1, 'months').format('DD MM YYYY') &&
                    moment(toDateTime).format('DD MM YYYY') ===
                    moment().format('DD MM YYYY') ? (
                    <div style={{ marginRight: '80px' }}>One month</div>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '5px'
                        }}
                    >
                        <div>{fromDateTime && moment(fromDateTime).tz('America/New_York').format('DD-MM-YYYY hh:mm')}</div>
                        <div style={{ marginLeft: '4px', marginRight: '4px' }}>to</div>
                        <div>{toDateTime && moment(toDateTime).tz('America/New_York').format('DD-MM-YYYY hh:mm')}</div>
                    </div>
                )}

                <div onClick={handleDateClick} style={{ cursor: 'pointer' }}>
                    <DatePickerIcon />
                </div>
            </div>

            <Menu
                id="date-range-menu"
                MenuListProps={{
                    'aria-labelledby': 'date-range-button'
                }}
                anchorEl={dateRangeMenuOpen}
                open={open}
                onClose={handleDateRangeMenu}
                PaperProps={{
                    style: {
                        width: '30ch'
                    }
                }}
            >
                {/* From date */}
                <span className="range_menu_title">Absolute time range</span>
                <div key="defaultView">
                    <div className="label">From</div>
                    <LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            showToolbar={true}
                            components={{
                                OpenPickerIcon: DatePickerIcon
                            }}
                            PopperProps={{ placement: 'auto' }}
                            toolbarTitle={
                                <>
                                    Select Date Time{' '}
                                    <div style={{position: 'fixed', marginTop: '15%', color: 'white',marginBottom: '10px'}}>
                                        {`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}
                                    </div>
                                </>
                            }
                            className="dateFilter"
                            ampm={false}
                            value={moment(fromDateTime).tz('America/New_York')}
                            minDateTime={moment(minDate).tz('America/New_York').startOf('day').subtract(1, 'hour')}
                            maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hours')}
                            onChange={(dateTime) => {
                                handleDateTimeChange('fromDateTime', dateTime);
                            }}
                            renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
                        />
                    </LocalizationProvider>
                </div>

                {/* To date */}
                <div>
                    <div className="label">To</div>
                    <LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
                        <DateTimePicker
                            showToolbar={true}
                            components={{
                                OpenPickerIcon: DatePickerIcon
                            }}
                            PopperProps={{
                                placement: 'auto'
                            }}
                            toolbarTitle={
                                <>
                                    Select Date Time{' '}
                                    <div style={{position: 'fixed', marginTop: '15%', color: 'white',marginBottom: '10px'}}>
                                        {`Timezone: ${moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'}`}
                                    </div>
                                </>
                            }
                            className="dateFilter"
                            ampm={false}
                            value={moment(toDateTime).tz('America/New_York')}
                            minDateTime={moment(fromDateTime).tz('America/New_York')}
                            maxDateTime={moment().tz('America/New_York').endOf('day').subtract(1, 'hour')}
                            onChange={(dateTime) => {
                                handleDateTimeChange('toDateTime', dateTime);
                            }}
                            renderInput={(params: any) => <TextField name="endDateTime" {...params} />}
                        />
                    </LocalizationProvider>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', gap: 8, padding: 8}}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ width: '50%' }}
                        onClick={() => {
                            clearTimeFilter()
                        }}
                    >
                        Clear
                    </Button>

                    <Button variant="contained" color="primary" onClick={applyDateTimeRange} style={{ width: '50%' }}>
                        Apply
                    </Button>
                </div>
            </Menu>
        </div>
    )
}