import { useEffect, useState } from 'react';
import {
	Box,
	Grid,
} from '@mui/material';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import { Link, useNavigate } from 'react-router-dom';
import AreaInfoWidget from 'components/Widgets/AreaInfoWidget';
import InprogressWidget from 'components/Widgets/InprogressWidget';
import { addOrUpdateInsights, deleteInsights, getInsights, getPlatformCostDetails } from 'service/service';
import { formatNumberWithCommas } from 'utils/common-util';
import Insights from 'components/DataEstate/Insights';
import '../FineOps/finops.scss';

const PlatformCostSummary = () => {
	const [costData, SetCostData] = useState([]);
	const [CloudCostYTD, SetCloudCostYTD] = useState('');
	const [integration, setIntegration] = useState('');
	const [visual, setVisual] = useState('');
	const [virtual, setVirtual] = useState('');
	const [CostChange, SetCostChange] = useState('-');
	const [azureCostLoading, setAzureCostLoading] = useState(true);
	const [azureLoader, setAzureLoader] = useState<boolean>(false);
	const [intLoader, setIntLoader] = useState<boolean>(false);
	const [visualLoader, setVisualLoader] = useState<boolean>(false);
	const [virtualLoader, setVirtualLoader] = useState<boolean>(false);
	const navigate = useNavigate();
	const [azureInsights, setAzureInsights] = useState<Insights[]>([]);
	const [integrationInsights, setIntegrationInsights] = useState<Insights[]>([]);
	const [visualInsights, setVisualInsights] = useState<Insights[]>([]);
	const [virtualInsights, setVirtualInsights] = useState<Insights[]>([]);

	const widgetParam = ['Azure Cost', 'Integration Platform', 'Visualization Platform', 'Virtualization Platform'];
	const integrationData = [
		{
		  'Year': 2023,
		  'Tibco': 2201294.71, 
		  'Kafka': 0,
		  'Informatica': 5554321.42,
		},
		{
		  'Year': 2024,
		  'Tibco': 2950380.83, 
		  'Kafka': 1617814.00,
		  'Informatica': 4942939.77,
		},
		{
		  'Year': 2025,
		  'Tibco': 1765656.96, 
		  'Kafka': 2713920.00,
		  'Informatica': 3488226.95,
		}
	];

	const visualizationData = [
		{
		  'Year': 2023,
		  'Tableau': 0, 
		  'PowerBI': 5285534,
		  'Tellius': 669691,
		},
		{
		  'Year': 2024,
		  'Tableau': 6161937, 
		  'PowerBI': 7069704,
		  'Tellius': 678095,
		},
		{
		  'Year': 2025,
		  'Tableau': 5028434, 
		  'PowerBI': 48677,
		  'Tellius': 0,
		}
	];

	const virtualizationData = [
		{
			'Year': 2023,
			'Denodo': 263063.00, 
			'Presto': 55100.00,
		},
		{
			'Year': 2024,
			'Denodo': 250652.00, 
			'Presto': 2148497.00,
		},
		{
			'Year': 2025,
			'Denodo': 272000.00, 
			'Presto': 988592.00,
		}
	];

	const colorMapping = {
		'Tibco': '#009BD8',
		'Kafka': '#28A745',
		'Informatica': '#FFC107',
		// 'Support': '#FD783B',
	};
	
	const gradientMapping = {
		'Tibco': { top: '#009BD8', bottom: '#053367' },
		'Kafka': { top: '#28A745', bottom: '#0F3D1F' },
		'Informatica': { top: '#FFC107', bottom: '#665000' },
		// 'Support': { top: '#FD783B', bottom: '#FFB394' }
	};
	
	const colorMappingVisual = {
		'Tableau': '#009BD8',
		'PowerBI': '#28A745',
		'Tellius': '#FFC107',
	};
	
	const gradientMappingVisual = {
		'Tableau': { top: '#009BD8', bottom: '#053367' },
		'PowerBI': { top: '#28A745', bottom: '#0F3D1F' },
		'Tellius': { top: '#FFC107', bottom: '#665000' },
	};
	
	const colorMappingVirtual = {
		'Denodo': '#009BD8',
		'Presto': '#28A745',
	};
	
	const gradientMappingVirtual = {
		'Denodo': { top: '#009BD8', bottom: '#053367' },
		'Presto': { top: '#28A745', bottom: '#0F3D1F' },
	};

	function formatAbsNumber(number: any) {
		if (number != null && !isNaN(number) && number != 0) {
			const absNumber = Math.abs(number);
        	return absNumber % 1 === 0 
            	? absNumber.toLocaleString('en-US', { maximumFractionDigits: 0 }) // No decimals for whole numbers
            	: absNumber.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }); // Show decimals if needed
    	} 
		return '0';
	}

	const formatNumberByUnits = (number: number) => {
		if (number >= 1000000) {
			return `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
				number / 1000000
			)} MM`;
		}
		if (number >= 1000) 
			return `${formatNumberWithCommas((number / 1000).toFixed(2), true)} K`;
		return new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number);
	};

	const fetchCostDetails = async () => {
		const res = await getPlatformCostDetails();
		if (res.success) {
			const ytdCount = res?.data?.ytd[0]?.KPI_VALUE ?? 0;
			SetCloudCostYTD(formatNumberByUnits(ytdCount));

			const lmcgCount = res?.data?.lmcg[0]?.KPI_VALUE ?? 0;
			SetCostChange(lmcgCount.toString());

			SetCostData(res.data.programCost);
			setAzureCostLoading(false);
		}
	};

	const handleNavigate = (chartType: any = null) => {
		let path = ''
		if(chartType === 1)
			path = `/D&AIXOps/platformCostSummary/insightView?type=${widgetParam[1]}`;
		else if(chartType === 2)
			path = `/D&AIXOps/platformCostSummary/insightView?type=${widgetParam[2]}`;
		else if(chartType === 3)
			path = `/D&AIXOps/platformCostSummary/insightView?type=${widgetParam[3]}`;
		else 
			path = `/finops/executive-dashboard`;
		navigate(path);
	};

	const setDataForChart = (chartId: number, data: any) => {
		switch (chartId) {
			case 0:
				setAzureInsights(data);
				break;
			case 1:
				setIntegrationInsights(data);
				break;
			case 2:
				setVisualInsights(data);
				break;
			case 3:
				setVirtualInsights(data);
				break;
		}
	};

	const setLoaderForChart = (chartId: number, isLoading: boolean) => {
		switch (chartId) {
			case 0:
				setAzureLoader(isLoading);
				break;
			case 1:
				setIntLoader(isLoading);
				break;
			case 2:
				setVisualLoader(isLoading);
				break;
			case 3:
				setVirtualLoader(isLoading);
				break;
		}
	};

	// Function to handle adding a new insight
	const handleAddInsight = async (newInsight: any, chartId: any) => {
		try {
			setLoaderForChart(chartId, true);
			const xopsType = chartId === 0 ? widgetParam[0] : (chartId === 1 ? widgetParam[1] 
				: (chartId === 2 ? widgetParam[2] : widgetParam[3]));
			const response = await addOrUpdateInsights({
				title: newInsight?.title,
				xopsType: xopsType,
				description: newInsight?.description
			});
			if (response.success) {
				fetchInsights(chartId);
			} else {
				setLoaderForChart(chartId, false);
			}
		} catch (error) {
			console.error('Failed to add insight:', error);
		}
	};
		
	// Function to handle updating an insight
	const handleUpdateInsight = async (updatedInsight: any, chartId: any) => {
		try {
			setLoaderForChart(chartId, true);
			//Fetch all required data concurrently
			const xopsType = chartId === 0 ? widgetParam[0] : (chartId === 1 ? widgetParam[1] 
				: (chartId === 2 ? widgetParam[2] : widgetParam[3]));
			const response = await addOrUpdateInsights({
					title: updatedInsight?.title,
					xopsType: xopsType,
					description: updatedInsight?.description,
					insight_id: updatedInsight?.id
				});
			if (response.success) {
				fetchInsights(chartId);
			} else {
				setLoaderForChart(chartId, false);
			}
		} catch (error) {
			console.error('Failed to update insight:', error);
		}
	};
		
	// Function to handle deleting an insight
	const handleDeleteInsight = async (insightId: number, chartId: any) => {
		try {
			setLoaderForChart(chartId, true);
			const response = await deleteInsights(insightId);
			if (response.success) {
				fetchInsights(chartId);
			} else {
				setLoaderForChart(chartId, false);
			}
		} catch (error) {
			console.error('Failed to delete insight:', error);
		}
	};

	//Function to fetch insights
	const fetchInsights = async (chartId: any) => {
		setLoaderForChart(chartId, true);
		try {
			const response = await getInsights(widgetParam[chartId]);
			if (response.success) {
				setDataForChart(chartId, response.data.data)
				setLoaderForChart(chartId, false);
			} else {
				setLoaderForChart(chartId, false);
			}
		} catch (error) {
			console.error('Failed to get insights:', error);
		}
	};

	//Function to fetch insights
	const fetchTotalCost = async () => {
		const intTotalCost = integrationData
		.filter(item => item.Year === 2025)
		.reduce((sum, item) => {
			return sum + item.Tibco + item.Kafka + item.Informatica;
		}, 0);
		setIntegration(formatNumberByUnits(intTotalCost));
		const visualTotalCost = visualizationData
		.filter(item => item.Year === 2025)
		.reduce((sum, item) => {
			return sum + item.Tableau + item.PowerBI + item.Tellius;
		}, 0);
		setVisual(formatNumberByUnits(visualTotalCost));
		const virtualTotalCost = virtualizationData
		.filter(item => item.Year === 2025)
		.reduce((sum, item) => {
			return sum + item.Denodo + item.Presto;
		}, 0);
		setVirtual(formatNumberByUnits(virtualTotalCost));
	};

	// Fetch insights
	useEffect(() => {
		fetchInsights(0);
		fetchTotalCost();
	}, []);

	useEffect(() => {
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/D&AIXOps/platformCostSummary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.remove('root');
			}
		} else {
			if (activeId) {
				activeId.classList.add('root');
			}
		}
		fetchCostDetails();
	}, []);
	
	return (
		<Box mt="80px" bgcolor='blue.darker'>	
			<PageHeader title="Platform Cost Summary" icon={DashboardIcon}></PageHeader>
			<Grid container spacing={2} marginTop="1rem" px={2}>
				<Grid item xs={12} md={8} lg={6}>
					<AreaInfoWidget
						title="Azure Cost (YTD)"
						data={costData}
						value={'$' + CloudCostYTD}
						change={formatAbsNumber(CostChange)}
						changeType={CostChange === null || CostChange === undefined
							? ''
							: parseFloat(CostChange) > 0.0
							? 'increase'
							: parseFloat(CostChange) < 0.0
							? 'decrease'
							: ''}
						isLoading={azureCostLoading}
						hasLegend = {false}
						handleEvent={() => handleNavigate()}
						loader = {azureLoader}
						fetchInsights = {() => fetchInsights(0)}
						insights={azureInsights}
						setInsights={setAzureInsights}
						onAdd={(newInsight) => handleAddInsight(newInsight, 0)}
						onUpdate={(newInsight) => handleUpdateInsight(newInsight, 0)}
						onDelete={(newInsight) => handleDeleteInsight(newInsight, 0)}
					/>
				</Grid>
				<Grid item xs={12} md={8} lg={6}>
					<AreaInfoWidget
						title="Integration Platform Cost (YTD)"
						data={integrationData}
						value={'$' + integration}
						change={formatAbsNumber(2)}
						changeType={CostChange === null || CostChange === undefined
							? ''
							: parseFloat(CostChange) > 0.0
							? 'increase'
							: parseFloat(CostChange) < 0.0
							? 'decrease'
							: ''}
						isLoading={integrationData ? false : true}
						colorMapping={colorMapping} 
						gradientMapping={gradientMapping} 
						hasLegend
						handleEvent={() => handleNavigate(1)}
						loader = {intLoader}
						fetchInsights = {() => fetchInsights(1)}
						insights={integrationInsights}
						setInsights={setIntegrationInsights}
						onAdd={(newInsight) => handleAddInsight(newInsight, 1)}
						onUpdate={(newInsight) => handleUpdateInsight(newInsight, 1)}
						onDelete={(newInsight) => handleDeleteInsight(newInsight, 1)}
					/>
				</Grid>
				<Grid item xs={12} md={8} lg={6}>
					<AreaInfoWidget
						title="Data Visualization Platform (YTD)"
						data={visualizationData}
						value={'$' + visual}
						change={formatAbsNumber(2)}
						changeType={CostChange === null || CostChange === undefined
							? ''
							: parseFloat(CostChange) > 0.0
							? 'increase'
							: parseFloat(CostChange) < 0.0
							? 'decrease'
							: ''}
						isLoading={visualizationData ? false : true}
						colorMapping={colorMappingVisual} 
						gradientMapping={gradientMappingVisual} 
						hasLegend
						handleEvent={() => handleNavigate(2)}
						loader = {visualLoader}
						fetchInsights = {() => fetchInsights(2)}
						insights={visualInsights}
						setInsights={setVisualInsights}
						onAdd={(newInsight) => handleAddInsight(newInsight, 2)}
						onUpdate={(newInsight) => handleUpdateInsight(newInsight, 2)}
						onDelete={(newInsight) => handleDeleteInsight(newInsight, 2)}
					/>
				</Grid>
				<Grid item xs={12} md={8} lg={6}>
					<AreaInfoWidget
						title="Data Virtualization Platform (YTD)"
						data={virtualizationData}
						value={'$' + virtual}
						change={formatAbsNumber(2)}
						changeType={CostChange === null || CostChange === undefined
							? ''
							: parseFloat(CostChange) > 0.0
							? 'increase'
							: parseFloat(CostChange) < 0.0
							? 'decrease'
							: ''}
						isLoading={virtualizationData ? false : true}
						colorMapping={colorMappingVirtual} 
						gradientMapping={gradientMappingVirtual} 
						hasLegend
						handleEvent={() => handleNavigate(3)}
						loader={virtualLoader}
						fetchInsights = {() => fetchInsights(3)}
						insights={virtualInsights}
						setInsights={setVirtualInsights}
						onAdd={(newInsight) => handleAddInsight(newInsight, 3)}
						onUpdate={(newInsight) => handleUpdateInsight(newInsight, 3)}
						onDelete={(newInsight) => handleDeleteInsight(newInsight, 3)}
					/>
				</Grid>
				<Grid item xs={12} md={8} lg={6}>
					<Link to={'/D&AIXOps/platformCostSummary/insightView?type=Other Platforms'} 
						style={{ textDecoration: 'none', color: '#FFF', cursor: 'pointer' }}>
						<InprogressWidget title={'Other Platforms Cost (YTD)'} tooltipLabel="COMING SOON" />
					</Link>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PlatformCostSummary;

