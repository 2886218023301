import { useEffect, useMemo, useState } from 'react';
import { Box, TableContainer, Typography, Button, Stack, InputAdornment } from '@mui/material';
import { Search, FilterList } from '@mui/icons-material';
import colors from 'assets/theme/base/colors';
import CustomDropdown from 'components/Dropdown';
import AdvancedBuilder from './AdvancedBuilder';
import OutlinedButton from 'components/Button/OutlinedButton';
import { dropdownSxProps } from './utilities';
import CustomTextField from 'components/Inputs/CustomTextField';
import { formatQuery } from 'react-querybuilder';
import { useSearchParams } from 'react-router-dom';

// Example usage:
// const dummyOptions = ['pep-datahub-prod-01-sub', 'pep-gtmvn-apac-prod-scus-01-rg', 'databricksngibcvwhhednarqc']
// 	const fields = {
// 		resourceGroup: { name: 'resourceGroup', label: 'Resource Group', options: dummyOptions },
// 		resource: { name: 'resource', label: 'Resource', options: dummyOptions }
// 	}
// <PepWiseQueryBuilder fields={fields}/>

const initialQuery = {
	id: '660802c2-f66d-46ed-a996-24962e922680',
	rules: [
		// {
		// 	id: '0963be83-b471-4c8e-9185-1c5107fd62d9',
		// 	field: 'resourceGroup',
		// 	value: 'pep-datahub-prod-01-sub',
		// 	operator: 'in'
		// },
		// 'and',
		// {
		// 	id: '4bdc3b00-1abe-4c67-ad35-8ea43d65b0a6',
		// 	field: 'resource',
		// 	value: 'pep-gtmvn-apac-prod-scus-01-rg',
		// 	operator: 'in'
		// },
		// 'or',
		// {
		// 	id: 'a4a142bb-2862-4b55-a943-4064e1c64654',
		// 	field: 'resourceGroup',
		// 	operator: '=',
		// 	valueSource: 'value',
		// 	value: 'databricksngibcvwhhednarqc'
		// },
		// 'and',
		// {
		// 	id: 'ad88eb46-f5b4-46ad-a42c-9c746500a2b8',
		// 	rules: [
		// 		{
		// 			id: '171ccd9e-4356-4ea5-bbf8-cd4dd6c6a47e',
		// 			field: 'resourceGroup',
		// 			operator: '=',
		// 			valueSource: 'value',
		// 			value: 'pep-datahub-prod-01-sub'
		// 		},
		// 		'and',
		// 		{
		// 			id: '77e44929-b8c3-4baf-bd73-064be6e9cedb',
		// 			field: 'resource',
		// 			operator: 'in',
		// 			valueSource: 'value',
		// 			value: 'databricksngibcvwhhednarqc'
		// 		},
		// 		'or',
		// 		{
		// 			id: 'cb045c68-4b9d-4d9d-b157-9e33c83a8ef2',
		// 			field: 'resourceGroup',
		// 			operator: '=',
		// 			valueSource: 'value',
		// 			value: 'pep-gtmvn-apac-prod-scus-01-rg'
		// 		}
		// 	]
		// },
		// 'and',
		// {
		// 	id: '9c724da5-53fa-4c1d-9014-5bb0a70f56a4',
		// 	field: 'resourceGroup',
		// 	operator: '=',
		// 	valueSource: 'value',
		// 	value: 'pep-datahub-prod-01-sub'
		// }
	]
};

const initialClearQuery = {
	id: '660802c2-f66d-46ed-a996-24962e922680',
	rules: [
		{
            field: 'Subscription',
            operator: '=',
            value: ''
        },
		'and',
        {
            field: 'ResourceGroup',
            operator: '=',
            value: ''
        }
	]
};

const PepWiseQueryBuilder = ({
	fields,
	queryPreferenceOptions,
	queryPreference,
	setQueryPreference,
	setQueryProps,
	loadOptionsForField,
	fieldValueOptionsCallback,
	valueField,
	setTotalPages,
	sortingInfo,
	setSortingInfo
}: any) => {
	const [isAdvancedBuilder, setIsAdvancedBuilder] = useState(true);
	const [query, setQuery] = useState<any>(initialQuery);
	const [searchQuery, setSearchQuery] = useState<any>('');
	const [queryPreferenceVal, setQueryPreferenceVal] = useState('Resource_flat');
	const [isFormValid, setIsFormValid] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams();

	// const validateForm = () => {
	// 	const hasEmptyField = query.rules.some((rule: { field: string;operator:string; value: string }) => {
	// 		return rule.field === "" || rule.operator === "" ||  rule.value === ""; 
	// 	});
	// 	setIsFormValid(!hasEmptyField); 
	// };

	const validateForm = () => {
		const hasEmptyField = (rules: any[]): boolean => {
			return rules.some((rule) => {
				if (typeof rule !== 'object') return false;
				if (Array.isArray(rule.rules)) return hasEmptyField(rule.rules);
				return !rule.field || !rule.operator || (rule.operator !== 'null' && rule.operator !== 'notNull' && !rule.value);
			});
		};
		
		setIsFormValid(!hasEmptyField(query.rules));
	};

	const handleRunQuery = () => {
		setSortingInfo({ sortBy: '', sortDir: '' });
		setTotalPages(0);
		setQueryPreference(queryPreferenceVal);
		// Replace rules with empty field values with a default condition (1 = 1)
		const filteredRules = query.rules.map((rule: { field: string; operator: string; value: string; id: string }) => {
			if (rule.field === "") {
				return { field: "1", operator: "=", value: "1", id: rule.id };
			}
			return rule;
		});
		const formattedQuery = isAdvancedBuilder ? formatQuery({ ...query, rules: filteredRules }, 'parameterized') : searchQuery;
		setQueryProps({
			isSearch: !isAdvancedBuilder,
			query: formattedQuery
		});
	};

	const handleClear = () => {
		const programName = decodeURIComponent(searchParams.get('programName') || '');
		const projectName = decodeURIComponent(searchParams.get('projectName') || '');
		if(programName) {
			const newRules = [
				"and",
				{
					field: fields.PepWiseProgram.name,
					operator: 'contains',
					value: programName
				},
				"and",
				{
					field: fields.PepWiseProject.name,
					operator: 'contains',
					value: projectName
				}
			];
			const newQuery = {
				rules: [...initialClearQuery.rules, ...newRules] 
			};
			setQuery(newQuery);
		}
		else 
			setQuery(initialClearQuery);
	};

	const handleFieldChange = (fieldKey: string, subscription: string) => {
		if (loadOptionsForField) {
		  loadOptionsForField(fieldKey, subscription);
		}
	};

	useEffect(() => {
		validateForm();
	}, [query]);

	const isDisabled = useMemo(() => {
		if (!searchQuery && !isAdvancedBuilder) {
			return true;
		} else if (query.rules.length === 0 && isAdvancedBuilder) {
			return true;
		}
		return false;
	}, [query, searchQuery, isAdvancedBuilder]);


	return (
		<Stack
			sx={{
				backgroundColor: 'primary.main',
				color: 'white.main',
				p: 2,
				'& .query-rule-row-combinator-dropdown': {
					position: 'relative',
					bgcolor: 'primary.main'
				},
				'& .query-rule-row-head-icon': {
					zIndex: 2,
					bgcolor: 'primary.main'
				}
			}}
		>
			<Stack
				direction={{ xs: 'column', sm: 'row' }}
				spacing={1}
				justifyContent="space-between"
				alignItems="center"
				pb={1}
				borderBottom={`0.5px solid ${colors.dark.dark}`}
			>
				<Box display="flex" gap={4} alignItems="center">
					<Typography>Query Preferences</Typography>
					<CustomDropdown
						options={queryPreferenceOptions.map((item: any) => ({
							value: item.value,
							label: item.label
						}))}
						handleChange={(e) => {
							setQueryPreferenceVal(e.target.value);
						}}
						value={queryPreferenceVal}
						boxProps={{
							maxWidth: 400,
							sx: {
								zIndex: 10
							}
						}}
						selectComponentProps={{
							sx: dropdownSxProps
						}}
					/>
				</Box>
				<Box display="flex" gap={1} alignItems="center">
					<Button
						sx={{border: '1px solid #009BD8',color: colors.white.main,fontWeight: '700',
							textTransform: 'uppercase',}}
						disabled={isDisabled}
						variant="pepwiseSecondaryOutline2"
						onClick={handleClear}
					>
						Clear
					</Button>
					<Button variant="pepwiseSecondary"  sx={{ minWidth: 130 }}  disabled={(!isFormValid && isAdvancedBuilder)} onClick={handleRunQuery}>
						Run Query
					</Button>
					{/* <OutlinedButton
						sx={{ color: 'info.main', borderColor: 'info.main', minWidth: 35, height: 35, cursor: 'default' }}
						onClick={() => {
							setQuery(initialQuery);
							setIsAdvancedBuilder((prev) => !prev);
						}}
					>
						{isAdvancedBuilder ? <Search /> : <FilterList />}
					</OutlinedButton> */}
				</Box>
			</Stack>
			{isAdvancedBuilder ? (
				<TableContainer
					sx={{
						maxHeight: 300,
						overflow: 'auto',
						'& .MuiTableCell-root, & .MuiTableRow-root': {
							backgroundColor: 'primary.main',
							color: 'text.subtle'
						},
						'& .MuiCheckbox-root': {
							color: 'text.subtle',
							'&.Mui-checked': {
								color: 'text.subtle'
							}
						}
					}}
				>
					<AdvancedBuilder
						valueField={valueField}
						fields={fields}
						query={query}
						setQuery={setQuery}
						handleFieldChange={handleFieldChange}
						fieldValueOptionsCallback={fieldValueOptionsCallback}
					/>
				</TableContainer>
			) : (
				<Box mt={2}>
					<CustomTextField
						placeholder="Quick Search"
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search sx={{ color: 'white.main' }} />
								</InputAdornment>
							),
							sx: {
								'& .MuiOutlinedInput-notchedOutline': {
									borderColor: 'dark.dark',
									borderWidth: '1px',
									borderStyle: 'solid'
								},
								'&:hover .MuiOutlinedInput-notchedOutline': {
									borderColor: 'dark.dark'
								},
								'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
									borderColor: 'dark.dark'
								}
							}
						}}
					/>
				</Box>
			)}
		</Stack>
	);
};

export default PepWiseQueryBuilder;
