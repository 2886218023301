import { Box, Chip, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import serviceRequestIcon from '../../assets/service-request.svg';
import React, { useEffect, useRef, useState } from 'react';
import PageHeader from 'components/Header/Page';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import ReactECharts from 'echarts-for-react';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import LoaderComponent from 'components/Loader';
import { exportDEIncidentDetails, fetchDEIncidentDetails, fetchServiceRequestSummary, getDataEstateSector } from 'service/service';
import moment from 'moment';
import { Column } from 'react-data-grid';
import { convertDecimalToTime, convertShortDateToActualDateFormat, formatNumberWithCommas } from 'utils/common-util';
import DoughnutChartWidget from 'components/Widgets/DoughnutWidget';
import SectorFilter from 'components/Dropdown/SectorFilter';
import { sectorListDetails } from 'Context/contexts/cacheData/DataEstate/type';
import GuageWidget from 'components/Widgets/GaugeWidget';
 
const majorIncident = () => {
	const [controller] = useCacheDataController();
	const { deMetricsLastSyncDate } = controller;
	const [dateFilter, setDateFilter] = useState<any>('Apr 2024');
	const [customMonth, setCustomMonth] = useState('');
	const [filterMonth, setFilterMonth] = useState<any>(localStorage.getItem('deMajorIncident') == 'true' ? localStorage.getItem('deIncidentDetailSelectedDate') : '0Y');
	const [filterDefaultMonth, setFilterDefaultMonth] = useState<any>(localStorage.getItem('deMajorIncident') == 'true' ? localStorage.getItem('deIncidentDetailSelectedDate') : '0Y');
	const [chartType, setChartType] = useState('');
	const [dimension, setDimension] = useState('');
	const [sectorName, setSectorName] = useState('all');
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [allSectorOptionsList, setAllSectorOptionsList] = useState<sectorListDetails | null>(null);
	const [sectorTagList, setSectorTagList] = useState<any>({ incident: true, outage: true});
	const [availabilityLoader, SetAvailabilityLoader] = useState<boolean>(true);
	const [availability, SetAvailability] = useState({});

	const applyDateFilter = (selectedDate: any) => {
		getMajorIncidentsFunc(selectedDate);
		setDateFilter(selectedDate);
	};

	const formatNumberByUnits = (number: number) => {
		if (number >= 1000000) {
			return `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
				number / 1000000
			)}MM`;
		}
		if (number >= 1000) {
			return `${formatNumberWithCommas((number / 1000).toFixed(2), true)}K`;
		}
		return new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number);
	};

	interface Row {
		incidentId: string,
		relatedProblem: string,
		shortDescription: string,
		configurationItem: string,
		classDescription: string,
		openedDate: string,
		closedDate: string,
		resolvedDate: string,
		priority: string,
		businessCriticality: string,
		assignmentGroup: string,
		assignedTo: string,
		vendorName: string,
		status: string,
		outageType: string,
		serviceOffering: string,
		category: string,
		subCategory: string,
		mttr: string
	}

	const renderStatus = (status: any) => {
		switch (status) {
			case 'closed':
				return <Chip label={status} color="success" />;
			case 'open':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

	const setdateFilter = () => {
		localStorage.setItem('deIncidentDetailSelectedDate', filterMonth);
		localStorage.setItem('deMajorIncident', 'false');
	}
	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'incidentId',
				name: 'Incident ID',
				minWidth: 120,
				width: 120,
				formatter(props) {
					if (props.row.assignmentGroup.includes('EDW PLATFORM TERADATA') || // teradata
						(props.row.assignmentGroup.includes('INFRA AZURE CLOUD') && props.row.incidentId == 'INC10974136') ||
						(props.row.assignmentGroup.includes('ENTERPRISE DATA WAREHOUSE') || // databrick
							props.row.assignmentGroup.includes('ADVANCED ANALYTICS PLATFORM SUPPORT') &&
							props.row.incidentId == 'INC09974786' || props.row.incidentId == 'INC10192559' || props.row.incidentId == 'INC11223786') // databrick
					) {
						return (
							<Link to={`/dataestate/incident-impact-analysis/${props.row.incidentId}`} state={props.row} className="jobsNameList" onClick={setdateFilter}>
								{props.row.incidentId}
							</Link>
						);
					} else
						return props.row.incidentId
				},
			},
			// {
			// 	key: 'relatedProblem',
			// 	name: 'Related Problem',
			// 	width: 140,
			// 	minWidth: 140
			// },
			{
				key: 'shortDescription',
				name: 'Short Description',
				width: 230,
				minWidth: 200,
				formatter(props) {
					const data = props.row.shortDescription ? props.row.shortDescription : '-';
					return <div title={props.row.shortDescription} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'configurationItem',
				name: 'Configuration Item Name',
				width: 230,
				minWidth: 200
			},
			{
				key: 'classDescription',
				name: 'Class Description',
				width: 230,
				minWidth: 200
			},
			{
				key: 'openedDate',
				name: 'Open Date',
				minWidth: 150,
				formatter(props: any) {
					const openedDate = props.row.openedDate;
					if (openedDate !== 'undefined' && openedDate !== null) {
						return moment(openedDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'outageStartTime',
				name: 'Outage Start Time',
				minWidth: 150,
				formatter(props: any) {
					const outageStartTime = props.row.outageStartTime;
					if (outageStartTime !== 'undefined' && outageStartTime !== null) {
						return moment(outageStartTime).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'outageEndTime',
				name: 'Outage End Time',
				minWidth: 150,
				formatter(props: any) {
					const outageEndTime = props.row.outageEndTime;
					if (outageEndTime !== 'undefined' && outageEndTime !== null) {
						return moment(outageEndTime).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'outageDuration',
				name: 'Outage Duration',
				minWidth: 150,
				formatter(props: any) {
					const outageDuration = props.row.outageDuration;
					if (outageDuration !== 'undefined' && outageDuration !== null) {
						return decimalHoursToHMS(outageDuration);
					}
					return '';
				}
			},
			{
				key: 'outageType',
				name: 'Outage Type',
				width: 150,
				minWidth: 150
			},
			{
				key: 'closedDate',
				name: 'Close Date',
				minWidth: 150,
				formatter(props: any) {
					const closedDate = props.row.closedDate;
					if (closedDate !== 'undefined' && closedDate !== null) {
						return moment(closedDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'resolvedDate',
				name: 'Resolve Date',
				minWidth: 150,
				formatter(props: any) {
					const resolvedDate = props.row.resolvedDate;
					if (resolvedDate !== 'undefined' && resolvedDate !== null) {
						return moment(resolvedDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'priority',
				name: 'Priority',
				minWidth: 130,
				width: 130,
			},
			{
				key: 'businessCriticality',
				name: 'Business Criticality',
				width: 190,
				minWidth: 190
			},
			{
				key: 'assignmentGroup',
				name: 'Assignment Group',
				width: 230,
				minWidth: 200,
				formatter(props) {
					const data = props.row.assignmentGroup ? props.row.assignmentGroup : '-';
					return <div title={props.row.assignmentGroup} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'serviceOffering',
				name: 'Service Offering',
				width: 230,
				minWidth: 200,
				formatter(props) {
					const data = props.row.serviceOffering ? props.row.serviceOffering : '-';
					return <div title={props.row.serviceOffering} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'category',
				name: 'Category',
				width: 230,
				minWidth: 200,
				formatter(props) {
					const data = props.row.category ? props.row.category : '-';
					return <div title={props.row.category} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'subCategory',
				name: 'Sub Category',
				width: 230,
				minWidth: 200,
				formatter(props) {
					const data = props.row.subCategory ? props.row.subCategory : '-';
					return <div title={props.row.subCategory} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'assignedTo',
				name: 'Assigned To',
				minWidth: 125,
				width: 125
			},
			{
				key: 'mttr',
				name: 'MTTR',
				width: 150,
				minWidth: 150,
				formatter(props) {
					const data = props.row.mttr ? Number(props.row.mttr).toFixed(2) : '-';
					return <div title={props.row.mttr} className='has-ellipsis'>{data}</div>;
				}
			},
			// {
			// 	key: 'vendorName',
			// 	name: 'Vendor Name',
			// 	minWidth: 130,
			// 	width: 130,
			// },
		];
	}
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [MTTRData, SetMTTRData] = useState([]);
	const [IncidentsData, SetIncidentsData] = useState([]);
	const [OutagesData, SetOutagesData] = useState<any>({});
	const [MTTRDataObject, SetMTTRDataObject] = useState<any>({});

	const [doughnutMTTRTotal, setDoughnutMTTRTotal] = useState<any>();
	const [doughnutSeriesData, setDoughnutSeriesData] = useState<any>([]);
	const [MTTRLoading, setMTTRLoading] = useState(true);

	const [doughnutIncidentTotal, setDoughnutIncidentTotal] = useState<any>();
	const [doughnutIncSeriesData, setDoughnutIncSeriesData] = useState<any>([]);
	const [incidentLoading, setIncidentLoading] = useState(true);

	const [doughnutOutagTotal, setDoughnutOutageTotal] = useState<any>();
	const [doughnutOutageSeriesData, setDoughnutOutageSeriesData] = useState<any>([]);
	const [OutageLoading, setOutageLoading] = useState(true);

	const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const totalElements = paginationData?.totalElements || 0;
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);

	const defaultColumnNames = [
		'Incident ID',
		// 'Related Problem',
		'Short Description',
		'Configuration Item Name',
		'Open Date',
		'Outage Start Time',
		'Outage End Time',
		'Outage Duration',
		'Resolve Date',
		'Close Date',
		'Priority',
		'Business Criticality',
		'Assignment Group',
		'Service Offering',
		'Category',
		'Sub Category',
		'Assigned To',
		// 'Vendor Name',
		'Status'
	];

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		incidentsDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension, sectorName);
	};

	const handlepageSizeChange = (event: any) => {
		incidentsDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension, sectorName);
	};
	const handleSorting = (sortingInfo: any) => {
		incidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension, sectorName);
	};

	function decimalHoursToHMS(decimalHours: any) {
		// Calculate hours
		const hours = Math.floor(decimalHours);
		// Calculate remaining minutes from the decimal part
		const minutes = Math.floor((decimalHours - hours) * 60);
		// Calculate seconds from the remaining decimal part of minutes
		const seconds = Math.round(((decimalHours - hours) * 60 - minutes) * 60);

		// Format the output
		if (hours === 0 && minutes === 0) {
			return `${seconds ? seconds : 1} Seconds`;
		} else if (hours === 0) {
			return `${minutes} Minutes and ${seconds} Seconds`;
		} else {
			return `${hours} Hours, ${minutes} Minutes, and ${seconds} Seconds`;
		}
	}

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		incidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension, sectorName);
	};
	const handleSearch = (searchDetails: any, columnKey: any) => {
		incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	async function getMajorIncidentsFunc(selectedDate?: string, sector?: string) {
		setLoading(true);

		// Function to get color by team name
		const getColorByTeam = (team: string) => {
			switch (team) {
			case 'Legacy EDW':
				return '#299BFF';
			case 'Azure':
				return '#01C008';
			case 'Databricks':
				return '#FFA200';
			case 'Presto':
				return '#FD783B';
			default:
				return '#FF6349';
			}
		};

		if(sector == 'Blank')
			sector = '';

		const formattedDate = convertShortDateToActualDateFormat(selectedDate);
		// let azureData = {
		// 	"distinctIncidentCount": 0,
		// 	"Assignment_Group_Name": "INFRA AZURE CLOUD"
		// }

		// if ((selectedDate != '' && selectedDate != '0Y' && selectedDate != '0M' && selectedDate != '1M') &&
		// 	(
		// 		(selectedDate?.includes('-') && selectedDate == '2024-12') ||
		// 		selectedDate?.includes('M') || selectedDate?.includes('Y')
		// 	)) {
		// 	azureData.distinctIncidentCount = 1
		// }

		fetchServiceRequestSummary('MTTR', selectedDate, sector, formattedDate).then(res => {
			SetMTTRData(res?.data?.result);
			const data: any = { 'Legacy EDW': null, 'Azure': null, 'Databricks': null, 'Presto': null, 'Others': null };
			let totalSum = 0;
			const mttrResult = res?.data?.result.groupAverages;
			if (mttrResult['Legacy EDW'] !== null) {
				data['Legacy EDW'] = mttrResult['Legacy EDW'];
			}
			if (mttrResult['Azure'] !== null) {
				data['Azure'] = mttrResult['Azure'];
			}
			if (mttrResult['Databricks'] !== null) {
				data['Databricks'] = mttrResult['Databricks'];
			}
			if (mttrResult['Others'] !== null) {
				data['Others'] = mttrResult['Others'];
			}

			const average = res?.data?.result.totalAverage[0].totalAvg;
			let seriesData = Object.keys(data).map((team: string) => ({
				name: team,
				value: data[team],
				percentage: ((data[team] / totalSum) * 100).toFixed(2),
				formattedName: `${((data[team] / totalSum) * 100).toFixed(2)}%`,
				color: getColorByTeam(team) // Add color based on team
			}));

			setDoughnutMTTRTotal(formatNumberByUnits(average || 0));
			setDoughnutSeriesData(seriesData);
			setMTTRLoading(false);
			setLoading(false);
		}).catch(error => {
			console.error(error);
		})

		fetchServiceRequestSummary('incidents', selectedDate, sector, formattedDate).then(res => {
			SetIncidentsData(res?.data?.result);
			const data: any = { 'Legacy EDW': null, 'Azure': null, 'Databricks': null, 'Presto': null, 'Others': null };
			let totalSum = 0;
			// if ((selectedDate != '' && selectedDate != '0Y' && selectedDate != '0M' && selectedDate != '1M') &&
			// 	(
			// 		(selectedDate?.includes('-') && selectedDate == '2024-12') ||
			// 		selectedDate?.includes('M') || selectedDate?.includes('Y')
			// 	)) {
			// 	res.data.result.push(azureData);
			// }
			res?.data?.result.forEach((incident: any) => {
				if (incident.Assignment_Group_Name !== null) {
					if (incident.Assignment_Group_Name === 'EDW PLATFORM TERADATA') {
						data['Legacy EDW'] = data['Legacy EDW'] + incident.distinctIncidentCount;
						totalSum = totalSum + incident.distinctIncidentCount;
					}
					if (incident.Assignment_Group_Name === 'INFRA AZURE CLOUD') {
						data['Azure'] = data['Azure'] + incident.distinctIncidentCount;
						totalSum = totalSum + incident.distinctIncidentCount;
					}
					if (incident.Assignment_Group_Name === 'ADVANCED ANALYTICS PLATFORM SUPPORT' || incident.Assignment_Group_Name === 'ENTERPRISE DATA WAREHOUSE') {
						data['Databricks'] = data['Databricks'] + incident.distinctIncidentCount;
						totalSum = totalSum + incident.distinctIncidentCount;
					}
					// if (incident.Presto != 0) {
					// 	data['Presto'] = data['Presto'] + incident.Presto;
					// 	totalSum += incident.Presto;
					// }
					if (incident.Assignment_Group_Name !== 'ADVANCED ANALYTICS PLATFORM SUPPORT' && incident.Assignment_Group_Name !== 'EDW PLATFORM TERADATA' &&
						incident.Assignment_Group_Name !== 'ENTERPRISE DATA WAREHOUSE' && incident.Assignment_Group_Name !== 'DATA INTEGRATION L3 SUPPORT NAB' &&
						incident.Assignment_Group_Name !== 'INFRA AZURE CLOUD') {
						data['Others'] = data['Others'] + incident.distinctIncidentCount;
						totalSum = totalSum + incident.distinctIncidentCount;
					}
				}
			})
			let seriesData = Object.keys(data).map((team: string) => ({
				name: team,
				value: data[team],
				percentage: ((data[team] / totalSum) * 100).toFixed(2),
				formattedName: `${((data[team] / totalSum) * 100).toFixed(2)}%`,
				color: getColorByTeam(team) // Add color based on team
			}));

			setDoughnutIncidentTotal(formatNumberByUnits(totalSum));
			setDoughnutIncSeriesData(seriesData);
			setIncidentLoading(false);
			setLoading(false);

		}).catch(error => {
			console.error(error);
		})


		fetchServiceRequestSummary('outage', selectedDate, sector, '').then(res => {
			SetOutagesData(res?.data?.result);
			const data: any = { 'Legacy EDW': null, 'Azure': null, 'Databricks': null, 'Presto': null, 'Others': null };
			let totalSum = 0;
			res?.data?.result.forEach((outage: any) => {
				// totalSum = 0;
				if (outage.Assignment_Group_Name !== null) {
					if (outage.Assignment_Group_Name === 'EDW PLATFORM TERADATA') {
						data['Legacy EDW'] = data['Legacy EDW'] + outage.distinctIncidentCount;
						totalSum = totalSum + outage.distinctIncidentCount;
					}
					if (outage.Assignment_Group_Name === 'INFRA AZURE CLOUD') {
						data['Azure'] = data['Azure'] + outage.distinctIncidentCount;
						totalSum = totalSum + outage.distinctIncidentCount;
					}
					if (outage.Assignment_Group_Name === 'ADVANCED ANALYTICS PLATFORM SUPPORT' || outage.Assignment_Group_Name === 'ENTERPRISE DATA WAREHOUSE') {
						data['Databricks'] = data['Databricks'] + outage.distinctIncidentCount;
						totalSum = totalSum + outage.distinctIncidentCount;
					}
					// if (outage.Presto != 0) {
					// 	data['Presto'] = data['Presto'] + outage.Presto;
					// 	totalSum += outage.Presto;
					// }
					if (outage.Assignment_Group_Name !== 'ADVANCED ANALYTICS PLATFORM SUPPORT' && outage.Assignment_Group_Name !== 'EDW PLATFORM TERADATA' &&
						outage.Assignment_Group_Name !== 'ENTERPRISE DATA WAREHOUSE' && outage.Assignment_Group_Name !== 'DATA INTEGRATION L3 SUPPORT NAB' &&
						outage.Assignment_Group_Name !== 'INFRA AZURE CLOUD') {
						data['Others'] = data['Others'] + outage.distinctIncidentCount;
						totalSum = totalSum + outage.distinctIncidentCount;
					}
				}
			});
			let seriesData = Object.keys(data).map((team: string) => ({
				name: team,
				value: data[team],
				percentage: ((data[team] / totalSum) * 100).toFixed(2),
				formattedName: `${((data[team] / totalSum) * 100).toFixed(2)}%`,
				color: getColorByTeam(team) // Add color based on team
			}));

			setDoughnutOutageTotal(formatNumberByUnits(totalSum));
			setDoughnutOutageSeriesData(seriesData);
			setOutageLoading(false);
			setLoading(false);

		}).catch(error => {
			console.error(error);
		})

		// Availbility sample data
		const res = {
			data: {
				AvailabilityCount: [
					{
						VALUE: 50.00,
						KPI_NAME: "Availability",
						DIMENSION: "Availability"
					}
				]
			}
		};
		if (res?.data?.AvailabilityCount && res?.data?.AvailabilityCount[0]?.VALUE) {
			let AvailabilityOption = {
				backgroundColor: '#003771',
				series: [
					// Outer Gauge for Availability
					{
						type: 'gauge',
						center: ['50%', '55%'],
						min: 0,
						max: 100,
						splitNumber: 5, // 5 increments for 0, 20, 40, 60, 80, 100
						itemStyle: {
							color: '#e5e5e5'
						},
						progress: {
							show: false
						},
						pointer: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								width: 30, // width of the gauge arc
								color: [
									[
										`${(res?.data?.AvailabilityCount[0]?.VALUE / 100).toFixed(2)}`, 
										'#FD7347'
									], // Orange color for the progress
									[1, '#e5e5e5'] // Light grey color for the remaining part
								]
							}
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							distance: -50,
							color: '#999',
							fontSize: 12,
							formatter: (value: any) => {
								return `${value.toLocaleString()}%`;
							}
						},
						anchor: {
							show: false
						},
						title: {
							show: false
						},
						detail: {
							valueAnimation: true,
							width: '60%',
							lineHeight: 40,
							borderRadius: 8,
							offsetCenter: [0, 10],
							fontSize: 20,
							formatter: `${res?.data?.AvailabilityCount[0]?.VALUE.toFixed(2)}%`,
							color: '#e5e5e5'
						},
						data: [
							{
								value: res?.data?.AvailabilityCount[0]?.VALUE
							}
						]
					},
					// Inner Gauge for Availability
					{
						type: 'gauge',
						center: ['50%', '55%'],
						min: 0,
						max: 100,
						itemStyle: {
							color: '#FD7347'
						},
						progress: {
							show: true,
							width: 20
						},
						pointer: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						axisLabel: {
							show: false
						},
						detail: {
							valueAnimation: true,
							width: '60%',
							lineHeight: 40,
							borderRadius: 8,
							offsetCenter: [0, '-20%'],
							fontSize: 14,
							fontWeight: 'normal',
							formatter: 'Availability',
							color: '#e5e5e5'
						},
						data: [
							{
								value: res?.data?.AvailabilityCount[0]?.VALUE
							}
						]
					},
				]
			};
			// Setting the Availability chart options
			SetAvailability(AvailabilityOption);
			SetAvailabilityLoader(false);
		}
		
	}

	async function incidentsDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any, selectedDate: any, type: any, dimension: any, sector: string) {
		setLoading(true);
		if(sector == 'Blank')
			sector = '';

		if (pageSize !== size) {
			pageNumber = 1;
		}
		const res = await fetchDEIncidentDetails(sector, pageNumber.toString(), size.toString(), selectedDate, type, dimension);
		if (res.success) {
			if (res.data) {
				setIncidentDetails(res.data.data);
				setPaginationData(res.data.page);
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				setLoading(false);
			}
		}
	}

		// export
		async function exportIncidentsDetailFunc(sortingInfo: any, jobFilterInfo: any, selectedDate: any, type: any, dimension: any, sector: string) {
			let exportColumns = [
				'Incident ID',
				'Short Description',
				'Configuration Item Name',
				'Open Date',
				'Outage Start Time',
				'Outage End Time',
				'Outage Duration',
				'Resolve Date',
				'Close Date',
				'Priority',
				'Business Criticality',
				'Assignment Group',
				'Service Offering',
				'Category',
				'Sub Category',
				'Assigned To'
			];
			let formatType = "EXCEL"
			let tableName = "Major Incident Details"
			
			await exportDEIncidentDetails(exportColumns, formatType, tableName, sector, selectedDate, type, dimension);
		};

	useEffect(() => {
		localStorage.setItem('deIncidentDetailSelectedDate', filterMonth);
		localStorage.setItem('deMajorIncident', 'false');
		incidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo), filterMonth, chartType, dimension, 'all');
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/majorIncident') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		fetchSectorOptionsData();
		getMajorIncidentsFunc(filterMonth,'all')

	}, []);

	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}
	const handleDateChange = (selectedDate: string) => {
		incidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo), selectedDate, chartType, dimension, sectorName);
		getMajorIncidentsFunc(selectedDate, sectorName);
		setFilterDefaultMonth(selectedDate)
	};

	const handleINCReportOnclick = (param: any, title?: string) => {
		if (param && param.name) {
			if (chartType == title && dimension == param.name) {
				incidentsDetailFunc(page, pageSize, sortingInfo, param.name, filterMonth, '', '', sectorName);
				setChartType('');
				setDimension('');
			} else {
				incidentsDetailFunc(page, pageSize, sortingInfo, param.name, filterMonth, title, param.name, sectorName);
				setChartType(title != null ? title : '');
				setDimension(param.name)
			}
		}
	};

	async function fetchSectorOptionsData() { 
		let sectorKpi = [];
		sectorKpi.push("incident","outage");
		const sectorRes = await getDataEstateSector(sectorKpi);
		if (sectorRes.success) {
			if(sectorRes.data) {
				setAllSectorOptionsList(sectorRes.data);
				const allSectors = [
					...sectorRes.data.incident.map((item: any) => item.sector),
					...sectorRes.data.outages.map((item: any) => item.sector)
				];
				const uniqueSectors = Array.from(new Set(allSectors));
				const sectorNameList = uniqueSectors.map(sector => {
					return sector === "" ? "Blank" : sector ;
				});
				setSectorOptionsList(sectorNameList.sort());
			}
		}
	}

	function formatMTTR(totalHours: number): string {
		const { Days, Hours, Minutes } = convertDecimalToTime(totalHours);
		return `${Days > 0 ? Days + ' Days\n' : ''} ${Hours > 0 ? Hours + ' Hrs' : ''} ${Minutes > 0 ? Minutes + ' Mins' : ''}`;
	}

	const handleSectorChange = (value: string) => {
		setLoading(true);
		setSectorName(value); 
		sectorTagUpdatefun(value);
	};

	const sectorTagUpdatefun = (sector: string) => {
		if(sector != 'all'){
			if(sector == 'Blank')
				sector = '';

			const isInIncident = allSectorOptionsList?.incident.some(item => item.sector === sector);
			const isInOutage = allSectorOptionsList?.outages.some(item => item.sector === sector);

			const filteredData = {
				incident: isInIncident || false,
				outage: isInOutage || false
			};
			setSectorTagList(filteredData);
			getMajorIncidentsFunc(filterMonth, filteredData.incident == true ? sector : 'all');
			incidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),filterMonth, chartType, dimension, sector);
		} else {	
			const filteredData = {
				incident: true,
				outage: true
			};
			setSectorTagList(filteredData);
			getMajorIncidentsFunc(filterMonth, sector);
			incidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),filterMonth, chartType, dimension, sector);
		}
	}

	return (
		<Box mt="100px">
			<PageHeader title="Major INC Summary" icon={serviceRequestIcon} lastSync={deMetricsLastSyncDate}>
				<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={handleDateChange}
				/>
				<SectorFilter
					sectorName={sectorName}
					setSectorName={setSectorName}
					sectorOptionsList={sectorOptionsList}
					handleChange={handleSectorChange}
				/>
			</PageHeader>
			<Grid container spacing={2} p={3}>
				<Grid item xs={12} sm={6} md={3}>
					<DoughnutChartWidget
						title="Major INC Details"
						isLoading={incidentLoading}
						seriesData={doughnutIncSeriesData}
						totalText={`{small|Total}\n{large|${doughnutIncidentTotal}}`}
						dataLabel={true}
						legendLabel={true}
						handleEvent={handleINCReportOnclick}
						sectorLabel={sectorTagList?.incident == true ? (sectorName == 'all' ? '' : sectorName) : ''}
						chartSize='250px'
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<DoughnutChartWidget
						title="Outages"
						isLoading={OutageLoading}
						seriesData={doughnutOutageSeriesData}
						totalText={`{small|Total}\n{large|${doughnutOutagTotal}}`}
						dataLabel={true}
						legendLabel={true}
						sectorLabel={sectorTagList?.outage == true ? (sectorName == 'all' ? '' : sectorName) : ''}
						chartSize='250px'
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<DoughnutChartWidget
						title="Mean time To Resolve (MTTR)"
						isLoading={MTTRLoading}
						seriesData={doughnutSeriesData}
						totalText={`{small|Total Mean Time}\n{large|${formatMTTR(doughnutMTTRTotal)}}`}
						dataLabel={true}
						legendLabel={true}
						hoursFormat={true}
						sectorLabel={sectorTagList?.incident == true ? (sectorName == 'all' ? '' : sectorName) : ''}
						chartSize='250px'
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<GuageWidget title="Availability" isLoading={availabilityLoader} data={availability} />
				</Grid>
			</Grid>
			<Grid p={2} mt={0.5}>
				{!loading ? (
					<CustomizedDataGrid
						title={`${chartType != '' && dimension != '' ? `${dimension} ${chartType}` : 'INC Details'}`}
						tableColumns={getColumns()}
						tableData={incidentDetails && incidentDetails.length !== 0 ? incidentDetails : 0}
						tableType="incident-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						searchDataValues={searchDetails}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={{ totalElements }}
						listLength={incidentDetails && incidentDetails.length !== 0 ? incidentDetails.length : 0}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isFetching={loading}
						showExport={true}
						fileName={`Jobs.xlsx`}
						isDateRangeFilter={false}
						isDisabled={false}
						exportTableData={() =>
							exportIncidentsDetailFunc(
								sortingInfo,
								parseFilter(filterInfo),
								filterMonth,
								chartType,
								dimension,
								sectorName
							)
						}
						isShowMoreButton
					/>
				) : (
					<LoaderComponent />
				)}
			</Grid>
		</Box>
	);
};

export default majorIncident;



