import { useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableRow, Button, IconButton, TableHead, Box, Stack } from '@mui/material';
import { Add, FormatIndentIncrease } from '@mui/icons-material';
import QueryRules from './QueryRules';
import { useSearchParams } from 'react-router-dom';
import { add, update } from 'react-querybuilder';
import {
	canGroupSelectedRules,
	defaultRuleValue,
	getAllGroupPaths,
	groupSelectedRules,
	outerGroupSelectedRules,
	sortArrayOfArrays
} from './utilities';

const AdvancedBuilder = ({ fields, query, setQuery, handleFieldChange, fieldValueOptionsCallback, valueField }: any) => {
	const [selectedPaths, setSelectedPaths] = useState<any>([]);
	const groupPaths = useRef<number[][]>([]);
	const defaultRuleRef = useRef(false);
	const [error, setError] = useState('');

	const handleSetQuery = (newQuery: any, isAddRow: boolean = false) => {
		if(isAddRow && query.rules.length > 0) {
			if((query.rules[0]?.field === 'Subscription' && (!query.rules[0]?.value && !newQuery.rules[0]?.value)) && 
				(query.rules[2]?.field === 'ResourceGroup' && (!query.rules[2]?.value && !newQuery.rules[2]?.value))) {
				setError('Subscription/Resource Group is required');
				return;
			}
			else if(query.rules[0]?.field === 'Subscription' && (!query.rules[0]?.value && !newQuery.rules[0]?.value)){
				setError('Subscription is required');
				return;
			}
			else if(query.rules[2]?.field === 'ResourceGroup' && (!query.rules[2]?.value && !newQuery.rules[2]?.value)){
				setError('Resource Group is required');
				return;
			}
		}
		setError('');
		groupPaths.current = getAllGroupPaths(newQuery.rules).slice(1);
		setQuery(newQuery);
		setSelectedPaths([]);
	};

	const handleCheckboxChange = (path: any) => {
		setSelectedPaths((prevPaths: any) => {
			if (prevPaths.some((p: any) => JSON.stringify(p) === JSON.stringify(path))) {
				return prevPaths.filter((p: any) => JSON.stringify(p) !== JSON.stringify(path));
			} else {
				return [...prevPaths, path];
			}
		});
	};

	const handleGroupAction = () => {
		const canGroupRes = canGroupSelectedRules(query, selectedPaths);
		if (canGroupRes.canGroup) {
			const updatedQuery =
				canGroupRes.groupType === 'outer'
					? outerGroupSelectedRules(query, selectedPaths)
					: groupSelectedRules(query, selectedPaths);
			// console.log('query grouped', updatedQuery);
			handleSetQuery(updatedQuery);
		}
	};

	const [searchParams] = useSearchParams();
		  // Extract query parameters
	const programId = searchParams.get('programId');
		  const projectId = searchParams.get('projectId');
		  const programName = decodeURIComponent(searchParams.get('programName') || '');
		  const projectName = decodeURIComponent(searchParams.get('projectName') || '');

		  useEffect(() => {
			const newInitialValue = [
				{
					field: fields.Subscription.name || 'Subscription',
					operator:
						fields.Subscription.fieldOptions?.operators?.find((operator: any) => operator.name === '=')?.value || '=',
					value: ''
				},
				 "and",
				{
					field: fields.ResourceGroup?.name || 'ResourceGroup', 
					operator:
						fields.ResourceGroup?.fieldOptions?.operators?.find((operator: any) => operator.name === '=')?.value || '=',
					value: ''
				}
			];
		if ((!defaultRuleRef.current && (!query.rules || query.rules.length === 0)) 
			|| (!defaultRuleRef.current && programName && projectName && fields.PepWiseProgram && fields.PepWiseProject)) {
			if (programName && projectName && fields.PepWiseProgram && fields.PepWiseProject) {
				const newRules = [
					'and',
					{
						field: fields.PepWiseProgram.name,
						operator: 'contains',
						value: programName
					},
					'and',
					{
						field: fields.PepWiseProject.name,
						operator: 'contains',
						value: projectName
					}
				];
				const newQuery = {
					rules: [...newInitialValue, ...newRules] 
				};
				handleSetQuery(newQuery);
				defaultRuleRef.current = true;
			}  else if ((fields.Subscription || fields.ResourceGroup) && (!query.rules || query.rules.length === 0)) {
				// if (fields.Subscription) {
				// 	handleSetQuery(add(query, newInitialValue[0], []));
				// }
				// if (fields.ResourceGroup) {
				// 	handleSetQuery(add(query, newInitialValue[2], []));
				// }
				const newQuery = {
					rules: [...newInitialValue] 
				};
				handleSetQuery(newQuery);
				defaultRuleRef.current = true;
			} 
		}
		else if (defaultRuleRef.current && (!programName && !projectName) && (query.rules || query.rules.length > 0 )) {
			defaultRuleRef.current = false;
			const newQuery = {
				rules: [...newInitialValue] 
			};
			handleSetQuery(newQuery);
		}
	}, [fields, valueField, programName,projectName]);

	return (
		<>
			<Table sx={{ borderCollapse: 'separate', borderSpacing: 6 }} stickyHeader>
				{/* Headers */}
				<TableHead>
					<TableRow sx={{ color: 'dark.dark' }}>
						<TableCell width={50}>
							<IconButton
								disabled={!canGroupSelectedRules(query, selectedPaths).canGroup}
								sx={{ color: 'text.subtle' }}
								onClick={() => handleGroupAction()}
							>
								<FormatIndentIncrease />
							</IconButton>
						</TableCell>
						{/* group tree branch style */}
						{!!groupPaths.current.length && (
							<TableCell component="th" variant="head">
								<Box />
							</TableCell>
						)}
						<TableCell width={120} component="th" variant="head">
							And/Or
						</TableCell>
						<TableCell width={400} component="th" variant="head">
							Field
						</TableCell>
						<TableCell width={240} component="th" variant="head">
							Operator
						</TableCell>
						<TableCell component="th" variant="head">
							Value &nbsp;&nbsp;
							{error && <span style={{ color: 'red'}}>{error}</span>}
						</TableCell>
						{/* <TableCell width={100} align="center">
						<Button
							variant="text"
							color="secondary"
							sx={{ minWidth: 130 }}
							startIcon={<Add />}
							onClick={() => {
								handleSetQuery(add(query, defaultRuleValue, []));
							}}
						>
							Add Clause
						</Button>
					</TableCell> */}
					</TableRow>
				</TableHead>

				<TableBody>
					<QueryRules
						rules={query.rules}
						fields={fields}
						query={query}
						setQuery={handleSetQuery}
						onCheckboxChange={handleCheckboxChange}
						checkedRules={selectedPaths}
						groupPaths={groupPaths}
						handleFieldChange={handleFieldChange}
						fieldValueOptionsCallback={fieldValueOptionsCallback}
					/>
				</TableBody>
			</Table>
			<Stack direction="row" justifyContent="flex-end" pr={2} pt={2}>
				<Button
					variant="text"
					color="secondary"
					sx={{ minWidth: 130 }}
					startIcon={<Add />}
					onClick={() => {
						handleSetQuery(add(query, defaultRuleValue, []), true);
					}}
				>
					Add Clause
				</Button>
			</Stack>
		</>
	);
};

export default AdvancedBuilder;
