import { Box, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import LoaderComponent from 'components/Loader';
import Insights from './Insights';
import ExecutiveTabs from './ExecutiveTabs';
import { addOrUpdateInsights, deleteInsights, getInsights } from 'service/service';

const ExecutiveView2024OperationalMetrics = () => {
	const [insights, setInsights] = useState<Insights[]>([]);
	const [loader, setLoader] = useState<boolean>(false);
	
	interface Insights {
		insightId: number;
		title: string;
		description: string;
		opsType: string;
		createdBy: string;
		updatedBy: string;
		createdDate: string;
		updatedDate: string;
	}

	//Function to fetch insights
	const fetchInsights = async () => {
		setLoader(true);
		try {
			const response = await getInsights('2024 Operational Metrics');
			if (response.success) {
				setInsights(response.data.data);
				setLoader(false);
				console.log('response', response);
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to get insights:', error);
		}
	};

	// Function to handle adding a new insight
	const handleAddInsight = async (newInsight: any) => {
		try {
			setLoader(true);
			const response = await addOrUpdateInsights({
				title: newInsight?.title,
				xopsType: '2024 Operational Metrics',
				description: newInsight?.description
			});
			if (response.success) {
				console.log('response', response);
				fetchInsights();
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to add insight:', error);
		}
	};

	// Function to handle updating an insight
	const handleUpdateInsight = async (updatedInsight: any) => {
		try {
			setLoader(true);
			const response = await addOrUpdateInsights({
				title: updatedInsight?.title,
				xopsType: 'Incidents Summary',
				description: updatedInsight?.description,
				insight_id: updatedInsight?.id
			});
			if (response.success) {
				console.log('response', response);
				fetchInsights();
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to update insight:', error);
		}
	};

	// Function to handle deleting an insight
	const handleDeleteInsight = async (insightId: number) => {
		try {
			setLoader(true);
			const response = await deleteInsights(insightId);
			if (response.success) {
				console.log('response', response);
				fetchInsights();
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error('Failed to delete insight:', error);
		}
	};

	// Fetch insights
	useEffect(() => {
		fetchInsights();
	}, []);

	return (
		<Box bgcolor="blue.darker" mt="80px">
			<ExecutiveTabs />			
			<Box marginTop={4} pl={3} pr={3}>
				<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Typography sx={{ color: '#009BDB', fontSize: '16px', fontWeight: 600 }}>Insights/Actions</Typography>
					<Divider sx={{ flexGrow: 1, backgroundColor: '#009BDB', marginLeft: 2 }} />
				</Box>
				<Typography
					variant="body2"
					sx={{ fontStyle: 'italic', color: '#71758F', fontSize: '14px', fontWeight: '500', marginBottom: 5 }}
				>
					You can display and update Insights/Actions if any.
				</Typography>
			</Box>
			{loader ? (
				<LoaderComponent />
			) : (
				<Box sx={{ mb: 3 }}>
					<Insights
						insights={insights}
						setInsights={setInsights}
						onAdd={handleAddInsight}
						onUpdate={handleUpdateInsight}
						onDelete={handleDeleteInsight}
					/>
				</Box>
			)}
		</Box>
	);
};
export default ExecutiveView2024OperationalMetrics;
