import { useSearchParams } from 'react-router-dom';
import RuleRow from './RuleRow';
import { getCombinatorByPath } from './utilities';

// DO NOT DELETE THE COMMENTED NOTES BELOW, AS THEY WILL BE USEFUL FOR FUTURE REFERENCE.
const RenderRules = ({
	rules,
	fields,
	query,
	setQuery,
	combinator = null,
	path = [],
	combinatorPath = [],
	onCheckboxChange,
	checkedRules,
  	groupPaths,
	handleFieldChange,
	fieldValueOptionsCallback,
}: any) => {
	const [searchParams] = useSearchParams();
	const programName = decodeURIComponent(searchParams.get('programName') || '');
	const projectName = decodeURIComponent(searchParams.get('projectName') || '');
	// we will loop and combine the combinator and rule as one row and push to array.
	const rulesArray = [];

	for (let i = 0; i < rules.length; i++) {
		const item = rules[i];
		let currentPath = [...path, i];
		// if item is string combinator, we need to get next item also, which is rule object
		if (typeof item === 'string') {
			// item is string means we reached combinator. so update combinator path.
			const currentCombinatorPath = [...path, i];
			// next item after combinator will be rule object
			const nextItem = rules[i + 1];
			currentPath = [...path, i + 1];
			if (nextItem && typeof nextItem === 'object') {
				// if item is group, which is array of rule objects, we need to call RenderRules recursively
				// else we render the rule object
				if (nextItem.rules) {
					rulesArray.push(
						<RenderRules
							key={nextItem.id}
							fields={fields}
							rules={nextItem.rules}
							combinator={item}
							query={query}
							setQuery={setQuery}
							path={currentPath}
							combinatorPath={currentCombinatorPath}
							onCheckboxChange={onCheckboxChange}
							checkedRules={checkedRules}
              				groupPaths={groupPaths}
							handleFieldChange={handleFieldChange}
							fieldValueOptionsCallback={fieldValueOptionsCallback}
						/>
					);
				} else {
					rulesArray.push(
						<RuleRow
							key={nextItem.id}
							fields={fields}
							rule={nextItem}
							combinator={item}
							query={query}
							setQuery={setQuery}
							path={currentPath}
							combinatorPath={currentCombinatorPath}
							groupedRules={rules}
							onCheckboxChange={onCheckboxChange}
							isChecked={checkedRules.some((p: any) => JSON.stringify(p) === JSON.stringify(currentPath))}
              				groupPaths={groupPaths}
							handleFieldChange={handleFieldChange}
							fieldValueOptionsCallback={fieldValueOptionsCallback}
							disableBtn = {((i === 1) && (i === 1 && item === 'and') 
								&& (query.rules[i + 1].field === nextItem.field && query.rules[i + 1].field === 'ResourceGroup')) ? true : false}
						/>
					);
				}
				// we skip next item since it is rendered already
				i++;
			}
		} else if (item.rules) {
      // for nested group, get combinator from last added path
      const currComb = getCombinatorByPath(query, combinatorPath)
			rulesArray.push(
				<RenderRules
					key={item.id}
					fields={fields}
					rules={item.rules}
          			combinator={currComb ?? null}
          			query={query}
					setQuery={setQuery}
					path={currentPath}
					combinatorPath={combinatorPath}
					onCheckboxChange={onCheckboxChange}
					checkedRules={checkedRules}
          			groupPaths={groupPaths}
					handleFieldChange={handleFieldChange}
					fieldValueOptionsCallback={fieldValueOptionsCallback}
				/>
			);
		} else {
			rulesArray.push(
				<RuleRow
					key={item.id}
					fields={fields}
					rule={item}
					combinator={combinator}
					query={query}
					setQuery={setQuery}
					path={currentPath}
					combinatorPath={combinatorPath}
					groupedRules={rules}
					onCheckboxChange={onCheckboxChange}
					isChecked={checkedRules.some((p: any) => JSON.stringify(p) === JSON.stringify(currentPath))}
					groupPaths={groupPaths}
					handleFieldChange={handleFieldChange}
					fieldValueOptionsCallback={fieldValueOptionsCallback}
					disableBtn = {(i === 0) && (query.rules[i].field === item.field && 
						query.rules[i].field === 'Subscription') ? true : false}
				/>
			);
		}
	}

	return <>{rulesArray}</>;
};

export default RenderRules;
