import { Box, Typography, Tooltip, Stack, Chip, debounce } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import ToolTipIcon from '../../assets/info-icon.svg';
import NoPrgramFound from '../../assets/no-program-found.svg';
import LoaderComponent from 'components/Loader';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { defaultOptions, getChartOptions } from './chartWidgetUtility';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import { useEffect, useRef, useState } from 'react';
import { BadgeData, ChartWidgetProps } from './type';
import {formatDateAndValue} from '../../utils/formateValue'

const ChartWidget = ({
	badge,
	chartBgColor = '#1b1e35',
	conversionUnitProp = null,
	chartInterval = 0,
	isLoading = false,
	isOverlayWidget = false,
	isInProgress = false,
	title = '',
	tooltipLabel = '',
	data,
	footer,
	minTitleHeight = '50.5px',
	hasBadge = true,
	lastUpdatedDate = '',
	handleEvent,
	restrictToMillions = false,
	isDatabrick = false,
	isForeCast = false,
	isYearly = false,
	isFinops = false,
	sectorLabel = ''
}: Readonly<ChartWidgetProps>) => {
	const [controller] = useCacheDataController();
	const { conversionUnit } = controller;
	const [windowSize, setWindowSize] = useState(window.innerWidth);
	const [gridLeft, setGridLeft] = useState('5%');
	const chartContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// re-render chart to fix alignment when resizing the browser
		const handleResize = debounce(() => {
			if (chartContainerRef.current) {
				const containerWidth = chartContainerRef.current.offsetWidth;
				const calculatedLeft =
					containerWidth < 420
						? '18%'
						: containerWidth < 600
						? '15%'
						: containerWidth < 820
						? '12%'
						: containerWidth < 920
						? '9%'
						: containerWidth < 1260
						? '7%'
						: '5%';
				setGridLeft(calculatedLeft);
				setWindowSize(window.innerWidth);
			}
		}, 200);

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const result = getChartOptions(data ?? {}, conversionUnitProp, conversionUnit, chartInterval, lastUpdatedDate, isDatabrick, isForeCast, isYearly, isFinops);
	const { chartOption, badgePercentage, chartTooltip } = result;
	const badgeData: BadgeData | null = badge
		? badge
		: badgePercentage
		? {
				badgeLabel: `${Math.abs(Number(badgePercentage.toFixed(2)))}% /YEAR`,
				badgeArrow: Math.sign(badgePercentage) == -1 ? 'down' : 'up'
		  }
		: null;


	const adjustedOption: any =
		!chartOption || Object.keys(chartOption).length === 0 
			? defaultOptions
			: (conversionUnitProp == null || conversionUnitProp == 'cost') ? {
					...chartOption,
					grid: {
						...chartOption.grid,
						left: gridLeft
					},
					tooltip: {
						...chartOption.tooltip,
						formatter: function (params : any) {
							if (Array.isArray(params)) {
								return params.map(item => {
									const formattedValue = formatDateAndValue(item.value, restrictToMillions, title, isDatabrick, isYearly);
									return formattedValue;
								}).join('<br/>');
							} else {
								const formattedValue = formatDateAndValue(params.value, restrictToMillions, title, false, isYearly);
								return formattedValue;
							}
						}
					}
				} : {
					...chartOption,
					grid: {
						...chartOption.grid,
						left: gridLeft
					} 
				}

	return (
		<Box display="flex" flexGrow={1} height="100%" position="relative">
			<Stack
				minHeight="100px"
				p={2}
				bgcolor="primary.main"
				fontFamily="Montserrat"
				borderRadius="5px"
				flexGrow={1}
				{...(handleEvent && { onClick: handleEvent, sx: { cursor: 'pointer' } })}
			>
				<Stack direction="row" gap={2} justifyContent="space-between" alignItems="start" minHeight={minTitleHeight}>
					<Stack direction="row" gap={1.5} alignItems="start">
						<Typography variant={isOverlayWidget ? 'overlayWidgetTitle' : 'widgetTitle'}>{title}</Typography>
						{(!!tooltipLabel || !!chartTooltip) && (
							<Tooltip title={!!tooltipLabel ? tooltipLabel : chartTooltip} arrow>
								<img src={ToolTipIcon} />
							</Tooltip>
						)}
					</Stack>
					<Stack direction="row" gap={1} alignItems="start">
					
					{!isLoading && hasBadge && badgeData && (
						<Chip
							variant="chartBadge"
							// below sx is given, becoz mui chip is override by other css files, remove below sx once css files are removed
							sx={{
								backgroundColor: '#009BD8 !important',
							}}
							icon={badgeData.badgeArrow === 'up' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
							label={badgeData.badgeLabel}
							title={badgeData.badgeTitle}
						/>
					)}

					{sectorLabel != '' && (
							<Box bgcolor="blue.darker" sx={{
								borderRadius: '16px',
								width: 'auto',
								float: 'right',
								display: 'flex',
								height: 'auto',
								alignItems: 'center',
								padding: '5px'
							}}>
								<Typography variant="widgetTitle" sx={{ fontSize: '11px', textAlign: 'center', fontWeight: '500 !important' }}>{sectorLabel}</Typography>
							</Box>
					
					)}
						</Stack>
				</Stack>
				<Stack minHeight="350px" flexGrow={1} justifyContent="center" key={windowSize} ref={chartContainerRef}>
					{!isLoading ? (
						<ReactECharts
							option={adjustedOption}
							style={{ width: '100%', height: '100%' }}
							opts={{ renderer: 'canvas', width: 'auto' }}
							notMerge={true}
							lazyUpdate={true}
						/>
					) : (
						<LoaderComponent />
					)}
				</Stack>
				{isInProgress && (
					<Box position="absolute" right={15} bottom={10}>
						<Tooltip title="In Progress/Validation">
							<img
								src={NoPrgramFound}
								style={{
									height: '20px',
									width: '20px'
								}}
							/>
						</Tooltip>
					</Box>
				)}
			</Stack>
		</Box>
	);
};

export default ChartWidget;
