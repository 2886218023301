import { Box, Stack, StackProps, Tooltip, Typography, TypographyProps } from '@mui/material';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import { ReactComponent as ContactIcon } from '../../assets/contact-icon.svg';
import PhoneIcon from "@mui/icons-material/Phone";

type TitleObject = {
	text: string;
	link?: string; // Optional link if the title is clickable
};

type contactObject = {
	name: string;
	email: string;
	phone: number
};

type PageHeaderProps = {
	title?: string | Array<string> | Array<TitleObject>;
	icon?: string;
	iconSize?: number;
	lastSync?: string | null;
	children?: React.ReactNode;
	childrenContainerProps?: StackProps;
	isDataRefresh?: boolean;
	hideTitle?: boolean;
	hideIcon?: boolean;
	executiveData?: boolean;
	executiveContact?: contactObject;
	exeLastSync?: string;
};

const breadCrumbStyle: TypographyProps = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	maxWidth: '125px'
};

const PageHeader = ({
	title,
	icon,
	iconSize,
	lastSync,
	children,
	childrenContainerProps,
	isDataRefresh = false,
	hideTitle = false,
	hideIcon = false,
	executiveData = false,
	executiveContact,
	exeLastSync
}: PageHeaderProps) => {

	const handleClick = (event: any, title: string, link?: any) => {
		if (event.type === 'click' && title === 'Incident Details' && link === '/dataestate/majorIncident') {
			localStorage.setItem('deMajorIncident', 'true');
		}
	};

	return (
		<Stack
			direction={{ xs: 'column', md: 'row' }}
			justifyContent={{ xs: 'center', md: 'space-between' }}
			position="sticky"
			top={80}
			zIndex={100}
			bgcolor="blue.darker"
			px={2}
		>
			<Stack direction="row" gap={0.5} alignItems="center">
				{!hideIcon && !!icon && (
					<Box component="img" src={icon} alt="icon" {...(!!iconSize && { width: iconSize })} />
				)}

				{!hideTitle && !!title && (
					typeof title === 'string' ? (
						<>
							<Typography variant="breadcrumbs">{title}</Typography>
						{executiveContact?.name && <Tooltip title={
							<><Typography variant="body2" color="light.diff" style={{fontSize: '13px', color: 'rgba(169, 173, 203, 1)', fontWeight: '500', fontStyle: 'italic'}}>Contact Person</Typography>
							<Typography variant="body2" color="inherit" style={{fontSize: '12px'}}>{executiveContact.name} </Typography>
							<Typography variant="body2" color="light.diff" style={{fontSize: '12px', fontWeight: '500', color: 'rgba(245, 245, 245, 1)', fontStyle: 'italic'}}>{executiveContact.email}</Typography>
							{ executiveContact.phone && <Typography variant="body2" color="light.diff" style={{fontSize: '12px', fontWeight: '500', color: 'rgba(245, 245, 245, 1)', fontStyle: 'italic'}}><PhoneIcon style={{ verticalAlign: "middle", width: '15px', height: '15px', color: 'blue' }} /> {executiveContact.phone}</Typography> }
							</>
							} arrow>
							<ContactIcon style={{ verticalAlign: 'middle', marginLeft: '5px' }} />
						</Tooltip>}
						{ executiveData && <Box>
							<Stack direction="row" color="light.main" fontSize="14px" style={{ color: 'rgba(113, 117, 143, 1)', fontWeight: '400'}} gap={0.5} ml={1}>
							<ReloadIcon /> <> Last Refreshed: {exeLastSync} </>
							</Stack>					
						</Box> }
						</>
					) : (
						<Stack direction="row" alignItems="center" flexWrap="wrap">
							{title.map((item, idx) =>
								typeof item === 'string' ? (
									<Stack key={`${item}-${idx}`} direction="row" alignItems="center">
										<Typography
											variant="breadcrumbs"
											{...((idx < title.length - 1 || item.length > 60) && idx !== 2 && breadCrumbStyle)}
										>
											{item}
										</Typography>
										{idx < title.length - 1 && <ChevronRightIcon />}
									</Stack>
								) : (
									<Stack key={`${item.text}-${idx}`} direction="row" alignItems="center">
										{item.link ? (
											<Box
												component={Link}
												to={item.link}
												sx={{
													color: 'light.main',
													textDecoration: 'none',
													...((idx < title.length - 1 || item.text.length > 60) && breadCrumbStyle)
												}}
											>
												<Typography
													variant="breadcrumbs"
													title={item.text}
													onClick={(e) => handleClick(e, item.text, item.link)}
												>
													{item.text}
												</Typography>
											</Box>
										) : (
											<Typography
												variant="breadcrumbs"
												title={item.text}
												{...((idx < title.length - 1 || item.text.length > 60) && idx !== 2 && breadCrumbStyle)}
											>
												{item.text}
											</Typography>
										)}
										{idx < title.length - 1 && <ChevronRightIcon />}
									</Stack>
								)
							)}
						</Stack>
					)
				)}

				{!!lastSync && (
					<Stack direction="row" color="light.main" fontSize="12px" gap={0.5} ml={1}>
						<ReloadIcon /> {isDataRefresh ? <> Last Data Refresh: {lastSync} </> : <> Last Sync: {lastSync}</>}
					</Stack>
				)}
			</Stack>
			<Stack
				direction="row"
				gap={2}
				alignItems="center"
				justifyContent={{ xs: 'flex-end' }}
				pr={{ xs: 1, md: 'initial' }}
				{...childrenContainerProps}
			>
				{children}
			</Stack>
		</Stack>
	);
};

export default PageHeader;
