// import Header from './Header';
// import Footer from '../LandingPage/LandingFooter';
import '../LandingPage/LandingPage.scss';
import {  Grid } from '@mui/material';
import dataPipelineLogo from '../../assets/data-pipeline-logo.svg';

import CustomDashboardIcon from '../../assets/Custom Dashboard_Small.svg';
import { useEffect, useState, lazy, Suspense } from 'react';
// import { getDecodedToken, role, capabilities } from '../../service/service';
import FinOpsLogo from '../../assets/FinOps_small.svg';
import landingBackArrow from '../../assets/landingBackArrow.svg';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';

// Lazy load components
const Header = lazy(() => import('./Header'));
const Footer = lazy(() => import('../LandingPage/ModuleLandingFooter'));
const capabilitiesNew : any = localStorage.getItem('capabilities');


export default function DataopsLanding() {
	const [memberData, setMemberData] = useState<any>(null);
	const [isPanelOpen, setPanelOpen] = useState<boolean>(false);
	const [controller, _] = usePepwiseAuthController();
	const { accessToken, capabilities } = controller;
	console.log(capabilitiesNew, 'capabilitiesNew==')
	const capabilitiesArray = capabilitiesNew ? capabilitiesNew.split(',') : [];
	

	// to display data
	useEffect(() => {
		// console.log(moment(sd).diff(end, 'days'))
		// const tokenData = getDecodedToken();
		// console.log('token', accessToken);
		setMemberData(accessToken);
	}, []);

	//navigate to data-pipeline observability
	const handleDataPipelineClick = (event: any, type: string, url: string) => {
		event.preventDefault(); // Prevent the default context menu from showing up
		if (type === 'blank') {
			// Open the URL in a new tab if type is 'blank'
			window.open(url, '_blank');
		} else {
			// Navigate to the provided URL in the current tab
			window.location.href = url;
		}
	};

	const handleBackClick = () => {
		window.location.href = '/LandingPage';
	};
	

	return (
		<div className="outerLandingPageContainer">
			<Header memberData={memberData} />
			<div style={{ maxWidth: '86%', margin: '0 auto' }}>
                <div className="landingMessage-container">
					<img src={landingBackArrow} alt="CCLogo" className="landingMessageImage" onClick={handleBackClick} />
					<p className="landingMessage">{`Data Pipeline Observability`}</p>
				</div>


				<div className="welcome_text">
					<p>Click on the module below to monitor!</p>
				</div>
				<Grid container spacing={1} style={{ marginTop: '0px' }}>
                {capabilitiesArray.includes('Data Ops') ? (
					<Grid
						item
						xs={4}
						style={{ cursor: 'pointer'}}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/data-pipeline/programs')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/data-pipeline/programs');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
                                <img src={dataPipelineLogo} alt="tableauLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }} />
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
									DataOps Sustain
								</h3>
							</div>
							<p
								className="message1"
								title="Monitor jobs or pipelines for a specific project."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Monitor jobs or pipelines for a specific project.
							</p>
						</div>
					</Grid>
                    ) : (
					''
					)}
                {capabilitiesArray.includes('Data Ops') ? (
                    <Grid
						item
						xs={4}
						
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/data-pipeline/programOpsDashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/data-pipeline/programOpsDashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
                                <img src={CustomDashboardIcon} alt="tableauLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }}/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
                                    Program Ops Dashboards
								</h3>
							</div>
							<p
								className="message1"
								title="Observe custom Grafana dashboards."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Observe custom Grafana dashboards.
							</p>
						</div>
					</Grid>
                    ) : (
						''
				)} 
				{ capabilitiesArray.includes('Data Ops') ? (
                    <Grid
						item
						xs={4}
						
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/data-pipeline/executiveOps')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/data-pipeline/executiveOps');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
							<img src={dataPipelineLogo} alt="tableauLogo" className="obs_logo" style={{ display: 'block', margin: '0 auto' }} />
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
                                    Sector Executive Dashboard
								</h3>
							</div>
							<p
								className="message1"
								title="across all workflows"
								style={{ margin: '0', textAlign: 'center' }}
							>
								Unified platform offering comprehensive visibility into enterprise data program execution, dependencies and performance across all workflows. 
							</p>
						</div>
					</Grid>
                    ) : (
						''
				)}
				</Grid>
			</div>
			<Footer />
		</div>
	);
}
