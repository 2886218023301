import React, { useState } from 'react';
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from '@mui/material';

const VariableModal = ({ handleClose, onManageVariables, selectedVariable }: any) => {
	const [variable, setVariable] = useState<any>(selectedVariable ?? {});
	const [errorText, setErrorText] = useState<string|null>(null);

	const checkMandatoryFields = () => {
        if(!variable.type || !variable.name || !variable.identifier || !variable.value){
            setErrorText('Please fill up all required fields.')
            return false
        }
        return true
    }

	const handleSaveChannel = () => {
		const isValid = checkMandatoryFields()
        if(!isValid) return
		const uuid = selectedVariable ? selectedVariable.uuid : crypto.randomUUID()
		onManageVariables({ ...variable, isEnabled: true, uuid }, selectedVariable ? 'update' : 'add');
	};

	const handleVariableChange = ({ name, value }: any) => {
		setErrorText(null)
		setVariable({ ...variable, [name]: value });
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
			<FormControl fullWidth required>
				<FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' }, textAlign: 'left' }}>
                	Select Type
				</FormLabel>
				<RadioGroup row name="channelType" value={variable.type} onChange={(e) => handleVariableChange({name: 'type', value: e.target.value})}>
					<FormControlLabel
						value={'static'}
						control={<Radio variant="pepwiseRadio" />}
						label={'Static'}
					/>
					<FormControlLabel
						value={'sql'}
						control={<Radio variant="pepwiseRadio" />}
						label={'SQL'}
					/>
				</RadioGroup>
			</FormControl>
			
			<div style={{ display: 'flex', flexDirection: 'row', gap: 32 }}>
				<FormControl required>
					<TextField
						label="Variable Name*"
						variant="outlined"
						size="small"
						value={variable.name}
						onChange={(e) => handleVariableChange({ name: 'name', value: e.target.value })}
						sx={{
							width: 300,
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#66B2FF'
								},
								'&:hover fieldset': {
									borderColor: '#66B2FF'
								},
								'&.Mui-focused fieldset': {
									borderColor: '#66B2FF'
								}
							}
						}}
					/>
				</FormControl>
				<FormControl required>
					<TextField
						label="Identifier*"
						variant="outlined"
						size="small"
						value={variable.identifier}
						onChange={(e) => handleVariableChange({ name: 'identifier', value: e.target.value })}
						sx={{
							width: 300,
							'& .MuiOutlinedInput-root': {
								'& fieldset': {
									borderColor: '#66B2FF'
								},
								'&:hover fieldset': {
									borderColor: '#66B2FF'
								},
								'&.Mui-focused fieldset': {
									borderColor: '#66B2FF'
								}
							}
						}}
					/>
				</FormControl>
			</div>
			<FormControl required>
				<FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' }, textAlign: 'left' }}>
                    Query / Value
                </FormLabel>
				<TextField
					fullWidth
					multiline
					rows={5}
					variant="outlined"
					value={variable.value}
					placeholder="Query/Value"
					onChange={(e) => handleVariableChange({ name: 'value', value: e.target.value })}
					InputProps={{
						sx: {
							width: 730,
							marginTop: 2,
							color: 'white',
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: '#1E3A5F'
							},
							'&:hover .MuiOutlinedInput-notchedOutline': {
								borderColor: '#3A506B'
							},
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: '#66B2FF'
							}
						}
					}}
				/>
			</FormControl>

			<Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Typography
					style={{
						color: '#880000',
						cursor: 'pointer',
						marginTop: '6px',
						marginRight: '25px'
					}}					
				>
					{errorText ? `*${errorText}` : ''}
				</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
					<Typography
						style={{
							color: 'white',
							cursor: 'pointer',
							marginTop: '6px',
							marginRight: '25px'
						}}
						onClick={handleClose}
					>
						CANCEL
					</Typography>
					<Button variant="pepwiseSecondary" onClick={handleSaveChannel}>
						SAVE
					</Button>
				</Box>
			</Box>
		</div>
	);
};

export default VariableModal;
