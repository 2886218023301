import React, { useEffect, useMemo, useState } from 'react';
import { Stack, Box, Typography, Button, Alert } from '@mui/material';
import { ReactComponent as ResourceMapping } from '../../../../src/assets/resourceMappingNew.svg';
import styled from '@emotion/styled';
import AsyncAutoComplete from '../../Inputs/ResourceMapping/AsyncAutocomplete';
import { getBulkEditFinopsinputProgramProjectOption, getBulkEditFinopsinputSecEnvOption, getFinopsResourceMappingList } from 'service/service';
import { postBulkEditFinopsResourceMappingList } from 'service/service';

import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import RowTypography from 'components/Table/RowTypography';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import BulkUpdateConflictsModal from './BulkUpdateConflictModal';
import LoaderComponent from 'components/Loader';
import CommonSuccessModal from './CommonSuccessModal';
import RowChip from 'components/Table/RowChip';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import colors from 'assets/theme/base/colors';

const StyledConfirmationIcon = styled(ResourceMapping)({
	width: '5rem',
	height: '5rem'
});

interface BulkEditResponse {
	success: boolean;
	statusCode: number;
	data: {
	  message: any;
	};
  }
  

interface Option {
	value: string;
	label: string;
}

interface Row {
	id: string;
	[key: string]: any;
}

type ModalWidth = "sm" | "md" | "lg" | "xl";

interface CustomFormModalProps {
	columnNames: any;
	queryPreference: any;
	bulkSelectedRows: any;
  	setBulkSelectedRows: any;
	onClose: () => void;
	onSave: (formData: FormData, changedCells: any, rowsToUpdate: any) => void;
	onCancel: () => void;
	onConfirm: () => void;
	onTitleChange: (newTitle: string) => void;
    onWidthChange: (newWidth: ModalWidth) => void;
	allocationType: {
		key: string;
		label: string;
	};
}

interface FormData {
	PepwiseProgram: string;
	PepwiseProject: string;
	PepwiseSector: string;
	PepwiseEnvironment: string;
	// DNAI_Value: string;
}

const BulkEditModalConfirm: React.FC<CustomFormModalProps> = ({
	onClose,
	onSave,
	onCancel,
	onConfirm,
	bulkSelectedRows,
	setBulkSelectedRows,
	queryPreference,
	onTitleChange,
	onWidthChange,
	allocationType
}) => {
	const isNestedTreeView = queryPreference.split('_')[0] === 'ResourceGroup';
	const defaultColumns = [ 'Sectors', 'Environment', 'Programs', 'Projects', 'Current Allocation %'];
	const isTreeView = queryPreference.split('_')[1] === 'tree';
	const defaultColumnNames = ['Resource', 'Sector', 'Environment', 'Project', 'Program', 'Allocation %', 'Start Date', 'End Date'];
	
	const initialFormData: FormData = {
		PepwiseProgram: '',
		PepwiseProject: '',
		PepwiseSector: '',
		PepwiseEnvironment: ''
		// DNAI_Value: ''
	};

	const [formData, setFormData] = useState<FormData>(initialFormData);
	const [groupId, setGroupId] = useState<string[]>([]);
	const [showbulkEditInput, setShowbulkEditInput] = useState<boolean>(true);
	const [showConflictModal, setShowConflictModal] = useState<boolean>(false);
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
	const [bulkEditResponse, setBulkEditResponse] = useState<any>(null);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [selectedProgramId, setSelectedProgramId] = useState<number>();
	const [selectedProjectId, setSelectedProjectId] = useState<number>();
	const [programOptions, setProgramOptions] = useState<Option[]>([]);
	const [projectOptions, setProjectOptions] = useState<Option[]>([]);
	const [sectorOptions, setSectorOptions] = useState<Option[]>([]);
	const [environmentOptions, setEnvironmentOptions] = useState<Option[]>([]);
	const [selectedProgram, setSelectedProgram] = useState<number | null>(null);
	const [projectsByProgram, setProjectsByProgram] = useState<Record<string, Option[]>>({});
	const [isProjectDisabled, setIsProjectDisabled] = useState(true);
	const [isProgramDisabled, setIsProgramDisabled] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [parentRows, setParentRows] = useState<any[]>([]);
	
	const columnNames = [
		{ name: 'resource', label: 'Resource', isEditable: false },
		{ name: 'environment', label: 'Environment', isEditable: false },
		{ name: 'project', label: 'Project', isEditable: false },
		{ name: 'program', label: 'Program', isEditable: false },
		{ name: 'sector', label: 'Sector', isEditable: false },
		{ name: 'allocation', label: 'Allocation %', isEditable: false },
		{ name: 'startDate', label: 'Start Date', isEditable: false },
		{ name: 'endDate', label: 'End Date', isEditable: false },
	]

	const columns = [
		{ name: 'sNo', label: 'S.No' },
		{ name: 'Resource', label: 'Resource' },
		{ name: 'PepwiseSector', label: 'Sectors' },
		{ name: 'PepwiseEnvironment', label: 'Environment' },
		{ name: 'PepwiseProgram', label: 'Programs' },
		{ name: 'PepwiseProject', label: 'Projects' },
		{ name: 'AllocPercent', label: 'Current Allocation %' }
	];

	const isFormValid = () => {
		return (
		  selectedProjectId &&
		  selectedProjectId &&
		  formData.PepwiseProgram &&
		  formData.PepwiseProject &&
		  formData.PepwiseSector &&
		  formData.PepwiseEnvironment
		);
	  };

	const handleChange = (field: keyof FormData, value: string) => {
		setFormData((prevState) => {
			// If `PepwiseProgram` is being updated, reset `PepwiseProject`
			if (field === 'PepwiseProgram') {
				return {
				  ...prevState,
				  [field]: value, // Update `PepwiseProgram`
				  PepwiseProject: '', // Reset `PepwiseProject`
				};
			  }
			  // Otherwise, just update the specified field
			  return {
				...prevState,
				[field]: value,
			  };
		});
	};

	const handleCancel = () => {
		setFormData(initialFormData);
		// setBulkSelectedRows({})
		onCancel();
	};

	// bulk update save api call
	const postBulkEdit = async (
		groupId: string[],
		formData: FormData,
		override: 'N' | 'Y' = 'N',
		skipconflict: 'N' | 'Y' = 'N',
		selectedProgramId?: number,
		selectedProjectId?: number
	) => {
		try {
			setIsLoading(true);
			const BulkEditRes = (await postBulkEditFinopsResourceMappingList({
				groupId: groupId,
				...formData,
				override: override,
				skipconflict: skipconflict,
				programId: selectedProgramId,
				projectId: selectedProjectId
			})) as { response: any; data?: any; success?: boolean };


			// Check if `BulkEditRes` exists (success case)
			if (BulkEditRes.success) {
				// const { success } = BulkEditRes;
				if (BulkEditRes.success) {
					onTitleChange('Allocation Modified');
					onWidthChange('md');
					setBulkEditResponse(BulkEditRes.data);
					setShowbulkEditInput(false);
					setShowConflictModal(false);
					setShowSuccessModal(true);
					setFormData(initialFormData);
					setBulkSelectedRows({})
					return {success: true, data: BulkEditRes.data};
				} else {
					// Handle failure when success flag is false
					onTitleChange('Allocation Conflicts During Bulk Update');
					onWidthChange('md');
					setBulkEditResponse(BulkEditRes.data);
					setShowbulkEditInput(false);
					setShowConflictModal(true);
					setShowSuccessModal(false);
					return {success: false, data: BulkEditRes.data};
				}
			} else if (BulkEditRes.response.data) {
				const { success } = BulkEditRes.response.data;
				const { message } = BulkEditRes.response.data;

				if (success) {
					onTitleChange('Allocation Modified');
					onWidthChange('md');
					setBulkEditResponse(BulkEditRes.response.data);
					setShowbulkEditInput(false);
					setShowSuccessModal(true);
					return {success: true, data: BulkEditRes.response.data};
				} else {
					// Handle failure when success flag is false
					onTitleChange('Allocation Conflicts During Bulk Update');
					onWidthChange('md');
					setBulkEditResponse(BulkEditRes.response.data);
					setShowbulkEditInput(false);
					setShowConflictModal(true);
					return {success: false, data: BulkEditRes.response.data};
				}
			}
		} catch (error) {
			console.log('Error in postBulkEdit:', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const rows = Object.values(bulkSelectedRows);
		const groupIds = rows.map((row: any) => row.groupId)
		.filter(Boolean);

		const uniqueGroupIds = groupIds.reduce((acc: any[], currentGroupId: any) => {
			if (!acc.includes(currentGroupId)) {
				acc.push(currentGroupId);
			}
			return acc;
		}, []);

		setGroupId(uniqueGroupIds);
	}, [bulkSelectedRows]);

	const handleViewLogic = (bulkEditResponse: { result: any[] }, formData: FormData) => {
		let updatedRows;
		//   if (isTreeView) {
		//     // Tree View Logic
		//     updatedRows = Object.keys(bulkSelectedRows).reduce((acc, rowId) => {
		//       let row = { ...bulkSelectedRows[rowId] };

		//       // Update child rows if they exist
		//       if (row.values && Array.isArray(row.values)) {
		//         row.values = row.values.map((childRow: any) => {
		//           const updatedChildRow = { ...childRow };

		//           // Apply formData to child rows
		//           (Object.keys(formData) as Array<keyof FormData>).forEach((key) => {
		//             if (formData[key]) {
		//               updatedChildRow[key] = formData[key];
		//             }
		//           });

		//           return updatedChildRow;
		//         });
		//       }

		//       acc[rowId] = row;
		//       return acc;
		//     }, {} as typeof bulkSelectedRows);
		//   } else {

		// Flat View Logic
		updatedRows = bulkEditResponse?.result?.reduce(
			(acc, r) => {
				const row = { ...r };
				// if (row.values) {
				// 	return acc;
				// }
				(Object.keys(formData) as Array<keyof FormData>).forEach((key) => {

					if (formData[key]) {
						row[key] = formData[key];
						acc.changedCells = {
							...acc.changedCells,
							[r.groupId]: {
								...acc.changedCells[r.groupId],
								[key]: true
							}
						};
					}
				});
				acc[r.groupId] = row;
				return acc;
			},
			{ changedCells: {} } as typeof bulkSelectedRows
		);
		const { changedCells, ...rowsToUpdate } = updatedRows;
		
		return { changedCells, rowsToUpdate };
	// }
	}

	const handleSave = async (bulkSelectedRows: any, override: 'N' | 'Y' = 'N', skipconflict: 'N' | 'Y' = 'N') => {
		const response = await postBulkEdit(groupId, formData, override, skipconflict, selectedProgramId, selectedProjectId);
		if (response?.success) {
			let res = response.data;
			const { changedCells, rowsToUpdate } = handleViewLogic(res, formData);
			setBulkSelectedRows(rowsToUpdate);
			onSave(formData, changedCells, rowsToUpdate);
		}
	};

	// Fetch program and project options
	const getInputProgramProjectOptions = async (input: string) => {
		try {
			const fieldsRes = await getBulkEditFinopsinputProgramProjectOption({ searchText: input });
			const programs = fieldsRes.data.programs;

			const programOptions: Option[] = programs.map((program: any) => ({
				value: program.programId,
				label: program.programName
			}));

			// Map `projects` for each program into a nested structure
			const projects: Record<string, Option[]> = {};
			programs.forEach((program: any) => {
				projects[program.programName] = program.projects.map((project: any) => ({
					value: project.projectId,
					label: project.projectName
				}));
			});

			return { programOptions, projects };
		} catch (error) {
			console.error('Error fetching program/project options:', error);
			return { programOptions: [], projects: {} };
		}
	};

	const getProgramProjectOptions = async (input: string | undefined, type: 'project' | 'program'): Promise<Option[]> =>{
		const { programOptions, projects } = await getInputProgramProjectOptions(input?? "");
		programOptions && setProgramOptions(programOptions);
		projects && setProjectsByProgram(projects);
		if(type === "program") return programOptions;
		return projects[`${formData.PepwiseProgram}`] ?? [];
	}

	useEffect(() => {
		const fetchOptions = async () => {
			let programName: any = '';
			if (searchParams.get('programName')) programName = searchParams.get('programName');
			const { programOptions, projects } = await getInputProgramProjectOptions(programName);
			setProgramOptions(programOptions);
			setProjectsByProgram(projects);

			if(searchParams.get('programId')) setSelectedProgramId(Number(searchParams.get('programId')));
			if (!!programName) {
				setFormData((prevState) => ({
					...prevState,
					PepwiseProgram: programName
				}));
				setIsProgramDisabled(true);
			}
		};
		fetchOptions();
		getInputSecEnvOptions();
	}, []);

	// Update project options when a program is selected
	useEffect(() => {
		if(searchParams.get('projectId')) setSelectedProjectId(Number(searchParams.get('projectId')));
		if (!!formData.PepwiseProgram && searchParams.get('projectName')) {
			setProjectOptions(projectsByProgram[`${formData.PepwiseProgram}`] || []);
			const param: any = searchParams.get('projectName');
			setFormData((prevState) => ({
				...prevState,
				PepwiseProject: param
			}));
			setIsProjectDisabled(true);
		} 
		else if (!!formData.PepwiseProgram) {
			setProjectOptions(projectsByProgram[`${formData.PepwiseProgram}`] || []);
			setIsProjectDisabled(false);
		} else {
			setProjectOptions([]);
			setIsProjectDisabled(true);
		}
	}, [formData.PepwiseProgram]);

	// fetch sector and environment options
	const getInputSecEnvOptions = async () => {
		try {
			const fieldsRes = await getBulkEditFinopsinputSecEnvOption();

				const sectorsOptions = fieldsRes.data.sectors.filter(Boolean).map((item: string) => ({
					value: item,
					label: item,
				}));

				const environmentsOptions = fieldsRes.data.environments.filter(Boolean).map((item: string) => ({
					value: item,
					label: item,
				}));
				setSectorOptions(sectorsOptions);
				setEnvironmentOptions(environmentsOptions);
		} catch (error) {
			console.error('Error fetching options:', error);
			return [];
		} 
	};

	function toggleSubRow(name: string, resourceTypes: any) {
		setSelectedRows((prev) => {
			const rows = [...prev];
			const rowIndex = rows.findIndex((r: any) => r.name === name);
			const row = rows[rowIndex];
			const newRows = [...rows];
			let children: Row[] = [];
			let childRows: Row[] = [];
			if (!row.isExpanded) {
				if (resourceTypes?.length > 0) {
					childRows = resourceTypes;
				}
				children = childRows.map((x: any) => {
					return { ...x, isChildren: true };
				});
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: true, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = true;
			} else {
				children = row.children ?? [];
				newRows.splice(Number(rowIndex) + 1, children.length);
				newRows[rowIndex] = { ...row, isExpanded: false };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = false;
			}
			return newRows;
		});
	}

	function getColumns(editedRows: Record<string, any>): Array<any> {
		const [groupBy] = queryPreference.split('_');

		return columnNames.map((col: any, idx: number) => ({
			key: col.name,
			name: col.label,
			formatter({ row, isCellSelected, column }: any) {
				const isGroupBy = column.key.toLowerCase() === groupBy.toLowerCase();
				const hasChildren = !!row.values;
				const startDate = moment(row.startDate).format('YYYY-MM-DD');
				const endDate = (row.endDate === '9999-12-31' || row.endDate === '') 
								? moment().format('YYYY-MM-DD') 
								: moment(row.endDate).format('YYYY-MM-DD');
				return (
					<>
						{column.key === allocationType.key && idx === 0 && isGroupBy && hasChildren && (
							<CellExpanderFormatter
								isCellSelected={isCellSelected}
								expanded={row.isExpanded === true}
								onCellExpand={() => {
									toggleSubRow(row.name, row.values);
								}}
							/>
						)}
						{'   '}
						{['sector', 'project', 'program'].includes(col.name) ? (
                        row[column.key] && <RowChip value={row[column.key]} rowId={row.id}  />
                    ) : (
                        <RowTypography isSuccessor={!row.values}>
                            {hasChildren ? (isGroupBy ? row.name : '') 
							: (col.name === 'startDate' || col.name === 'endDate') 
							? (col.name === 'startDate' ? startDate : endDate) : row[column.key]}
                        </RowTypography>
                    )}

					</>
				);
			},
			width: 200
		}));
	}

	function getColumn(): Array<any> {
		return columns.map((col: any, idx: number) => {

			return {
				key: col.name,
				name: col.label,
				editable: false,
				formatter({ row, column }: any) {
						if (column.key === 'AllocPercent') {
							return (
								<Stack direction="row" justifyContent="space-between" alignItems="center">
									<RowTypography isSuccessor={!row[column.key]}>
										{row[column.key] ? `${row[column.key]}%` : ''}
									</RowTypography>
								</Stack>
							);
						}

						if (['PepwiseSector', 'PepwiseProject', 'PepwiseProgram'].includes(column.key)) {
							if (column.key === 'PepwiseSector') {
								if (row['SectorCount'] > 1) {
									return (
										<Stack direction="row" justifyContent="space-between" alignItems="center">
											{/* <RowTypography isSuccessor={!row[column.key]}> */}
											<RowChip
												value={`${row['SectorCount']} SECTORS`}
												rowId={row.id}
												// isExpanded={expandedRows[row.id]}
												// onToggle={toggleRowExpansion}
											/>
											{/* </RowTypography> */}
										</Stack>
									);
								} else {
									if (row[column.key]) {
										return (
											<Stack direction="row" justifyContent="space-between" alignItems="center">
												{/* <RowTypography isSuccessor={!row[column.key]}> */}
												<RowChip
													value={row[column.key]}
													rowId={row.id}
													// isExpanded={expandedRows[row.id]}
													// onToggle={toggleRowExpansion}
												/>
												{/* </RowTypography> */}
											</Stack>
										);
									}
								}
							}

							if (column.key === 'PepwiseProject') {
								if (row['ProjectCount'] > 1) {
									return (
										<Stack direction="row" justifyContent="space-between" alignItems="center">
											{/* <RowTypography isSuccessor={!row[column.key]}> */}
											<RowChip
												value={`${row['ProjectCount']} PROJECTS`}
												rowId={row.id}
												// isExpanded={expandedRows[row.id]}
												// onToggle={toggleRowExpansion}
											/>
											{/* </RowTypography> */}
										</Stack>
									);
								} else {
									if (row[column.key]) {
										return (
											<Stack direction="row" justifyContent="space-between" alignItems="center">
												{/* <RowTypography isSuccessor={!row[column.key]}> */}
												<RowChip
													value={row[column.key]}
													rowId={row.id}
													// isExpanded={expandedRows[row.id]}
													// onToggle={toggleRowExpansion}
												/>
												{/* </RowTypography> */}
											</Stack>
										);
									}
								}
							}

							if (column.key === 'PepwiseProgram') {
								if (row['ProgramCount'] > 1) {
									return (
										<Stack direction="row" justifyContent="space-between" alignItems="center">
											{/* <RowTypography isSuccessor={!row[column.key]}> */}
											<RowChip
												value={`${row['ProgramCount']} PROGRAMS`}
												rowId={row.id}
												// isExpanded={expandedRows[row.id]}
												// onToggle={toggleRowExpansion}
											/>
											{/* </RowTypography> */}
										</Stack>
									);
								} else {
									if (row[column.key]) {
										return (
											<Stack direction="row" justifyContent="space-between" alignItems="center">
												{/* <RowTypography isSuccessor={!row[column.key]}> */}
												<RowChip
													value={row[column.key]}
													rowId={row.id}
													// isExpanded={expandedRows[row.id]}
													// onToggle={toggleRowExpansion}
												/>
												{/* </RowTypography> */}
											</Stack>
										);
									}
								}
							}
						}
					return (
						<Stack direction="row" justifyContent="space-between" alignItems="center">
							<RowTypography isSuccessor={!row[column.key]}>{row[column.key] || ''}</RowTypography>
						</Stack>
					);
				},
				width: col.name === 'sNo' ? 70 : 168
			};
		});
	}


	useEffect(() => {
			if (isNestedTreeView) {
				const allRows = Object.values(bulkSelectedRows).filter((item: any) => item.isResource === true)
					.map((item: any, index: number) => ({
						...item,
						sNo: index + 1, // Assign serial number
					}));
				// .flatMap(
				// 	(item: any) => {
				// 		if (isNestedTreeView) {
				// 			return item.values; // Flatten nested rows if needed
				// 		}
				// 		return item; // Keep flat structure
				// 	}
				// );
	
				setParentRows(allRows.filter((item: any) => item));
			} else {
				const allRows = Object.values(bulkSelectedRows)
					.flatMap((item: any) => (isNestedTreeView ? item.values : item))
					.filter((item: any) => item?.values)
					.map((item: any, index: number) => ({
						...item,
						sNo: index + 1, // Assign serial number
					}));
				setParentRows(allRows.filter((item: any) => item?.values));
			}
			
		}, [bulkSelectedRows]);



	useEffect(() => {
		setSelectedRows(Object.values(bulkSelectedRows).filter((item: any) => !item.values));
	}, [bulkSelectedRows]);
	

	if (isLoading) {
		return <LoaderComponent />;
	}

	if (showbulkEditInput) {
		return (
			<Stack spacing={2} sx={{ flexDirection: 'column', gap: 1, textAlign: 'left', p: '1rem' }}>
				<Typography sx={{ color: 'text.subtle', fontSize: '14px' }}>{parentRows?.length} Resources selected</Typography>
				<Stack pb={3} marginBottom={1} borderBottom={`2px solid ${colors.dark.dark}`}>
					<CustomizedDataGrid
						title=""
						tableColumns={getColumn()}
						tableData={parentRows}
						defaultColumnNames={defaultColumns}
						autoSetDraggedColumns={false}
						isRenderPagination={false}
						isShowMoreButton={false}
						style={{ maxHeight: '150px' }}
					/>
				</Stack>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent="center"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%', pt: '0.7rem' }}
				>
					<AsyncAutoComplete
						textFieldLabel={'Program'}
						sx={{ width: '31rem' }}
						inputValue={formData['PepwiseProgram']}
						value={formData['PepwiseProgram']}
						onChange={(event, value) => {
							const selectedProgram = programOptions.find((option) => option.label === value);
							const programId = selectedProgram && Number(selectedProgram.value);

							programId && setSelectedProgramId(programId);
							handleChange('PepwiseProgram', value);
						}}
						mappingType=""
						columnName={'PepwiseProgram'}
						options={programOptions}
						disabled={isProgramDisabled}
						fieldValueOptionsCallback={async (input) => await getProgramProjectOptions(input, 'program')}
					/>
					<AsyncAutoComplete
						textFieldLabel={'Project'}
						sx={{ width: '31rem' }}
						inputValue={formData['PepwiseProject']}
						value={formData['PepwiseProject']}
						onChange={(event, value) => {
							const selectedProject = projectOptions.find((option) => option.label === value);
							const projectId = selectedProject && Number(selectedProject.value);

							projectId && setSelectedProjectId(projectId);
							handleChange('PepwiseProject', value);
						}}
						mappingType=""
						columnName={'PepwiseProject'}
						options={projectOptions}
						disabled={isProjectDisabled}
						// fieldValueOptionsCallback={async (input) => await getProgramProjectOptions(input, 'project')}
					/>
				</Stack>
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent="center"
					alignItems="center"
					spacing={2}
					sx={{ width: '100%' }}
				>
					<AsyncAutoComplete
						textFieldLabel={'Sector'}
						sx={{ width: '31rem' }}
						inputValue={formData['PepwiseSector']}
						onChange={(event, value) => handleChange('PepwiseSector', value)}
						mappingType="finopsResourceMapping"
						columnName={'PepwiseSector'}
						options={sectorOptions}
						// fieldValueOptionsCallback={async () => getInputSecEnvOptions('sectors')}
					/>
					<AsyncAutoComplete
						textFieldLabel={'Environment'}
						sx={{ width: '31rem' }}
						inputValue={formData['PepwiseEnvironment']}
						onChange={(event, value) => handleChange('PepwiseEnvironment', value)}
						mappingType="finopsResourceMapping"
						columnName={'PepwiseEnvironment'}
						options={environmentOptions}
						// fieldValueOptionsCallback={async () => getInputSecEnvOptions('environments')}
					/>
				</Stack>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Alert
						severity="info"
						sx={{
							display: "flex",
    						alignItems: "center",
							'& .MuiAlert-icon': {
								color: `text.subtle`,
								fontSize: '1.2rem'
							}
						}}
					>
						<Typography sx={{ color: 'text.subtle', fontSize: '14px' }}>
							Resource will be 100% allocated to the selected project,
						</Typography>
						<Typography sx={{ color: 'text.subtle', fontSize: '14px' }}>during bulk update.</Typography>
					</Alert>
					<Stack direction="row" sx={{ justifyContent: 'flex-end', gap: 2, p: '7px' }}>
						<Button variant="pepwiseOutline" sx={{ width: '8rem' }} onClick={handleCancel}>
							CANCEL
						</Button>
						<Button
							disabled={!isFormValid()}
							variant="pepwiseSecondary"
							sx={{ width: '8rem' }}
							onClick={() => handleSave(bulkSelectedRows)}
						>
							UPDATE
						</Button>
					</Stack>
				</Stack>
			</Stack>
		);
	}

	if (showConflictModal) {
		return (
			<BulkUpdateConflictsModal
				onCancel={handleCancel}
				onTitleChange={onTitleChange}
				onWidthChange={onWidthChange}
				bulkEditResponse={bulkEditResponse}
				bulkSelectedRows={bulkSelectedRows}
				handleSave={handleSave}
				formData={formData}
				allocationType={allocationType}
				queryPreference={queryPreference}
			/>
		);
	}

	if (showSuccessModal) {
		return (
			<CommonSuccessModal
				onTitleChange={onTitleChange}
				onWidthChange={onWidthChange}
				onCancel={handleCancel}
				getColumns={() => getColumns(selectedRows)}
				messageTitle="Resource Allocation Successful"
				bulkEditResponse={bulkEditResponse}
				defaultColumnNames={defaultColumnNames}
				buttonLabel="CLOSE"
				onConfirm={onConfirm}
				successMessage={`resource${bulkEditResponse.result.length>1?'s':""} allocated successfully`}
				allocationType={allocationType}
			/>
		);
	}
	return null;
};

export default BulkEditModalConfirm;
