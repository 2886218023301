import {
    Grid,
    FormControl,
    TextField,
    Typography,
    Box,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox
  } from "@mui/material";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useState } from "react";

const SilenceModal = ({ handleClose, onManageSilences, selectedSilence }: any) => {
	const [silence, setSilence] = useState(selectedSilence ?? {});
  const [errorText, setErrorText] = useState<string|null>(null);
  const channels = {
    email: false,
    serviceNow: false,
    teams: false
  } as any

  if(selectedSilence?.silencedChannels?.length){
    for(let channel of selectedSilence.silencedChannels){
      channels[channel] = true
    }
  }
  const [silencedChannels, setSilencedChannels] = useState(channels);

  const handleChannelChange = (e: any) => {
    setSilencedChannels({...silencedChannels, [e.target.name]: e.target.checked})
  }

  const handleChange = ({ name, value }: any) => {
    setErrorText(null)
    setSilence((prev: any) => ({ ...prev, [name]: value }));
  };

  const checkMandatoryFields = () => {
    if(!silence.name || !silence.startTime || !silence.endTime || !silence.timeIdentifier){
        setErrorText('Please fill up all required fields.')
        return false
    }
    return true
  }

  const handleSaveSilence = () => {
    const isValid = checkMandatoryFields()
    if(!isValid) return
    const uuid = selectedSilence ? selectedSilence.uuid : crypto.randomUUID()
    const channels = Object.entries(silencedChannels).filter(([ch, isEnabled]) => !!isEnabled).map(([ch, isEnabled]) => ch)
    onManageSilences({ ...silence, isEnabled: true, uuid, silencedChannels: channels }, selectedSilence ? 'update' : 'add');
  };

  return (
		<Grid container spacing={2}>
			{/* First Input */}
			<Grid item xs={6}>
				<FormControl fullWidth>
					<TextField
						label="Silence Name*"
						variant="outlined"
						size="small"
						value={silence.name}
						onChange={(e) => handleChange({ name: 'name', value: e.target.value })}
						sx={{
							width: 300,
							'& .MuiOutlinedInput-root': {
								'& fieldset': { borderColor: '#66B2FF' },
								'&:hover fieldset': { borderColor: '#66B2FF' },
								'&.Mui-focused fieldset': { borderColor: '#66B2FF' }
							}
						}}
					/>
				</FormControl>
			</Grid>

      <Grid item xs={6}>
				<FormControl fullWidth>
					<TextField
						label="Time Identifier*"
						variant="outlined"
						size="small"
						value={silence.timeIdentifier}
						onChange={(e) => handleChange({ name: 'timeIdentifier', value: e.target.value })}
						sx={{
							width: 300,
							'& .MuiOutlinedInput-root': {
								'& fieldset': { borderColor: '#66B2FF' },
								'&:hover fieldset': { borderColor: '#66B2FF' },
								'&.Mui-focused fieldset': { borderColor: '#66B2FF' }
							}
						}}
					/>
				</FormControl>
			</Grid>

			{/* Date Pickers */}
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Grid item xs={6}>
					<FormControl fullWidth>
						<DateTimePicker
							label="Start Time (UTC)*"
							value={silence.startTime}
							onChange={(newValue) => handleChange({ name: 'startTime' , value: newValue})}
              
							renderInput={(params) => (
                <TextField
                {...params}
                fullWidth
                sx={{
    
                  width: 300,
                  '& .MuiOutlinedInput-root': {
                    '& input': {
                      padding: '15px'
                    },
                    '& fieldset': { borderColor: '#66B2FF' },
                    '&:hover fieldset': { borderColor: '#66B2FF' },
                    '&.Mui-focused fieldset': { borderColor: '#66B2FF' }
                  },
                  '& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root input': {
                    padding: '9px'
                  }
                }}
              />
							)}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={6}>
					<FormControl fullWidth required>
						<DateTimePicker
							label="End Time (UTC)*"
							value={silence.endTime}
							onChange={(newValue) => handleChange({name: 'endTime' , value: newValue})}
							renderInput={(params) => (
								<TextField
									{...params}
									fullWidth
									sx={{
      
										width: 300,
										'& .MuiOutlinedInput-root': {
											'& input': {
												padding: '15px'
											},
											'& fieldset': { borderColor: '#66B2FF' },
											'&:hover fieldset': { borderColor: '#66B2FF' },
											'&.Mui-focused fieldset': { borderColor: '#66B2FF' }
										},
                    '& .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root input' : {
                      padding: '9px'
                    }
									}}
								/>
							)}
						/>
					</FormControl>
				</Grid>
			</LocalizationProvider>

			{/* Second Input */}
			<Grid item xs={12}>
				<FormControl fullWidth>
					<TextField
						label="Message"
						variant="outlined"
						size="small"
						value={silence.message}
						onChange={(e) => handleChange({ name: 'message', value: e.target.value })}
						sx={{
							width: '90%',
							'& .MuiOutlinedInput-root': {
								'& fieldset': { borderColor: '#66B2FF' },
								'&:hover fieldset': { borderColor: '#66B2FF' },
								'&.Mui-focused fieldset': { borderColor: '#66B2FF' }
							}
						}}
					/>
				</FormControl>
			</Grid>

			{/* Channel Selection as Radio Buttons */}
			<Grid item xs={12}>
				<Typography
					variant="body1"
					sx={{
						color: '#009BD8',
						textAlign: 'left',
						display: 'flex',
						alignItems: 'center'
					}}
				>
					Select Channels to be Silenced*
					<hr style={{ width: '65%', margin: '0 10px', borderColor: '#009BD8' }} />
				</Typography>				
        <Box display="flex" gap={2} mt={1}>
          <FormControlLabel
            control={<Checkbox checked={silencedChannels.email} onChange={handleChannelChange} name="email" />}
            label={<Typography color="white">Email</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox checked={silencedChannels.serviceNow} onChange={handleChannelChange} name="serviceNow" />
            }
            label={<Typography color="white">Service Now</Typography>}
          />
          <FormControlLabel
            control={<Checkbox checked={silencedChannels.teams} onChange={handleChannelChange} name="teams" />}
            label={<Typography color="white">Teams</Typography>}
          />
        </Box>
			</Grid>

			{/* Buttons */}
			<Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Typography
            style={{
              color: '#880000',
              cursor: 'pointer',
              marginTop: '6px',
              marginRight: '25px'
            }}					
          >
            {errorText ? `*${errorText}` : ''}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Typography
              style={{
                color: 'white',
                cursor: 'pointer',
                marginTop: '6px',
                marginRight: '25px'
              }}
              onClick={handleClose}
            >
              CANCEL
            </Typography>
            <Button variant="pepwiseSecondary" onClick={handleSaveSilence}>
              SAVE
            </Button>
          </Box>
        </Box>
			</Grid>
		</Grid>
	);
};
export default SilenceModal;
