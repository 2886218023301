import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Popover,
	Select,
	Stack,
	Typography
} from '@mui/material';
import { powerBIGatewayDetailData, powerBIGatewayMetricsData ,powerBIGatewayVisualizationData} from '../../service/service';
import { powerBICapacityDetailData } from '../../service/service';
import '../PowerBIObservability/powerBIObs.scss';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import { useSearchParams } from 'react-router-dom';
import { powerBICapacitySearch } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import Loader from 'components/Loader';
import NumberWidget from 'components/Widgets/NumberWidget';
import LoaderComponent from 'components/Loader';
import RowTypography from 'components/Table/RowTypography';
import StatusChip from 'components/Table/StatusChip';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import SectorCheckFilter from 'components/Dropdown/SectorCheckFilter';
import PageHeader from 'components/Header/Page';
import GatewayObservabilityLogo from 'assets/powerbi_gateway_obs.svg';
import CustomDropdown from 'components/Dropdown';
import ThroughputChart from './ThroughputChart';
import DateFilterDropdown from 'components/Dropdown/DateFilter';

const GatewayObservability = () => {
	interface Row {
        gatewayName: string;
		sector: string;
		vmName: string;
		networkThroughput: string;
		hosted: string;
		environment: string;
		reportsImpacted: string;
		datasourceType: string;
		datasourceName: string;
		datasourceStatus: string;
		scheduledRefreshers: string;
		averageDataProcessingDuration: string;
		countOfQueries: string;
		normalCompletionTime: string;
		actualCompletionTime: string;
		missedSLA: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
            {
				key: 'gatewayName',
				name: 'Gateway',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'sector',
				name: 'Sector',
				width: 190,
				minWidth: 190,
			},
			{
				key: 'vmName',
				name: 'VM Name',
				width: 150,
				minWidth: 150,
			},
			{
				key: 'networkThroughput',
				name: 'Data Throughput (MBps)',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'hosted',
				name: 'Hosted',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'environment',
				name: 'Environment',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'reportsImpacted',
				name: 'Reports Impacted',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'datasourceType',
				name: 'Data Source Type',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'datasourceName',
				name: 'Data Source',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'datasourceStatus',
				name: 'Data Source Status',
				width: 200,
				minWidth: 200,
                formatter: ({ row }) => <StatusChip status={row.datasourceStatus} />
			},
			{
				key: 'scheduledRefreshers',
				name: 'Schedule Refreshers',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'averageDataProcessingDuration',
				name: 'Average Data Processing Duration',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'countOfQueries',
				name: 'Count of Queries',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'normalCompletionTime',
				name: 'Normal Completion Time',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'actualCompletionTime',
				name: 'Actual Completion Time',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'missedSLA',
				name: 'Missed SLA',
				width: 180,
				minWidth: 180,
			},
		];
	}
	interface RowThroughput {
		gateway: string,
		source: string,
		throughput: {gateway: number; source: number}[],
		averageThroughput: string,
		currentThroughput: string,
	}

	function getColumnsThroughput(): Array<Column<RowThroughput>> {
		return [
			{
				key: 'gateway',
				name: 'Gateway',
				width: 350,
				minWidth: 350,
			},
			{
				key: 'source',
				name: 'Source',
				width: 350,
				minWidth: 350,
			},
			{
				key: 'throughput',
				name: 'Data Throughput',
				width: 450,
				minWidth: 450,
				cellClass: 'center-text',
				formatter: ({ row }) => (
					<ThroughputChart data={row.throughput} />
				  ), 
			},
			{
				key: 'averageThroughput',
				name: 'Average Throughput',
				width: 350,
				minWidth: 350,
				formatter: ({ row }) => `${row.averageThroughput} MBps`,
			},
			{
				key: 'currentThroughput',
				name: 'Current Throughput',
				width: 350,
				minWidth: 350,
				formatter: ({ row }) => `${row.currentThroughput} MBps`,
			}
		];
	}

	const [gatewayDetails, setGatewayDetails] = useState<Row[]>([]);
	const [throughputDetails, setThroughputDetails] = useState<RowThroughput[]>([]);
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [tableLoadingThroughput, setTableLoadingThroughput] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [drillDownStatusfilterInfo, setDrilldownStatusFilterInfo] = useState([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState<{ [key: string]: any }>({});
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const hasDrillDown = searchParams.has('isDrillDowned');
	const [chartOption, setChartOption] = useState<any>();
	const [gatewayStatusFilter, setGatewayStatusFilter] = useState<string | null>(null);
	const checkedListRef = useRef<any>([]);
	const [metricsData, setMetricsData] = useState<any>({
			totalGateways : 0,
			onlineGateways : 0,
			offlineGateways : 0,
			gatewaysWithPerformanceIssues : 0,
			missedSLARefreshes : 0,
			totalRefreshes: 0,
			growthRate: 0
		});
	const [customMonth, setCustomMonth] = useState('');
	const [filterMonth, setFilterMonth] = useState('0T');
	const [selectedMonth, setSelectedMonth] = useState('Today');	

	const defaultColumnNames = [
		'Gateway',
		'Sector',
		'VM Name',
		'Data Throughput (MBps)',
		'Hosted',
		'Environment',
		'Reports Impacted',
		'Data Source',
		'Data Source Type',
		'Data Source Status',
		'Schedule Refreshers',
		'Average Data Processing Duration',
		'Count of Queries',
		'Normal Completion Time',
		'Actual Completion Time',
		'Missed SLA'
	];

	const defaultColumnNamesThroughput = [
		'Gateway',
		'Source',
		'Data Throughput',
		'Average Throughput',
		'Current Throughput'
	];

	const handleOkClick = () => {
		const updatedCheckedList = [...checkedListRef.current]; // Just copying the checkedList

		if (updatedCheckedList.length > 0) {
			setCurrSectorFilter(updatedCheckedList);
		} else {
			setCurrSectorFilter([]);
		}
		// updateSharedValue({ sectorFilter: updatedCheckedList });
		// fetchData();
		setFilterInfo([]);
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';
		gatewayDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
		gatewayThroughputFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		//fetchCapacityData();
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		gatewayDetailsFunc(value, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
		gatewayThroughputFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		gatewayDetailsFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo),selectedMonth);
		gatewayThroughputFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		gatewayDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
		gatewayThroughputFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		console.log(filterInfo, 'filterinfo');
		gatewayDetailsFunc(1, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
		gatewayThroughputFunc(1, pageSize, sortingInfo, parseFilter(filterInfo))
	};
	// const handleSearch = (searchDetails: any, columnKey: any) => {
	// 	// incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	// };

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	async function fetchData() {

			try {
				let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
					? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
					: null;
				setLoading(true);
				const response = await powerBIGatewayMetricsData(storedSectorFilter);
				
				const totalRefreshes = (response.data.data.totalRefreshes && response.data.data.totalRefreshes.length > 0) ? 
				response.data.data.totalRefreshes?.reduce(
					(acc: number, item: {total_refreshcount: number}) => 
						acc + (item.total_refreshcount || 0),
					0
					) : 0;
	
				// Transform the data to match the expected structure
				if (response && response.data) {
					   const transformedData = {
					    totalGateways : response.data.data.totalGateways[0].TotalGateways || 0,
					   onlineGateways : response.data.data.onlineGateways || 0,
					   offlineGateways : response.data.data.offlineGateways || 0,
					   gatewaysWithPerformanceIssues: response.data.data.gatewaysWithPerformanceIssues[0].TotalDistinctGateways || 0,
					   missedSLARefreshes: response.data.data.missedSLARefreshes.length || 0,
					   totalRefreshes: totalRefreshes || 0,
					   growthRate: response.data.data.growthRate
					   };
					setMetricsData(transformedData);
				} else {
				}
			} catch (error) {
			} finally {
				setLoading(false);
			}
		}

	async function gatewayDetailsFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any, dateLabel: string) {
		// Reset page number if page size has changed
		if (pageSize !== size) {
			pageNumber = 1;
		}

		try {
			// Retrieve stored sector filter from localStorage
			let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
				: null;

			setTableLoading(true);
			let selectedDate = dateLabel ? dateLabel : null 
			let response;
			response = await powerBIGatewayDetailData(
				pageNumber.toString(),
				size.toString(),
				storedSectorFilter,
				sortingInfo,
				jobFilterInfo ? jobFilterInfo : null
			);
			
			// Check if response contains the necessary data
			const gatewayData = response?.data?.result || []; // Assuming 'result' contains the relevant data

			// Fetch the missedSLA data
			const metricsResponse = await powerBIGatewayMetricsData(storedSectorFilter);
			const missedSLARefreshes = metricsResponse?.data?.data?.missedSLARefreshes || []; // Array of missed SLA data
			
			const throughputResponse = await powerBIGatewayVisualizationData(storedSectorFilter);
			const throughputData = throughputResponse?.data?.data?.ThroughPutDataDetails;

			// Map throughput data to a structure where each item has the GatewayId and averageThroughput
			const throughputMap = throughputData.reduce((map: any, item: any) => {
				map[item.Gateway] = item.AverageThroughput_MBps || '0'; // Map Gateway to AverageThroughput
				return map;
			}, {});

			// Transform the fetched data into the desired structure
			const incidentData = gatewayData.map((item: any) => {
				// Find the corresponding missed SLA record for the current GatewayId
				const missedSLAForGateway = missedSLARefreshes.find(
					(missedSLA: { GatewayId: string }) => missedSLA.GatewayId === item.gatewayId
				);

			// Get the average throughput for the current gatewayId from throughputMap
			const networkThroughput = throughputMap[item.gatewayId] || '0'; // Default to '0' if not found
				return {
					incidentId: item.gatewayId, // Using GatewayId as incidentId for uniqueness
					gatewayName: item.gatewayName,
	                datasourceName: item.datasourceName,
	                datasourceType: item.datasourceType,
	                hosted: item.hosted,
	                sector: item.sector,
	                environment: item.environment,
	                datasourceStatus: item.datasourceStatus,
	                vmName: item.vmName,
	                scheduledRefreshers: item.scheduledRefreshers,
	                reportsImpacted: item.reportCount,
	                countOfQueries: item.countOfQueries,
	                averageDataProcessingDuration: item.averageDataProcessingDuration,
	                missedSLA: missedSLAForGateway ? missedSLAForGateway.TotalRecordCount : 0, // Assign value or 0 if not found
					actualCompletionTime: missedSLAForGateway ? missedSLAForGateway.ActualCompletionTime : 0, // Assign ActualCompletionTime or 0
					normalCompletionTime: missedSLAForGateway ? missedSLAForGateway.AvgCompletionTime : 0, // Assign AvgCompletionTime or 0,
					networkThroughput: networkThroughput
					};
				});
			

			setGatewayDetails(incidentData);

			// Update pagination data based on the response
			const paginationData = response.data.page;
			setPaginationData(paginationData); // Set pagination data directly from response
			
			// Update state for current page and size
			if (pageNumber > response.data.page.totalPages) {
				setPage(response.data.page.currentPage);
			} else {
				setPage(pageNumber);
			}
			setPageSize(size);
			setSortingInfo({ sortBy: sortingInfo.sortBy, sortDir: sortingInfo.sortDir });
			setFilterData(response?.data?.filterOptions);
		} catch (error) {
			console.error('Error fetching gateway data:', error);
		} finally {
			setTableLoading(false);
		}
	}

	async function powerBICapacitySearchFunc(searchDetails: any, columnKey: any) {
		const res = await powerBICapacitySearch(
			searchDetails,
			columnKey,
			parseFilter(filterInfo),
			checkedListRef.current,
			drillDownStatusfilterInfo
		);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}
	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails, columnKey);
		if (searchDetails?.length >= 3) powerBICapacitySearchFunc(searchDetails, columnKey);
	};

	// Delete below dummyData once API is set
	const dummyData = {
		months: ['Jan 24', 'Feb 24', 'Mar 24', 'Apr 24', 'May 24', 'Jun 24', 'Jul 24', 'Aug 24', 'Sep 24', 'This Month'],
		usageLevels: {
			'00-35%': [10, 15, 12, 13, 14, 11, 12, 13, 14, 15],
			'36-60%': [12, 10, 11, 10, 11, 12, 12, 11, 10, 12],
			'61-80%': [14, 16, 15, 14, 13, 14, 16, 15, 14, 13],
			'81-90%': [10, 12, 11, 10, 12, 11, 10, 12, 11, 12],
			'91%+': [10, 9, 11, 12, 10, 13, 11, 12, 10, 11]
		}
	};
	const fetchDummyData: () => Promise<typeof dummyData> = async () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(dummyData);
			}, 500);
		});
	};

	async function gatewayThroughputFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
		let sourceKind = '';
		try{
			// Reset page number if page size has changed
		if (pageSize !== size) {
			pageNumber = 1;
		}
		let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
			? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
			: null;
			setTableLoadingThroughput(true);
	
		// Fetching data from the API
		const response = await powerBIGatewayVisualizationData(storedSectorFilter);
		const throughPutData = response.data.data.ThroughPutDataDetails;
	
		// Mapping the data from API to the structure you need
		const formattedThroughputData: RowThroughput[] = throughPutData.map((item: any) => {
			const throughputArray: number[] = item.ThroughputChart.split(',').map(Number);
			const formattedDate = new Date(item.ExecutionDate).toLocaleDateString();
			const formattedThroughput = throughputArray.map((value, index) => ({
				gateway: formattedDate, 
				source: value, 
			  }));

			  //Clean and extract value for kind
			  try {
				const cleanedSource = item.Source.replace(/^\["/, '').replace(/"\]$/, '');
				const cleanedJsonWithComma = cleanedSource.replace(/:/g, ',').split(',');
				sourceKind = cleanedJsonWithComma[1].replace(/"/g, '').replace(/^\\|\\$/g, ''); // Remove the quotes around the value
            } catch (parseError) {
			}
			return {
				gateway: item.name,
				source: sourceKind,
				throughput: formattedThroughput,
				averageThroughput: item.AverageThroughput_MBps || '0',
				currentThroughput: item.CurrentThroughput_MBps || '0',
			};
		});
	
		// Update the state with the actual data
		setThroughputDetails(formattedThroughputData);
		paginationData.totalElements = throughPutData.length;  // Update totalElements based on the API response
		paginationData.totalPages = Math.ceil(throughPutData.length / size);  // Update totalPages based on the page size
		setPage(pageNumber);
		setPageSize(size);
		setSortingInfo(sortingInfo);
		}catch (error) {
		} finally {
			setTableLoadingThroughput(false);
		}
	}
	


	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		localStorage.removeItem('ml-summary-sector-filter');
		// let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
		// 	? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
		// 	: null;
		// 	console.log("Fetched Data1:", storedSectorFilter );
		// if (storedSectorFilter) {
		// 	setCheckedList(storedSectorFilter);
		// 	setCurrSectorFilter(storedSectorFilter);
		// }
        gatewayDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),selectedMonth);
		gatewayThroughputFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/powerBI/gatewayObservability/dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.remove('root');
			}
		}
		fetchData();
	}, []);

	const handleGatewayClick = (status: string) => {
		let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
				? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
				: null;
		setGatewayStatusFilter(status); 
		const statusArray:any =[];
		statusArray.push(status)
		if(filterInfo.sector)
		// setFilterInfo([]);
		setFilterInfo(prevFilterInfo => {
			return Object.keys(prevFilterInfo).reduce((newFilterInfo, key) => {
				newFilterInfo[key] = (key === 'sector' && storedSectorFilter) ? prevFilterInfo[key] : [];
				return newFilterInfo;
			}, {} as { [key: string]: any });
		});
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';
		if(status == 'Live'){
			updateFilterInfo('status', status);
			gatewayDetailsFunc('1', pageSize, sortingInfo, { status: statusArray },selectedMonth);
		}
		if(status == 'Offline'){
			updateFilterInfo('status', status);
			gatewayDetailsFunc('1', pageSize, sortingInfo, { status: statusArray },selectedMonth);
		}
		if(status == 'Yes'){
			updateFilterInfo('hasPerformanceIssue', status);
			gatewayDetailsFunc('1', pageSize, sortingInfo, { hasPerformanceIssue: statusArray },selectedMonth);
		}
		if(status == 'Scheduled'){
			updateFilterInfo('refreshType', status);
			gatewayDetailsFunc('1', pageSize, sortingInfo, { refreshType: statusArray },selectedMonth);
		}
		if(status){
			updateFilterInfo('missedSLA', status);
			gatewayDetailsFunc('1', pageSize, sortingInfo, { missedSLA: statusArray },selectedMonth);
		}
	};
	const updateFilterInfo = (key: string, value: any) => {
		setFilterInfo(prev => ({ ...prev, [key]: value }));
	};

	const infoCardData = [
		{
			title: 'Gateways Online',
			handleEvent: () => handleGatewayClick('Live'),
			number: metricsData.onlineGateways,
			totalNumber: metricsData.totalGateways,
			totalRefreshers: ''
		},
		{
			title: 'Gateways Offline',
			handleEvent: () => handleGatewayClick('Offline'),
			number: metricsData.offlineGateways,
			totalNumber: metricsData.totalGateways,
			totalRefreshers: ''
		},
		{
			title: 'Gateways With Performance Issue',
			handleEvent: () => handleGatewayClick('Yes'),
			number: metricsData.gatewaysWithPerformanceIssues,
			totalNumber: metricsData.totalGateways,
			totalRefreshers: ''
		},
		{
			title: 'Total Number of Refreshes (In Last 7 days)',
			handleEvent: () => handleGatewayClick('Scheduled'),
			number: '',
            totalNumber: '',
			totalRefreshers: metricsData.totalRefreshes ? metricsData.totalRefreshes.toLocaleString() : '',
		},
		{
			title: 'Dataset Refreshers Missed SLA',
			handleEvent: () => handleGatewayClick(metricsData.missedSLARefreshes),
			number: metricsData.missedSLARefreshes,
            totalNumber: '',
			totalRefreshers: ''
		}
	];

	const handleDateChange = (selectedDate: string) => {
		let dateLabel = '';
		switch(selectedDate){
			case '0T': dateLabel = 'Today';
				break;
			case '1T': dateLabel = 'Previous Day';
				break;
			case '0W': dateLabel = 'This Week';
				break;
			case '1W': dateLabel = 'Last Week';
				break;
			case '0M': dateLabel = 'This Month';
				break;
			case '1M': dateLabel = 'Last Month';
				break;
			case '3M': dateLabel = 'Last 3 Months';
				break;
			case '6M': dateLabel = 'Last 6 Months';
				break;	
			case '9M': dateLabel = 'Last 9 Months';
				break;
			case '0Y': dateLabel = 'This Year';
				break;
			case '1Y': dateLabel = 'Last Year';
				break;
			case '2Y': dateLabel = 'Last 2 Years';
				break;	
			default: dateLabel = selectedDate;
				break;	
		}
		setSelectedMonth(dateLabel);
	};
	
	return (
		<Stack mt="80px" width="100%">
			<PageHeader title="Gateway Observability" icon={GatewayObservabilityLogo} lastSync={''} iconSize={30}>
			<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={handleDateChange}
					boxProps={{ sx: { backgroundColor: 'primary.main',
						minHeight: '50px', 
					 } }}
					 showTodayOption={true}
					 showPreviousDayOption={true}
					 showThisWeekOption={true}
					 showLastWeekOption={true}
				/>
				<SectorCheckFilter
					sectorStorageName="ml-summary-sector-filter"
					handleOkClick={handleOkClick}
					checkedListRef={checkedListRef}
				/>
			</PageHeader>
			<Stack p={2} spacing={2}>
				<Grid container spacing={1}>
					<Grid item container xs={12} spacing={1}>
						{infoCardData.map((info, idx) => (
							<Grid key={info.title} item xs={12} md={4} sx={{ maxWidth: { md: '20%', xs: '100%' } }}>
                                <NumberWidget
									isLoading={loading}
									title={info.title}
									tooltipLabel=""
									handleEvent={info.handleEvent}
									ratioNumber={{
										number: info.number,
										totalNumber: info.totalNumber,
										growthRate: ''
									}}
									containerProps={{
										minHeight: '50px',
									}}
                                    refreshersRateUnitIcon={info.title ==  'Total Number of Refreshes (In Last 7 days)' ? 'upArrow' : '' }
                                    unitIconColor='green'
									refreshersRate={info.title ==  'Total Number of Refreshes (In Last 7 days)' ? metricsData.growthRate : ''}
									totalRefreshers={info.totalRefreshers}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
				{!tableLoading ? (
					<CustomizedDataGrid
						title="Details"
						tableColumns={getColumns()}
						tableData={gatewayDetails}
						tableType="gateway-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={gatewayDetails.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={tableLoading}
					/>
				) : (
					<Stack justifyContent="center" alignItems="center" minHeight="100px">
						<LoaderComponent />
					</Stack>
				)}
				{!tableLoadingThroughput ? (
					<CustomizedDataGrid
						title="Details"
						tableColumns={getColumnsThroughput()}
						tableData={throughputDetails}
						tableType="throughput-details-ver"
						defaultColumnNames={defaultColumnNamesThroughput}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={throughputDetails.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={tableLoadingThroughput}
						rowHeight={150} 
						headerRowHeight={35}
					/>
				) : (
					<Stack justifyContent="center" alignItems="center" minHeight="100px">
						<LoaderComponent />
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default GatewayObservability;
