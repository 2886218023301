import React, { useEffect, useRef, useState } from 'react';
import {
	Grid,
	Stack,
	Box,
} from '@mui/material';
import '../TableauObs/Summary/Summary.scss';
import { FetchFinOpsDetailsSummary, fetchFinOpsExecutiveSummary, FetchFinOpsResourceSummary, getDetailSummarySector, FetchFinOpsCostKpi } from '../../service/service';
import '../FineOps/finops.scss';
import ReactECharts from 'echarts-for-react';
import '../DataEstate/data-estate-dashboard.scss';
import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { CellExpanderFormatter } from '../cell_expander_formatter';
import { Link, useSearchParams } from 'react-router-dom';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import FilterAll, { FilterCheckedListType } from 'components/Dropdown/FilterAll';
import PageHeader from 'components/Header/Page';
import DetailsIcon from '../../assets/finop-detail.svg';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import DoughnutChartWidget from 'components/Widgets/DoughnutWidget';
import { formatNumberWithCommas } from 'utils/common-util';

const DetailsSummary = () => {	
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [DetailsCloudCost, SetDetailsCloudCost] = useState('-')
	const [years, setYears] = useState<any[]>([]);
    const [grafanaSummaryByResourceType, setGrafanaSummaryByResourceType] = useState<any>()	
	const [grafanaSummaryByResourceTypeLoading, setGrafanaSummaryByResourceTypeLoading] = useState(true);
	const [cloudCost, setCloudCost] = useState('');
	const [lastSyncDate, setLastSyncDate] = useState('');

	const seriesColor = [
		'#5470c6', 
		'#a2d9ce', 
		'#fac858', 
		'#ee6666',
		'#73c0de', 
		'#3ba272', 
		'#fc8452', 
		'#9a60b4', 
		'#ea7ccc', 
		'#4a90E2', 
		'#91cc75', 
	];
	  
	interface SeriesData {
		name: string;
		dataKey: string;
		color: string;
	}
	const [barSeriesData, setBarSeriesData] = useState<SeriesData[]>([]);
	
	const [groupingInfo, setGroupingInfo] = useState<any>({});

	const [doughnutTotal, setDoughnutTotal] = useState<any>();
	const [doughnutSeriesData, setDoughnutSeriesData] = useState<any>([]);

	const [grafanaAzureCostLoading, setGrafanaAzureCostLoading] = useState(true);
    const [grafanaAzureCostOptions, setGrafanaAzureCostOptions] = useState<any>();
	const [gridWidth, setGridWidth] = useState(window.innerWidth);
	function formatNumber(number:any) {
		if (number != null && !isNaN(number) && number !== 0) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return "0";
		}
	}

	function formatNumberOne(number:any) {
		if (number != null && !isNaN(number) && number !== 0) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
		} else {
			return "0";
		}
	}

	function formaNumberTwo(number:any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return "0.00";
		}
	}

	const formatNumberByUnits = (number: number) => {
		if (number >= 1000000) {
			return `${new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
				number / 1000000
			)}MM`;
		}
		if (number >= 1000) {
			return `${formatNumberWithCommas((number / 1000).toFixed(2), true)}K`;
		}
		return new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(number);
	};
	
	const callFetchFinOpsCostKpi = (groupFilters?: any) => {
		SetDetailsCloudCost('-');

		setGrafanaSummaryByResourceTypeLoading(true);
		let lmcg: boolean = false;
		if (searchParams.get('lmcg')) {
			lmcg = Boolean(searchParams.get('lmcg'));
		}
		FetchFinOpsCostKpi(groupFilters, lmcg)
			.then((res) => {
				let data = res.data.totalCost.YTDCost;
				let totalSum = res.data.totalCost.totalSum;

				// Transform data into ECharts series format
				let seriesData = Object.keys(data).map((team: string, index) => ({
				name: team,
				value: data[team],
				percentage: ((data[team] / totalSum) * 100).toFixed(2),
				formattedName: `${((data[team] / totalSum) * 100).toFixed(2)}%`
				}));

				const others = seriesData.filter((item: { name: string; }) => item.name === 'Others');
				seriesData = seriesData.filter((item: { name: string; }) => item.name !== 'Others');
				seriesData = seriesData.concat(others);

				seriesData = seriesData.map((item, index) => ({
					...item,
					color: seriesColor[index % seriesColor.length]
				}));

				setDoughnutTotal(formatNumberByUnits(totalSum));
				setDoughnutSeriesData(seriesData);
				setGrafanaAzureCostLoading(false);

				const costTrendData = res.data.chartData;
				const arrayData = res?.data?.chartData ? Object.entries(res.data.chartData).map(([key, value]) => ({
					[key]: value
				})) : [];
				// Extract all the months and teams
				const months = Object.keys(costTrendData);
				let teams: any[] = []; 
				if (costTrendData && costTrendData[months[0]]) {
					months.forEach(month => {
						const monthTeams = Object.keys(res.data.chartData[month]).filter(key => key !== 'TotalCost');
						monthTeams.forEach(team => {
							if (!teams.includes(team)) {
								teams.push(team);
							}
						});
					});
				}
				const othersVal = teams.filter(team => team === 'Others');
				teams = teams.filter(team => team !== 'Others');
				if(othersVal)
					teams = teams.concat(othersVal);

				const series = teams.map((team, index) => ({
					name: team,
					dataKey: team,
					color: seriesColor[index % seriesColor.length],
				}));
				setBarSeriesData(series);

				setGrafanaSummaryByResourceType(arrayData);
				setGrafanaSummaryByResourceTypeLoading(false);

			})
			.catch((error) => {
				console.error(error);
			});
	};
	
	async function getDetailsSummary() {
		let groupInfos:any= {};
		if (searchParams.get('sector')) {
			groupInfos['Sectors'] = [searchParams.get('sector')];
		}
		if (searchParams.get('programName')) {
			groupInfos['Program'] = [searchParams.get('programName')];
		}
		if (searchParams.get('team')) {
			groupInfos['Team'] = [searchParams.get('team')];
		}
		if (searchParams.get('consumedService')) {
			groupInfos['ConsumedService'] = [searchParams.get('consumedService')];
		}
		if (searchParams.get('environmentName')) {
			groupInfos['Environment'] = [searchParams.get('environmentName')];
		}
		if (searchParams.get('month')) {
			const monthParam = [searchParams.get('month')];
			const monthData = monthParam[0]?.split(',');
			groupInfos['Month'] = monthData;
		}
		if (searchParams.get('year')) {
			const yearParam = [searchParams.get('year')];
			const yearData = yearParam[0]?.split(',');
			groupInfos['Year'] = yearData;
		}
		else 
			groupInfos['Year'] = [new Date().getFullYear()];
		callFetchFinOpsCostKpi(groupInfos);

		// FetchFinOpsDetails('ytd')
		// 	.then((res) => {
		// 		let count = res?.data?.result[0]?.total_cost;
		// 		SetDetailsCloudCost((count / 1000000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		setGroupingInfo(groupInfos);
		detailTableFunc(page, pageSize, sortingInfo, groupInfos);
	}
	
// table

interface Row {
	projectName: string;
	totalCost: any,
	isExpanded?: boolean;
	isExpandedChild?: boolean;
	consumedservices:[];
	consumedservice:any,
	children: Row[];
}
  useEffect(() => {
    const handleResize = () => setGridWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
function getColumns(): Array<Column<Row>> {
	const columns = [
		{
			key: 'projectName',
			name: 'Project',
			minWidth: 200,
			width:gridWidth-((years.length+1)*135),
			// width: (years.length > 1) ? 499 : 600,
			formatter({ row, isCellSelected }: { row: Row, isCellSelected: boolean }) {
				const hasChildren = row.consumedservices;
				const hasChildrenofChild = row.consumedservice;
				return (
					<>
						{hasChildren && (
							<CellExpanderFormatter
								isCellSelected={isCellSelected}
								expanded={row.isExpanded === true}
								onCellExpand={() => {
									toggleSubRow(row.projectName,row.consumedservices);
								}}
							/>
						)}
						{'   '}
						<Link
							to=''
							state={row}
							style={{ paddingLeft: hasChildren ? '5px' : '10px', paddingRight: '10px' }}
						>
						     {row.projectName}
						</Link>
						{' '}
						<Link
							to=''
							state={row}
							style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px' }}
						>
						     {row.consumedservice}
						</Link>
					</>
				);
			}
		},
		{
			key: 'totalCost',
			name: 'Total',
			maxWidth:100,
			formatter({ row }: { row: Row }) {
				let formattedCost;
				if (row.totalCost >= 1000000) {
					formattedCost = `${formaNumberTwo((row.totalCost / 1000000))}MM`;
				} else if (row.totalCost >= 1000) {
					formattedCost = `${formaNumberTwo((row.totalCost / 1000))}k`;
				} else {
					formattedCost = formaNumberTwo(row.totalCost);
				}
				return (
					<Box textAlign='right'>${formattedCost}</Box>
				);
			}
		},
	];
    years.forEach(year => {
		if (!defaultColumnNames.includes(year)) {
            defaultColumnNames.push(year);
        }
        columns.push({
            key: year,
            name: year,
			maxWidth:100,
			// maxWidth: (years.length > 1) ? 300 : 335,
            formatter({ row }) {
				let formattedCost;
				const yearKey = year as keyof typeof row;
				const costValue = parseFloat(row[yearKey]?.toString() || '0');
				if (costValue >= 1000000) {
					formattedCost = `${formaNumberTwo((costValue / 1000000))}MM`;
				} else if (costValue >= 1000) {
					formattedCost = `${formaNumberTwo((costValue / 1000))}k`;
				} else {
					formattedCost = formaNumberTwo(costValue);
				}
				return (
					<Box textAlign='right'>${formattedCost}</Box>
				);
            }
        });
    });
	const totalIndex = defaultColumnNames.indexOf('Total');
	if (totalIndex > -1) {
		defaultColumnNames.splice(totalIndex, 1); 
	}
	defaultColumnNames.push('Total'); 
	return columns;
}

let projectName:any='';
function toggleSubRow(name: string,resourceTypes:any) {
	const rows = projectExpandList.current;
	const rowIndex = rows.findIndex((r: any) => r.projectName === name);
	const row = rows[rowIndex];
	const newRows = [...rows];
	let children: Row[] = [];
	let childRows: Row[] = [];
    projectName = name;
	if (!row.isExpanded) {
		if (resourceTypes.length>0) {
			childRows = resourceTypes;
		}
		children = childRows.map((x: any) => {
			return { ...x, isChildren: true };
		});
		newRows.splice(Number(rowIndex) + 1, 0, ...children);
		newRows[rowIndex] = { ...row, isExpanded: true, children };
		localStorage.setItem('boxRows', JSON.stringify(newRows));
		row.isExpanded = true;
	}
	else
	{
		children = row.children;
		newRows.splice(Number(rowIndex) + 1, children?.length);
		newRows[rowIndex] = { ...row, isExpanded: false };
		localStorage.setItem('boxRows', JSON.stringify(newRows));
		row.isExpanded = false;
	}
	projectExpandList.current = newRows;
	setProjectList([...newRows]);
}
function toggleSubOfSubRow(name: string,resourceType:string) {
	const rows = projectList;
	const rowIndex = rows.findIndex((r: any) => r.projectName === name);
	const row = rows[rowIndex];
	const newRows = [...rows];
	let children: Row[] = [];
	let childRows: Row[] = [];
	if (!row.isExpandedChild) {
		setLoading(true);
		const resourceList = FetchFinOpsResourceSummary(page, pageSize, name, resourceType);
		resourceList.then((res) => {
			setLoading(false);
			if (res.success) {
			   childRows = res.data.result;
			}
			children = childRows.map((x: any) => {
			 	return {...x,isChildren: true };
			});
			newRows.splice(Number(rowIndex) + 1, 0, ...children);
			newRows[rowIndex] = { ...row, isExpanded: row.isExpandedChild, isExpandedChild: row.isExpandedChild, consumedservice: resourceType, children };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			setProjectList(newRows);
		});
	}
	else
	{
		children = row.children;
		newRows.splice(Number(rowIndex) + 1, children.length);
		newRows[rowIndex] = { ...row, isExpanded: row.isExpandedChild, isExpandedChild: row.isExpandedChild };
		localStorage.setItem('boxRows', JSON.stringify(newRows));
		setProjectList(newRows);
	}
	row.isExpandedChild = !row.isExpandedChild;
}
const defaultColumnNames = ['Project','Total'];
const [projectList, setProjectList] = useState<Row[]>([]);
const projectExpandList = useRef<Row[]>([]);
const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
const [page, setPage] = useState(1);
const [pageSize, setPageSize] = useState(100);

const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
	detailTableFunc(value, pageSize, sortingInfo, groupingInfo);
};

const handlepageSizeChange = (event: any) => {
	detailTableFunc(page, event.target.value, sortingInfo, groupingInfo);
};

	const [selectedSite, setSelectedSite] = useState<string[]>([]);
	const [selectedEnvironment, setSelectedEnvironment] = useState<string>('all');
	const [selectedSector, setSelectedSector] = useState<string[]>([]);

	const fetchSectorOptionsData = async (
		filter: string,
		selectedProgram: { Sector: string | string[]; searchQuery: (string | undefined)[] }
	) => {
		if (filter === 'Program') {
			const res = await fetchFinOpsExecutiveSummary('program', selectedProgram);
			return res;
		} else {
			const res = await getDetailSummarySector(filter, selectedProgram);
			return res;
		}
	};

	const handleFilterApply = (
		groupInfos: Record<string, FilterCheckedListType[keyof FilterCheckedListType]>,
		checkedList: FilterCheckedListType
	) => {
		setGroupingInfo(groupInfos);
		callFetchFinOpsCostKpi(groupInfos);
		setLoading(true);
		let lmcg: boolean = false;
		if (searchParams.get('lmcg')) {
			lmcg = Boolean(searchParams.get('lmcg'));
		}
		FetchFinOpsDetailsSummary('', '', groupInfos, sortingInfo, lmcg).then((res) => {
			setLoading(false);
			setProjectList(res.data.result);
			projectExpandList.current = res.data.result;
			setPaginationData(res.data.page);
			setPage(page);
			setPageSize(pageSize);
			setSortingInfo(sortingInfo);
			const uniqueYears = new Set<string>();
			res.data.result.forEach((item: {}) => {
				Object.keys(item).forEach(key => {
					if (key !== 'projectName' && key !== 'totalCost' && key !== 'consumedservices') {
						uniqueYears.add(key);
					}
				});
			});
			const yearColumns = Array.from(uniqueYears).sort();
			setYears(yearColumns);
		});
		setSelectedSector(checkedList.sectors);
	};

	async function fetchLastSyncData() {
		fetchFinOpsExecutiveSummary('lastSync')
		.then((res) => {
			let lastSyncData = res?.data?.result[0].REFRESHDATE ?? '';
			setLastSyncDate(lastSyncData);
		})
		.catch((error) => {
			console.error(error);
		});
	}

	useEffect(() => {
		//TODO: remove DOM manipulation after all components change to mui without css
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/finops/details'|| window.location.pathname === '/dataestate/financialDetails') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		fetchLastSyncData();
		getDetailsSummary();
	}, []);

	const handleSorting = (sortingInfo: any) => {
		detailTableFunc(page, pageSize, sortingInfo, groupingInfo);
	};

	async function detailTableFunc(pageNumber: any, size: any, sortingInfo: any, groupInfo?: any) {
		setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		setLoading(true);
		let lmcg: boolean = false;
		if (searchParams.get('lmcg')) {
			lmcg = Boolean(searchParams.get('lmcg'));
		}
		const res = await FetchFinOpsDetailsSummary(pageNumber.toString(), size.toString(), groupInfo, sortingInfo, lmcg);
		if (res.success) {
			setLoading(false);
			setProjectList(res.data.result);
			projectExpandList.current = res.data.result;
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			const uniqueYears = new Set<string>();
			res.data.result.forEach((item: {}) => {
				Object.keys(item).forEach(key => {
					if (key !== 'projectName' && key !== 'totalCost' && key !== 'consumedservices') {
						uniqueYears.add(key);
					}
				});
			});
			const yearColumns = Array.from(uniqueYears).sort();
        	setYears(yearColumns);  
		}
	}
	
	return (
		<Box mt="80px" bgcolor='blue.darker'>
			{loading && <Loader />}
			<PageHeader title={[
					{
						text: 'Platform Cost Summary',
						link: '/D&AIXOps/platformCostSummary'
					},
					{
						text: 'AZURE DB DETAILS'
					}
				]} icon={DetailsIcon} lastSync={lastSyncDate} isDataRefresh = {true}
			>
				<FilterAll handleFilterApply={handleFilterApply} fetchSectorOptionsData={fetchSectorOptionsData} />
			</PageHeader>
			<Grid container spacing={2} p={3}>
				<Grid item xs={12} md={4}>
					<DoughnutChartWidget
						// title="Azure Cloud Cost (YTD)"
						title="Azure Cloud Cost"
						isLoading={grafanaAzureCostLoading}
						seriesData={doughnutSeriesData}
						totalText={`{small|Total Cost}\n{large|$${doughnutTotal}}`}
					/>
				</Grid>
				<Grid item xs={12} md={8}>
					<BarChartWidget
						title="Cost Summary (MoM)"
						isLoading={grafanaSummaryByResourceTypeLoading}
						data={grafanaSummaryByResourceType}
						seriesData={barSeriesData}
						isCost
						legendLabel="Programs"
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={projectList}
						tableType="finops-detail"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={projectList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						title="Detailed View"
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default DetailsSummary;
function team(value: string, index: number, array: string[]): value is string {
	throw new Error('Function not implemented.');
}

