import { update } from 'react-querybuilder';
import CustomAutocomplete from 'components/Inputs/CustomAutocomplete';
import { autocompleteSxProps } from './utilities';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import LoaderComponent from 'components/Loader';

const AsyncAutoComplete = ({ query, setQuery, path, fields, rule, fieldValueOptionsCallback, subscription, disabled }: any) => {
	const defaultOptions: any[] =
		fields[rule.field]?.fieldOptions?.options?.map((item: any) => ({
			value: item,
			label: item
		})) ?? [];
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [fetchedOptions, setFetchedOptions] = useState<any[]>(defaultOptions);
	const [primaryOptions, setPrimaryOptions] = useState<any[]>([]);
  	const isFetchOptionEnabled = !!fieldValueOptionsCallback && inputValue.length > 2

	const fetchOptions = async (query: string): Promise<any[]> => {
		const fieldsRes = await fieldValueOptionsCallback({ searchField: rule.field, searchText: query, subscription: subscription });
		if (fieldsRes.success) {
			const options = fieldsRes.data.result.filter(Boolean).map((item: any) => ({
				value: item,
				label: item
			}));
			return options;
		}
		return [];
	};

	const fetchValueOptions = async (): Promise<any[]> => {
		const subQuery = query?.rules?.find?.((rule: any) => 
			typeof rule === 'object' && rule?.field === 'Subscription'
		);
		const sub = query.rules[0]?.field === 'Subscription' ? query.rules[0]?.value : (subQuery ? subQuery.value : '');
		const fieldsRes = await fieldValueOptionsCallback({ searchField: rule.field, searchText: '', subscription: sub });
		if (fieldsRes.success) {
			const options = fieldsRes.data.result.filter(Boolean).map((item: any) => ({
				value: item,
				label: item
			}));
			return options;
		}
		return [];
	};

	const handleSubscriptionChange = async (val: any) => {
		const newVal = val?.value ?? '';
		const updatedQuery = update(query, 'value', newVal, path);
		const updatedRules = updatedQuery.rules.map((row: any) =>
			row.field &&
			row.field !== 'Subscription' 
			// && row.field !== 'PepWiseProgram' &&
			// row.field !== 'PepWiseProject'
				? { ...row, value: '' }
				: row
		);
		setQuery({
			...updatedQuery,
			rules: updatedRules,
		});
	};

	const handleFocus = () => {
		if (rule.field !== '' && rule.field !== 'Subscription') {
			setLoading(true);
			fetchValueOptions()
				.then((options) => {
					setFetchedOptions(options);
					setPrimaryOptions(options);
				})
				.finally(() => setLoading(false));
		}
	};
	
	useEffect(() => {
		if (inputValue.length < 3) {
			if (rule.field !== '') {
				setFetchedOptions(primaryOptions);
			}
		}
	}, [fields, rule]);

	useEffect(() => {
		if(rule.field !== ""){
		setLoading(true);
		fetchOptions(inputValue)
			.then((options) => {
				setFetchedOptions(options)
				setPrimaryOptions(options)
			})
			.finally(() => setLoading(false));
		}
	}, []);

	useEffect(() => {
		if (rule.field !== '') {
			setLoading(true);
			fetchOptions(inputValue)
				.then((options) => {
					setFetchedOptions(options);
					setPrimaryOptions(options);
				})
				.finally(() => setLoading(false));
		}
	}, [rule.field]);

	useEffect(() => {
		if (isFetchOptionEnabled) {
			setLoading(true);
			fetchOptions(inputValue)
				.then((options) => setFetchedOptions(options))
				.finally(() => setLoading(false));
		} else {
			setFetchedOptions(primaryOptions);
		}
	}, [inputValue]);

	return (
		<CustomAutocomplete onFocus={handleFocus} disabled = {disabled}
			// freeSolo
			noOptionsText="No data found"
			{...(isFetchOptionEnabled && { filterOptions: (x) => x })}
			onInputChange={(_e, value, reason) => {
				if (!rule.field) {
					return;
				}
				if(reason === 'input'){
					setInputValue(value);
					setQuery(update(query, 'value', value, path));
				}
			}}
			onChange={(_e, val) => {
				if (!rule.field) {
					return;
				}
				if (rule.field === 'Subscription') {
					handleSubscriptionChange(val);
				} else 
					setQuery(update(query, 'value', val?.value ?? '', path));
			}}
			options={loading ? [''] : fetchedOptions}
			value={rule.value}
			sx={autocompleteSxProps}
			renderOption={(props, option) => {
				const { key, ...optionProps } = props;
				return (
					<Box component="li" key={key} {...optionProps}>
						{loading ? <LoaderComponent size={25} /> : <Typography>{option?.label ?? ''}</Typography>}
					</Box>
				);
			}}
		/>
	);
};

export default AsyncAutoComplete;
