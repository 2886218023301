import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import PageHeader from 'components/Header/Page';
import QueryIcon from 'assets/query.svg';
import PepWiseQueryBuilder from 'components/QueryBuilder';
import TableSection from './TableSection';
import { inputFieldType, getOperators } from '../../QueryBuilder/utilities';
import { getFinopsResourceMappingList } from 'service/service';
import LoaderComponent from 'components/Loader';
import { useSearchParams } from 'react-router-dom';
import DashboardIcon from '../../../assets/material-symbols_dashboard.svg';

const ResourceMapping = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [programId, setProgramId] = useState<any>('');
	const [projectId, setProjectId] = useState<any>('');
	const [programName, setProgramName] = useState<any>('');
	const [projectName, setProjectName] = useState<any>('');
	const [keyFields, setKeyFields] = useState({});
	const [loading, setLoading] = useState(true);
	const [valueField, setValueField] = useState<any>({});
	const [totalPages, setTotalPages] = useState(0);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });

	const fetchFieldOptions = async (fieldKey: string, subscription?: string) => {
		try {
			const fieldsRes = await getFinopsResourceMappingList({ searchField: fieldKey, subscription: subscription });
			if (fieldsRes.success) {
				const options = fieldsRes.data.result.map((x: any) => {
					return x === null ? '' : x;
				});
				setKeyFields((prevFields: any) => ({
					...prevFields,
					[fieldKey]: {
						...prevFields[fieldKey],
						fieldOptions: {
							...(prevFields[fieldKey]?.fieldOptions || {}),
							options
						}
					}
				}));
				return options;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const fetchKeyFieldsData = async () => {
		try {
			const fieldsRes = await getFinopsResourceMappingList({ searchField: 'keyFields' });
			if (fieldsRes.success) {
				const keyFieldRes = fieldsRes.data.result;
				const fieldOptions = {
					autocomplete: [
						'Resource',
						'ResourceGroup',
						'Subscription',
						'MeterCategory',
						'BusinessSector',
						'ClusterName',
						'Sector',
						'Environment',
						'ConsumedService',
						'Program',
						'ProjectName',
						'PepWiseSector',
						'PepWiseEnvironment',
						'PepWiseProgram',
						'PepWiseProject'
					],
					textfield: [''],
					boolean: ['']
				};
				const fields = await Promise.all(
					keyFieldRes.map(async (field: string) => {
						const fieldType = fieldOptions.autocomplete.includes(field)
							? inputFieldType.autocomplete
							: fieldOptions.textfield.includes(field)
							? inputFieldType.textfield
							: inputFieldType.autocomplete;
						return {
							[field]: {
								name: field,
								label: field.replace(/([a-z])([A-Z][a-z]+)$/, '$1 $2').trim(),
								fieldOptions: {
									fieldInput: fieldType,
									options: null,
									operators: fieldOptions.boolean?.includes(field) ? getOperators('boolean') : getOperators('string'),
									valueType: fieldOptions.boolean?.includes(field) ? 'boolean' : 'string'
								}
							}
						};
					})
				);
				return fields.reduce((acc, field) => ({ ...acc, ...field }), {});
			}
		} catch (error) {
			console.error('Error fetching fields:', error);
			return {};
		}
	};

	const fetchFields = async () => {
		setLoading(true);
		const fieldsData = await fetchKeyFieldsData();
		setKeyFields(fieldsData);
		setLoading(false);
	};

	const fetchValue = async () => {
		setLoading(true);
		const newValueField = await fetchFieldOptions ("Subscription");
		setValueField(newValueField);
		setLoading(false);
	};

	useEffect(() => {
		//TODO: remove DOM manipulation after all components change to mui without css
		const activeId = document.getElementById('root');
		if (activeId) {
			document.head.classList.add('theme-bg-blue');
			document.body.classList.add('theme-bg-blue');
		}
		if (searchParams.get('programId')) 
			setProgramId(searchParams.get('programId'));
		if (searchParams.get('projectId')) 
			setProjectId(searchParams.get('projectId'));
		if (searchParams.get('programName')) 
			setProgramName(searchParams.get('programName')?.replace(/%20/g, ' '));
		if (searchParams.get('projectName')) 
			setProjectName(searchParams.get('projectName')?.replace(/%20/g, ' '));
		else
			setQueryProps({
				isSearch: false,
				query: ''
			});
		fetchFields();
		if (!searchParams.get('programId')) 
			fetchValue();
	}, []);

	const [queryProps, setQueryProps] = useState({
		isSearch: false,
		query: ""
	});
	const [queryPreference, setQueryPreference] = useState('Resource_flat');
	const queryPreferenceOptions = [
		{
			label: 'Tree List of Resource Groups',
			value: 'ResourceGroup_tree'
		},
		{
			label: 'Flat List of Resources',
			value: 'Resource_flat'
		},
		{
			label: 'Tree List of DBSQL Clusters',
			value: 'SqlEndpointId_tree'
		},
		{
			label: 'Tree List of Interactive Clusters',
			value: 'ClusterName_inttree'
		},
		{
			label: 'Tree List of Job Clusters',
			value: 'ClusterJobSource_jobtree'
		}
	];
	
	return (
		<Box mt="70px" bgcolor="blue.darker">
			{searchParams.get('programName') ? 
			<PageHeader
				icon={DashboardIcon}
				title={[
					{
						text: 'Programs',
						link: '/finops/resourcemapping/programs'
					},
					{
						text: programName,
						link: `/finops/resourcemapping/programs/${programId}/${encodeURIComponent(programName)}`
					},
					{
						text: projectName,
						link: `/finops/resourcemapping/projects/${programId}/${projectId}/${encodeURIComponent(programName)}/${encodeURIComponent(projectName)}`
					},
					{
						text: 'Search & Allocate'
					}
				]}
			/> : 
			<PageHeader title="Search & Allocate" icon={QueryIcon} />}
			<Stack p={2} spacing={2}>
				{loading ? (
					<LoaderComponent size={50} pt={10} />
				) : (
					<>
						<PepWiseQueryBuilder
							valueField={valueField}
							fields={keyFields}
							setQueryProps={setQueryProps}
							queryPreference={queryPreference}
							setQueryPreference={setQueryPreference}
							queryPreferenceOptions={queryPreferenceOptions}
							loadOptionsForField={fetchFieldOptions}
							fieldValueOptionsCallback={getFinopsResourceMappingList}
							setTotalPages = {setTotalPages}
							sortingInfo={sortingInfo} 
        					setSortingInfo={setSortingInfo}
						/>
						<TableSection queryPreference={queryPreference} fields={keyFields} queryProps={queryProps} isAddResource={true} totalPages= {totalPages} setTotalPages = {setTotalPages} sortingInfo={sortingInfo} 
        				setSortingInfo={setSortingInfo}/>
					</>
				)}
			</Stack>
		</Box>
	);
};

export default ResourceMapping;
