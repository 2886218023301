import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Chip,
	FormControlLabel,
	IconButton,
	Menu,
	MenuItem,
	Switch,
	Typography
} from '@mui/material';
import { CustomizedDataGrid } from '../customized_data_grid';
import DialogueModal from 'components/Modal/DialogModal';
import { Column } from 'react-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteConfirmationDialog from './DeleteModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { ChannelRow } from './createAlert';
import ChannelModal from './ChannelModal';

export const ChannelsTab = ({ channels, setChannels }: any) => {
    const [showChannelModal, setShowChannelModal] = useState(false);
    const defaultColumnNames = ['Template Name', 'Channel', 'Subject', 'Recipient', 'Status', 'Action'];
    const [selectedChannel, setSelectedChannel] = useState<ChannelRow|null>(null);

    const handleManageDeliveryChannel = (type: string, channelConfig: any) => {
        setSelectedChannel(null)
        if(type === 'Service Now'){
            channelConfig.payload = JSON.parse(JSON.stringify(channelConfig.payload))
        }
		setChannels({ ...channels, [type]: { ...channelConfig, channel: type } });
		setShowChannelModal(false);
	};

    function getColumns(): Array<Column<ChannelRow>> {
		return [
		  {
			key: "templateName",
			name: "Template Name",
			minWidth: 150,
			width: 308,
		  },
		  {
			key: "channel",
			name: "Channel",
			width: 200,
			minWidth: 150,
		  },
		  {
			key: "emailSubject",
			name: "Subject",
			minWidth: 95,
		  },
		  {
            key: "emailRecipients",
            name: "Recipient",
            minWidth: 95,
            formatter({ row }: any) {
                const recipients = Array.isArray(row.emailRecipients) ? row.emailRecipients.join(', ') : '';
                return (
                    <span>{recipients}</span>
                );
            },
          },
		  {
			key: "isEnabled",
			name: "Status",
			minWidth: 120,
			formatter({ row }: any) {
			  return (
				<Chip
					style={{ minWidth: 64, backgroundColor: row.isEnabled ? "#01C00826" : "#D82B5133", marginTop: 2 }}
				  	label={row.isEnabled ? "ACTIVE" : "INACTIVE"}
				/>
			  );
			},
		  },
		  {
			key: "action",
			name: "Action",
			formatter({ row }: any) {
			  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
			  const [openDialog, setOpenDialog] = useState(false);
			  const [channelToDelete, setChannelToDelete] = useState("");
	  
			  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
				setAnchorEl(event.currentTarget);
			  };
	  
			  const handleClose = () => {
				setAnchorEl(null);
			  };
	  
			  const handleToggleStatus = () => {
				const channel = channels[row.channel];
				setChannels({
				  ...channels,
				  [row.channel]: { ...channel, isEnabled: !row.isEnabled },
				});
				handleClose();
			  };
	  
			  const handleDeleteClick = () => {
				setChannelToDelete(row.channel); // Store selected channel
				setOpenDialog(true); // Open confirmation dialog
				handleClose();
			  };
	  
			  const handleDeleteConfirm = () => {
				const updatedChannels = { ...channels };
				delete updatedChannels[channelToDelete]; // Remove channel
				setChannels(updatedChannels);
				setOpenDialog(false); // Close dialog after delete
			  };
	  
			  return (
				<>
				  {/* Action Buttons */}
				  <Box sx={{ display: "flex", alignItems: "center" }}>
					<IconButton
					  aria-label="edit"
					  size="small"
					  onClick={() => {
						setSelectedChannel(channels[row.channel]);
						setShowChannelModal(true);
					  }}
					  sx={{ color: "#71758F" }}
					>
					  <EditIcon fontSize="small" />
					</IconButton>
	  
					<IconButton
					  aria-label="more"
					  size="small"
					  onClick={handleClick}
					  sx={{ color: "#71758F" }}
					>
					  <MoreVertIcon fontSize="small" />
					</IconButton>
	  
					<Menu
					  anchorEl={anchorEl}
					  open={Boolean(anchorEl)}
					  onClose={handleClose}
					  sx={{
						"& .MuiMenu-paper": {
						  backgroundColor: "#003771 !important",
						  width: "12%",
						},
					  }}
					>
					  <MenuItem onClick={handleDeleteClick} sx={{ marginLeft: "25px" }}>
						<DeleteIcon />
						<span style={{ fontSize: "1.0rem", marginLeft: "6px" }}>Delete</span>
					  </MenuItem>
					  <MenuItem onClick={handleToggleStatus} sx={{ marginLeft: "25px" }}>
						<FormControlLabel
						  control={
							<Switch checked={row.isEnabled} size="small" color="default" />
						  }
						  label={row.isEnabled ? "Deactivate" : "Activate"}
						/>
					  </MenuItem>
					</Menu>
				  </Box>
	  
				  {/* Delete Confirmation Dialog */}
				  <DeleteConfirmationDialog
					open={openDialog}
					onClose={() => setOpenDialog(false)}
					onConfirm={handleDeleteConfirm}
					silenceName={channelToDelete}
				  />
				</>
			  );
			},
			minWidth: 200,
		  },
		];
    }
    return (
        <Box sx={{ mt: 3, padding: '20px', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="body2" sx={{ opacity: 0.7 }}>
                    ⓘ This section is to create multiple delivery channels for the alert.
                </Typography>
                <Button
					variant="outlined"
                    color="secondary"
                    onClick={() => setShowChannelModal(true)}
                    disabled={Object.keys(channels).length === 3}
                >
                    CREATE CHANNEL
                </Button>
            </Box>
            <CustomizedDataGrid
                tableColumns={getColumns()}
                tableData={Object.values(channels)}
                tableType="Alert"
                defaultColumnNames={defaultColumnNames}
                listLength={Object.keys(channels).length}
                isDateRangeFilter={false}
                showExport={false}
                isShowMoreButton={false}
                page={1}
                pageSize={0}
                paginationData={0}
            />
            <DialogueModal
                title={selectedChannel ? 'Edit Channel' : 'Create Channel'}
                isOpen={showChannelModal}
                handleClose={() => {
                    setShowChannelModal(false);
                    setSelectedChannel(null);
                }}
            >
                <ChannelModal
                    selectedChannel={selectedChannel}
                    channelOptions={['Email', 'Service Now', 'Teams'].filter(
                        (channel) => !Object.keys(channels).includes(channel)
                    )}
                    handleClose={() => {
                        setShowChannelModal(false);
                        setSelectedChannel(null);
                    }}
                    onAddChannel={handleManageDeliveryChannel}
                />
            </DialogueModal>
        </Box>
    )
}