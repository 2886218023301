import {  Grid } from '@mui/material';
import dataPipelineLogo from '../../assets/data-pipeline-logo.svg';
import PlatformCostLogo from '../../assets/ri_money-dollar-circle-fill.svg';
import CustomDashboardIcon from '../../assets/Custom Dashboard_Small.svg';
import { useEffect, useState, lazy, Suspense } from 'react';
// import { getDecodedToken, role, capabilities } from '../../service/service';
import FinOpsLogo from '../../assets/FinOps_small.svg';
import landingBackArrow from '../../assets/landingBackArrow.svg';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import IBPLogo from '../../assets/ibp.svg';

// Lazy load components
const Header = lazy(() => import('./Header'));
const Footer = lazy(() => import('../LandingPage/ModuleLandingFooter'));
const capabilitiesNew : any = localStorage.getItem('capabilities');



export default function ProgramExecutiveLanding() {
	const [memberData, setMemberData] = useState<any>(null);
	const [controller, _] = usePepwiseAuthController();
	const { accessToken, capabilities } = controller;
	const capabilitiesArray = capabilitiesNew.split(',')

	// to display data
	useEffect(() => {
		// console.log(moment(sd).diff(end, 'days'))
		// const tokenData = getDecodedToken();
		// console.log('token', accessToken);
		setMemberData(accessToken);
	}, []);

	//navigate to data-pipeline observability
	const handleDataPipelineClick = (event: any, type: string, url: string) => {
		event.preventDefault(); // Prevent the default context menu from showing up
		if (type === 'blank') {
			// Open the URL in a new tab if type is 'blank'
			window.open(url, '_blank');
		} else {
			// Navigate to the provided URL in the current tab
			window.location.href = url;
		}
	};

	const handleBackClick = () => {
		window.location.href = '/LandingPage';
	};


	return (
		<div className="outerLandingPageContainer">
			<Header memberData={memberData} />
			<div style={{ maxWidth: '86%', margin: '0 auto' }}>
				<div className="landingMessage-container">
					<img src={landingBackArrow} alt="CCLogo" className="landingMessageImage" onClick={handleBackClick} />
					<p className="landingMessage">{`Program Executive Dashboard`}</p>
				</div>

				<div className="welcome_text">
					<p>Click on the module below to monitor!</p>
				</div>
				<Grid container spacing={1} style={{ marginTop: '0px' }}>
					{capabilitiesArray.includes('IBP') ? (
						<Grid
							item
							xs={4}
							style={{ cursor: 'pointer' }}
							onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/ibp/dashboard')}
							onContextMenu={(e) => {
								e.preventDefault();
								handleDataPipelineClick(e, 'blank', '/ibp/dashboard');
							}}
						>
							<div className="observalbility_screens" style={{ textAlign: 'center' }}>
								<div className="title">
									<img
										src={IBPLogo}
										alt="CCLogo"
										className="obs_logo"
										style={{ display: 'block', margin: '0 auto' }}
									/>
								</div>
								<div>
									<h3 className="obsTitle" style={{ margin: '10px 0' }}>
									Integrated Business Planning (IBP)
									</h3>
								</div>
								<p
									className="message1"
									title="Monitoring the IBP Turkey data landscape across the EDAP platforms leveraged."
									style={{ margin: '0', textAlign: 'center' }}
								>
									Monitoring the IBP Turkey data landscape across the EDAP platforms leveraged.
								</p>
							</div>
						</Grid>
					) : (
						''
					)}
					{capabilitiesArray.includes('IBP') ? (
					<Grid
						item
						xs={4}
						onClick={(e) => handleDataPipelineClick(e, 'no-blank', '/mdip/dashboard')}
						onContextMenu={(e) => {
							e.preventDefault();
							handleDataPipelineClick(e, 'blank', '/mdip/dashboard');
						}}
					>
						<div className="observalbility_screens" style={{ textAlign: 'center' }}>
							<div className="title">
								<img
									src={IBPLogo}
									alt="tableauLogo"
									className="obs_logo"
									style={{ display: 'block', margin: '0 auto' }}
								/>
							</div>
							<div>
								<h3 className="obsTitle" style={{ margin: '10px 0' }}>
								MDIP Executive Dashboard
								</h3>
							</div>
							<p
								className="message1"
								title="Real-time dashboard-key metrics & insights for strategic decisions."
								style={{ margin: '0', textAlign: 'center' }}
							>
								Real-time dashboard-key metrics & insights for strategic decisions.
							</p>
						</div>
					</Grid>
					) : (
						''
					)}
				</Grid>
			</div>
			<Footer />
		</div>
	);
}
