import { Box, Tab, Tabs, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const tabs = [
	{ label: 'Summary', path: '/D&AIXOps/executiveView/Summary' },
	{ label: '2024 Operational Metrics', path: '/D&AIXOps/executiveView/2024OperationalMetrics' },
	{ label: 'Incidents', path: '/D&AIXOps/executiveView/Incidents' },
	{ label: 'Change Requests', path: '/D&AIXOps/executiveView/ChangeRequests' },
	{ label: 'Service Requests', path: '/D&AIXOps/executiveView/ServiceRequests' }
];

const ExecutiveTabs = () => {
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(0);

	useEffect(() => {
		const currentTab = tabs.findIndex((tab) => tab.path === location.pathname);
		if (currentTab !== -1) {
			setActiveTab(currentTab);
		}
	}, [location.pathname]);

	return (
		<Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, ml: 2 }}>
			<Tabs
				value={activeTab}
				onChange={(_, newValue) => setActiveTab(newValue)}
				indicatorColor="primary"
				textColor="primary"
				variant="fullWidth"
				sx={{
					width: '100%',
					'.MuiTab-root': {
						backgroundColor: 'rgba(0, 55, 113, 1)',
						color: '#FFF',
						textTransform: 'none',
						minHeight: '48px',
						fontSize: '0.95rem',
						paddingTop: '10px',
						paddingBottom: '6px'
					},
					'.Mui-selected': {
						color: 'rgba(0, 155, 216, 1) !important',
						backgroundColor: '#1b1e35 !important',
						borderBottom: '3px solid rgba(0, 155, 216, 1) !important'
					}
				}}
			>
				{tabs.map((tab, index) => {
					const isComingSoonTab = tab.label === 'Change Requests' || tab.label === 'Service Requests';

					const tabLabel = isComingSoonTab ? (
						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<Typography>{`${tab.label} – `}</Typography>
							<Box
								sx={{
									backgroundColor: 'rgb(150, 151, 165)',
									borderRadius: '20px',
									ml: '5px'
								}}
							>
								<Typography
									sx={{
										fontSize: '12px',
										fontWeight: 700,
										color: "#FFFFFF",
										p: '3px 3px 2px 3px',
										mr: '3px',
										ml: '3px',
									}}
								>
									COMING SOON
								</Typography>
							</Box>
						</Box>
					) : (
						tab.label
					);
					return <Tab key={index} label={tabLabel} component={Link} to={tab.path} />;
				})}
			</Tabs>
		</Box>
	);
};

export default ExecutiveTabs;
